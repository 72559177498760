<div class='row padding-10'>
	<div class='col-md-12 col-sm-12 col-xs-12 mb-2 text-right doNotPrint'>
		<!-- <div *ngIf="hasSentiment==false">
			<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5 " 
			(click)="Upgradeunlock()"><i class="fa fa-lock" aria-hidden="true"></i>
				&nbsp;Upgrade to access</button>
		</div> -->
		<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5 "
		*ngIf="hasSentiment==true" (click)="openEmployeeQR()"><i class='fa fa-plus '></i>&nbsp;&nbsp;Create Employee QRCode</button>
	</div>
	<div class='col-md-12 col-sm-12 col-xs-12'>
		<div class="row">
			<div class="col-md-6" style="min-height: 390px !important;">
				<mat-card class="text-center">
					<div id="print-section">
						<!--Your html stuff that you want to print-->
						<div class='text-1-1x'>
							Give Your feedback
						</div>
						<div class='text-1-3x text-bold'>
							{{companyName}}
						</div>
						<!--  -->
						<qrcode #parent [qrdata]="layoutUrl" [width]="512" [level]="'M'"></qrcode>
					</div>
					<!-- <button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5 doNotPrint" (click)="print()" >
						Print QR Code Image</button> -->
					<!-- <button class='btn btn-primary'>Download QR Code Image</button> -->
					<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5 doNotPrint"
						(click)="saveAsImage(parent)">
						Download QR Code Image</button>
				</mat-card>
			</div>

			<div class="col-md-6" style="min-height: 390px !important;">
				<mat-card style="min-height: 390px !important;"> 
					<div *ngIf="hasSentiment==false">
						<!--  [ngStyle]="{'filter' : hasSentiment == false? 'blur(3px)' : 'blur(0px)'}" -->
						<div>
							<img style="width: 100% !important;" src='../../../../../assets/media/sentiment-img/Create-employee-QRcode-02.svg' />
						</div>
						<br/>
						<div style="text-align: center;">
							<button class="btn btnWarn" (click)="Upgradeunlock()"><i class="fa fa-lock" aria-hidden="true"></i>
								&nbsp;Upgrade to access</button>
						</div>
						<!-- <div [ngClass]="{'comingSoon cursor-pointer' : hasSentiment == false}" (click)="Upgradeunlock()">
							<div [ngStyle]="{'filter' : hasSentiment == false? 'blur(3px)' : 'blur(0px)'}" >
								<img 
								style="width: 460px !important;height: 353px !important;" src='../../../../../assets/media/sentiment-img/Create-employee-QRcode.jpg' />
							</div>
						</div> -->
					</div>

					<div class="table-container" *ngIf="hasSentiment==true">
						<table class="table emp">
							<thead >
								<tr class="text-bold" style="font-family: system-ui;">
									<th >Employee Name</th>
									<th >Department</th>
									<th >Download</th>
								</tr>
							</thead>
							
							<tr *ngFor="let item of tempArr;let i = index">
								<td>
									{{item.employeeName}}
								</td>
								<td>
									{{item.department}}
								</td>
								<td class="text-center">
									<span class="qrcodeSty">
										<qrcode #parent [width]="1024" [hight]="1024" [qrdata]="item.landingPageURL"
											[level]="'M'">
										</qrcode>
									</span>
										<i class="fas fa-download cursor-pointer" style="color: #368cd7;" (click)="saveQRAsImage(parent,item.employeeName)"></i>
								</td>
							</tr>

							<tr class="text-center" *ngIf="tempArr == ''">
								<td colspan="3" style="font-size: 20px; text-align: center;"> No data available</td>
							</tr>
						</table>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</div>