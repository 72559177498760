<div class="parentDiv">
<div class="bg">
  <!-- kt-error_container -->
  <div class="" style="opacity: 0;">
    <!-- kt-error_title -->
    <div class="">
      <h1>{{title}}</h1>
    </div>
    <!-- kt-error_subtitle -->
    <p class="">
      {{subtitle}}
    </p>
    <!-- kt-error_description -->
    <p class="" [innerHTML]="desc"></p>
  </div>
</div>
</div>
