import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'kt-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent{
  @Input() type = 'error-v5';
  // full background image
  @Input() image = '';
  // error code, some error types template has it
  @Input() code = '404';
  // error title
  @Input() title = 'Oops!';
  // error subtitle, some error types template has it
  @Input() subtitle = 'Something went wrong here';
  // error descriptions
  @Input() desc = 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator';
  // return back button title
  @Input() return = 'Return back';

  @HostBinding('class') classes: string = 'kt-grid kt-grid--ver kt-grid--root';
  // constructor() { }

  // ngOnInit() {
  //   scope.pageService.setTitle('Dashboard')
  // }

}
