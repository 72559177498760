<div class='col-12'>
	<div class='row padding-15'>
		<div class='col-11 text-1-2x text-bold'>
			Email Template
		</div>
		<div class='col-1 text-1-2x '>
			<i class="fa fa-times cursor-pointer" (click)='dialogRef.close()'></i>
		</div>
	</div>
	<div class='col-12'>
		<mat-divider></mat-divider>
	</div>
	<div class='row padding-15'>
		<div class='col-12'>
			<div class='back' >
				<mat-card class=' myHTMl' >
				</mat-card>
			</div>
		</div>
	</div>
</div>
