// Angular
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from  './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {

	constructor(private router: Router,
		public http: HttpClient){

	}
	logout(){
		var accessKey = localStorage.getItem(environment.authTokenKey);
		let data={Key:accessKey
		}
		localStorage.clear();
		//location.reload();

		this.http.post(environment.apiPath + 'Logout',data)
			///// this.http.get(environment.apiPath + 'test')
			.subscribe((res: any) => {
				debugger;
				if (res['success']) {
					setTimeout(() => {
						//this.router.navigate(['/auth/login'])
						location.reload();
				}, 500);
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					//this.pageService.showError('Oops!', err.error.errorMsg);
				}
			});

		setTimeout(() => {
				//this.router.navigate(['/auth/login'])
				location.reload();
		}, 500);
	}
}
