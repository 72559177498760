import { DataTableService } from './../../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';


// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/internal/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
// Api
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
declare function callPostApi(any1, any2): any
import { map } from 'rxjs/internal/operators';
declare function phoneText(any1, any2, any3, any4, any5): any
declare var gr;
declare var gtag

declare global {
	interface Window {
	  trackdesk: any;
	  TrackdeskObject: any;
	}
  }

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
	registerForm: UntypedFormGroup;
	inviteForm: UntypedFormGroup;
	loading = false;
	errors: any = [];
	public userData = {
		FirstName: '',
		LastName: '',
		Email: '',
		Password: '',
		PhoneNumber: '',
		Country: '',
		TimeZone: '',
		Key: environment.Key
	};
	inviteData = {
		FirstName: '',
		LastName: '',
		Email: '',
		CompanyName: '',
		Key: environment.Key
	}
	token = '';
	public param: any;

	FirstName: '';
	LastName: '';
	Email: '';

	validToken: boolean;
	thankyouFlag: boolean = false
	countryList = [];
	timeZoneList = [];
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	currentDomainUrl = ''
	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		public http: HttpClient,
		public pageService: PageConfigService,
		public dataService: DataTableService,
		private route: ActivatedRoute) {

		this.unsubscribe = new Subject();

	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	*/

	/**
	 * On init
	 */
	ngOnInit() {

		var scope = this;
		scope.pageService.setTitle('Sign Up')
		var url = window.location.href
		var arr = url.split("/");
		var result = arr[0] + "//" + arr[2];
		scope.currentDomainUrl = result.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
		// scope.currentDomainUrl =!scope.currentDomainUrl.includes('localhost')

		

		if (!scope.currentDomainUrl.includes('localhost') && !scope.currentDomainUrl.includes('grabyourreviews')) {

			scope.router.navigate(['/error/403'])

			// return;
		} else {
			localStorage.clear();

			scope.token = scope.router['browserUrlTree'].queryParams.token;
			if (scope.token != undefined && scope.token.length >= 0) {
				scope.http.get(environment.apiPath + 'VerifyInvitation?token=' + scope.token)
					.subscribe((res) => {
						if (res['success']) {
							scope.validToken = true;
							let data = JSON.parse(scope.pageService.decrypt(res['data']))

							scope.userData.FirstName = data['FirstName'];
							scope.userData.LastName = data['LastName'];
							scope.userData.Email = data['Email'];
							scope.cdr.markForCheck();
							scope.initRegisterForm();
							scope.pageService.showHideAsideAuth();
						} else {
							scope.pageService.showError('Oops!!!', res['errorMsg']);
							scope.validToken = false;
							scope.cdr.markForCheck()
							scope.initInvitationForm();
						}
					}, (err) => {
						if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
							this.validToken = false;
							this.pageService.showError('Oops!', err.error.errorMsg);
						}
					})
			} else {
				//use Query perameter 
		this.route.queryParams.subscribe(params => {
			this.FirstName = params.firstname;
			this.LastName = params.lastname;
			this.Email = params.email;
		}
		);
		this.userData.FirstName = this.FirstName;
		this.userData.LastName = this.LastName;
		this.userData.Email = this.Email;
				scope.validToken = true;
				scope.initRegisterForm();
			}
		}

		
	}

	/*
	* On destroy
	*/
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	getCurrentTimeZone() {
		var scope = this;

		let date = new Date();
		let str = date.toString()
		return str.substring(str.indexOf('(') + 1, str.indexOf(')'))
	}
	initInvitationForm() {

		this.inviteForm = this.fb.group({
			firstName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			lastName: [''],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
				// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				Validators.maxLength(320)
			]),
			],
			companyName: [''],
			agree: [false, Validators.compose([Validators.required])]
		});
	}

	errorMsg: any;

	initRegisterForm() {
		var scope = this;

		scope.registerForm = scope.fb.group({
			firstName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
				// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				Validators.maxLength(320)
			]),
			],

			TimeZone: ['', Validators.compose([
				Validators.required,
			]),
			],
			PhoneNumber: ['', Validators.compose([
				Validators.required,
				// Validators.pattern('^([()\\- x+]*\d[()\\- x+]*){8,12}$'),
				// Validators.minLength(8),
				// Validators.maxLength(10)
			]),
			],
			country: ['', Validators.compose([
				Validators.required,
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100),
				Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-]).{8,}$')
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			agree: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});

		scope.countryList = scope.dataService.countryList;
		scope.timeZoneList = scope.pageService.getTimeZone();
		if (scope.getCurrentTimeZone().length > 0) {
			scope.userData.TimeZone = scope.getCurrentTimeZone()
			let tempTimeZone = this.userData.TimeZone;
			if (this.userData.TimeZone != '') {
				for (var i = 0; i < this.timeZoneList.length; i++) {
					if (this.timeZoneList[i].id.toLocaleLowerCase() == tempTimeZone.toLocaleLowerCase()) {
						this.userData.TimeZone = this.timeZoneList[i].id;
						break
					} else {
						this.userData.TimeZone = '';
					}
				}
			}
		}
		else {
			scope.userData.TimeZone = 'GMT Standard Time'
		}

		scope.cdr.markForCheck();
		let ip = localStorage.getItem('IpAddress')
		if (ip != undefined && ip != null) {
			setTimeout(() => {
				var input = document.querySelector("#registerPhoneText");
				var errorMsg = document.querySelector("#error-msg-phone-register");
				var validMsg = document.querySelector("#valid-msg-phone-register");

				phoneText(ip, input, errorMsg, validMsg, scope)
				scope.http.get("https://ipapi.co/" + ip + "/json/")
					.subscribe((res) => {
						scope.userData['Country'] = res['country_code'];
						scope.cdr.markForCheck();
					}, (err) => {
						if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
							this.pageService.showError('Oops!', err.error.errorMsg);
						}
					})

			}, 500)
		} else {
			scope.http.get(environment.ipAddressApiPath)
				.subscribe(data => {
					localStorage.setItem('IpAddress', data['ip']);
					let ip = data['ip'];
					var input = document.querySelector("#registerPhoneText");
					var errorMsg = document.querySelector("#error-msg-phone-register");
					var validMsg = document.querySelector("#valid-msg-phone-register");
					phoneText(ip, input, errorMsg, validMsg, scope)
					scope.http.get("https://ipapi.co/" + ip + "/json/")
						.subscribe((res) => {
							scope.userData['Country'] = res['country_code'];
							scope.cdr.markForCheck();
						}, (err) => {
							if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
								this.pageService.showError('Oops!', err.error.errorMsg);
							}
						})
				})
		}


	}

	demo: false;
	/**
	 * Form Submit
	 */
	submitInvite() {
		var scope = this;
		let controls2 = scope.inviteForm.controls;

		// check form Validation
		if (scope.inviteForm.invalid) {
			Object.keys(controls2).forEach(controlName =>
				controls2[controlName].markAsTouched()
			);
			return;
		}
		if (!controls2['agree'].value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			scope.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
			return;
		}

		let finalData = { Data: scope.pageService.encrypt(JSON.stringify(scope.inviteData)) }
		scope.loading = true;
		scope.http.post(environment.apiPath + 'Signup', finalData)
			.subscribe((user) => {

				if (user['success']) {
					// scope.authNoticeService.setNotice('Invitation sent successfully', 'success');
					scope.thankyouFlag = true;
					// scope.router.navigateByUrl('/auth/login');
				} else {
					scope.authNoticeService.setNotice(user['errorMsg'], 'danger');
				}
				scope.loading = false;
				scope.cdr.markForCheck();
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	isEmailVerification: boolean = false;
	emailAddress: any = "";
	submit() {
		var scope = this;
		const controls = this.registerForm.controls;

		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		if (document.getElementById('error-msg-phone-register').innerHTML != '') {
			return
		}


		if (!controls['agree'].value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			this.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
			return;
		}
		scope.userData['Token'] = scope.token;
		let httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			})
		};
		// call Create Account Api

		scope.loading = true;
		scope.http.post(environment.apiPath + 'CreateAccount', scope.userData)
			.subscribe((user) => {
				if (user['success']) {
					scope.authNoticeService.setNotice('You are successfully registered', 'success');
					// scope.router.navigateByUrl('/auth/login');
					this.isEmailVerification = true;
					this.emailAddress = this.registerForm.controls.email.value;

					//added by unnati
					// gr("track", "conversion", { email: "actual@email.com" });
					gr("track", "conversion", { email: this.registerForm.controls.email.value });
					// console.log('Account created successfully.')
					//Tracking code for TrackDesck affiliate
					window.trackdesk = window.trackdesk || [];
					window.TrackdeskObject = window.TrackdeskObject || {};
					window.TrackdeskObject.q = window.TrackdeskObject.q || [];
					window.TrackdeskObject.q.push(arguments);

					window.trackdesk('grabyourreviews', 'externalCid', {
						externalCid: this.registerForm.controls.email.value,
						revenueOriginId: '317a9a56-eceb-4c62-8d43-da6ebd89a4c3'
					  });
					//Affiliate tracking code ends
					
					//Google Ads Conversion Tracking
					
					this.gtag_report_conversion(location)
						
					//ENDS Google Ads Conversion Tracking
					

					this.cdr.detectChanges();
				} else {
					scope.authNoticeService.setNotice(user['errorMsg'], 'danger');
				}
				scope.loading = false;
				scope.cdr.markForCheck();
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	gtag_report_conversion(url: Location): boolean {
		const callback = (): void => {
		  if (typeof(url) !== 'undefined') {
			window.location = url;
		  }
		};
		gtag('event', 'conversion', {
		  'send_to': 'AW-10992960526/0iE7CMfw8vcYEI6I7fko',
		  'event_callback': callback
		});
		return false;
	  }

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isInviteControlHasError(controlName: string, validationType: string): boolean {
		const control2 = this.inviteForm.controls[controlName];
		if (!control2) {
			return false;
		}

		const result2 = control2.hasError(validationType) && (control2.dirty || control2.touched);
		return result2;
	}
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	sendEmailAgain() {
		this.pageService.showLoader();
		this.http.get(environment.apiPath + 'ReSendVerificationEmail?Key=' + environment.Key + "&email=" + this.registerForm.controls['email'].value)
			.subscribe((res) => {
				if (res['success']) {
					this.pageService.hideLoader();
					this.pageService.showSuccess('Sent', 'Verification email sent successfully')
				} else {
					this.pageService.hideLoader();
					this.pageService.showError('Oops!!!', res['errorMsg'])
				}
			}, (err) => {
				this.pageService.hideLoader();
				this.pageService.showError(err.error.errorMsg, "Error");
			})
	}

}