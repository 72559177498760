import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';

import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-optinpage',
  templateUrl: './optinpage.component.html',
  styleUrls: ['./optinpage.component.scss']
})
export class OptinpageComponent implements OnInit {

  constructor(public pageService:PageConfigService) { }

  ngOnInit() {
    // var url = window.location.href
    // var arr = url.split("/");
    // var result = arr[0] + "//" + arr[2];
    
    // window.location.href = url.replace(result, environment.landingPageDomain)
    this.pageService.setTitle('OptIn')
  }

}
