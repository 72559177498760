<!-- Bad Modal Start-->
<div id="Modalcontact" role="dialog">
	<div class="col-md-12">
		<!-- Modal content-->
		<form [formGroup]="badForm">
			<div class='row padding-15 text-center'>
				<span [innerHTML]='layoutData["MessageforPrivateReview"]'>
				</span>
			</div>
			<div class='col-12'>
				<mat-divider></mat-divider>
			</div>
			<div class="modal-body">
				<div class="row cust-error" *ngIf="isShowError">
					<div class="col-12 m-1">
						Enter email or phone number
					</div>
				</div>
				<div class="reviewsource-formrow">
					<div class="form-group">
						<ngb-rating [max]="5" [(rate)]="Rating" formControlName="Rating" [readonly]="false"
							style="outline:none;">
						</ngb-rating>
					</div>
					<div class="form-group">
						<textarea style="margin-bottom:30px;" formControlName="message" [(ngModel)]="dataModel.message"
							required="yes" rows="2" class="form-control" id="message"
							placeholder="{{PrivateWhatToDoPlaceHolderMsg}}"
							[ngClass]="{'errorTextBox': submittedFlag && badForm.controls.message.errors}"></textarea>
					</div>
					<div class="form-group" *ngIf="HasNameEnable == true">
						<input type="text" formControlName="name" [(ngModel)]="dataModel.name" class="form-control"
							id="name" placeholder="{{PrivateNamePlaceholderMsg}}"
							[ngClass]="{'errorTextBox': HasNameRequired == true && submittedFlag && badForm.controls.name.errors}">
					</div>
					<div class="form-group" *ngIf="HasEmailEnable == true">
						<input type="email" formControlName="email" [(ngModel)]="dataModel.email" class="form-control"
							id="email" placeholder="{{PrivateEmailPlaceholderMsg}}"
							[ngClass]="{'errorTextBox':HasEmailRequired == true && submittedFlag && badForm.controls.email.errors}">
					</div>
					<div class="form-group" *ngIf="HasPhoneNumberEnable == true">
						<input type="number" formControlName="phoneNumber" class="form-control" id="phoneNumber"
							maxlength="10" min="9" placeholder="{{PrivatePhoneNumberPlaceholderMsg}}"
							[ngClass]="{'errorTextBox': HasPhoneNumberRequired == true && submittedFlag && badForm.controls.phoneNumber.errors}">
						<!-- <span style="color: #ff4f4f;" *ngIf="submittedFlag && isMobileNumberError">Enter valid phone
							number</span> -->
						<mat-error *ngIf="submittedFlag && badForm.controls.phoneNumber.errors">
							<strong *ngIf='submittedFlag && badForm.controls.phoneNumber.errors.pattern'>*Enter valid
								phone
								number</strong>
						</mat-error>
					</div>
					<!-- <div class="form-group">
							<input formControlName="phoneNumber" type="tel" id='registerPhoneText'
							placeholder="{{PrivatePhoneNumberPlaceholderMsg}}" class="form-control"
							 [ngClass]="{'errorTextBox': submittedFlag && badForm.controls.phoneNumber.errors}"
							 [(ngModel)]="dataModel.phoneNumber">					
						<mat-error *ngIf="badForm.controls.phoneNumber.errors.pattern" style="font-size: 12px;">
							<strong>Enter Valid number</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('phoneNumber','maxlength')" style="font-size: 12px;">
							<strong>Enter Valid number</strong>
						</mat-error>
					</div> -->
					<div class="reviewsource-formbottomtxt" *ngIf="!isShowPublicMsg">
						<span>{{PrivatePostAReviewTextMsg}}</span>
						<span class="down primary-color cursor-pointer"
							(click)='closeDialog()'>{{PrivatePostClickhereText}}</span>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btn-success" (click)='submit()'
					[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}">{{PrivateSubmitButtonTextMsg}}</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal"
					(click)='dialogRef.close()'>{{PrivateCloseButtonTextMsg}}</button>
			</div>
		</form>
		<!-- Bad Modal end-->
	</div>
</div>