import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	Renderer2,
	ViewChild
} from '@angular/core';
import { filter } from 'rxjs/internal/operators';
import { NavigationEnd, Router } from '@angular/router';
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuAsideService, MenuOptions, OffcanvasOptions } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { data } from 'jquery';

@Component({
	selector: 'kt-aside-left',
	templateUrl: './aside-left.component.html',
	styleUrls: ['./aside-left.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideLeftComponent implements OnInit, AfterViewInit {
	@ViewChild('asideMenu', { static: true }) asideMenu: ElementRef;

	currentRouteUrl: string = '';
	insideTm: any;
	outsideTm: any;
	isExpired: boolean = false;
	menuCanvasOptions: OffcanvasOptions = {
		baseClass: 'kt-aside',
		overlay: true,
		closeBy: 'kt_aside_close_btn',
		toggleBy: {
			target: 'kt_aside_mobile_toggler',
			state: 'kt-header-mobile__toolbar-toggler--active'
		}
	};
	menuList: any[];
	menuOptions: MenuOptions = {
		// vertical scroll
		scroll: null,

		// submenu setup
		submenu: {
			desktop: {
				// by default the menu mode set to accordion in desktop mode
				default: 'dropdown',
			},
			tablet: 'accordion', // menu set to accordion in tablet mode
			mobile: 'accordion' // menu set to accordion in mobile mode
		},

		// accordion setup
		accordion: {
			expandAll: false // allow having multiple expanded accordions in the menu
		}
	};

	/**
	 * Component Conctructor
	 *
	 * @param htmlClassService: HtmlClassService
	 * @param menuAsideService
	 * @param layoutConfigService: LayouConfigService
	 * @param router: Router
	 * @param render: Renderer2
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		public htmlClassService: HtmlClassService,
		public menuAsideService: MenuAsideService,
		public layoutConfigService: LayoutConfigService,
		private router: Router,
		public http: HttpClient,
		private render: Renderer2,
		private cdr: ChangeDetectorRef,
		public pageService: PageConfigService
	) {
		this.pageService.identity.subscribe((res) => {
			if (res == 'paymentUpdated') {
				this.ngOnInit();
			}
		})
	}

	ngAfterViewInit(): void {
	}

	accessKey;
	resellerFlag;
	isAgencyFlag;
	isExpiredFlag;
	showBranding;
	currentPlan;
	currentCountry;

	ngOnInit() {
		var scope = this;
		scope.currentPlan = JSON.parse(localStorage.getItem('current-plan'));
		scope.currentCountry = localStorage.getItem('currentCountry');

		this.showBranding = localStorage.getItem('showBranding');
		scope.menuAsideService.menuList$.subscribe((list) => {
			scope.menuList = JSON.parse(JSON.stringify(list));
			this.accessKey = localStorage.getItem(environment.authTokenKey)
			let GRDetail = localStorage.getItem('GRDetail');
			if (GRDetail == null || GRDetail == undefined) {
				let data={Key:this.accessKey};
				this.http.post(environment.apiV2Path + 'GetDetails',data).subscribe((res: any) => {
					localStorage.setItem('GRDetail', JSON.stringify(res['data']));
					if (res) {
						this.isAgencyFlag = res.data.isAgency;
						this.isExpiredFlag = res.data.isExpired;
						this.resellerFlag = res.data.isReseller;
						let isApiAccess = res.data.isApiAccess
						let view = res.data.view;
						let viewPermission = JSON.parse(scope.pageService.decrypt(view))

						// scope.isExpired = JSON.parse(localStorage.getItem('isExpired'))
						let permission = localStorage.getItem('user-access')
						var jsonStr = permission;
						var jsonObject = (new Function("return " + jsonStr))();
						if (jsonObject != undefined) {
							// scope.reportsPermissionFlag = jsonObject['Reporting']

							// scope.cdr.detectChanges()

						}

						//A2P Brand Registration hide app sumo and free trial user
						let planName = localStorage.plan;
						if (planName == "Medium" || planName == "Small" ||
							planName == "Agency" || planName == "Large" ||
							planName == "Agency-LR" || planName == "Premium"
						) {
							if (this.currentCountry == "US" || this.currentCountry == "CA") {
								scope.menuList = scope.menuList.filter((aln: any) => {
									if (aln.title == "A2P Brand Registration") {
										this.cdr.markForCheck();
										return true
									}
									else {
										this.cdr.markForCheck();
										return aln;
									}
								})
							}
							else {
								scope.menuList = scope.menuList.filter((aln: any) => {
									if (aln.title == "A2P Brand Registration") {
										this.cdr.markForCheck();
										return false
									}
									else {
										this.cdr.markForCheck();
										return aln;
									}
								})
							}
						}
						else if ((planName == "\"Grab Your Reviews Tier 2\"" || planName == "\"Grab Your Reviews Tier 3\"" || planName == "\"Grab Your Reviews Tier 1\"") || scope.currentPlan == "Free Trial") {
							scope.menuList = scope.menuList.filter((aln: any) => {
								if (aln.title == "A2P Brand Registration") {
									this.cdr.markForCheck();
									return false
								}
								else {
									this.cdr.markForCheck();
									return aln;
								}
							})

						}
						// end A2P Brand Registration hide app sumo and free trial user

						if (this.isAgencyFlag == false) {
							scope.menuList = scope.menuList.filter((aln, i) => {
								if (aln.title.toLocaleLowerCase() == "general settings") {
									let ar = aln.submenu.filter((alone, i) => {
										if (
											alone.title.toLocaleLowerCase() != "profile usage"
										) {
											return alone
										}
									})

									aln.submenu = JSON.parse(JSON.stringify(ar));
									return aln
								} else {
									return aln
								}
							})
						}

						if (this.isExpiredFlag == false) {
							if (this.isAgencyFlag == false) {
								if (this.resellerFlag == false) {
									scope.menuList = scope.menuList.filter((aln, i) => {
										if (aln.title.toLocaleLowerCase() == "general settings") {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() != "users" &&
													alone.title.toLocaleLowerCase() != "setup domain" &&
													alone.title.toLocaleLowerCase() != "company profiles" &&
													// alone.title.toLocaleLowerCase() != "profile usage" &&
													alone.title.toLocaleLowerCase() != "branding" &&
													alone.title.toLocaleLowerCase() != "default settings" &&
													alone.title.toLocaleLowerCase() != "notifications" &&
													alone.title.toLocaleLowerCase() != "default template settings" &&
													alone.title.toLocaleLowerCase() != "billing") {

													return alone
												}

												if (alone.title.toLocaleLowerCase() == "setup domain") {

													return false
												}
											})

											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln
										} else {
											return aln
										}
									})
									scope.menuList = scope.menuList.filter((aln, i) => {
										if (aln.title.toLocaleLowerCase() == 'customization options') {
											if (jsonObject['LandingPage'] == true) {
												return aln;
											}
										} else if (aln.title.toLocaleLowerCase() == 'reports') {
											if (jsonObject['Reporting'] == true) {
												return aln;
											}
										} else if (aln.title.toLocaleLowerCase() == 'widgets') {
											if (jsonObject['Widgets'] == true) {
												return aln;
											}
										} else if (aln.title.toLocaleLowerCase() == 'get reviews') {
											if (jsonObject['Invites'] == true) {
												return aln;
											}
										} else if (aln.title.toLocaleLowerCase() == 'general settings') {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() == 'integrations') {
													if (isApiAccess == true) {
														return alone;
													}
												} else {
													return alone;
												}
											})
											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln;
										} else {
											return aln;
										}
									})
									scope.menuList = scope.menuList.filter((aln, i) => {
										if (aln.title.toLocaleLowerCase() == 'customization options') {
											// if (jsonObject['LandingPage'] == true) {
											// 	return aln;
											// }
											let ar = aln.submenu.filter((alone, i) => {

												if (alone.title.toLocaleLowerCase() == "opt in page") {
													if (viewPermission['HasOptin'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == "qr code") {
													if (viewPermission['HasQRCode'] == true) {
														return alone;
													}
												} else {
													return alone
												}
											})
											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln;
										} else if (aln.title.toLocaleLowerCase() == 'reports') {
											if (viewPermission['HasReport'] == true) {
												return aln;
											}
										} else if (aln.title.toLocaleLowerCase() == 'widgets') {

											let ar = aln.submenu.filter((alone, i) => {

												if (alone.title.toLocaleLowerCase() == 'website widgets') {
													if (viewPermission['HasWebsiteWidget'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == 'social sharing') {
													if (viewPermission['HasSocialSharing'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == 'email widgets') {
													if (viewPermission['HasEmailWidget'] == true) {
														return alone;
													}
												} else {
													return alone
												}
											})
											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln;
										} else if (aln.title.toLocaleLowerCase() == 'get reviews') {
											return aln;
											// }
										} else if (aln.title.toLocaleLowerCase() == 'general settings') {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() == 'usage') {
													if (this.resellerFlag == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == 'setup domain') {
													if (viewPermission['HasCustomDomain'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == 'branding') {
													if (viewPermission['HasCustomDomain'] == true) {
														return alone;
													}
												}
												else {
													return alone
												}
											})
											aln.submenu = JSON.parse(JSON.stringify(ar));

											return aln;
										} else if (aln.title.toLocaleLowerCase() == 'integrations') {
											if (this.resellerFlag == true) {
												return aln;
											}
										} else {
											return aln;
										}
									})
									scope.menuList = scope.menuList.filter((aln, i) => {

										if (aln.title.toLocaleLowerCase() == "reports") {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() == "performance report") {
													return false
												} else if (alone.title.toLocaleLowerCase() == "my report") {
													return alone
												}
												if (alone.title.toLocaleLowerCase() == "qr report") {
													return alone
												}
											})

											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln
										} else {
											return aln
										}


									})
								} else {
									//Reseller Menu Permissions
									scope.menuList = scope.menuList.filter((aln, i) => {

										if (aln.title.toLocaleLowerCase() == "general settings") {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() != "default settings" &&
													alone.title.toLocaleLowerCase() != "default template settings") {
													return alone
												}


											})

											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln
										} else {
											return aln
										}


									})
									scope.menuList = scope.menuList.filter((aln, i) => {

										if (aln.title.toLocaleLowerCase() == 'customization options') {

											let ar = aln.submenu.filter((alone, i) => {

												if (alone.title.toLocaleLowerCase() == "opt in page") {
													if (viewPermission['HasOptin'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == "qr code") {
													if (viewPermission['HasQRCode'] == true) {
														return alone;
													}
												} else {
													return alone
												}
											})
											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln;
										} else if (aln.title.toLocaleLowerCase() == 'reports') {
											if (viewPermission['HasReport'] == true) {
												return aln;
											}
										} else if (aln.title.toLocaleLowerCase() == 'widgets') {

											let ar = aln.submenu.filter((alone, i) => {

												if (alone.title.toLocaleLowerCase() == 'website widgets') {
													if (viewPermission['HasWebsiteWidget'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == 'social sharing') {
													if (viewPermission['HasSocialSharing'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == 'email widgets') {
													if (viewPermission['HasEmailWidget'] == true) {
														return alone;
													}
												} else {
													return alone
												}
											})
											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln;
										} else if (aln.title.toLocaleLowerCase() == 'get reviews') {
											return aln;
										} else if (aln.title.toLocaleLowerCase() == 'general settings') {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() == 'integrations') {
													if (viewPermission['HasAPI'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == 'setup domain') {
													if (viewPermission['HasCustomDomain'] == true) {
														return alone;
													}
												} else if (alone.title.toLocaleLowerCase() == 'branding') {
													if (viewPermission['HasCustomDomain'] == true) {
														return alone;
													}
												}
												else {
													return alone
												}
											})
											aln.submenu = JSON.parse(JSON.stringify(ar));

											return aln;
										} else {
											return aln;
										}
									})

									scope.menuList = scope.menuList.filter((aln, i) => {

										if (aln.title.toLocaleLowerCase() == "reports") {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() == "performance report") {
													return alone
												} else if (alone.title.toLocaleLowerCase() == "my report") {
													return alone
												}
												if (alone.title.toLocaleLowerCase() == "qr report") {
													return alone
												} else {
													return false
												}
											})

											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln
										} else {
											return aln
										}


									})
								}
							}
							else {

								// this.menuList = this.menuList.filter((aln: any) => {
								// 	if (aln.title.toLocaleLowerCase() == "notifications") {
								// 		this.cdr.markForCheck();
								// 		return false;
								// 	} else {
								// 		this.cdr.markForCheck();
								// 		return aln;
								// 	}
								// })

								scope.menuList = scope.menuList.filter((aln, i) => {

									if (aln.title.toLocaleLowerCase() == "general settings") {
										let ar = aln.submenu.filter((alone, i) => {

											if (this.resellerFlag) {
												if (alone.title.toLocaleLowerCase() == "setup domain") {
													return true
												}
											} else {
												if (alone.title.toLocaleLowerCase() == "setup domain") {
													return false;
												}

												if (alone.title.toLocaleLowerCase() == "branding") {
													return false
												}

												if (alone.title.toLocaleLowerCase() == "company profiles") {
													return false
												}
												if (alone.title.toLocaleLowerCase() == "profile usage") {
													return false
												}
												if (alone.title.toLocaleLowerCase() == "notifications") {
													return false
												}

												if (alone.title.toLocaleLowerCase() == "integrations") {
													if (viewPermission['HasAPI']) return true
													else return false
												}

												if (alone.title.toLocaleLowerCase() == "users") {
													return false
												}

												if (alone.title.toLocaleLowerCase() == "usage") {
													return false
												}

												if (alone.title.toLocaleLowerCase() == "billing") {
													return false
												}
											}

											if (alone.title.toLocaleLowerCase() != "default settings" &&
												alone.title.toLocaleLowerCase() != "default template settings") {
												return alone
											}
										})

										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln
									} else {
										return aln
									}


								})

								if (this.resellerFlag == true) {
									scope.menuList = scope.menuList.filter((aln, i) => {

										if (aln.title.toLocaleLowerCase() == "reports") {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() == "performance report") {
													return alone
												} else if (alone.title.toLocaleLowerCase() == "my report") {
													return alone
												}
												if (alone.title.toLocaleLowerCase() == "qr report") {
													return alone
												}
												else {
													return false
												}
											})

											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln
										} else {
											return aln
										}


									})
								} else {
									scope.menuList = scope.menuList.filter((aln, i) => {

										if (aln.title.toLocaleLowerCase() == "reports") {
											let ar = aln.submenu.filter((alone, i) => {
												if (alone.title.toLocaleLowerCase() == "performance report") {
													return false
												} else if (alone.title.toLocaleLowerCase() == "my report") {
													return alone
												}
												if (alone.title.toLocaleLowerCase() == "qr report") {
													return alone
												} else {
													return false
												}
											})

											aln.submenu = JSON.parse(JSON.stringify(ar));
											return aln
										} else {
											return aln
										}


									})
								}

							}
						}
					}
				}, (err) => {
					if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

						this.pageService.showError('Oops!', err.error.errorMsg);
					}
				});
			}
			else {
				let res: any = {data:null};
				res.data = JSON.parse(localStorage.getItem('GRDetail'));
				if (res) {
					this.isAgencyFlag = res.data.isAgency;
					this.isExpiredFlag = res.data.isExpired;
					this.resellerFlag = res.data.isReseller;
					let isApiAccess = res.data.isApiAccess
					let view = res.data.view;
					let viewPermission = JSON.parse(scope.pageService.decrypt(view))

					// scope.isExpired = JSON.parse(localStorage.getItem('isExpired'))
					let permission = localStorage.getItem('user-access')
					var jsonStr = permission;
					var jsonObject = (new Function("return " + jsonStr))();
					if (jsonObject != undefined) {
						// scope.reportsPermissionFlag = jsonObject['Reporting']

						// scope.cdr.detectChanges()

					}


					//A2P Brand Registration hide app sumo and free trial user
					let planName = localStorage.plan;
					if (planName == "Medium" || planName == "Small" ||
						planName == "Agency" || planName == "Large" ||
						planName == "Agency-LR" || planName == "Premium"

					) {
						if (this.currentCountry == "US" || this.currentCountry == "CA") {
							scope.menuList = scope.menuList.filter((aln: any) => {
								if (aln.title == "A2P Brand Registration") {
									this.cdr.markForCheck();
									return true
								}
								else {
									this.cdr.markForCheck();
									return aln;
								}
							})
						}
						else {
							scope.menuList = scope.menuList.filter((aln: any) => {
								if (aln.title == "A2P Brand Registration") {
									this.cdr.markForCheck();
									return false
								}
								else {
									this.cdr.markForCheck();
									return aln;
								}
							})
						}
					}
					else if ((planName == "\"Grab Your Reviews Tier 2\"" || planName == "\"Grab Your Reviews Tier 3\"" || planName == "\"Grab Your Reviews Tier 1\"") || scope.currentPlan == "Free Trial") {

						scope.menuList = scope.menuList.filter((aln: any) => {
							if (aln.title == "A2P Brand Registration") {
								this.cdr.markForCheck();
								return false
							}
							else {
								this.cdr.markForCheck();
								return aln;
							}
						})

					}
					// end A2P Brand Registration hide app sumo and free trial user


					if (this.isAgencyFlag == false) {
						scope.menuList = scope.menuList.filter((aln, i) => {
							if (aln.title.toLocaleLowerCase() == "general settings") {
								let ar = aln.submenu.filter((alone, i) => {
									if (
										alone.title.toLocaleLowerCase() != "profile usage"
									) {
										return alone
									}
								})

								aln.submenu = JSON.parse(JSON.stringify(ar));
								return aln
							} else {
								return aln
							}
						})
					}

					if (this.isExpiredFlag == false) {
						if (this.isAgencyFlag == false) {
							if (this.resellerFlag == false) {
								scope.menuList = scope.menuList.filter((aln, i) => {
									if (aln.title.toLocaleLowerCase() == "general settings") {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() != "users" &&
												alone.title.toLocaleLowerCase() != "setup domain" &&
												alone.title.toLocaleLowerCase() != "company profiles" &&
												// alone.title.toLocaleLowerCase() != "profile usage" &&
												alone.title.toLocaleLowerCase() != "branding" &&
												alone.title.toLocaleLowerCase() != "default settings" &&
												alone.title.toLocaleLowerCase() != "notifications" &&
												alone.title.toLocaleLowerCase() != "default template settings" &&
												alone.title.toLocaleLowerCase() != "billing") {

												return alone
											}

											if (alone.title.toLocaleLowerCase() == "setup domain") {

												return false
											}
										})

										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln
									} else {
										return aln
									}
								})
								scope.menuList = scope.menuList.filter((aln, i) => {
									if (aln.title.toLocaleLowerCase() == 'customization options') {
										if (jsonObject['LandingPage'] == true) {
											return aln;
										}
									} else if (aln.title.toLocaleLowerCase() == 'reports') {
										if (jsonObject['Reporting'] == true) {
											return aln;
										}
									} else if (aln.title.toLocaleLowerCase() == 'widgets') {
										if (jsonObject['Widgets'] == true) {
											return aln;
										}
									} else if (aln.title.toLocaleLowerCase() == 'get reviews') {
										if (jsonObject['Invites'] == true) {
											return aln;
										}
									} else if (aln.title.toLocaleLowerCase() == 'general settings') {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() == 'integrations') {
												if (isApiAccess == true) {
													return alone;
												}
											} else {
												return alone;
											}
										})
										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln;
									} else {
										return aln;
									}
								})
								scope.menuList = scope.menuList.filter((aln, i) => {
									if (aln.title.toLocaleLowerCase() == 'customization options') {
										// if (jsonObject['LandingPage'] == true) {
										// 	return aln;
										// }
										let ar = aln.submenu.filter((alone, i) => {

											if (alone.title.toLocaleLowerCase() == "opt in page") {
												if (viewPermission['HasOptin'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == "qr code") {
												if (viewPermission['HasQRCode'] == true) {
													return alone;
												}
											} else {
												return alone
											}
										})
										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln;
									} else if (aln.title.toLocaleLowerCase() == 'reports') {
										if (viewPermission['HasReport'] == true) {
											return aln;
										}
									} else if (aln.title.toLocaleLowerCase() == 'widgets') {

										let ar = aln.submenu.filter((alone, i) => {

											if (alone.title.toLocaleLowerCase() == 'website widgets') {
												if (viewPermission['HasWebsiteWidget'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == 'social sharing') {
												if (viewPermission['HasSocialSharing'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == 'email widgets') {
												if (viewPermission['HasEmailWidget'] == true) {
													return alone;
												}
											} else {
												return alone
											}
										})
										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln;
									} else if (aln.title.toLocaleLowerCase() == 'get reviews') {
										return aln;
										// }
									} else if (aln.title.toLocaleLowerCase() == 'general settings') {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() == 'usage') {
												if (this.resellerFlag == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == 'setup domain') {
												if (viewPermission['HasCustomDomain'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == 'branding') {
												if (viewPermission['HasCustomDomain'] == true) {
													return alone;
												}
											}
											else {
												return alone
											}
										})
										aln.submenu = JSON.parse(JSON.stringify(ar));

										return aln;
									} else if (aln.title.toLocaleLowerCase() == 'integrations') {
										if (this.resellerFlag == true) {
											return aln;
										}
									} else {
										return aln;
									}
								})
								scope.menuList = scope.menuList.filter((aln, i) => {

									if (aln.title.toLocaleLowerCase() == "reports") {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() == "performance report") {
												return false
											} else if (alone.title.toLocaleLowerCase() == "my report") {
												return alone
											}
											if (alone.title.toLocaleLowerCase() == "qr report") {
												return alone
											}
										})

										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln
									} else {
										return aln
									}


								})
							} else {
								//Reseller Menu Permissions
								scope.menuList = scope.menuList.filter((aln, i) => {

									if (aln.title.toLocaleLowerCase() == "general settings") {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() != "default settings" &&
												alone.title.toLocaleLowerCase() != "default template settings") {
												return alone
											}


										})

										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln
									} else {
										return aln
									}


								})
								scope.menuList = scope.menuList.filter((aln, i) => {

									if (aln.title.toLocaleLowerCase() == 'customization options') {

										let ar = aln.submenu.filter((alone, i) => {

											if (alone.title.toLocaleLowerCase() == "opt in page") {
												if (viewPermission['HasOptin'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == "qr code") {
												if (viewPermission['HasQRCode'] == true) {
													return alone;
												}
											} else {
												return alone
											}
										})
										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln;
									} else if (aln.title.toLocaleLowerCase() == 'reports') {
										if (viewPermission['HasReport'] == true) {
											return aln;
										}
									} else if (aln.title.toLocaleLowerCase() == 'widgets') {

										let ar = aln.submenu.filter((alone, i) => {

											if (alone.title.toLocaleLowerCase() == 'website widgets') {
												if (viewPermission['HasWebsiteWidget'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == 'social sharing') {
												if (viewPermission['HasSocialSharing'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == 'email widgets') {
												if (viewPermission['HasEmailWidget'] == true) {
													return alone;
												}
											} else {
												return alone
											}
										})
										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln;
									} else if (aln.title.toLocaleLowerCase() == 'get reviews') {
										return aln;
									} else if (aln.title.toLocaleLowerCase() == 'general settings') {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() == 'integrations') {
												if (viewPermission['HasAPI'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == 'setup domain') {
												if (viewPermission['HasCustomDomain'] == true) {
													return alone;
												}
											} else if (alone.title.toLocaleLowerCase() == 'branding') {
												if (viewPermission['HasCustomDomain'] == true) {
													return alone;
												}
											}
											else {
												return alone
											}
										})
										aln.submenu = JSON.parse(JSON.stringify(ar));

										return aln;
									} else {
										return aln;
									}
								})

								scope.menuList = scope.menuList.filter((aln, i) => {

									if (aln.title.toLocaleLowerCase() == "reports") {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() == "performance report") {
												return alone
											} else if (alone.title.toLocaleLowerCase() == "my report") {
												return alone
											}
											if (alone.title.toLocaleLowerCase() == "qr report") {
												return alone
											} else {
												return false
											}
										})

										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln
									} else {
										return aln
									}


								})
							}
						}
						else {

							// this.menuList = this.menuList.filter((aln: any) => {
							// 	if (aln.title.toLocaleLowerCase() == "notifications") {
							// 		this.cdr.markForCheck();
							// 		return false;
							// 	} else {
							// 		this.cdr.markForCheck();
							// 		return aln;
							// 	}
							// })

							scope.menuList = scope.menuList.filter((aln, i) => {

								if (aln.title.toLocaleLowerCase() == "general settings") {
									let ar = aln.submenu.filter((alone, i) => {

										if (this.resellerFlag) {
											if (alone.title.toLocaleLowerCase() == "setup domain") {
												return true
											}
										} else {
											if (alone.title.toLocaleLowerCase() == "setup domain") {
												return false;
											}

											if (alone.title.toLocaleLowerCase() == "branding") {
												return false
											}

											if (alone.title.toLocaleLowerCase() == "company profiles") {
												return false
											}
											if (alone.title.toLocaleLowerCase() == "profile usage") {
												return false
											}
											if (alone.title.toLocaleLowerCase() == "notifications") {
												return false
											}

											if (alone.title.toLocaleLowerCase() == "integrations") {
												if (viewPermission['HasAPI']) return true
												else return false
											}

											if (alone.title.toLocaleLowerCase() == "users") {
												return false
											}

											if (alone.title.toLocaleLowerCase() == "usage") {
												return false
											}

											if (alone.title.toLocaleLowerCase() == "billing") {
												return false
											}
										}

										if (alone.title.toLocaleLowerCase() != "default settings" &&
											alone.title.toLocaleLowerCase() != "default template settings") {
											return alone
										}
									})

									aln.submenu = JSON.parse(JSON.stringify(ar));
									return aln
								} else {
									return aln
								}


							})

							if (this.resellerFlag == true) {
								scope.menuList = scope.menuList.filter((aln, i) => {

									if (aln.title.toLocaleLowerCase() == "reports") {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() == "performance report") {
												return alone
											} else if (alone.title.toLocaleLowerCase() == "my report") {
												return alone
											}
											if (alone.title.toLocaleLowerCase() == "qr report") {
												return alone
											}
											else {
												return false
											}
										})

										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln
									} else {
										return aln
									}


								})
							} else {
								scope.menuList = scope.menuList.filter((aln, i) => {

									if (aln.title.toLocaleLowerCase() == "reports") {
										let ar = aln.submenu.filter((alone, i) => {
											if (alone.title.toLocaleLowerCase() == "performance report") {
												return false
											} else if (alone.title.toLocaleLowerCase() == "my report") {
												return alone
											}
											if (alone.title.toLocaleLowerCase() == "qr report") {
												return alone
											} else {
												return false
											}
										})

										aln.submenu = JSON.parse(JSON.stringify(ar));
										return aln
									} else {
										return aln
									}


								})
							}

						}
					}
				}

			}

		})

		this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
				this.cdr.markForCheck();
			});

		const config = this.layoutConfigService.getConfig();

		if (objectPath.get(config, 'aside.menu.dropdown') !== true && objectPath.get(config, 'aside.self.fixed')) {
			this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-scroll', '1');
		}

		if (objectPath.get(config, 'aside.menu.dropdown')) {
			this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown', '1');
			// eslint-disable-next-line max-len
			this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown-timeout', objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout'));
		}
		this.cdr.detectChanges();
	}

	/**
	 * Check Menu is active
	 * @param item: any
	 */
	isMenuItemIsActive(item): boolean {
		if(item){
			if (item.submenu) {
				return this.isMenuRootItemIsActive(item);
			}

			if (!item.page) {
				return false;
			}

			return this.currentRouteUrl.indexOf(item.page) !== -1;
		}
		else {
			return false;
		}

	}

	/**
	 * Check Menu Root Item is active
	 * @param item: any
	 */
	isMenuRootItemIsActive(item): boolean {
		let result: boolean = false;

		for (const subItem of item.submenu) {
			result = this.isMenuItemIsActive(subItem);
			if (result) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
	mouseEnter(e: Event) {
		// check if the left aside menu is fixed
		if (document.body.classList.contains('kt-aside--fixed')) {
			if (this.outsideTm) {
				clearTimeout(this.outsideTm);
				this.outsideTm = null;
			}

			this.insideTm = setTimeout(() => {
				// if the left aside menu is minimized
				if (document.body.classList.contains('kt-aside--minimize') && KTUtil.isInResponsiveRange('desktop')) {
					// show the left aside menu
					this.render.removeClass(document.body, 'kt-aside--minimize');
					this.render.addClass(document.body, 'kt-aside--minimize-hover');
				}
			}, 50);
		}
	}

	/**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
	mouseLeave(e: Event) {
		if (document.body.classList.contains('kt-aside--fixed')) {
			if (this.insideTm) {
				clearTimeout(this.insideTm);
				this.insideTm = null;
			}

			this.outsideTm = setTimeout(() => {
				// if the left aside menu is expand
				if (document.body.classList.contains('kt-aside--minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
					// hide back the left aside menu
					this.render.removeClass(document.body, 'kt-aside--minimize-hover');
					this.render.addClass(document.body, 'kt-aside--minimize');
				}
			}, 100);
		}
	}

	/**
	 * Returns Submenu CSS Class Name
	 * @param item: any
	 */
	getItemCssClasses(item) {
		let classes = 'kt-menu__item';

		if (objectPath.get(item, 'submenu')) {
			classes += ' kt-menu__item--submenu';
		}

		if (!item.submenu && this.isMenuItemIsActive(item)) {
			classes += ' kt-menu__item--active kt-menu__item--here';
		}

		if (item.submenu && this.isMenuItemIsActive(item)) {
			classes += ' kt-menu__item--open kt-menu__item--here';
		}

		// custom class for menu item
		const customClass = objectPath.get(item, 'custom-class');
		if (customClass) {
			classes += ' ' + customClass;
		}

		if (objectPath.get(item, 'icon-only')) {
			classes += ' kt-menu__item--icon-only';
		}

		return classes;
	}

	getItemAttrSubmenuToggle(item) {
		let toggle = 'hover';
		if (objectPath.get(item, 'toggle') === 'click') {
			toggle = 'click';
		} else if (objectPath.get(item, 'submenu.type') === 'tabs') {
			toggle = 'tabs';
		} else {
			// submenu toggle default to 'hover'
		}

		return toggle;
	}
}
