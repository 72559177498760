import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'kt-my-modal-second',
  templateUrl: './my-modal-second.component.html',
  styleUrls: ['./my-modal-second.component.scss']
})
export class MyModalSecondComponent implements OnInit {
  locationLinkUrl: '';
  collectReview: boolean = false;
  displayOnReviewPage: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MyModalSecondComponent>,
    @Inject(MAT_DIALOG_DATA) public navData: any) { }

  ngOnInit() {
    var scope = this;

    scope.locationLinkUrl = scope.navData.target.siteURL
    scope.collectReview = scope.navData.target.collectReview
    scope.displayOnReviewPage = scope.navData.target.getReview
  }

  onSubmitMapLink() {

    let data = {
      locationLinkUrl: this.locationLinkUrl,
      name: this.navData.target.reviewSiteName,
      collectReview: this.collectReview,
      siteData: this.navData.target,
      getReview: this.displayOnReviewPage
    }
    this.dialogRef.close(data)
  }
}
