<div class="card text-center">
    <div class="row text-center">
        <div class="col-md-12 m-3">
            <img src='../../../../../assets/media/custom-icons/positive.png' class='verifyIcon' style="height: 100px;"/>
            <img src='../../../../../assets/media/custom-icons/negative.png' class='verifyIcon' style="height: 100px;" *ngIf="isPaymentNotDone"/>
        </div>
        <div class="col-md-12 m-3">
            <!-- <h2 *ngIf="isPaymentDone" style="color: #30a125 !important;">Success</h2>
            <h2 *ngIf="isPaymentNotDone" style="color: #f13f40 !important;">Failed</h2> -->

            <p>Woohoo, All set to skyrocket your business reputation!</p>
            <p *ngIf="isPaymentNotDone">Wistful, something went wrong!</p>
        </div>
        <div class="col-md-12 m-3">
            <button  (click)='openBilling()' class="btn btn-primary btn-elevate kt-login__btn-primary">
                Close</button>
            <!-- <button class="btn btn-primary btn-sm" (click)="redirectUser('dashboard')">Go to Dashboard</button> -->
            <span *ngIf="isPaymentNotDone">
                <!-- <button class="btn btn-primary btn-sm" (click)="redirectUser('billing')">Try Again</button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                <button class="btn btn-primary btn-sm" (click)="choosePlan()">Choose Another Plan</button>
            </span>
        </div>
    </div>
</div>