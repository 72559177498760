import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { MAT_DIALOG_DATA, MatDialogRef, MatCheckbox, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from '../../.././../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageConfigService } from '../../../../../core/_base/layout/services/page-config.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ThankyoubillingComponent } from '../thankyoubilling/thankyoubilling.component';

@Component({
  selector: 'kt-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent implements OnInit {

  stripeObj: any;

  cardNumberObj: any;
  cardExpiryDateObj: any;
  cardCvvObj: any;

  isChanged: boolean = false;
  accessKey: string;
  planList: any
  userId: any;
  selectedPlan: any = '';
  loading: boolean = false;
  currentPlan = '';
  couponCode = '';
  couponSubmitted: boolean = false;
  validCoupon: boolean = false;
  invalidCoupon: boolean = false;
  terms: boolean = false;
  formSubmitted: boolean = false;
  cardRadioButton = '1';
  pricingPage = ''
  hideexistingRadio: boolean = false;
  invalidCard: boolean = false;
  viewAgencyPlan: boolean = false;
  isAgencyPlan: boolean = false;
  agencyPricing = 0;
  agencyPlanDrop = '10';
  usageData: any
  invalidPlan: boolean = false;
  currency = '';
  isCancelled: boolean = false;
  accountCountry: any;
  isExpired: boolean = false;

  currentCountry: any = "";

  contactSales: UntypedFormGroup;
  isSubmit: boolean = false;
  planName: any;

  @ViewChild('mat-checkbox') matCheckBox: MatCheckbox
  constructor(public cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public navData: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpgradePlanComponent>,
    public router: Router,
    public http: HttpClient,
    public pageService: PageConfigService,
    public fb: UntypedFormBuilder) {
    this.contactSales = this.fb.group({
      personName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      planName: [''],
      message: ['I want to upgrade my plan.']
    })
  }

  expiryDate: any;

  ngOnInit() {
    this.isAgencyPlan = this.navData.isAgency;
    this.loadSt();
    var scope = this;
    setTimeout(() => {
      // scope.openUpdate()
    }, 500)

    this.currentCountry = localStorage.getItem("currentCountry");
    this.contactSales.controls.personName.setValue(localStorage.getItem("personName"));
    this.contactSales.controls.phoneNumber.setValue(localStorage.getItem("phoneNumber"));
    this.contactSales.controls.email.setValue(localStorage.getItem("adminemail"));
    // scope.isCancelled = JSON.parse(scope.navData.isCancelled)
    // this.expiryDate = this.navData.expiryDate;
    scope.accessKey = localStorage.getItem(environment.authTokenKey)
    scope.currentPlan = JSON.parse(localStorage.getItem('plan'))
    scope.accountCountry = JSON.parse(localStorage.getItem('account'));
    scope.isExpired = JSON.parse(localStorage.getItem('isExpired'));

    // scope.usageData = JSON.parse(scope.navData.targetData);
    // scope.pageService.showLoader()
    // scope.http.get(environment.apiPath + 'Getplanlist?Key=' + scope.accessKey)
    //   .subscribe((res) => {

    //     if (res['success'] == true) {
    //       scope.planList = JSON.parse(scope.pageService.decrypt(res['data']))
    //       this.planName = this.planList[0].PlanName;
    //       if (scope.isExpired == true) {
    //         scope.planList.forEach(test => {
    //           if (test['PlanName'].toLocaleLowerCase() == scope.currentPlan.toLocaleLowerCase()) {
    //             scope.selectedPlan = test;
    //           }
    //         });
    //       }
    //       if (scope.planList.length > 0) {
    //         if (scope.planList[0].Currency.toLocaleLowerCase() == 'inr') {
    //           scope.currency = 'inr'
    //           scope.pricingPage = environment.pricingPageInd
    //         } else {
    //           scope.currency = 'usd'
    //           scope.pricingPage = environment.pricinigPage
    //         }
    //       }
    //       scope.userId = res['userId'];

    //       // [
    //       //   {
    //       //     Currency: null
    //       //     Features: null
    //       //     PlanId: "plan_HGnqfLuoxaY2tm"
    //       //     PlanName: "Free"
    //       //     Price: ""
    //       //   }
    //       // ]
    //     }
    //   }, (err) => {
    //     this.pageService.showError('Oops!', err.error.errorMsg);
    //   })

    // scope.loadUsageData();
    scope.loadCardData()

  }

  cardNumber: any;
  brandName: any;
  expMonth: any;
  expYear: any;

  loadCardData() {
    var scope = this;
    scope.pageService.showLoader()
    scope.http.get(environment.apiPath + 'GetCardDetails?Key=' + scope.accessKey)
      .subscribe((res: any) => {

        scope.pageService.hideLoader()
        if (res['success'] == true) {
          scope.hideexistingRadio = false;

          this.cardNumber = 'XXXX XXXX XXXX ' + res.lastDigits;
          this.brandName = res.brand;
          this.expMonth = res.expMonth;
          this.expYear = res.expYear;

          scope.cardRadioButton = '1';
          scope.radioButtonChange();
          scope.cdr.markForCheck()
        } else {

          scope.hideexistingRadio = true;
          scope.cardRadioButton = '2';
          scope.radioButtonChange();
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  loadUsageData() {
    var scope = this;
    scope.pageService.showLoader()
    scope.http.get(environment.apiPath + 'getusagedata?Key=' + scope.accessKey)
      .subscribe((res) => {

        scope.pageService.hideLoader()
        if (res['success']) {
          scope.usageData = res

          // companyProfiles: "3"
          // email: "400"
          // errorMsg: null
          // sms: "40"
          // success: true
          // usedCompanyProfiles: "2"
          // usedEmail: "8"
          // usedSMS: "0"
          // usedUsers: "1"
          // users: "1"
          scope.cdr.markForCheck()
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  radioButtonChange() {
    var scope = this;

    if (scope.cardRadioButton == '2') {
      setTimeout(() => {
        scope.loadSt()

      }, 1000)
    }
  }
  openAgencyPlan() {
    var scope = this;

    scope.viewAgencyPlan = true;
  }
  changeTab() {
    var scope = this;
    scope.isChanged = !scope.isChanged;
    if (scope.isAgencyPlan == true) {
      scope.viewAgencyPlan = true;
    }
    if (scope.isChanged == true) {
      if (scope.viewAgencyPlan == true) {
        scope.viewAgencyPlan = false;
        scope.isAgencyPlan = true;
        // if (scope.agencyPlanDrop == '10') {
        //   scope.agencyPricing = 10 * 20;
        // } else if (scope.agencyPlanDrop == '25') {
        //   scope.agencyPricing = 25 * 18;
        // } else if (scope.agencyPlanDrop == '50') {
        //   scope.agencyPricing = 50 * 16;
        // } else if (scope.agencyPlanDrop == '100') {
        //   scope.agencyPricing = 100 * 13;
        // } else if (scope.agencyPlanDrop == '250') {
        //   scope.agencyPricing = 100 * 10;
        // } else if (scope.agencyPlanDrop == '500') {
        //   scope.agencyPricing = 100 * 8;
        // }
        this.agencyPlanDrop = this.minProfile;
        this.agencyPricing = this.profileRate;
        this.cdr.detectChanges();
        scope.openUpdate();
        if (scope.currentPlan.toLocaleLowerCase() == 'free trial') {
          scope.hideexistingRadio = true;
          scope.cardRadioButton = '2';
          scope.radioButtonChange();
        }

      } else {

        let usage = typeof scope.usageData['usedCompanyProfiles'] == 'string' ? parseInt(scope.usageData['usedCompanyProfiles']) : scope.usageData['usedCompanyProfiles']

        if (scope.selectedPlan.PlanName.toLocaleLowerCase() != 'large' && usage > 1 && this.isCancelled) {
          scope.invalidPlan = true;
          scope.isChanged = !scope.isChanged;
        } else {
          scope.isAgencyPlan = false;
          scope.openUpdate();
          if (scope.currentPlan.toLocaleLowerCase() == 'free trial') {
            scope.hideexistingRadio = true;
            scope.cardRadioButton = '2';
            scope.radioButtonChange();
          }
        }
        scope.openUpdate();
      }
      scope.loadCardData()
    }
  }
  openUpdate() {
    var scope = this;
    scope.cdr.markForCheck()

    // setTimeout(() => {
    //   scope.loadSt()

    // }, 1000)
  }
  async loadSt() {
    var scope = this;
    scope.stripeObj = await loadStripe(environment.stripeKey);

    // stripe1.set

    // InitStripeButton();

    var elements = scope.stripeObj.elements();

    var style = {
      base: {
        color: '#000',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        // fontColor:'#',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#ff0000',
        iconColor: '#ff0000'
      }
    };

    // Create an instance of the card Element.
    scope.cardNumberObj = elements.create('cardNumber', { iconStyle: "solid", showIcon: true, hidePostalCode: true, style: style });
    scope.cardExpiryDateObj = elements.create('cardExpiry', { style: style });
    scope.cardCvvObj = elements.create('cardCvc', { style: style });

    // Add an instance of the card Element into the `card-element` <div>.
    scope.cardNumberObj.mount('#cardNumber');
    scope.cardExpiryDateObj.mount('#cardExpiryDate');
    scope.cardCvvObj.mount('#cardCvv');

    // Handle real-time validation errors from the card Element.
    // scope.cardObj['addEventListener']('change', function (event) {

    //   var displayError = document.getElementById('card-errors');
    //   if (event.error) {
    //     displayError.textContent = event.error.message;
    //   } else {
    //     displayError.textContent = '';
    //   }
    // });

    // Handle form submission.

    // form.addEventListener('submit', function(event) {
    //   event.preventDefault();


    // });





  }
  changeCoupon() {
    var scope = this;
    scope.couponSubmitted = false;
    scope.validCoupon = false;
    scope.invalidCoupon = false;
    scope.cdr.markForCheck();
  }
  validateCoupon() {
    var scope = this;
    scope.couponSubmitted = true;
    scope.validCoupon = true;
    scope.cdr.markForCheck();
  }

  submitForm() {
    var scope = this;
    scope.invalidCard = false;
    scope.formSubmitted = true;
    if (scope.cardRadioButton == '2') {
      scope.stripeObj.createToken(scope.cardNumberObj, scope.cardExpiryDateObj, scope.cardCvvObj).then(function (result) {

        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;

          if (scope.terms == false) {
            return;
          }
        } else {
          // Send the token to your server.
          if (scope.terms == false) {
            return;
          }
          scope.stripeTokenHandler(result.token);
        }
      });
    } else {
      scope.stripeTokenHandler('');
    }
  }

  stripeTokenHandler(token) {
    var scope = this;
    if (token.id != undefined) {
      if (token.card != undefined) {

        if (scope.accountCountry.toLocaleLowerCase() == 'in') {
          if (token.card.brand.toLocaleLowerCase() == 'visa' || token.card.brand.toLocaleLowerCase() == "mastercard") {
            scope.callUpdateCard(token.id)
          } else {
            scope.invalidCard = true;
            return
          }
        }
        else {
          scope.callUpdateCard(token.id);
        }

      } else {
        scope.invalidCard = true;
        return
      }

    } else {
      scope.invalidCard = true;
      this.callUpdateCard(token)
      return
    }
  }

  callUpdateCard(token) {
    var scope = this;
    scope.loading = true;
    scope.couponSubmitted = true;
    // let finalData={Data:JSON.stringify(scope.pageService.encrypt(data))}
    if (scope.isAgencyPlan == true) {
      let data = {
        CardToken: token,
        Key: scope.accessKey,
        Quantity: this.navData.data.MinSeats,
        CouponCode: scope.couponCode
      }
      scope.callAgecySubscription(data);
    } else {
      let data: any = {
        // PlanId: this.navData.data.isYearly ? this.navData.data.plan.yearly : this.navData.data.plan.monthly,
        CardToken: token,
        Key: scope.accessKey,
        // TransactionType: 'New',
        Type: 'plan',
        CouponCode: scope.couponCode
      }

      if (this.navData.data.isYearly) {
        data.PlanId = this.navData.data.plan.yearly
      } else {
        if (this.isSwitchToYearly) {
          data.PlanId = this.navData.data.plan.yearly
        } else {
          data.PlanId = this.navData.data.plan.monthly
        }
      }

      if (scope.isCancelled == true) {
        this.callNewSubscription(data);
      } else {
        if (scope.currentPlan.toLocaleLowerCase() !== 'free trial') {
          scope.callUpdateSubscription(data);
        } else {
          this.callNewSubscription(data);
        }
      }


    }
  }

  callAgecySubscription(data) {
    var scope = this;
    scope.pageService.showLoader()
    scope.http.post(environment.apiPath + 'UpgradeToAgency', data)
      .subscribe((res) => {

        scope.loading = false;
        scope.pageService.hideLoader()
        scope.cdr.markForCheck();
        if (res['success']) {
          scope.closeModal({ flag: true })
          // this.openThanyou();
          // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: true } });
        } else {           
          // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: false } });
          this.pageService.hideLoader();
          scope.pageService.showError('Oops!!!', res['errorMsg']);
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: false } });
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  callUpdateSubscription(data) {
    var scope = this;
    // scope.pageService.showLoader()
    scope.http.post(environment.apiPath + 'UpdateSubscription', data)
      .subscribe((res) => {
        scope.loading = false;
        scope.pageService.hideLoader()
        scope.cdr.markForCheck();
        if (res['success']) {
          scope.closeModal({ flag: true })
          // this.openThanyou();
          // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: true } });
          // window.location.reload()
          this.pageService.checkBilling("accountUpgraded")
          // localStorage.setItem("isExpired","false")
        } else {
          // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: false } });
          scope.pageService.showError('Oops!!!', res['errorMsg']);
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        scope.loading = false;
        // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: false } });
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  callNewSubscription(data) {
    var scope = this
    scope.http.post(environment.apiPath + 'NewSubscription', data)
      .subscribe((res) => {
        scope.loading = false;
        scope.pageService.hideLoader()
        scope.cdr.markForCheck();
        if (res['success']) {
          scope.closeModal({ flag: true })
          // this.openThanyou()
          // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: true } });
          this.pageService.checkBilling("accountUpgraded")
        } else {
          // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: false } });
          scope.pageService.showError('Oops!!!', res['errorMsg']);
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        scope.loading = false;
        // this.router.navigate(['/general/billing/payment-status'], { state: { isPaymentDone: false } });
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  changeCheckbox(targetItem, value) {
    var scope = this
    if (value.checked == true) {
      scope.selectedPlan = targetItem;
    } else {
      scope.selectedPlan = undefined;
    }
    scope.cdr.markForCheck();
  }

  closeModal(data) {
    if (data != undefined) {
      this.dialogRef.close(data)
    } else {
      this.dialogRef.close()
    }
  }

  // set plan

  checkPlan: any = 1;
  borderClrStr: any = '2px solid green!important'
  minProfile: any = 11;

  profileRate: any = 220;
  setPlan(value) {
    if (value == 5) {
      this.checkPlan = 0;
      this.minProfile = 5;
      this.profileRate = 125;
    } else if (value == 11) {
      this.checkPlan = 1;
      this.minProfile = 11;
      this.profileRate = 220;
    } else if (value == 25) {
      this.checkPlan = 2;
      this.minProfile = 25;
      this.profileRate = 450;
    } else if (value == 75) {
      this.checkPlan = 3;
      this.minProfile = 75;
      this.profileRate = 1200;
    } else if (value == 150) {
      this.checkPlan = 4;
      this.minProfile = 150;
      this.profileRate = 2250;
    } else if (value == 300) {
      this.checkPlan = 5;
      this.minProfile = 300;
      this.profileRate = 4200;
    }
  }


  contactSale() {
    this.isSubmit = true;

    if (this.contactSales.invalid) {
      return
    }

    let data = {
      key: this.accessKey,
      Name: this.contactSales.controls.personName.value,
      Plan: this.contactSales.controls.planName.value,
      Email: this.contactSales.controls.email.value,
      Phone: this.contactSales.controls.phoneNumber.value,
      Message: this.contactSales.controls.message.value
    }

    this.pageService.showLoader();
    this.http.post(environment.apiPath + 'SendSalesEmail', data).subscribe((res: any) => {
      this.pageService.showSuccess("Success", "One of our executive will get in touch with you soon.")
      this.closeModal(undefined);
      this.pageService.hideLoader();
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

      this.pageService.hideLoader();
      this.pageService.showError("Oops", err.error.errorMsg);
      }
    })
  }

  isSwitchToYearly: boolean = false;

  switchToYearly(e) {
    if (e.target.checked) {
      this.isSwitchToYearly = true;
    } else {
      this.isSwitchToYearly = false;
    }
  }

  openThanyou() {
    var scope = this;

    // scope.router.navigate(['/general/billing/pricing-plans'])
    let thankMOdel = scope.dialog.open(ThankyoubillingComponent, {
      width: '600px',
      panelClass: 'editModal',
      // position:{top:'10px'},
      // disableClose: true,
    })

    thankMOdel.afterClosed().subscribe(modelResult => {
      location.reload()
    })
  }
}
