<div class='col-12 padding-15'>
	<div class='row padding-15'>
		<div class='col-lg-11 col-sm-6 col-xs-6 text-1-2x text-bold'>
			Employee QR Code
		</div>
	</div>
	<div class="col-12">
		<form [formGroup]="EmployeeQR">

			<div class="col-12">
				<div>
					<mat-label class='text-bold'>Employee Name:</mat-label>
					<input type="text" class="form-control" name="employee" placeholder="Employee Name" formControlName="employee" [(ngModel)]="Employee" />
				</div>
				<mat-error *ngIf="formsubmitted && EmployeeQR.controls.employee.errors">
					<strong *ngIf='formsubmitted && EmployeeQR.controls.employee.errors.required'>*Required</strong>
				</mat-error>
			</div><br />
			<div class="col-12">
				<div>
					<mat-label class='text-bold'>Department:</mat-label>
					<input type="text" class="form-control" name="department" placeholder="Department" formControlName="department" [(ngModel)]="Department" />
				</div>
			</div><br />
		</form>
		<div class="text-center" *ngIf="showqrcode">
			<qrcode class="qrcodeparent" [qrdata]="layoutUrl" [width]="512" [level]="'M'"></qrcode>
		</div>
		<div class="col-12">
			<div class="col-12 text-center">

				<button class="btn btn-primary btn-elevate kt-login__btn-primary" (click)="ongenerate()">
					{{showqrcode==false? "Generate" : "Save & Download"}}</button>&nbsp;
				<button mat-button (click)="dialogRef.close()">Cancel</button>
			</div>
		</div>

	</div>
</div>
