import { environment } from './../../../../../environments/environment';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/internal/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: UntypedFormGroup;
	loading = false;
	errors: any = [];
	currentDomainUrl=''
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		public pageService: PageConfigService,
		public http: HttpClient
	) {
		this.unsubscribe = new Subject();
		
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		var scope = this;

		scope.initRegistrationForm();

		scope.pageService.showHideAsideAuth();
		scope.pageService.setTitle('Forgot Password')
		var url = window.location.href
		var arr = url.split("/");
		var result = arr[0] + "//" + arr[2];
		scope.currentDomainUrl = result.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		var scope = this;
		const controls = scope.forgotPasswordForm.controls;
		/** check form */
		if (scope.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		scope.loading = true;

		const email = controls['email'].value;
		let data = {
			Key: environment.Key,
			Email: email,
			DomainName:scope.currentDomainUrl
		}
		if (!scope.currentDomainUrl.includes('localhost') && !scope.currentDomainUrl.includes('grabyourreviews')) {
			data['DomainName'] = scope.currentDomainUrl
		} else {
			data['DomainName'] = 'app.grabyourreviews.com'
		}
		
		let finalData = {
			Data: scope.pageService.encrypt(JSON.stringify(data))
		}
		scope.loading = true;
		scope.http.post(environment.apiPath + "ForgetPassword", finalData)
			.subscribe((res) => {
				scope.loading = false;
				scope.cdr.markForCheck();
				if (res['success']) {
					scope.authNoticeService.setNotice('Password reset link is sent to your email address.', 'success');
					scope.router.navigateByUrl('/auth/login');
					// https://dev.grabyourreviews.com/ForgetPassword?token=Q%2fSK3M29NR7jDkYIu0O%2bOA%3d%3d
				} else {
					scope.authNoticeService.setNotice(res['errorMsg'], 'danger');
				}

			},(err) =>{
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					this.pageService.showError('Oops!',err.error.errorMsg);
				}
			})
		// this.authService.requestPassword(email).pipe(
		// 	tap(response => {
		// 		if (response) {
		// 			this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');
		// 			this.router.navigateByUrl('/auth/login');
		// 		} else {
		// 			this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', {name: this.translate.instant('AUTH.INPUT.EMAIL')}), 'danger');
		// 		}
		// 	}),
		// 	takeUntil(this.unsubscribe),
		// 	finalize(() => {
		// 		this.loading = false;
		// 		this.cdr.markForCheck();
		// 	})
		// ).subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
