<div class='col-12 padding-12'>
	<mat-tab-group class='customTab' (selectedTabChange)='innerTabChanged($event)' selectedIndex="0">
		<mat-tab class='text-bold' *ngFor="let item of smsTempLateData;let i=index" [label]="item.templateTitle">
			<div class='row padding-10'>
				<div class='col-md-11 col-sm-6 col-xs-6 text-right text-1x margin-tb-5'>
					Your Timezone<br /><b>{{timezone}}</b>&nbsp;
					<a class='cursor-pointer primary-color' (click)='openSettings()'>
						<i class='far fa-edit' ></i>
					</a>
				</div>
				<div class='col-md-1 col-sm-6 col-xs-6  text-right margin-tb-5'>
					<button class="btn danger-back-color btn-elevate kt-login__btn-primary " 
					(click)='openConfirmAlert(item.id)' 
					[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': deleteLoading}">
					<i class='far fa-trash-alt' style="position: relative;left: 15%;"></i></button>
					<!-- <button class="btn danger-back-color btn-elevate kt-login__btn-primary " (click)='openConfirmAlert(item.id)' [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': deleteLoading}"> -->
						
				</div>
				<div class='row padding-tb-5'>
					<div class='col-md-3 col-sm-12 col-xs-12'>
						<mat-label>Template Title</mat-label><br />
						<input name='templateTitle' [(ngModel)]="item.templateTitle" class="form-control templateTitle {{'templateTitle'+i}}" type="text" (ngModelChange)='textEntered("edit")'>

					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<mat-label>Send Interval</mat-label>
						<select [(ngModel)]="item.sendInterval" class="form-control" placeholder='Sent' (ngModelChange)='intervalChanged(item.sendInterval,i)'>
							<option value="assoonaspossible" selected>As soon as possible</option>
							<option value="1">1 Days</option>
							<option value="2">2 Days</option>
							<option value="3">3 Days</option>
							<option value="4">4 Days</option>
							<option value="5">5 Days</option>
							<option value="6">6 Days</option>
							<option value="7">7 Days</option>
							<option value="8">8 Days</option>
							<option value="9">9 Days</option>
							<option value="10">10 Days</option>
							<option value="11">11 Days</option>
							<option value="12">12 Days</option>
							<option value="13">13 Days</option>
							<option value="14">14 Days</option>
							<option value="15">15 Days</option>
						</select>
					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<div *ngIf='item.sendInterval!="assoonaspossible"'>
							<mat-label>Time&nbsp;</mat-label><br />
							<input [id]='"timepickerSms" +i' name='time' [(ngModel)]="item.sentTime" class="ui-timepicker-input timepicker form-control " type="text">
						</div>

					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<mat-checkbox [(ngModel)]="item.sentWeekend">Sent on weekend </mat-checkbox>
					</div>
					<div class='col-md-3 col-sm-12 col-xs-12'>
						<div class='notes roundedBox-radius padding-10 notes-back-color'>
							<span class="text-bold">Notes :</span>
							There may be 1 hour difference while sending Review Request.
						</div>
					</div>
				</div>
				<div class='row'>
					<div class='col-md-9 col-sm-12 col-xs-12'>
						<div class='col-12 margin-tb-10 smily-parent'>
							<mat-label>Message</mat-label><br />
							<textarea class='form-control messageTextArea {{"message"+i}}' (ngModelChange)='textEntered("edit")' [(ngModel)]="item.message" (ngModelChange)='textareaChanged($event,"textCounter"+i)' maxlength="160">

                           </textarea>
							<div class='smily cursor-pointer' (click)='openEmojiPop()'><i class="far fa-smile"></i></div>
							<section class="chat-session" *ngIf="openEmoji">
								<emoji-mart class="emoji-mart" set='apple' (emojiSelect)="addEmoji($event,item)" title="Pick your emoji…">
								</emoji-mart>
							</section>
							<div class='text-right textCount {{"textCounter"+i}}'>{{item.message.length}}/160</div>
						</div>

						<div class='col-12 text-center'>
							<button  class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"  (click)='onSMStemplateSubmit(item,"edit",i)' [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">
								Save</button>
						</div>
					</div>
					<div class='col-md-3 col-sm-12 col-xs-12 margin-tb-5'>
						<div class='col-12 margin-tb-5 notes roundedBox-radius padding-10 notes-back-color'>
							<span class="text-bold">Template placeholders</span>
							<br />
							You can use the following placeholders in our invitation templates for service reviews.
							<br />
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{firstname}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{firstname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The first name of the customer.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{name}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{name}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The full name of the customer.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{companyname}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{companyname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The name of the company.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{ownername}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{ownername}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The name of the company.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{reviewurl}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{reviewurl}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The one-click review link.
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-tab>
		<mat-tab class='text-bold' label='Create new template' *ngIf='smsTempLateData.length<5'>
			<div class='col-12 padding-10'>
				<div class='row'>
					<div class='col-md-12 col-sm-6 col-xs-6 text-right text-1x margin-tb-5'>
						Your Timezone<br /><b>{{timezone}}</b>&nbsp;
						<a href='/get-reviews/invitation-settings?from=edit_timezone'>
							<i class='far fa-edit'></i>
						</a>
					</div>
					<div class='col-md-3 col-sm-12 col-xs-12'>
						<mat-label>Template Title</mat-label><br />
						<input name='emailname' (ngModelChange)='textEntered("new")' [(ngModel)]="newTemplate.templateTitle" class="form-control templateTitleNew" type="text">

					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<mat-label>Send Interval</mat-label>
						<select [(ngModel)]="newTemplate.sendInterval" class='form-control' (ngModelChange)='intervalChanged(newTemplate.sendInterval,"new")' placeholder='Sent'>
							<option value="assoonaspossible" selected>As soon as possible</option>
							<option value="1">1 Days</option>
							<option value="2">2 Days</option>
							<option value="3">3 Days</option>
							<option value="4">4 Days</option>
							<option value="5">5 Days</option>
							<option value="6">6 Days</option>
							<option value="7">7 Days</option>
							<option value="8">8 Days</option>
							<option value="9">9 Days</option>
							<option value="10">10 Days</option>
							<option value="11">11 Days</option>
							<option value="12">12 Days</option>
							<option value="13">13 Days</option>
							<option value="14">14 Days</option>
							<option value="15">15 Days</option>
						</select>
					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<div *ngIf='newTemplate.sendInterval!="assoonaspossible"'>
							<mat-label>Time&nbsp;</mat-label>
							<input id='timepickerSmsNew' class='form-control' name='time' [(ngModel)]="newTemplate.Time" class="ui-timepicker-input timepicker" type="text">
						</div>

					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<mat-checkbox [(ngModel)]="newTemplate.sentWeekend">Sent on weekend </mat-checkbox>
					</div>
					<div class='col-md-3 col-sm-12 col-xs-12'>
						<div class='notes roundedBox-radius padding-10 notes-back-color'>
							<span class="text-bold">Notes :</span>
							There may be 1 hour difference while sending Review Request.
						</div>
					</div>
				</div>
				<div class='row'>
					<div class='col-md-9 col-sm-12 col-xs-12'>
						<div class='col-12 margin-tb-10 smily-parent'>
							<mat-label>Message</mat-label><br />
							<textarea class='form-control messageTextArea messageNew' (ngModelChange)='textEntered("new")' (focus)='focusEvent($event)' [(ngModel)]="newTemplate.message" (ngModelChange)='textareaChanged($event,"textCounterNew")' maxlength="160">
                          </textarea>
							<div class='smily cursor-pointer' (click)='openEmojiPop()'><i class="far fa-smile"></i></div>
							<section class="chat-session" *ngIf="openEmoji">
								<emoji-mart class="emoji-mart" set='apple' (emojiSelect)="addEmoji($event,newTemplate)" title="Pick your emoji…">
								</emoji-mart>
							</section>
							<div class='text-right textCount textCounterNew'>{{newTemplate.message.length}}/160</div>
						</div>
						<!-- <div class='col-12 margin-tb-10 smily-parent'>
                            <mat-label>Message</mat-label><br />
                            <textarea class='form-control messageTextArea  messageNew' 
                            (ngModelChange)='textEntered("new")' [(ngModel)]="newTemplate.message" 
                            (ngModelChange)='textareaChanged($event,"textCounterNew")' maxlength="160"></textarea>
                            <div class='text-right textCount textCounterNew'>{{newTemplate.message.length}}/160</div>
                            <div class='smily cursor-pointer' (click)='openEmojiPop()'><i class="far fa-smile"></i></div>
                            <section class="chat-session" *ngIf="openEmoji">
                                <emoji-mart class="emoji-mart" set='apple'
                                (emojiSelect)="addEmoji($event,newTemplate)" title="Pick your emoji…">
                               </emoji-mart>
                            </section>
                        </div> -->
						<!-- <div class='col-12 margin-tb-10'>
                           <ejs-richtexteditor #editor class='editorClassNew' [toolbarSettings]="tools" [(ngModel)]="newTemplate.emailHTML">
                           </ejs-richtexteditor>
                       </div> -->
						<div class='col-12 text-center'>
							<button  class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"  (click)='onSMStemplateSubmit(newTemplate,"new")' [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">
								Create</button>
						</div>
					</div>
					<div class='col-md-3 col-sm-12 col-xs-12 margin-tb-5'>
						<div class='col-12 margin-tb-5 notes roundedBox-radius padding-10 notes-back-color'>
							<span class="text-bold">Template placeholders</span>
							<br />
							You can use the following placeholders in our invitation templates for service reviews.
							<br />
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{firstname}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{firstname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The first name of the customer.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{name}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{name}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The full name of the customer.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{companyname}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{companyname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The name of the company.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{ownername}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{ownername}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The name of the company.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{reviewurl}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{reviewurl}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The one-click review link.
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>

</div>
