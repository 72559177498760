import { DataTableService } from './../../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
// import { UserData } from './../../material/data-table/material-table/material-table.component';

// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/internal/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, Logout } from '../../../../core/auth';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

import { Actions, Effect, ofType } from '@ngrx/effects';
/**
 * ! Just example => Should be removed in development
 */
import { LayoutConfigService } from '../../../../core/_base/layout';
import { DeviceDetectorService } from 'ngx-device-detector';
declare function loadLiveChat(): any
const DEMO_PARAMS = {
	EMAIL: 'admin@demo.com',
	PASSWORD: 'demo'
};
@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: UntypedFormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	currentDomainUrl = ''
	isReseller: any;
	private unsubscribe: Subject<any>;
	public loginData = {
		Email: '',
		Password: '',
		Key: environment.Key,

	}
	isSignupLink: boolean;
	public brandData = {
	}
	loaded: boolean = false;
	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public http: HttpClient,
		public ngZone: NgZone,
		public layoutConfigService: LayoutConfigService,
		public pageService: PageConfigService,
		public deviceSevice: DeviceDetectorService,
		public dataService: DataTableService,
		private titleService: Title
	) {
		this.unsubscribe = new Subject();

	}

	openReg() {
		this.router.navigate(['/auth/register'])
	}
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	// public navigate(commands: any[]): void {
	// 	this.ngZone.run(() => this.router.navigate(commands)).then();
	// }



	// ngAfterViewInit() {
	// 	this.autoRedirectPage();

	// }

	// ngAfterViewChecked(){

	// 	this.autoRedirectPage();
	// }

	demo = false;

	value;
	autoRedirectPage() {
		let param = this.router['browserUrlTree'].queryParams.sso;
		let type = this.router['browserUrlTree'].queryParams.type;
		if (param != undefined && param.length > 0) {
			localStorage.setItem(environment.authTokenKey, param);
			let GRDetail = localStorage.getItem('GRDetail');
			let data={Key:param};
				this.http.post(environment.apiV2Path + 'GetDetails',data).subscribe((res: any) => {
					if (res.success) {
						localStorage.setItem('GRDetail', JSON.stringify(res['data']));
						localStorage.setItem("account", JSON.stringify(res.data.account));
						localStorage.setItem("currentCountry", res.data.country)
						localStorage.setItem("isAgency", JSON.stringify(res.data.isAgency))
						localStorage.setItem("isExpired", JSON.stringify(res.data.isExpired))
						localStorage.setItem("isSMSAllowed", res.data.isSMSAllowed)
						localStorage.setItem("isApiAccess", res.data.isApiAccess)
						localStorage.setItem("IsReseller", JSON.stringify(res.data.isReseller))
						localStorage.setItem("IsVerify", JSON.stringify(res.data.isVerified))
						localStorage.setItem("current-plan", JSON.stringify(res.data.plan))
						localStorage.setItem('plan', JSON.stringify(res['data']['plan']));
						localStorage.setItem("showBranding",JSON.stringify(res.data.showBranding) )
						localStorage.setItem("user-access", res.data.userAccess)
						localStorage.setItem("view", JSON.stringify(res.data.view))

						localStorage.setItem("hasCreatedProfile", res.data.hasCreatedProfile);
						localStorage.setItem("hasOnboarding", res.data.hasOnboarding)
						let data = {
							brandLogo: res['data']['brandInfo']['logoUrl'],
							brandName: res['data']['brandInfo']['brandName'],
							domainName: res['data']['brandInfo']['domainName']
						}
						localStorage.setItem('brand-info', JSON.stringify(data))
						this.value = res.data.isExpired
						// Call profile list api

						let GRProfileList: any = JSON.parse(localStorage.getItem("GRProfileList"));
						////
						let dataN={Key:param};
						this.http.post(environment.apiPath + "GetProfileList",dataN).subscribe((res: any) => {
							localStorage.setItem("GRProfileList", JSON.stringify(res));
							GRProfileList = res;
							if (res.success) {
								GRProfileList = res.data;
								if (res.data[0]) {
									localStorage.setItem("ProfileId", JSON.stringify(res.data[0].profileId));
									localStorage.setItem("shortName", JSON.stringify(res.data[0].shortName));
									let data={
										Key:param,
										ProfileId:res.data[0].profileId
									};
									this.http.post(environment.apiPath + 'GetProfileDetails',data)
										.subscribe((res1: any) => {
											localStorage.setItem("GRProfileDetail", JSON.stringify(res1));
											if (res1['success']) {
												localStorage.setItem('platformError', res1.platformError);
												localStorage.setItem('TimeZone', JSON.stringify(res1['data'].timeZone))
												localStorage.setItem('profileCountry', res1.data.countryCode);
												localStorage.setItem('personName', res1.data.personName);
												localStorage.setItem('phoneNumber', res1.data.phoneNumber);

											} 

											setTimeout(() => {

												this.pageService.hideLoader();
													if (type == 'upgrade') {
					
														//this.router.navigate(['/general/billing'])
														location.href = "/general/billing";
													}
													else {
														// this.pageService.hideSsoLoader();
														//this.router.navigate(['/dashboard']);
														//location.href = "/dashboard";

														if (localStorage.getItem("hasCreatedProfile") == "true" && localStorage.getItem("hasOnboarding") == "false") {
															//this.router.navigate(['/onboarding/review-site'])
															location.href = "/onboarding/review-site";

														} else if ((localStorage.getItem("hasCreatedProfile") == undefined 
														|| localStorage.getItem("hasCreatedProfile") == 'undefined' 
														|| localStorage.getItem("hasCreatedProfile") == "false") && localStorage.getItem("hasOnboarding") == "false") {

															//this.router.navigate(['/onboarding/setup-profile'])
															location.href = "/onboarding/setup-profile";
														}
														else {
															location.href = "/dashboard";
														}
					
													}
											}, 500);
										})
								}
								else {
									this.pageService.hideLoader();
											if (type == 'upgrade') {
			
												//this.router.navigate(['/general/billing'])
												location.href = "/general/billing";
											}
											else {
												//this.router.navigate(['/dashboard']);
												//location.href = "/dashboard";

												if (localStorage.getItem("hasCreatedProfile") == "true" && localStorage.getItem("hasOnboarding") == "false") {
													//this.router.navigate(['/onboarding/review-site'])
													location.href = "/onboarding/review-site";

												} else if ((localStorage.getItem("hasCreatedProfile") == undefined 
												|| localStorage.getItem("hasCreatedProfile") == 'undefined' 
												|| localStorage.getItem("hasCreatedProfile") == "false") && localStorage.getItem("hasOnboarding") == "false") {

													//this.router.navigate(['/onboarding/setup-profile'])
													location.href = "/onboarding/setup-profile";
												}
												else {
													location.href = "/dashboard";
												}
											}
										
								}

								
							} else {
								
								return false;
							}
						}, (err) => {
							
							if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
								//this.pageService.showError('Oops!', err.error.errorMsg);
							}
						})
						
					} else {
						
						return false;
					}
				}, (err) => {
					
					if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
						//this.pageService.showError('Oops!', err.error.errorMsg);
					}
				})
		}

	}



	ngOnInit(): void {
		var scope = this;
		
		let param = this.router['browserUrlTree'].queryParams.sso;
		if (param != undefined) {
			this.pageService.showLoader();
			this.autoRedirectPage();		
		}
		// if (param != undefined) {
		// 	setTimeout(() => {
		// 		
		// 		window.location.reload();
		// 	}, 500)
		// }


		scope.pageService.setTitle('Login')

		var scope = this;
		var url = window.location.href
		var arr = url.split("/");
		var result = arr[0] + "//" + arr[2];
		scope.currentDomainUrl = result.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
		// scope.currentDomainUrl='reviews.likereferrals.com'

		if (!scope.currentDomainUrl.includes('localhost') && !scope.currentDomainUrl.includes('grabyourreviews')) {



			if (localStorage.getItem('IsSignUpLink') != null) {
				scope.loaded = true;
				scope.isSignupLink = JSON.parse(localStorage.getItem('isSignupLink'))
				scope.cdr.markForCheck()
			} else {
				scope.loaded = true;
				// scope.isSignupLink = JSON.parse(localStorage.getItem('isSignupLink'))
				scope.cdr.markForCheck()
			}

		} else {
			scope.loaded = true;
			scope.isSignupLink = true;
			scope.cdr.markForCheck()
		}
		scope.dataService.getBrandbserver().subscribe((res) => {
			scope.loaded = true;
			if (res == true) {
				scope.isSignupLink = false;
				// localStorage.setItem('IsSignUpLink', JSON.stringify(false))
				scope.brandData = JSON.parse(localStorage.getItem('brand-info'))
				scope.currentDomainUrl = scope.brandData['currentDomain'];
				scope.cdr.markForCheck()
			}
		})

		this.initLoginForm();
		this.pageService.showHideAsideAuth();
		// redirect back to the returnUrl before login
		// this.route.queryParams.subscribe(params => {

		// 	this.returnUrl = params['returnUrl'] || '/';
		// });




	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		// this.layoutConfigService.setlayout2Config();
		let verify = JSON.parse(localStorage.getItem('IsVerify'))
		// if(!verify)
		// 	this.layoutConfigService.setlayout2Config();

	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		// if (!this.authNoticeService.onNoticeChanged$.getValue()) {
		// 	const initialNotice = `Use account
		// 	<strong>${DEMO_PARAMS.EMAIL}</strong> and password
		// 	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
		// 	this.authNoticeService.setNotice(initialNotice, 'info');
		// }

		this.loginForm = this.fb.group({
			email: [DEMO_PARAMS.EMAIL, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});

		//Android
		//Desktop
		//Tablet
		//Ios
		if (this.deviceSevice.isDesktop()) {
			this.loginData['Device'] = 'Desktop';
		} else if (this.deviceSevice.isMobile()) {
			this.loginData['Device'] = 'Android';
		} else if (this.deviceSevice.isTablet()) {
			this.loginData['Device'] = 'Tablet';
		}
		let deviceInfo = this.deviceSevice.getDeviceInfo();
		this.loginData['Browser'] = deviceInfo.browser;
		let ip = localStorage.getItem('IpAddress');
		this.loginData['IpAddress'] = ip;
		// this.pageService.getIpAddress()
		// .then((res)=>{
		// 	this.loginData['IpAddress']=res
		// })
	}

	/**
	 * Form Submit
	 */

	isPasswordError = false;
	isMailError = false;
	isWrongMailError = false;

	errMsg;
	submit() {
		var scope = this;
		const controls = scope.loginForm.controls;
		/** check form */
		if (scope.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		scope.loading = true;
		if (!scope.currentDomainUrl.includes('localhost') && !scope.currentDomainUrl.includes('grabyourreviews')) {
			scope.loginData['DomainName'] = scope.currentDomainUrl
		} else {
			scope.loginData['DomainName'] = 'app.grabyourreviews.com'
		}

		//Call Login Api
		scope.auth
			.login(scope.loginData)
			.pipe(
				tap(user => {
					if (user['success']) {
						scope.store.dispatch(new Login({ authToken: user.key }));
						localStorage.setItem('adminemail', scope.loginData.Email);

						localStorage.setItem('IsVerify', JSON.stringify(user['isVerify']));
						// localStorage.setItem('IsReseller', JSON.stringify(user['isReseller']));

						scope.pageService.getPermissions();

						scope.pageService.setIsVerify()
						localStorage.setItem("hasCreatedProfile", user["hasCreatedProfile"]);
						localStorage.setItem("hasOnboarding", user['hasOnboarding'])

						let param = user.key;
						let data={Key:param};
						this.http.post(environment.apiV2Path + 'GetDetails',data).subscribe((res: any) => {
							// alert()
							if (res.success) {
								localStorage.setItem('GRDetail', JSON.stringify(res['data']));
						localStorage.setItem("account", JSON.stringify(res.data.account));
						localStorage.setItem("currentCountry", res.data.country)
						localStorage.setItem("isAgency", JSON.stringify(res.data.isAgency))
						localStorage.setItem("isExpired", JSON.stringify(res.data.isExpired))
						localStorage.setItem("isSMSAllowed", res.data.isSMSAllowed)
						localStorage.setItem("isApiAccess", res.data.isApiAccess)
						localStorage.setItem("IsReseller", JSON.stringify(res.data.isReseller))
						localStorage.setItem("IsVerify", JSON.stringify(res.data.isVerified))
						localStorage.setItem("current-plan", JSON.stringify(res.data.plan))
						localStorage.setItem('plan', JSON.stringify(res['data']['plan']));
						localStorage.setItem("showBranding",JSON.stringify(res.data.showBranding) )
						localStorage.setItem("user-access", res.data.userAccess)
						localStorage.setItem("view", JSON.stringify(res.data.view))

						localStorage.setItem("hasCreatedProfile", res.data.hasCreatedProfile);
						localStorage.setItem("hasOnboarding", res.data.hasOnboarding)
								let data = {
									brandLogo: res['data']['brandInfo']['logoUrl'],
									brandName: res['data']['brandInfo']['brandName'],
									domainName: res['data']['brandInfo']['domainName']
								}
								localStorage.setItem('brand-info', JSON.stringify(data))
								this.value = res.data.isExpired
								// Call profile list api

								let GRProfileList: any = JSON.parse(localStorage.getItem("GRProfileList"));
								
								let dataN={Key:param};

								this.http.post(environment.apiPath + "GetProfileList",dataN).subscribe((res: any) => {
									localStorage.setItem("GRProfileList", JSON.stringify(res));
									GRProfileList = res;
									if (res.success) {

										
										GRProfileList = res.data;
										if (res.data[0]) {
											localStorage.setItem("ProfileId", JSON.stringify(res.data[0].profileId));
											localStorage.setItem("shortName", JSON.stringify(res.data[0].shortName));
											let data={
												Key:param,
												ProfileId:res.data[0].profileId
											};
											this.http.post(environment.apiPath + 'GetProfileDetails',data)
												.subscribe((res1: any) => {
													localStorage.setItem("GRProfileDetail", JSON.stringify(res1));
													if (res1['success']) {
														localStorage.setItem('platformError', res1.platformError);
														localStorage.setItem('TimeZone', JSON.stringify(res1['data'].timeZone))
														localStorage.setItem('profileCountry', res1.data.countryCode);
														localStorage.setItem('personName', res1.data.personName);
														localStorage.setItem('phoneNumber', res1.data.phoneNumber);

													} 

													this.RedirectToDashboardAfterLogin(scope, user);
												})
										}
										else {
											this.RedirectToDashboardAfterLogin(scope, user);
										}

										
									} else {
										return false;
									}
								}, (err) => {
									if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
										//this.pageService.showError('Oops!', err.error.errorMsg);
									}
								})
							}
						}, (err) => {
							if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
								//this.pageService.showError('Oops!', err.error.errorMsg);
							}
						})

					} else {

						this.errMsg = user.errorMsg
						this.loading = false;

						if (user.errorMsg.includes("The entered username and password do not match.")) {
							this.isWrongMailError = true;
							this.isPasswordError = false;
							this.isMailError = false;
							setTimeout(() => {
								this.isWrongMailError = false;
							}, 2500);
						} else if (user.errorMsg.includes("Too many failed login attempt. Try login after 30 minutes.")) {
							this.isWrongMailError = true;
							this.isPasswordError = false;
							this.isMailError = false;
							setTimeout(() => {
								this.isWrongMailError = false;
							}, 2500);
						}else if (user.errorMsg.includes("Verify your email address")) {
							this.isWrongMailError = false;
							this.isPasswordError = false;
							this.isMailError = true;
						}
						this.cdr.detectChanges();
						// user.errorMsg.length > 30 ? this.isPasswordError = true : this.isMailError = false;

						// if (!this.isError) scope.authNoticeService.setNotice(scope.translate.instant(user.errorMsg), 'danger');
					}
				}),
				takeUntil(scope.unsubscribe),
				// finalize(() => {
				// 	scope.loading = false;
				// 	scope.cdr.markForCheck();
				// })
			)
			.subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	resetPass() {
		let data = {
			Key: environment.Key,
			Email: this.loginForm.controls['email'].value,
			DomainName: this.currentDomainUrl
		}

		if (!this.currentDomainUrl.includes('localhost') && !this.currentDomainUrl.includes('grabyourreviews')) {
			data['DomainName'] = this.currentDomainUrl
		} else {
			data['DomainName'] = 'app.grabyourreviews.com'
		}

		let finalData = {
			Data: this.pageService.encrypt(JSON.stringify(data))
		}

		this.pageService.showLoader();
		this.http.post(environment.apiPath + 'ForgetPassword', finalData).subscribe((res: any) => {
			if (res.success) {
				this.isPasswordError = false;
				this.pageService.hideLoader();
				this.cdr.markForCheck();
				this.pageService.showSuccess('Sent', 'Please check your email to set your password')
			} else {
				if (res.status != 500 || res.statusText != "Internal Server Error") {
					this.pageService.hideLoader();
					//this.pageService.showError('Oops!', res.errorMsg);
				}
			}
		}, (err) => {
			if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
				this.pageService.hideLoader();
				//this.pageService.showError('Oops!', err.error.errorMsg);
			}
		})
	}

	resendVerificationMail() {
		this.pageService.showLoader();
		this.http.get(environment.apiPath + 'ReSendVerificationEmail?Key=' + environment.Key + "&email=" + this.loginForm.controls['email'].value)
			.subscribe((res) => {
				if (res['success']) {
					this.pageService.hideLoader();
					this.pageService.showSuccess('Sent', 'Verification email sent successfully')
				} else {

					this.pageService.hideLoader();
					this.pageService.showError('Oops!!!', res['errorMsg'])
				}
			}, (err) => {

				this.pageService.hideLoader();
				this.pageService.showError(err.error.errorMsg, "Error");
			})
	}

	checkAndRedirectURLFn(): boolean {
		let shouldDefaultUrl = true;
		let redirectURL = localStorage.getItem("redirectUrl");
		if (redirectURL != null && redirectURL != undefined && redirectURL != "") {
			if (redirectURL.toLocaleLowerCase().indexOf("auth") == -1) {
				shouldDefaultUrl = false;
			}
		}

		return shouldDefaultUrl;
	}


	RedirectToDashboardAfterLogin(scope:any, user:any):void{
		setTimeout(() => {
			if (!user['hasOnboarding']) {
				if (this.checkAndRedirectURLFn()) {
					this.router.navigate(['/onboarding/setup-profile'])
				}
				else {
					let redirectURL = localStorage.getItem("redirectUrl");
					this.router.navigate([redirectURL])
				}
			} else {
				let key = user['key'];
				let GRProfileList: any = JSON.parse(localStorage.getItem("GRProfileList"));
				if (GRProfileList) {
					if (GRProfileList.data.length > 0) {
						scope.pageService.hideLoader();
						if (this.value) {

							if (this.checkAndRedirectURLFn()) {
								this.router.navigate(['/general/billing'])
							}
							else {
								let redirectURL = localStorage.getItem("redirectUrl");
								this.router.navigate([redirectURL])
							}
							return;
						}
						if (user['hasCreatedProfile'] && !user['hasOnboarding']) {

							if (this.checkAndRedirectURLFn()) {
								this.router.navigate(['/onboarding/review-site'])
							}
							else {
								let redirectURL = localStorage.getItem("redirectUrl");
								this.router.navigate([redirectURL])
							}
						} else if (!user['hasCreatedProfile'] && !user['hasOnboarding']) {

							if (this.checkAndRedirectURLFn()) {
								this.router.navigate(['/onboarding/setup-profile'])
							}
							else {
								let redirectURL = localStorage.getItem("redirectUrl");
								this.router.navigate([redirectURL])
							}
						} else {
							setTimeout(() => {

								if (this.checkAndRedirectURLFn()) {
									scope.router.navigate(['/dashboard']);
								}
								else {
									let redirectURL = localStorage.getItem("redirectUrl");
									this.router.navigate([redirectURL])
								}
							}, 3000);
							scope.loading = false;
						}
					}
					else {
						if (user['hasCreatedProfile'] && !user['hasOnboarding']) {

							if (this.checkAndRedirectURLFn()) {
								this.router.navigate(['/onboarding/review-site'])
							}
							else {
								let redirectURL = localStorage.getItem("redirectUrl");
								this.router.navigate([redirectURL])
							}
						} else if (!user['hasCreatedProfile'] && !user['hasOnboarding']) {

							if (this.checkAndRedirectURLFn()) {
								this.router.navigate(['/onboarding/setup-profile'])
							}
							else {
								let redirectURL = localStorage.getItem("redirectUrl");
								this.router.navigate([redirectURL])
							}
						} else {
							if (this.checkAndRedirectURLFn()) {
								scope.router.navigate(['/dashboard']);
							}
							else {
								let redirectURL = localStorage.getItem("redirectUrl");
								this.router.navigate([redirectURL])
							}
							scope.loading = false;
						}
					}
				}
				else {
					let data={Key:key};
					scope.http.post(environment.apiPath + 'GetProfileList',data)
						.subscribe((res: any) => {
							if (res.success) {
								localStorage.setItem("GRProfileList", JSON.stringify(res.data));
								localStorage.setItem("ProfileId", JSON.stringify(res.data[0].profileId));
								localStorage.setItem("shortName", JSON.stringify(res.data[0].shortName));
							} else {
								return false;
							}
							if (res['data'].length > 0) {
								scope.pageService.hideLoader();
								if (this.value) {

									if (this.checkAndRedirectURLFn()) {
										this.router.navigate(['/general/billing'])
									}
									else {
										let redirectURL = localStorage.getItem("redirectUrl");
										this.router.navigate([redirectURL])
									}
									return;
								}
								if (user['hasCreatedProfile'] && !user['hasOnboarding']) {

									if (this.checkAndRedirectURLFn()) {
										this.router.navigate(['/onboarding/review-site'])
									}
									else {
										let redirectURL = localStorage.getItem("redirectUrl");
										this.router.navigate([redirectURL])
									}
								} else if (!user['hasCreatedProfile'] && !user['hasOnboarding']) {

									if (this.checkAndRedirectURLFn()) {
										this.router.navigate(['/onboarding/setup-profile'])
									}
									else {
										let redirectURL = localStorage.getItem("redirectUrl");
										this.router.navigate([redirectURL])
									}
								} else {
									setTimeout(() => {

										if (this.checkAndRedirectURLFn()) {
											scope.router.navigate(['/dashboard']);
										}
										else {
											let redirectURL = localStorage.getItem("redirectUrl");
											this.router.navigate([redirectURL])
										}
									}, 3000);
									scope.loading = false;
								}
							}
							else {
								if (user['hasCreatedProfile'] && !user['hasOnboarding']) {

									if (this.checkAndRedirectURLFn()) {
										this.router.navigate(['/onboarding/review-site'])
									}
									else {
										let redirectURL = localStorage.getItem("redirectUrl");
										this.router.navigate([redirectURL])
									}
								} else if (!user['hasCreatedProfile'] && !user['hasOnboarding']) {

									if (this.checkAndRedirectURLFn()) {
										this.router.navigate(['/onboarding/setup-profile'])
									}
									else {
										let redirectURL = localStorage.getItem("redirectUrl");
										this.router.navigate([redirectURL])
									}
								} else {
									if (this.checkAndRedirectURLFn()) {
										scope.router.navigate(['/dashboard']);
									}
									else {
										let redirectURL = localStorage.getItem("redirectUrl");
										this.router.navigate([redirectURL])
									}
									scope.loading = false;
								}
							}
						}, (err) => {
							if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
								//this.pageService.showError('Oops!', err.error.errorMsg);
							}
						})
				}

			}
		}, 500);
	}
}
