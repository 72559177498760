<div class='col-12'>
	<div class='row padding-15'>
		<div class='col-md-5 text-1-2x text-bold form-control no-border'>
			Add Company
		</div>
		<div class='col-md-6 text-1-2x text-bold'>
			<select class="form-control" name="ProfileId" [(ngModel)]="copyProfileId" *ngIf="isShow">
				<option value="">Copy from existing</option>
				<option [value]="item.profileId" *ngFor="let item of profileList">{{item.companyName}}</option>
			</select>
		</div>
		 <div class='col-md-1 text-1-2x text-center'>
			<i class="fa fa-times cursor-pointer" (click)='dialogRef.close()'></i>
		</div> 
	</div>
	<div class='col-12'>
		<mat-divider></mat-divider>
	</div>
	<div class='row padding-15'>
		<div class='col-12 padding-tb-10'>
			<form [formGroup]="addProfileForm">
				<div class='row'>
					<div class="col-6">
						<div class="form-group">
							<div>
								<mat-label class='text-bold'>Your Name</mat-label>
								<input type="text" formControlName="PersonName" class="form-control" name="PersonName"
									placeholder="Person Name">
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<div>
								<mat-label class='text-bold'>Company Name*</mat-label>
								<input type="text" formControlName="CompanyName" class="form-control" name="CompanyName"
									placeholder="Company Name" (blur)='generteShortName()'>
							</div>
							<mat-error *ngIf="submittedForm && addProfileForm.controls.CompanyName.errors">
								<strong
									*ngIf='submittedForm && addProfileForm.controls.CompanyName.errors.required'>*Required</strong>
							</mat-error>
						</div>
					</div>
				</div>
				<div class='row'>
					<div class="col-6">
						<div class="form-group">
							<div>
								<mat-label class='text-bold'>Email*</mat-label>
								<input type="text" formControlName="Email" class="form-control" name="Email"
									placeholder="Email address">
							</div>
							<mat-error *ngIf="submittedForm && addProfileForm.controls.Email.errors">
								<strong
									*ngIf='submittedForm && addProfileForm.controls.Email.errors.required'>*Required</strong>
								<strong *ngIf='submittedForm && addProfileForm.controls.Email.errors.email'>*Email
									Invalid</strong>
							</mat-error>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<mat-label class='text-bold tooltip-cust'>
								Unique Profile Name*
								<i class="fa fa-info" aria-hidden="true"
									ngbTooltip="Your unique webpage name example {{uniqueUrl[0]}}//{{uniqueUrl[1]}}{{uniqueUrl[2]}}/YourUniqueProfileName"
									placement="top"></i>
							</mat-label>
							<div class="input-group mb-3">
								<div class="input-group-prepend">
									<span class="input-group-text"
										id="basic-addon3">{{uniqueUrl[0]}}//{{uniqueUrl[1]}}{{uniqueUrl[2]}}/</span>
								</div>
								<input type="text" formControlName="ShortName" class='form-control shortName' [ngClass]="{'loadingTextbox': loadText,'rightTextbox': rightIcon,
							'wrongTextbox': wrongIcon}" name="ShortName" [(ngModel)]="addProfileForm.value.ShortName"
									placeholder="Unique Profile Name" (ngModelChange)='validateUrl()'
									autocomplete="off">
							</div>
							<!-- <div>
								<mat-label class='text-bold'>Unique Profile Name*</mat-label>
								<input type="text" formControlName="ShortName" 
								class='form-control shortName' [ngClass]="{'loadingTextbox': loadText,'rightTextbox': rightIcon,
							'wrongTextbox': wrongIcon}" name="ShortName" [(ngModel)]="addProfileForm.value.ShortName" 
							placeholder="Unique Profile Name" (ngModelChange)='validateUrl()' autocomplete="off">
							</div> -->
							<mat-error *ngIf="wrongIcon">
								<strong *ngIf='wrongIcon'>*Profile name already taken</strong>
							</mat-error>
							<mat-error *ngIf="submittedForm && addProfileForm.controls.ShortName.errors">
								<strong
									*ngIf='submittedForm && addProfileForm.controls.ShortName.errors.required'>*Required</strong>
							</mat-error>
						</div>
					</div>
				</div>
				<div class='row'>
					<div class="col-6">
						<div class="form-group">
							<div>
								<mat-label class='text-bold'>Country*</mat-label>
								<mat-select class="form-control" formControlName="Country" name="Country"
									(selectionChange)="selectCountry($event)">
									<mat-option *ngFor='let item of countryList' [value]='item.name'>
										{{item.name}}
									</mat-option>
								</mat-select>
								<!-- <input type="text" formControlName="Country" class="form-control" name="Country"> -->
							</div>
							<mat-error *ngIf="submittedForm && addProfileForm.controls.Country.errors">
								<strong
									*ngIf='submittedForm && addProfileForm.controls.Country.errors.required'>*Required</strong>
							</mat-error>
						</div>
					</div>

					<div class="col-6">
						<div class="form-group">
							<div>
								<mat-label class='text-bold'>Phone Number</mat-label>
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text" id="basic-addon1">{{cCode}}</span>
									</div>
									<input type="text" formControlName="PhoneNumber" class="form-control"
										name="PhoneNumber" placeholder="Phone Number" aria-describedby="basic-addon1">
								</div>
								<!-- <input type="text" formControlName="PhoneNumber" class="form-control" name="PhoneNumber" placeholder="Phone Number"> -->
							</div>
							<mat-error *ngIf="submittedForm && addProfileForm.controls.PhoneNumber.errors">
								<strong
									*ngIf='submittedForm && addProfileForm.controls.PhoneNumber.errors.pattern'>*Enter
									proper number</strong>
							</mat-error>
						</div>
					</div>

				</div>
				<div class='row'>
					<div class="col-6" *ngIf="isShowAlphaNumeric" style="display: none;">
						<div class="form-group">
							<div>
								<mat-label class='text-bold tooltip-cust'>
									SMS Sender Name*
									<i class="fa fa-info" aria-hidden="true"
										ngbTooltip="Your sender name for SMS review request" placement="top"></i>
								</mat-label>
								<input type="text" class="form-control" name="Alpha Numeric Sender Id"
									formControlName="AlphaNumericSenderId" placeholder="YOUR-CMP-NAME" maxlength="11">
							</div>
							<mat-error *ngIf="submittedForm && addProfileForm.controls.AlphaNumericSenderId.errors">
								<strong
									*ngIf='submittedForm && addProfileForm.controls.AlphaNumericSenderId.errors.required'>*Required</strong>
								<strong
									*ngIf='submittedForm && addProfileForm.controls.AlphaNumericSenderId.errors.pattern'>*Sender
									Id must be combination of alpha (a-z, A-Z) and numeric (0-9) </strong>
							</mat-error>
						</div>
					</div>
					<div [ngClass]="{'col-md-6':isShowAlphaNumeric == true,'col-md-12':isShowAlphaNumeric==false}">
						<div class="form-group">
							<div>
								<mat-label class='text-bold'>Industry*</mat-label>
								<select class="form-control" formControlName="Industry" name="Industry">
									<option value="">Select Industry</option>
									<option [value]="item" *ngFor="let item of industryList">{{item}}</option>
								</select>
							</div>
							<mat-error *ngIf="submittedForm && addProfileForm.controls.Industry.errors">
								<strong
									*ngIf='submittedForm && addProfileForm.controls.Industry.errors.required'>*Required</strong>
							</mat-error>
						</div>
					</div>
				</div>
			
				<div class='row text-center margin-tb-10'>
					<button class="btn btn-primary btn-elevate kt-login__btn-primary"
						[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}"
						(click)="onSubmit()">Submit</button>
					<button class="btn btnCancel" *ngIf="isShow" (click)="dialogRef.close()">Cancel</button>
				</div>
			</form>
		</div>
	</div>
</div>