<!-- Delete customer popup -->
<div class='col-12 padding-15 ' *ngIf='source=="delete customer"'>
	<div class=' col-12 text-1-1x text-center margin-tb-5 padding-10'>
		{{message}}
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn danger-back-color btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>
<!-- Delete Friend popup -->
<div class='col-12 padding-15 ' *ngIf='source=="delete friend"'>
	<div class=' col-12 text-1-1x text-center margin-tb-5 padding-10'>
		{{message}}
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn danger-back-color btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>
<!-- import csv alert -->
<div class='col-12 padding-15 ' *ngIf='source=="invite customer csv"'>
	<div class='col-12 line-height-2x text-1-1x margin-tb-5 padding-10 ' [innerHTML]='message'>
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn btn-warning text-color-light btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>
<!-- Delete template popup -->
<div class='col-12 padding-15 ' *ngIf='source=="delete template"'>
	<div class=' col-12 line-height-2x text-1-1x text-center margin-tb-5 padding-10'>
		{{message}}
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn danger-back-color btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>
<!-- Create sms template Alert -->
<div class='col-12 padding-15 ' *ngIf='source=="create sms" || source=="invite customer duplicate"'>
	<div class='col-12 text-1-5x text-bold  margin-tb-5 padding-10  text-center' [innerHTML]='title'>

	</div>
	<div class='col-12 line-height-2x text-1-1x margin-tb-5 padding-10 ' [innerHTML]='message'>
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn btn-warning text-color-light btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>
<!-- CSv Warning messge -->
<div class='col-12 padding-15 ' *ngIf='source=="csv upload warning"'>
	<div class=' col-12 line-height-2x text-1-1x text-center margin-tb-5 padding-10'>
		{{message}} <span class='primary-color text-right cursor-pointer padding-10' (click)="sampleCsv()">Download sample csv</span>
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn btn-warning text-color-light btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>

<!-- deleteb account -->
<div class='col-12 padding-15 ' *ngIf='source=="delete account"'>
	<div class=' col-12 text-1-5x text-bold margin-tb-5 padding-10 text-center' [innerHTML]='title'>

	</div>
	<div class='col-12 line-height-2x  margin-tb-5 padding-10 ' [innerHTML]='message'>
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn danger-back-color text-color-light btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>
<!-- Delete Domain -->
<div class='col-12 padding-15 ' *ngIf='source=="delete domain"'>
	<div class=' col-12 line-height-2x text-1-1x text-center margin-tb-5 padding-10'>
		{{message}}
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn danger-back-color btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>
<!-- Send on weekend confirm alert -->
<div class='col-12 padding-15 ' *ngIf='source=="sent weekend"'>
	<div class=' col-12 line-height-2x text-1-1x text-center margin-tb-5 padding-10'>
		{{message}}
	</div>
	<div class='row margin-tb-10 text-center'>
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn danger-back-color btn-elevate kt-login__btn-primary margin-lr-5"
		 (click)="onSubmit()">{{buttonText}}</button>
	</div>
</div>


<!-- Send hasShowOnWidget confirm alert -->
<div class='col-12 padding-15 ' *ngIf='source=="hasShowOnWidget"'>
	<div class=' col-12 line-height-2x text-1-1x text-center margin-tb-5 padding-10'>
		{{message}}
	</div>
	<div class='row margin-tb-10 text-center'>
		<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"
		(click)="onSubmit()">{{buttonText}}</button>
		<button mat-button class='margin-lr-5' (click)="closeModal()">No</button>
	</div>
</div>

<!-- Send hasShowOnWidget confirm alert -->
<div class='col-12 padding-15 ' *ngIf='source=="addusersucess"'>
	<div class=' col-12 line-height-2x text-1-1x text-center margin-tb-5 padding-10' style="align-items: center;display: flex;">
		<i class='fa fa fa-check-circle' style='color:#3bb607; font-size: 30px !important;' ></i>
		<div>&nbsp; {{message}} </div> 
	</div>
	<div class='row margin-tb-10 text-center'>
		<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"
		 (click)="closeModal()">Ok</button>
	</div>
</div>

<!-- Show confirm alert for AssignNumber-->
<div class='col-12 padding-15 ' *ngIf='source=="AssignNumber"'>
	<div class=' col-12 line-height-2x text-1-1x text-center margin-tb-5 padding-10'>
		{{message}}
	</div>
	<div class='row margin-tb-10 text-center'>
		<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"
		(click)="onSubmit()">{{buttonText}}</button>
		<button mat-button class='margin-lr-5' (click)="closeModal()">{{closeText}}</button>
	</div>
</div>
