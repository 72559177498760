
<br>
<br>
<div class="card-align" *ngIf="businessListing.length > 0">
	<span class='gray-color text-1x'>Choose Business Location</span>
	<br>
	<div *ngFor="let i of businessListing;let j=index" style="padding-top: 10px;">
		<div class="card" style="width: 100%;cursor: pointer;" *ngIf="i.metadata.placeId">
			<div class="card-body row">
				<div class="col-md-6" style="text-align: left;display: flex;
				flex-direction: column;">
					<span class="locationNameText">{{i.title}}</span>
					<span class="addressText" *ngIf="i.storefrontAddress">{{i.storefrontAddress.addressLines[0]}}</span>
				</div>
				<div class="col-md-6" style="text-align: left;">
					<button class="btn btn-outline-primary connect" (click)="connectAccount(i)">Connect</button>
					<!-- <button *ngIf="i.isServiceArea" class="btn btn-outline-primary connect" (click)="serviceAreaShow()" style="margin-right: 5px;">{{actBtn}} Service Area</button> -->
				</div>
				<!-- <div class="col-12" *ngIf="i.isServiceArea && showServiceArea" style="text-align: left;">
					<span style="font-weight: bold;">Service Area List</span>
					<br>
					<span *ngFor="let serviceArea of i.serviceAreaList;let serviceAreaIndex = index">
						{{serviceAreaIndex + 1}}). {{serviceArea.name}}
					</span>
				</div>							 -->
			</div>
		</div>
		<br>
	</div>
	<!-- <div *ngFor="let i of businessListing" (click)="getLocation(i.id,i.accountName,$event)" style="padding-top: 10px;">
		<div class="card" style="width: 100%;cursor: pointer;" [ngClass]="{'select': i.accountName == accountName,'text-white': i.accountName == accountName}">
			<div class="card-body">
				<span class="card-text" style="float: left;">{{i.accountName}}</span>
				<i class="arrow right"></i>
			</div>
		</div>
		<br>
	</div> -->
</div>
<div class="bgImg bgImgRes" *ngIf="businessListing.length == 0 && showNotice">
    <div class="row">
        <div class="col-md-12 col-xl-12 noAcMsgParent">
            <span class="noAcMsg">Can't find any business associated with selected account</span>
        </div>
        <div class="col-md-12 col-xl-12 noAcMsgParent">
            <button  style="text-transform: uppercase;" class="createAnotherAc btn" (click)="signWithOther()">Connect another account</button>
        </div>
    </div>
</div>

<ng-template #ServiceArea>
	<div>
		<div style="text-align: center;padding-top: 5%;">
			<h5>Service Area</h5>
		</div>
		<div *ngFor="let item of modalService.config.initialState;let i =index" style="margin-left: 5%;
			padding: 3%;">
			{{i + 1}}.) {{item.name}}
		</div>
	</div>
</ng-template>
