import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { environment } from './../../../../environments/environment';
import { DataTableService } from './../../../core/_base/layout/services/datatable.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Routes, Router } from '@angular/router';
import { MenuHorizontalService } from '../../../core/_base/layout/services/menu-horizontal.service';
import { filter } from 'lodash';

@Component({
  selector: 'kt-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss']
})
export class AddProfileComponent implements OnInit {
  copyProfileId = '';
  profileList: any;
  addProfileForm: UntypedFormGroup;
  loading: boolean = false;
  industryList = [
    'Auto & Car Dealer',
    'Auto Repair',
    'Dentistry',
    'Education & School',
    'Elder Care',
    'Employment',
    'Financial Services',
    'Healthcare',
    'Home Services',
    'Lawyer/Legal',
    'Moving & Movers',
    'Pet Services',
    'Product',
    'Property & Rentals',
    'Real Estate',
    'Recreation',
    'Religious Institution',
    'Restaurant & Dining',
    'Salons & Beauty',
    'Software',
    'Travel & Hospitality',
    'Wedding Industry',
    'Other',
  ];
  loadText: boolean = false;
  rightIcon: boolean = false;
  wrongIcon: boolean = false;
  submittedForm: boolean = false;
  accessKey: any;
  shortNameFlag: boolean = false;
  shortUrlRegEx = /[^A-Za-z0-9_-]/;
  EmailLimit: number = 0;
  SMSLimit: number = 0;
  showEmail: boolean = true;
  showSMS: boolean = true;
  public HasEmail: boolean = true;
  public HasSMS: boolean = true;
  buttonDisable: boolean = true;
  isAgency: boolean = false;
  currentPlan = '';

  constructor(public dataService: DataTableService,
    public pageService: PageConfigService,
    public menuService: MenuHorizontalService,
    public router : Router,
    public formBuilder: UntypedFormBuilder,
    public cdr: ChangeDetectorRef,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddProfileComponent>) {
  }

  countryList: any = [];
  country: any;
  countryCode: any;
  cCode : any;
  isShow = true;
  selectedCountry: any;

  isShowAlphaNumeric: boolean = false;
  uniqueUrl = window.location.href.split('/');

  ngOnInit() {
    var scope = this;
    let country = localStorage.getItem('currentCountry');
    scope.isAgency = JSON.parse(localStorage.getItem('isAgency'))
    scope.currentPlan = JSON.parse(localStorage.getItem('current-plan')).toLocaleLowerCase();

    this.cdr.markForCheck();

    if(localStorage.getItem('ProfileId') == '-1' || localStorage.getItem('ProfileId') == null || localStorage.getItem('ProfileId') == undefined){
      this.isShow = false;

    }else{
      this.isShow = true;
    }
    scope.addProfileForm = scope.formBuilder.group({
      PersonName: [''],
      CompanyName: ['', [Validators.required]],
      Industry: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.email]],
      ShortName: ['', [Validators.required]],
      PhoneNumber: ['',Validators.pattern("^[0-9]{8,10}$")],
      Country: ['', [Validators.required]],
      //AlphaNumericSenderId: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
      AlphaNumericSenderId: [''],
      HasEmail: [true],
      HasSMS : [true],
      EmailLimit: [''],
      SMSLimit: ['']
      // EmailLimit: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      // SMSLimit: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    })

    scope.profileList = scope.dataService.getData('ProfileList');
    scope.accessKey = localStorage.getItem(environment.authTokenKey)
    scope.pageService.createNewProfilePopup();
    this.countryList = this.dataService.countryList;
    var accountCode = localStorage.getItem('currentCountry');
    const toSelect = this.countryList.filter(c => c.code == accountCode);

    var val = toSelect[0]

    // bind country code dynamic
    this.countryList.filter((res) => {
      if(val.name.toLocaleLowerCase() == res.name.toLocaleLowerCase()){
        this.cCode = res.dial_code
        this.cdr.markForCheck();
      }
    })

    this.addProfileForm.get('Country').setValue(val.name);
    this.country = val.name;
    this.selectedCountry = val.name;
    let data = {
      value : val.name
    }

    this.selectCountry(data);

    this.countryCode = val.code

    scope.cdr.markForCheck()
  }
  validateUrl() {
    var scope = this;
    scope.rightIcon = false;
    scope.wrongIcon = false;

    if (scope.addProfileForm.value.ShortName.length <= 0) {

      return;
    }
    scope.loadText = true;
    if (scope.addProfileForm.value.ShortName.includes(' ')) {
      scope.loadText = false;
      scope.wrongIcon = true;
      return;
    }
    if (scope.shortUrlRegEx.test(scope.addProfileForm.value.ShortName)) {
      scope.wrongIcon = true;
      return;
    }
    scope.loadText = true;
    scope.http.get(environment.apiPath + 'ValidateShortname?ShortName=' + scope.addProfileForm.value.ShortName
      + '&Key=' + scope.accessKey)
      .subscribe((res) => {
        scope.loadText = false;;
        if (res['success']) {
          scope.rightIcon = true;
        } else {
          scope.wrongIcon = true;
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      },(err) =>{
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.showError('Oops!',err.error.errorMsg);
        }
      })

  }
  getCurrentTimeZone() {
    var scope = this;

    let date = new Date();
    let str = date.toString()
    return str.substring(str.indexOf('(') + 1, str.indexOf(')'))
  }
  generteShortName() {
    this.addProfileForm.value.ShortName = this.addProfileForm.value.CompanyName.replace(/ /g, '-').replace(/[&\/\\#,+()$~%.'":*?<>{}@]/g, '-').toLowerCase();
    this.cdr.markForCheck();
    this.validateUrl();
  }
  onSubmit() {
    var scope = this;
    scope.submittedForm = true;

   // if(scope.isAgency == true && scope.currentPlan == 'Grab Your Reviews Tier 1' || 'Grab Your Reviews Tier 2' || 'Grab Your Reviews Tier 3'){
      if (scope.addProfileForm.invalid) {
        return;
      }
    //  }
    //  else{
    //   this.addProfileForm.get('EmailLimit').setValue(0);
    //   this.EmailLimit = 0;
    //   this.addProfileForm.get('SMSLimit').setValue(0);
    //   this.SMSLimit = 0;
    //  }

    this.addProfileForm.get('EmailLimit').setValue(0);
      this.EmailLimit = 0;
      this.addProfileForm.get('SMSLimit').setValue(0);
      this.SMSLimit = 0;

    if (scope.wrongIcon) {
      return;
    }
    scope.submitData()
  }
  submitData() {
    var scope = this;
    let datatosend = scope.addProfileForm.value;
    datatosend['Key'] = scope.accessKey;
    // datatosend['TimeZone']=scope.getCurrentTimeZone()
    if (scope.copyProfileId.length > 0) {
      datatosend['CopyProfileId'] = scope.copyProfileId
    } else {
      datatosend['CopyProfileId'] = '';
    }

    datatosend['CountryCode'] = this.countryCode;
    scope.loading = true;

    scope.http.post(environment.apiPath + 'CreateProfile', datatosend)
      .subscribe((res) => {
        scope.loading = false;
        if (res['success']) {
      let data={
        Key:scope.accessKey
      };

			this.http.post(environment.apiPath + 'GetProfileList',data)
				.subscribe((res1) => {
					localStorage.setItem("GRProfileList", JSON.stringify(res1));
					localStorage.setItem('ProfileId', JSON.stringify(res['profileId']));
					this.pageService.createProfileJson(res['profileId']).subscribe();
					scope.pageService.setProfileListSubscriber();
					this.menuService.updateIndentity("createProfile");
					scope.pageService.showSuccess('Created', 'Profile created successfully.')
					scope.dialogRef.close({ flag: true, profileId: res['profileId'] })
					this.router.navigate(['/review-platforms']);
				})

        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      },(err) =>{
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.showError('Oops!',err.error.errorMsg);
        }
      })
  }

  selectCountry(event) {
    var selectedCountry = event.value
    const selectedValue = this.countryList.filter(c => c.name == selectedCountry);

    this.countryCode = selectedValue[0].code;
    if (this.countryCode == 'ZA' || this.countryCode == 'AU' || this.countryCode == 'UK' || this.countryCode == 'GB') {
      this.isShowAlphaNumeric = true;
      this.addProfileForm.controls.AlphaNumericSenderId.enable();
    } else {
      this.isShowAlphaNumeric = false;
      this.addProfileForm.controls.AlphaNumericSenderId.disable();
    }

    this.cCode = selectedValue[0].dial_code;
    this.cdr.markForCheck();
  }


// hidden by default email
// changeEmailToggle() {
//   var scope = this;
//   scope.showEmail = scope.HasEmail;
//   if (scope.HasEmail == true) {
//     scope.showEmail = true
//   }
//   else {
//     scope.showEmail = false
//   }
//   if (scope.showEmail == false) {
//     scope.EmailLimit = 0;
//   }
// }

// hidden by default SMSLimit
// changeSmsToggle() {
//   var scope = this;
//   scope.showSMS = scope.HasSMS;
//   if (scope.HasSMS == true) {
//     scope.showSMS = true
//   }
//   else {
//     scope.showSMS = false
//   }

//   if (scope.showSMS == false) {
//     scope.SMSLimit = 0;
//   }
// }
// increaseEmail() {
//   var scope = this;
//   scope.buttonDisable = false;
//     scope.EmailLimit += 1000;

//   scope.cdr.markForCheck()
// }
// decreaseEmail() {
//   var scope = this;
//   scope.buttonDisable = false;
//   if (scope.EmailLimit === 0 || scope.EmailLimit <= 0) return;
//   scope.EmailLimit -= 1;

//   scope.cdr.markForCheck()
// }
// increaseSms() {
//   var scope = this;
//   scope.buttonDisable = false;
//   scope.SMSLimit += 100;

//   scope.cdr.markForCheck()
// }
// decreaseSms() {
//   var scope = this;
//   scope.buttonDisable = false;
//   if (scope.SMSLimit === 0 || scope.SMSLimit <= 0) return;
//   scope.SMSLimit -= 1;

//   scope.cdr.markForCheck()
// }

}
