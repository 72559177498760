import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
@Component({
  selector: 'kt-popup-model',
  templateUrl: './popup-model.component.html',
  styleUrls: ['./popup-model.component.scss']
})
export class PopupModelComponent implements OnInit {
  msg:'';
  constructor(
    @Inject(MAT_DIALOG_DATA) public navData: any,
    public dialogRef:  MatDialogRef<PopupModelComponent>) {
      this.msg=navData.msg
    }

  ngOnInit() {
  }
}
