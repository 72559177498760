import { AddProfileComponent } from './../../../pages/add-profile/add-profile.component';
import { DataTableService } from './../../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
import { environment } from './../../../../../environments/environment';
// import { FormControl } from '@angular/forms';
// Angular

import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	Renderer2,
	ViewChild
} from '@angular/core';

// import $ from "jquery";

import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, ignoreElements } from 'rxjs/internal/operators';

// Object-Path
import * as objectPath from 'object-path';
// Layout
import {
	LayoutConfigService,
	MenuConfigService,
	MenuHorizontalService,
	MenuOptions,
	OffcanvasOptions
} from '../../../../core/_base/layout';
// HTML Class
import { HtmlClassService } from '../../html-class.service';
import { MatDialog } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
	selector: 'kt-menu-horizontal',
	templateUrl: './menu-horizontal.component.html',
	styleUrls: ['./menu-horizontal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MenuHorizontalComponent implements OnInit, AfterViewInit {

	@ViewChild('profileEle') profileElement: ElementRef
	currentRouteUrl: any = '';
	proName: any;
	init_proName: any;
	rootArrowEnabled: boolean;
	isLogo: any;
	menuOptions: MenuOptions = {
		submenu: {
			desktop: 'dropdown',
			tablet: 'accordion',
			mobile: 'accordion'
		},
		accordion: {
			slideSpeed: 200, // accordion toggle slide speed in milliseconds
			expandAll: false // allow having multiple expanded accordions in the menu
		}
	};
	showNewCompany: boolean = false;
	minProfile: any;
	maxProfile: any;
	offcanvasOptions: OffcanvasOptions = {
		overlay: true,
		baseClass: 'kt-header-menu-wrapper',
		closeBy: 'kt_header_menu_mobile_close_btn',
		toggleBy: {
			target: 'kt_header_mobile_toggler',
			state: 'kt-header-mobile__toolbar-toggler--active'
		}
	};
	isShowProfileList: boolean = false;
	// httpOptions: { headers: HttpHeaders; };
	profileList = [];
	profile: any;
	showList: boolean = true;


	/**
	 * Component Conctructor
	 *
	 * @param el: ElementRef
	 * @param htmlClassService: HtmlClassService
	 * @param menuHorService: MenuHorService
	 * @param menuConfigService: MenuConfigService
	 * @param layoutConfigService: LayouConfigService
	 * @param router: Router
	 * @param render: Renderer2
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private el: ElementRef,
		public htmlClassService: HtmlClassService,
		public menuHorService: MenuHorizontalService,
		private menuConfigService: MenuConfigService,
		private layoutConfigService: LayoutConfigService,
		private router: Router,
		private render: Renderer2,
		private cdr: ChangeDetectorRef,
		public http: HttpClient,
		public pageService: PageConfigService,
		public dataService: DataTableService,
		public dialog: MatDialog,

	) {
		this.proName = localStorage.getItem('proName');

	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
		// this.proName = "N21IbzExK2xBdEJibGhCd08vWXB4Zz09";
		// if(localStorage.getItem("ProfileId"))
		// this.proName = "N21IbzExK2xBdEJibGhCd08vWXB4Zz09";
	}


	changeProfile(v, v2) {
		var scope = this;
		scope.profileList.forEach((alone) => {


			if (alone.companyName == v || alone.profileId == v) {
				this.proName = alone.profileId;
				if (JSON.parse(localStorage.getItem("ProfileId")) == null) {
					this.dProfile = alone.profileId
				} else {
					this.dProfile = JSON.parse(localStorage.getItem("ProfileId"))
				}
				scope.pageService.setProfileId(alone.profileId);
				localStorage.setItem("shortName", alone.shortName)
				localStorage.setItem("companyName", alone.companyName);
				localStorage.setItem('hasMultiLocation', JSON.stringify(alone.hasMultiLocation));
				this.cdr.markForCheck()


				// return false;
			} else if (alone.multiLocation != null) {
				alone.multiLocation.forEach((aln) => {
					if (aln.profileId == v) {
						scope.pageService.setProfileId(aln.profileId);
						localStorage.setItem('hasMultiLocation', JSON.stringify(false))
					}
				})
			}
		})
		if (v2 == "setNotice") {
			this.menuHorService.updateIndentity("setNotice");
		}
		//scope.pageService.checkReviewStatus();
		scope.cdr.markForCheck()
	}
	/**
	 * On init
	 */


	changeProfileV1(v2) {
		let v = this.proName;
		localStorage.setItem('proName', this.proName);
		var scope = this;
		scope.profileList.forEach((alone) => {
			if (alone.companyName == v || alone.profileId == v) {
				this.proName = alone.profileId;
				if (JSON.parse(localStorage.getItem("ProfileId")) == null) {
					this.dProfile = alone.profileId
				} else {
					this.dProfile = JSON.parse(localStorage.getItem("ProfileId"))
				}
				scope.pageService.setProfileId(alone.profileId);
				localStorage.setItem("shortName", alone.shortName)
				localStorage.setItem("companyName", alone.companyName);
				localStorage.setItem('hasMultiLocation', JSON.stringify(alone.hasMultiLocation));
				this.cdr.markForCheck()

				localStorage.removeItem("GRProfileDetail");
				localStorage.removeItem("GRDetail");
				
				setTimeout(() => {
					window.location.reload();
				}, 500);
				// return false;
			} else if (alone.multiLocation != null) {
				alone.multiLocation.forEach((aln) => {
					if (aln.profileId == v) {
						scope.pageService.setProfileId(aln.profileId);
						localStorage.setItem('hasMultiLocation', JSON.stringify(false))
						localStorage.setItem("shortName", aln.shortName)
						localStorage.setItem("companyName", aln.companyName);
						localStorage.removeItem("GRProfileDetail");
						localStorage.removeItem("GRDetail");
						setTimeout(() => {
							window.location.reload();
						}, 500);
					}
				})
			}
		})
		if (v2 == "setNotice") {
			this.menuHorService.updateIndentity("setNotice");
		}
		//scope.pageService.checkReviewStatus();
		scope.cdr.markForCheck();

		
	}
	public identity;


	demo: any;
	dProfile;

	PlanName: any;
	showUpgrade: any;
	showPlanDetails: any;
	ngOnInit(): void {
		var scope = this;
		setTimeout(() => {
			scope.pageService.showLoader()
		}, 500)
		let accessKey = localStorage.getItem(environment.authTokenKey);
		let GRDetail = localStorage.getItem('GRDetail');
		GRDetail = GRDetail == 'null'?null:GRDetail;
		if (GRDetail == 'null' || GRDetail == null || GRDetail == undefined) {
			let data={Key:accessKey};
			scope.http.post(environment.apiV2Path + 'GetDetails',data)
				.subscribe((res: any) => {
					localStorage.setItem('GRDetail', JSON.stringify(res['data']));
					scope.PlanName = res.data.plan;
					scope.showUpgrade = true;
					scope.showPlanDetails = true;
					if (res.data.isReseller == true) {
						this.showPlanDetails = true;
						this.showUpgrade = true;
						this.cdr.detectChanges();
					} else {
						this.showPlanDetails = false;
						this.showUpgrade = false
						this.cdr.detectChanges();
					}
					if (scope.PlanName.toLocaleLowerCase().includes("grab your reviews")) {
						scope.PlanName = scope.PlanName.replace("Grab Your Reviews", "AppSumo")
					}
					if (scope.PlanName.toLocaleLowerCase().includes("tier 3") || scope.PlanName.toLocaleLowerCase().includes("premium") || scope.PlanName.toLocaleLowerCase().includes("agency"))

						scope.showUpgrade = false;
					// scope.showPlanDetails=true;

					this.cdr.detectChanges();
					scope.pageService.hideLoader();
				})
		}
		else {
			scope.pageService.hideLoader()
			let res: any = { data: null };
			res.data = JSON.parse(localStorage.getItem('GRDetail'));
			scope.PlanName = res.data.plan;
			scope.showUpgrade = true;
			scope.showPlanDetails = true;
			if (res.data.isReseller == true) {
				this.showPlanDetails = true;
				this.showUpgrade = true;
				this.cdr.detectChanges();
			} else {
				this.showPlanDetails = false;
				this.showUpgrade = false
				this.cdr.detectChanges();
			}
			if (scope.PlanName.toLocaleLowerCase().includes("grab your reviews")) {
				scope.PlanName = scope.PlanName.replace("Grab Your Reviews", "AppSumo")
			}
			if (scope.PlanName.toLocaleLowerCase().includes("tier 3") || scope.PlanName.toLocaleLowerCase().includes("premium") || scope.PlanName.toLocaleLowerCase().includes("agency"))

				scope.showUpgrade = false;
			// scope.showPlanDetails=true;

			this.cdr.detectChanges();
			scope.pageService.hideLoader();
		}

		this.loadList("")
		this.menuHorService.identity.subscribe((res: any) => {
			if (res == 'createProfile' || res == 'deleteProfile') {
				this.demo = res;
				// this.ngOnInit()
				this.loadList("serviceCall")

			}
		})
		let key = localStorage.getItem(environment.authTokenKey);

		if (GRDetail == null || GRDetail == undefined) {
			let data={Key:key};
			this.http.post(environment.apiV2Path + 'GetDetails',data).subscribe((res: any) => {
				localStorage.setItem('GRDetail', JSON.stringify(res['data']));
				if (res.data.isReseller == true) {
					this.showNewCompany = true;
					this.showPlanDetails = true;
					this.showUpgrade = true;
					this.cdr.detectChanges();
				} else {
					this.showNewCompany = false;
					this.showPlanDetails = false;
					this.showUpgrade = false
					this.cdr.detectChanges();
				}
				this.pageService.setReseller(res.data.isReseller)
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

					this.showNewCompany = false;
					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
		}
		else {
			let res: any = { data: null };
			res.data = JSON.parse(localStorage.getItem('GRDetail'));
			if (res.data.isReseller == true) {
				this.showNewCompany = true;
				this.showPlanDetails = true;
				this.showUpgrade = true;
				this.cdr.detectChanges();
			} else {
				this.showNewCompany = false;
				this.showPlanDetails = false;
				this.showUpgrade = false
				this.cdr.detectChanges();
			}
			this.pageService.setReseller(res.data.isReseller)
		}
		scope.isLogo = JSON.parse(localStorage.getItem('isLogo'));

		scope.pageService.getProgileId().subscribe(message => {

			if (message.id) {
				scope.profile = JSON.parse(localStorage.getItem('ProfileId'))
				scope.cdr.detectChanges();
				// scope.cdr.markForCheck();
			}
		});

		scope.pageService.getShowListFlag().subscribe((flag) => {
			if (flag) {
				scope.showList = flag.showFlag;
				scope.cdr.markForCheck();
			}
		})
		scope.rootArrowEnabled = scope.layoutConfigService.getConfig('header.menu.self.root-arrow');

		scope.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				scope.currentRouteUrl = scope.router.url;
				scope.cdr.markForCheck();
			});

		// nested
		setTimeout(() => {
		}, 1000);

		
	}

	changeCompany() {
		this.ngOnInit();
	}

	profileDrop = [];
	loadList(value) {
		var scope = this;
		scope.pageService.showLoader();

		let GRProfileList: any = JSON.parse(localStorage.getItem("GRProfileList"));
		if (GRProfileList && GRProfileList.success) {
			this.pageService.hideLoader();
			// localStorage.setItem('maxProfile',res['totalProfile'])
			this.minProfile = GRProfileList.data.length;
			this.maxProfile = GRProfileList.totalProfile;
			scope.profileList = GRProfileList.data;
			this.profileDrop = GRProfileList.data;
			let parentString = ''
			if (scope.profileList.length > 0) {
				scope.profileList.forEach((item) => {
					parentString += "<option [value]=" + item.profileId + " style='margin-top:5px !important'>" + item.companyName + "</option>"
					if (item.hasMultiLocation == true) {
						item.multiLocation.forEach((alone) => {
							parentString += "<option style='margin-top:5px !important' [value]=" + alone.profileId + ">&nbsp;&nbsp;&nbsp;&nbsp" + alone.companyName + "</option>"
						})


					}
				})
				setTimeout(() => {
					scope.profileElement.nativeElement.innerHTML = parentString;
					//this.proName = "N21IbzExK2xBdEJibGhCd08vWXB4Zz09";

				}, 500);

				scope.pageService.hideLoader();
			} else {
				parentString = '<option selected>Select Company Profile</option>';
				setTimeout(() => {
					scope.profileElement.nativeElement.innerHTML = parentString;
					//this.proName = "N21IbzExK2xBdEJibGhCd08vWXB4Zz09";
				}, 500);
				if (value == 'profileCall') this.openNewProfileDialog();
			}
			scope.dataService.setData('ProfileList', scope.profileList)
			if (scope.profileList.length > 0) {
				if (localStorage.getItem('ProfileId') == null || localStorage.getItem('ProfileId') == "null") {
					scope.profile = scope.profileList[0].profileId
				} else if (this.demo == 'createProfile') {
					let d = this.profileList.length - 1
					this.profile = scope.profileList[d].profileId
				} else {
					scope.profile = JSON.parse(localStorage.getItem('ProfileId'))
				}

				setTimeout(() => {
					scope.changeProfile(scope.profile, "")
				}, 500);

			}
		}
		else {
			let key = localStorage.getItem(environment.authTokenKey)
			let data={Key:key};
			scope.http.post(environment.apiPath + 'GetProfileList',data)
				.subscribe((res) => {
					localStorage.setItem("GRProfileList", JSON.stringify(res));
					if (res['success']) {
						this.pageService.hideLoader();
						// localStorage.setItem('maxProfile',res['totalProfile'])
						this.minProfile = res['data'].length;
						this.maxProfile = res['totalProfile'];
						scope.profileList = res['data'];
						this.profileDrop = res['data'];
						let parentString = ''
						if (scope.profileList.length > 0) {
							scope.profileList.forEach((item) => {
								parentString += "<option [value]=" + item.profileId + " style='margin-top:5px !important'>" + item.companyName + "</option>"
								if (item.hasMultiLocation == true) {
									item.multiLocation.forEach((alone) => {
										parentString += "<option style='margin-top:5px !important' [value]=" + alone.profileId + ">&nbsp;&nbsp;&nbsp;&nbsp" + alone.companyName + "</option>"
									})


								}
							})

							setTimeout(() => {
								scope.profileElement.nativeElement.innerHTML = parentString;
								//this.proName = "N21IbzExK2xBdEJibGhCd08vWXB4Zz09";
							}, 500);


							scope.pageService.hideLoader();
						} else {
							parentString = '<option selected>Select Company Profile</option>';
							setTimeout(() => {
								scope.profileElement.nativeElement.innerHTML = parentString;
								//this.proName = "N21IbzExK2xBdEJibGhCd08vWXB4Zz09";
							}, 500);
							if (value == 'profileCall') this.openNewProfileDialog();
						}
						scope.dataService.setData('ProfileList', scope.profileList)
						if (scope.profileList.length > 0) {
							if (localStorage.getItem('ProfileId') == null || localStorage.getItem('ProfileId') == "null") {
								scope.profile = scope.profileList[0].profileId
							} else if (this.demo == 'createProfile') {
								let d = this.profileList.length - 1
								this.profile = scope.profileList[d].profileId
							} else {
								scope.profile = JSON.parse(localStorage.getItem('ProfileId'))
							}
							setTimeout(() => {
								scope.changeProfile(scope.profile, "")
							}, 500);
						}
					} else {
						scope.pageService.showError('Oops!!!', res['errorMsg'])
						this.pageService.hideLoader();
						if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
							setTimeout(() => {
								scope.pageService.sessionExpired();
							}, environment.sessionTimeOut)
						}
					}

					scope.pageService.getProgileId().subscribe(message => {
						if (message) {
							scope.profile = message.id;

						}
					});
				}, (err) => {
					if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

						this.pageService.hideLoader();
						this.pageService.showError('Oops!', err.error.errorMsg);
					}
				})
		}

	}

	newProfile() {
		var scope = this
		this.minProfile = this.minProfile + 1;
		if (this.minProfile <= this.maxProfile) {
			// this.router.navigate(['/onboarding/setup-profile'])
			scope.openNewProfileDialog()
		} else {
			scope.pageService.showError('Oops!!!', 'Upgrade account to add company')
		}
	}

	openBilling() {
		//this.router.navigate(['/general/billing'])
		this.router.navigate(['/general/billing'], { queryParams: this.PlanName });
	}

	openNewProfileDialog(): void {
		var scope = this;
		if (this.minProfile <= this.maxProfile) {
			scope.pageService.openNewProfileDialog();
			const dialogRef = scope.dialog.open(AddProfileComponent, {
				width: '900px',
				// height:'100%',
				panelClass: 'AddProfile-nopadding',
				disableClose: true
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					scope.pageService.setProfileId(result['profileId'])
					// scope.router.navigate(['/setup-account'])
				} else {
					this.minProfile = this.minProfile - 1;
				}

			});

		} else {
			scope.pageService.showError('Oops!!!', 'Upgrade account to add company')
		}
	}

	/**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
	mouseEnter(e: Event) {
		// check if the left aside menu is fixed
		if (!document.body.classList.contains('kt-menu__item--hover')) {
			this.render.addClass(document.body, 'kt-menu__item--hover');
		}
	}

	/**
	 * Mouse Leave event
	 * @param event: MouseEvent
	 */
	mouseLeave(event: MouseEvent) {
		this.render.removeClass(event.target, 'kt-menu__item--hover');
	}

	/**
	 * Return Css Class Name
	 * @param item: any
	 */
	getItemCssClasses(item) {
		let classes = 'kt-menu__item';

		if (objectPath.get(item, 'submenu')) {
			classes += ' kt-menu__item--submenu';
		}

		if (!item.submenu && this.isMenuItemIsActive(item)) {
			classes += ' kt-menu__item--active kt-menu__item--here';
		}

		if (item.submenu && this.isMenuItemIsActive(item)) {
			classes += ' kt-menu__item--open kt-menu__item--here';
		}

		if (objectPath.get(item, 'resizer')) {
			classes += ' kt-menu__item--resize';
		}

		const menuType = objectPath.get(item, 'submenu.type') || 'classic';
		if ((objectPath.get(item, 'root') && menuType === 'classic')
			|| parseInt(objectPath.get(item, 'submenu.width'), 10) > 0) {
			classes += ' kt-menu__item--rel';
		}

		const customClass = objectPath.get(item, 'custom-class');
		if (customClass) {
			classes += ' ' + customClass;
		}

		if (objectPath.get(item, 'icon-only')) {
			classes += ' kt-menu__item--icon-only';
		}

		return classes;
	}

	/**
	 * Returns Attribute SubMenu Toggle
	 * @param item: any
	 */
	getItemAttrSubmenuToggle(item) {
		let toggle = 'hover';
		if (objectPath.get(item, 'toggle') === 'click') {
			toggle = 'click';
		} else if (objectPath.get(item, 'submenu.type') === 'tabs') {
			toggle = 'tabs';
		} else {
			// submenu toggle default to 'hover'
		}

		return toggle;
	}

	/**
	 * Returns Submenu CSS Class Name
	 * @param item: any
	 */
	getItemMenuSubmenuClass(item) {
		let classes = '';

		const alignment = objectPath.get(item, 'alignment') || 'right';

		if (alignment) {
			classes += ' kt-menu__submenu--' + alignment;
		}

		const type = objectPath.get(item, 'type') || 'classic';
		if (type === 'classic') {
			classes += ' kt-menu__submenu--classic';
		}
		if (type === 'tabs') {
			classes += ' kt-menu__submenu--tabs';
		}
		if (type === 'mega') {
			if (objectPath.get(item, 'width')) {
				classes += ' kt-menu__submenu--fixed';
			}
		}

		if (objectPath.get(item, 'pull')) {
			classes += ' kt-menu__submenu--pull';
		}

		return classes;
	}

	/**
	 * Check Menu is active
	 * @param item: any
	 */
	isMenuItemIsActive(item): boolean {
		if (item.submenu) {
			return this.isMenuRootItemIsActive(item);
		}

		if (!item.page) {
			return false;
		}

		return this.currentRouteUrl.indexOf(item.page) !== -1;
	}

	/**
	 * Check Menu Root Item is active
	 * @param item: any
	 */
	isMenuRootItemIsActive(item): boolean {
		if (item.submenu.items) {
			for (const subItem of item.submenu.items) {
				if (this.isMenuItemIsActive(subItem)) {
					return true;
				}
			}
		}

		if (item.submenu.columns) {
			for (const subItem of item.submenu.columns) {
				if (this.isMenuItemIsActive(subItem)) {
					return true;
				}
			}
		}

		if (typeof item.submenu[Symbol.iterator] === 'function') {
			for (const subItem of item.submenu) {
				const active = this.isMenuItemIsActive(subItem);
				if (active) {
					return true;
				}
			}
		}

		return false;
	}

	//Open new UI with SSO
	openNewExperience(){
		var url="https://my.grabyourreviews.com/auth/signin?sso="+localStorage.getItem(environment.authTokenKey);
		window.open(url);
	}

}
