<div class="col-xl-12 custom-dialog2" style="display: none">
	<div class="col-xl-12">
		<span class="listing" *ngIf="listingAccess">
			Do you have access to Facebook Pages ?
			<div style="margin-top: 10px">
				<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-8"
					(click)="noFacebookListing()">
					Yes
				</button>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<button class="btn btn-secondary btn-elevate kt-login__btn-secondary margin-lr-8"
					(click)="checkForFBAc()">
					No
				</button>
			</div>
		</span>
		<span></span>
		<!-- <span class="listing" *ngIf="dropdown1">
			<div class="row" style="margin-top: 10px;">
				<div class="col-md-6 col-xs-12">
					<label>Account Name</label>
					<br>
					<select class="col-12">
						<option>Choose Your Account Name</option>
						<option *ngFor="let i of businessListing">{{i.accountName}}</option>
					</select>
				</div>
				<div class="col-md-6 col-xs-12">
					<label>Account Name</label>
					<br>
					<select class="col-12">
						<option>Choose Your Account Name</option>
						<option *ngFor="let j of businessListing">{{j.accountName}}</option>
					</select>
				</div>
			</div>
		</span> -->
	</div>
</div>

<!-- *ngIf='!mapFlag && !isErrorPop' click here popup  Facebook and all platforms added unnnati free trial condition-->
<div class="col-xl-12 custom-dialog1" *ngIf="(!mapFlag && !stepFB1 && !isErrorPop) || isShow">

	<div class="col-xl-12" *ngIf="!freetrialFlag">
		<br />
		<div *ngIf="congratsFlag == true">
			<div class="headerParts row">
				<img [src]="'../../../../../../assets/media/party-popper.png'" style="height: 60px" />
			</div>
			<!-- Congrats Msg New  adeed by unnati-->
			<div class="row text-center">
				<div class="ExampleConmessage">
					Congratulations, Your {{ navData.name }} profile is
					successfully connected. It may take up to 3-4 hours to pull
					reviews.
				</div>
			</div>
			<div mat-dialog-actions>
				<button class="btn btnCancel" (click)="onSubmitCongrats()">
					Close
				</button>
			</div>
			<br />
		</div>
		<!-- Congrats Msg New adeed -->
		<div *ngIf="div1">
			<div class="headerParts row">
				<img [src]="
						'../../../../../../assets/media/social-logo/' +
						navData.name +
						'.png'
					" />
				<h1 mat-dialog-title class="margin-lr-5">
					{{ navData.name }}
				</h1>
			</div>
			<div class="urlParentClass margin-tb-5">
				<mat-label>Paste your {{ navData.name }} Profile URL</mat-label>
				<br />
				<input class="form-control" [(ngModel)]="linkUrl" (keyup)="validate()" (blur)="reset()" />
				<mat-error *ngIf="typed">
					<strong *ngIf="linkUrl.length == 0">*Required</strong>
					<strong *ngIf="linkUrl.length > 0 && invalidUrl">*Invalid URL</strong>
				</mat-error>
				<mat-error *ngIf="linkError">
					<strong>*Enter proper URL</strong>
				</mat-error>
			</div>
			<div class="Examplemessage" *ngIf="
					siteData.placeholderLink != null &&
					siteData.placeholderLink.length > 0
				">
				Your {{ navData.name }} URL looks like this :-
				<strong>{{ siteData.placeholderLink }}</strong>
			</div>
			<div class="row flexRow">
				<div class="col-xl-6" *ngIf="isReviewScrapAvailable == true && siteData.category != 'Collect Reviews'">
					<mat-slide-toggle [(ngModel)]="collectReview">Fetch review from this channel</mat-slide-toggle>
				</div>
				<div class="col-xl-6" *ngIf="isReviewScrapAvailable == true">
					<mat-slide-toggle [(ngModel)]="displayOnReviewPage">
						Display on review page FB
						<i class="fa fa-info" aria-hidden="true"
							ngbTooltip="Show this platform to customer to leave reviews." placement="top"></i>
					</mat-slide-toggle>
				</div>
				<div class="col-12 noteColor margin-tb-10 padding-15"
					*ngIf="instruction != null && instruction.length > 0">
					<b>Important note:</b>&nbsp;
					<div [innerHTML]="instruction"></div>
				</div>
				<div class="col-xl-12" *ngIf="isReviewScrapAvailable == false">
					<strong>Note</strong>: Reviews will not be fetched from this
					source, but you can collect it on this source.
				</div>
			</div>
			<div mat-dialog-actions>
				<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmit()"
					[disabled]="invalidUrl || linkUrl === ''">
					Connect
				</button>
				<button mat-button color="warn" (click)="onNoClick()">
					Cancel
				</button>
			</div>
			<br />
		</div>
	</div>
	<div class="col-xl-12" *ngIf="freetrialFlag">
		<div class="row">
			<div class="col-md-12">
				<div class="closeBtn text-1-2x text-center">
					<i class="fa fa-times cursor-pointer closeBtn" (click)="dialogRef.close()"></i>
				</div>
			</div>
		</div>
		<div class="headerParts row">
			<img [src]="
					'../../../../../../assets/media/social-logo/' +
					navData.name +
					'.png'
				" />
			<h1 mat-dialog-title class="margin-lr-5">
				{{ navData.name }}
			</h1>
		</div>
		<div class="row text-center">
			<div class="ExampleConmessage">
				Upgrade your account to connect your {{ navData.name }} profile.
			</div>
		</div>
		<div mat-dialog-actions>
			<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)="onUpgrade()">
				Upgrade
			</button>
			<button mat-button color="warn" (click)="onNoClick()">
				Cancel
			</button>
		</div>
	</div>
</div>
<!--Facebook Added-->
<div class="col-xl-12 custom-dialog2" style="min-height: 200px" *ngIf="stepFB1 && !isShow">
	<div class="closeBtn text-1-2x text-center">
		<i class="fa fa-times cursor-pointer closeBtn" (click)="dialogRef.close()"></i>
	</div>
	<div style="
			margin-top: 10px;
			margin-bottom: 10px;
			width: 100%;
			text-align: center;
			display: flex;
			padding-top: 20px;
			justify-content: center;
			flex-direction: column;
			align-items: center;" (click)="noFacebookListing()">
		<img src="../../../../../assets/media/images/continueFB.png" class="signinFaceBook" />
		<br />

		<!-- <span *ngIf="!freetrialFlag">
			Don't have Facebook access?
			<a class="cust-btn" (click)="toggleDisplay()">click here</a>
		</span> -->
	</div>
</div>
<!--End Facebook-->

<!-- error msg display Facebook reconnect added by unnati-->
<div class="col-xl-12 custom-dialog2" *ngIf="isErrorPop">
	<div class="col-xl-12">
		<div class="row">
			<div class="col-md-12">
				<div class="closeBtn text-1-2x text-center">
					<i class="fa fa-times cursor-pointer closeBtn" (click)="dialogRef.close()"></i>
				</div>
			</div>
		</div>
		<div class="row">
			<div style="text-align: center" class="col-xl-5 col-md-6">
				<span><img src="../../../../../assets/media/reviews_new.gif" class="google-gif-img" /></span>
			</div>
			<span class="listing col-md-6 col-xl-6" *ngIf="listingAccess">
				<span><img src="../../../../../assets/media/reviews.png" class="google-img" /></span>
				<p class="googleLoginNotice">
					Oops! something went wrong please try reconnecting Google
					with full permission to manage your reviews.
				</p>
				<button class="btn btn-primary margin-lr-5" (click)="onReconnect()">
					Reconnect
				</button>
			</span>
		</div>
	</div>
</div>

<!-- Google POPUP -->
<div class="col-xl-12 custom-dialog2" *ngIf="mapFlag && !isScrap && !isErrorPop">
	<div class="col-xl-12">
		<div class="row">
			<div class="col-md-12">
				<div class="closeBtn text-1-2x text-center">
					<i class="fa fa-times cursor-pointer closeBtn" (click)="dialogRef.close()"></i>
				</div>
			</div>
		</div>
		<div class="row">
			<div style="text-align: center" class="col-xl-5 col-md-6">
				<span><img src="../../../../../assets/media/reviews_new.gif" class="google-gif-img" /></span>
			</div>
			<span class="listing col-md-6 col-xl-6" *ngIf="listingAccess">
				<!-- Do you have access to Google Business Listing ? -->
				<span><img src="../../../../../assets/media/reviews.png" class="google-img" /></span>

				<!-- <p class="googleLoginNotice">
					Allow our app to have full permission to access your Google My Business account to help you manage your reviews.
				</p> -->
				<div style="margin-top: 10px" (click)="noGoogleListing()">
					<img class="signinGoogle" src="../../../../../assets/media/GoogleSignIn.png" />
				</div>
			</span>
			
			<span class="cust-margin" *ngIf="showGoogleClickhere" >
				Don't have google business listing access?
				<a class="cust-btn" (click)="checkForAc()">click here</a>
			</span>
		</div>
	</div>
</div>

<!-- googleMap click here popup  -->
<div class="col-xl-12 custom-dialog2">
	<div class="col-xl-12" *ngIf="isScrap">

		<br />
		<div class="headerParts row">
			<img [src]="
					'../../../../../../assets/media/social-logo/' +
					navData.name +
					'.png'
				" />
			<h1 mat-dialog-title class="margin-lr-5">{{ navData.name }}</h1>
		</div>
		<div class="mainPart" *ngIf="!customMapFlag">
			<div mat-dialog-content class="urlParentClass">
				<div class="col-md-12 no-padding margin-top-10">
					<input id="searchMapInput" class="mapControls" type="text" placeholder="Enter a business name" />
					<div id="map"></div>
				</div>
			</div>
			<div class="link">
				Business not found?
				<strong color="warn">
					<a (click)="customMap()">Click here</a></strong>
			</div>
			<div class="row flexRow">
				<div class="col-xl-6" *ngIf="isReviewScrapAvailable == true">
					<mat-slide-toggle [(ngModel)]="collectReview">Fetch review from this channel</mat-slide-toggle>
				</div>

				<div class="col-xl-6" *ngIf="isReviewScrapAvailable == true">
					<mat-slide-toggle [(ngModel)]="displayOnReviewPage">
						Display on review page
						<i class="fa fa-info" aria-hidden="true"
							ngbTooltip="Show this platform to customer to leave reviews." placement="top"></i>
					</mat-slide-toggle>
				</div>
				<div class="col-12 noteColor margin-tb-10 padding-15"
					*ngIf="instruction != null && instruction.length > 0">
					<b>Important note:</b>&nbsp;
					<div [innerHTML]="instruction"></div>
				</div>
				<div class="col-xl-12" *ngIf="isReviewScrapAvailable == false">
					<strong>Note</strong>: Reviews will not be fetched from this
					source, but you can collect it on this source.
				</div>
			</div>
			<div mat-dialog-actions>
				<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmitMap()">
					Continue
				</button>
				<button mat-button color="warn" (click)="onNoClick()">
					Cancel
				</button>
			</div>
			<br />
		</div>
		<div class="mainPart" *ngIf="customMapFlag">
			<div class="backlink" (click)="customMapFalse()">
				<< Back</div>
					<div mat-dialog-content class="urlParentClass">
						<div class="texts">
							<div class="title">Service-Area Businesses</div>
							<span>Google treats service-area businesses and businesses
								without a physical location differently.
								<!-- Find your business on Google and -->
								Enter <strong>EXACT SEARCH TERM</strong>
								by which you can find your business listing on Google.
								<!-- <a href='https://www.google.com/maps' target="_blank">Google Maps</a> -->
								<!-- and copy/paste the business name seen in google map. -->
							</span>
							<!-- copy/Paste the name of business seen in google map -->
						</div>
						<div class="row">
							<mat-label>Enter Business Name
								<b><i>(Exact name as google business listing)</i></b>
							</mat-label>
							<input class="form-control" (keyup.enter)="locationEntered($event)"
								[(ngModel)]="locationLink"
								pplaceholder="e.g. https://maps.google.com/?cid=12345678901234"
								(keyup)="locationTyped()" (blur)="reset()" />
						</div>
						<mat-error *ngIf="locationTypeFlag">
							<strong *ngIf="locationLink.length == 0">*Required</strong>
						</mat-error>
					</div>
					<div class="row flexRow">
						<div class="col-xl-6" *ngIf="isReviewScrapAvailable == true">
							<mat-slide-toggle [(ngModel)]="collectReview">Fetch review from this channel
							</mat-slide-toggle>
						</div>
						<div class="col-xl-6" *ngIf="isReviewScrapAvailable == true">
							<mat-slide-toggle [(ngModel)]="displayOnReviewPage">
								Display on review page
								<i class="fa fa-info" aria-hidden="true"
									ngbTooltip="Show this platform to customer to leave reviews." placement="top"></i>
							</mat-slide-toggle>
						</div>
						<div class="col-12 noteColor margin-tb-10 padding-15"
							*ngIf="instruction != null && instruction.length > 0">
							<b>Important note:</b>&nbsp;
							<div [innerHTML]="instruction"></div>
						</div>
						<div class="col-xl-12" *ngIf="isReviewScrapAvailable == false">
							<strong>Note</strong>: Reviews will not be fetched from this
							source, but you can collect it on this source.
						</div>
					</div>
					<div mat-dialog-actions>
						<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"
							(click)="onSubmitMap()">
							Continue
						</button>
						<button mat-button (click)="onNoClick()">Cancel</button>
					</div>
					<br />
			</div>
		</div>


	</div>