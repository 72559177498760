import { environment } from '../../../../environments/environment';
import { PageConfigService } from '../../../core/_base/layout/services/page-config.service';
import { BadModalComponent } from './../bad-modal/bad-modal.component';
import { GoodModalComponent } from './../good-modal/good-modal.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, Inject, Input, SimpleChanges, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ThankyouModalComponent } from '../thankyou-modal/thankyou-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'kt-client-review',
  templateUrl: './client-review.component.html',
  styleUrls: ['./client-review.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ClientReviewComponent implements OnInit, OnChanges {
  @Input() dataDis: any = [];
  layoutDetails: any = [];

  splittedArr = [];
  count = 0;
  publicWelcomeMessage;
  pageFound: boolean = false;
  reviewPageSettings = {};
  slug = '';
  nid = '';
  // layoutDetails = {};
  isPlatfomsConnected;
  layoutName = '';
  thresold: any;
  inviteData = {
    email: '',
    name: '',
  };
  public targetModel = ''
  public utm_source = '';
  reviewSubmitted: any = [];
  openUrlCount = 0;
  totalPlatformCount = 0;
  profileId: any;
  accessKey: string;
  companyName: string;

  loading: boolean = false;
  arrayLen;
  arrayreviewSubmitted;
  reviewSiteList = [];

  constructor(public pageService: PageConfigService,
    public http: HttpClient,
    public router: Router,
    public dialogRef: MatDialog,
    public cdr: ChangeDetectorRef,
    // public splashScreenService:SplashScreenService,
    private titleService: Title,
    public deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer) {
  }

  layoutList = [];
  hasMultiLocation: boolean = false;
  ngOnInit() {
    var scope = this;
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    scope.companyName = localStorage.getItem('shortName');

    scope.loadPage();
    // scope.parseData();


    if (scope.deviceService.isDesktop()) {
      scope.redirectTo()
    }
    if (scope.deviceService.isMobile()) {
      scope.redirectTo()
    }
    if (scope.deviceService.isTablet()) {
      scope.redirectTo()
    }
  }

  
  loadPage() {
    var scope = this;
    // this.pageService.showLoader();
    //suppose  url is /m2web/{nid}#h

    scope.splittedArr = scope.router.url.split('/'); //e.g ["", "m2web", "{nid}#h"]
    if (scope.splittedArr.length == 3) {//url comming from email inviations 
      // this.pageService.hideLoader();
      scope.slug = scope.splittedArr[1]
      //Now chck url contains # so we can decide that web have to open postive or negative popup
      if (scope.splittedArr[2].includes('#')) {//Yes we have happy or unhappy option wiith Nid, so we have to open popup (happy or unappy)
        //lets get NId first
        let splittedwithhash = scope.splittedArr[2].split('#')  //e.g ["{nid}", "h"]
        scope.nid = splittedwithhash[0] //e.g {nid}
        scope.targetModel = splittedwithhash[1] // e.g h
        scope.checkSlug(scope.slug, 'nidwithmodel');
      } else {//We don't have to open any popup ["", "m2web", "{nid}"]
        scope.nid = scope.splittedArr[2] //e.g {nid}
        scope.checkSlug(scope.slug, 'nidwithoutmodel');
        //call checkslug with nid
      }
    } else if (scope.splittedArr.length == 2) {//url comming from email widgets or QR code or none of hose ["", "m2web#h?utm_source=email_sign"]
      this.pageService.hideLoader();
      let secondUrl = scope.splittedArr[1]; //"m2web#h?utm_source=email_sign"
      if (secondUrl.includes('?')) {// check wheather url contains ? that means url comming from email wisget and qr code
        scope.utm_source = secondUrl.split('=')[1];
        if (secondUrl.includes('utm_source=email_sign')) {
          scope.utm_source = 'email_sign'
        }
        if (secondUrl.includes('utm_source=qr'))
          scope.utm_source = 'qr'
        if (scope.utm_source == 'email_sign') {
          if (secondUrl.includes('#')) {// check wheather url contains ? that means url comming from email wisget and qr code and with happy or unhappy
            scope.slug = secondUrl.split('#')[0]; //"m2web"
            let secondPart = secondUrl.split('#')[1] //"h?utm_source=email_sign"
            scope.targetModel = secondPart.split('?')[0] // h (happy model)
            scope.checkSlug(scope.slug, 'emailsign')
          } else {
            scope.slug = secondUrl.split('?')[0];
            scope.checkSlug(scope.slug, 'emailsignwithoutmodel')
          }
        } else if (scope.utm_source == 'qr') {///m2web?utm_source=qr
          scope.slug = secondUrl.split('?')[0]; //"m2web"
          if (secondUrl.includes('#')) {
            scope.errorPage()
          }
          else {
            scope.checkSlug(scope.slug, 'qr')
          }
          //call check slug with qr code
        } else {
          scope.slug = secondUrl.split('?')[0];
          scope.checkSlug(scope.slug, 'withoutmodel')
        }
      } else {//  in this case secondUrl  is /m2web#h or /m2web

        //["", "m2web#h"] or ["", "m2web"]
        if (secondUrl.includes('#')) {
          // secondUrl ["", "m2web#h"]
          scope.slug = secondUrl.split('#')[0];
          scope.targetModel = secondUrl.split('#')[1];
          scope.checkSlug(scope.slug, 'withmodel')
        } else {// splitArr ["", "m2web"]
          scope.slug = secondUrl;
          scope.checkSlug(scope.slug, 'withoutmodel')
          //call checkslug 
        }
      }
    } else {
      this.pageService.hideLoader();
      scope.errorPage()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('data change', changes)
    if (changes.dataDis.firstChange) return;
    //this.parseData();
  }


  // parseData() {
  //  // this.layoutDetails = this.dataDis;
  //   this.cdr.markForCheck();
  // }

  sanitizeHtml(html) {
    var scope = this;

    return scope.sanitizer.bypassSecurityTrustHtml(html)
  }
  checkSlug(slug, target) {
    // return true;
    this.pageService.showLoader();
    var scope = this;
    scope.layoutName = '';
    if (slug == "customization-options") {
      slug = scope.companyName;
    }
    scope.http.get(environment.apiPath + 'GetLandingPage?slug=' + slug)
      .subscribe((response: any) => {
        let res = JSON.parse(this.pageService.decrypt(response.data));
        scope.layoutDetails = res;
        this.layoutDetails['NId'] = this.nid;

        // added unnati 
        scope.reviewSiteList = [];
        res['ReviewSource'].filter((e) => {
          this.reviewSiteList.push(e);
        })
        if (this.reviewSiteList.length > 0) {
          this.arrayLen = this.reviewSiteList
        }
        scope.isPlatfomsConnected = res.ReviewSource;
        scope.titleService.setTitle(scope.layoutDetails['CompanyName'])
        scope.cdr.markForCheck()
        scope.layoutName = scope.layoutDetails['LayoutName'].toLocaleLowerCase();
        let tempthreshold = scope.layoutDetails['Threshold'].toLocaleLowerCase().replace(/ /g, '');
        if (scope.layoutDetails['Logo'] != undefined && scope.layoutDetails['Logo'] != null &&
          scope.layoutDetails['Logo'].length > 0) {
          scope.layoutDetails['Logo'] = scope.layoutDetails['Logo'] + '?lastmd=' + Math.random()
        }
        if (scope.layoutDetails['HasMultiLocation']) {
          scope.layoutDetails['MultiLocation'].forEach((aln) => {
            var a = aln.LandingPageUrl.replace(/^.*\/\/[^\/]+/, '').substring(1).split('/')[0];

            var b = scope.router.url
            var c = b.replace(/^.*\/\/[^\/]+/, '').substring(1).split('/')[0]
            var finalUrl = window.location.origin + b.replace(c, a);
            aln.LandingPageUrl = finalUrl
          })
        }
        scope.cdr.markForCheck()
        if (scope.layoutName == 'stars') {
          if (tempthreshold.includes('5')) {
            scope.thresold = 5;
          } else if (tempthreshold.includes('4')) {
            scope.thresold = 4;
          } else if (tempthreshold.includes('3')) {
            scope.thresold = 3;
          } else if (tempthreshold.includes('2')) {
            scope.thresold = 2;
          } else if (tempthreshold == 'alltoprivatefeedback') {
            scope.thresold = 6;
          } else if (tempthreshold == 'alltoreview') {
            scope.thresold = 0;
          }
        } else if (scope.layoutName == 'faces') {
          if (tempthreshold == 'happyfacetoreview') {
            scope.thresold = 3;
          } else if (tempthreshold == 'happyorneutralfacetoreview') {
            scope.thresold = 2;
          } else if (tempthreshold == 'alltoprivatefeedback') {
            scope.thresold = 4;
          } else if (tempthreshold == 'alltoreview') {
            scope.thresold = 0;
          }
        } else if (scope.layoutName == 'thumbs') {
          if (tempthreshold == 'thumbsuptoreview') {
            scope.thresold = 2;
          } else if (tempthreshold == 'alltoprivatefeedback') {
            scope.thresold = 3;
          } else if (tempthreshold == 'alltoreview') {
            scope.thresold = 0;
          }
        } else if (scope.layoutName == 'nps') {
          if (tempthreshold == '9ormoretoreview') {
            this.thresold = 4;
          } else if (tempthreshold == '6ormoretoreview') {
            this.thresold = 3;
          } else if (tempthreshold == 'alltoreview') {
            scope.thresold = 2;
          } else if (tempthreshold == 'alltoprivatefeedback') {
            scope.thresold = 6;
          }
        }
        else if (scope.layoutName == 'review platforms') {
          if (tempthreshold == 'directconnectreviewplatforms') {
            this.thresold = 4;
          } else if (tempthreshold == '1ormoretoreview') {
            this.thresold = 3;
          } else if (tempthreshold == 'alltoreview') {
            scope.thresold = 2;
          } else if (tempthreshold == 'alltoprivatefeedback') {
            scope.thresold = 6;
          }
        }
        this.publicWelcomeMessage = this.layoutDetails['ReviewPageSettings'].PublicWelcomeMessage;
        scope.reviewPageSettings = scope.layoutDetails['ReviewPageSettings'];
        scope.cdr.markForCheck()

        if (target == 'nidwithmodel') {
          if (scope.targetModel == 'h') {
            scope.welcomeUserTracker()

            setTimeout(() => {
              if (scope.layoutName == 'location') {
              } else {
                scope.goodModal();
                if (scope.layoutName == 'thumbs') {
                  scope.clickButtonTracker('thumbs up');
                } else if (scope.layoutName == 'faces') {
                  scope.clickButtonTracker('okay face');
                } else if (scope.layoutName == 'stars') {
                  if (scope.thresold == '5') {
                    scope.clickButtonTracker('5 star')
                  } else if (scope.thresold == '4') {
                    scope.clickButtonTracker('4 star')
                  } else if (scope.thresold == '3') {
                    scope.clickButtonTracker('3 star')
                  } else if (scope.thresold == '2') {
                    scope.clickButtonTracker('2 star')
                  } else if (scope.thresold == '1') {
                    scope.clickButtonTracker('1 star')
                  }
                } else if (this.layoutName == 'nps') {
                  this.clickButtonTracker("10")
                }
                else if (this.layoutName == 'review platforms') {
                  this.clickButtonTracker("connected")
                }
              }
            }, 500)
          } else if (scope.targetModel == 'u') {
            scope.welcomeUserTracker()
            setTimeout(() => {
              if (scope.layoutName == 'location') {
              } else {
                scope.badModal()
                if (scope.layoutName == 'thumbs') {
                  scope.clickButtonTracker('thumbs down');
                } else if (scope.layoutName == 'faces') {
                  scope.clickButtonTracker('bad face');
                } else if (scope.layoutName == 'stars') {
                  if (scope.thresold == 5) {
                    scope.clickButtonTracker('5 star')
                  } else if (scope.thresold == 4) {
                    scope.clickButtonTracker('4 star')
                  } else if (scope.thresold == 3) {
                    scope.clickButtonTracker('3 star')
                  } else if (scope.thresold == 2) {
                    scope.clickButtonTracker('2 star')
                  } else if (scope.thresold == 1) {
                    scope.clickButtonTracker('1 star')
                  } else if (this.layoutName == 'nps') {
                    this.clickButtonTracker("0")
                  }
                  else if (this.layoutName == 'review platforms') {
                    this.clickButtonTracker("not connected")
                  }
                }
              }
            }, 500)
          } else {
            scope.errorPage();
          }
        } else if (target == 'nidwithoutmodel') {
          scope.welcomeUserTracker()
        } else if (target == 'withmodel' || target == 'emailsign') {
          if (scope.targetModel == 'h') {
            setTimeout(() => {
              if (scope.layoutName == 'location') {
              } else {

                scope.goodModal()
              }
            }, 500)
          } else if (scope.targetModel == 'u') {
            setTimeout(() => {
              if (scope.layoutName == 'location') {
              } else {
                scope.badModal()
              }
            }, 500)
          } else {
            scope.errorPage();
          }
        } else if (target == 'qr') {
          scope.welcomeQrUserTracker()
        } else if (target == 'emailsignwithoutmodel' || target == 'withoutmodel') {
          scope.welcomeUserTracker()
        } else { scope.errorPage() }
        this.pageService.hideLoader();
      }, (err) => {
        scope.errorPage()
        this.pageService.hideLoader();
        this.pageService.showError('Oops!', 'Landed on at wrong page');
      })
  }
  //multilocation
  openLandingPageMulty(url) {

    window.open(url, '_self')
  }
  errorPage() {
    this.pageFound = false;
    this.router.navigate(['error/403'])
  }
  openModal(target, value) {
    var scope = this;
    if (target == 'up') {
      // added by unnati
      if (scope.isPlatfomsConnected.length == 0) {
        scope.badModal();
      } else {

        scope.goodModal();
      }
    } else {
      scope.badModal();
    }
    if (scope.nid != undefined && scope.nid.length > 0) {
      if (scope.layoutName == 'thumbs') {
        if (value == 'up') {
          scope.clickButtonTracker('thumbs up')
        } else if (value == 'down') {
          scope.clickButtonTracker('thumbs down')
        }
      } else if (scope.layoutName == 'faces') {
        if (value == 'good') {
          scope.clickButtonTracker('good face')
        } else if (value == 'okay') {
          scope.clickButtonTracker('okay face')
        } else if (value == 'bad') {
          scope.clickButtonTracker('bad face')
        }
      } else if (scope.layoutName == 'stars') {
        if (value == '5') {
          scope.clickButtonTracker('5 star')
        } else if (value == '4') {
          scope.clickButtonTracker('4 star')
        } else if (value == '3') {
          scope.clickButtonTracker('3 star')
        } else if (value == '2') {
          scope.clickButtonTracker('2 star')
        } else if (value == '1') {
          scope.clickButtonTracker('1 star')
        }
      } else if (scope.layoutName == 'nps') {
        scope.clickButtonTracker(value)
      }
      else if (scope.layoutName == 'review platforms') {
        scope.clickButtonTracker(value)
      }
    } else if (scope.utm_source == 'qr') {
      if (scope.layoutName == 'thumbs') {
        if (value == 'up') {
          scope.clickButtonQrTracker('thumbs up')
        } else if (value == 'down') {
          scope.clickButtonQrTracker('thumbs down')
        }
      } else if (scope.layoutName == 'faces') {
        if (value == 'good') {
          scope.clickButtonQrTracker('good face')
        } else if (value == 'okay') {
          scope.clickButtonQrTracker('okay face')
        } else if (value == 'bad') {
          scope.clickButtonQrTracker('bad face')
        }
      } else if (scope.layoutName == 'stars') {
        if (value == '5') {
          scope.clickButtonQrTracker('5 star')
        } else if (value == '4') {
          scope.clickButtonQrTracker('4 star')
        } else if (value == '3') {
          scope.clickButtonQrTracker('3 star')
        } else if (value == '2') {
          scope.clickButtonQrTracker('2 star')
        } else if (value == '1') {
          scope.clickButtonQrTracker('1 star')
        }
      } else if (scope.layoutName == 'nps') {
        scope.clickButtonQrTracker(value)
      }
      else if (scope.layoutName == 'review platforms') {
        scope.clickButtonQrTracker(value)
      }
    }
  }

  goodModal() {
    var scope = this;
    this.layoutDetails['NId'] = this.nid;
    scope.layoutDetails['slug'] = scope.slug

    let goodModal = scope.dialogRef.open(GoodModalComponent, {
      width: '430px',
      data: scope.layoutDetails,
      panelClass: 'editModal',
      disableClose: true
    })

    goodModal.afterClosed().subscribe((result) => {
      if (result) {
        if (result.isOpenBad) {
          scope.badModal();
        }
        else if (result.isReviewed) {
          //Thankyou
          if (this.layoutDetails['OpenSameTab'] == false) {
            scope.thankYouModal(scope.reviewPageSettings['PublicReviewThankYouMsg']);
          }
          if (scope.utm_source == 'qr') {
            scope.reviewSourceQrTracker(result.siteName)

          } else {
            scope.reviewSourceTracker(result.siteName)
          }
        }
      }
    })
  }
  badModal() {
    var scope = this;
    scope.layoutDetails['email'] = scope.inviteData.email;
    scope.layoutDetails['name'] = scope.inviteData.name,
      scope.layoutDetails['NId'] = scope.nid;
    scope.layoutDetails['slug'] = scope.slug
    let badModal = scope.dialogRef.open(BadModalComponent, {
      width: '430px',
      data: scope.layoutDetails,
      panelClass: 'editModal',
    })
    badModal.afterClosed().subscribe((result) => {
      if (result) {
        if (result.isOpenGood) {
          scope.goodModal();
        } else if (result.isSubmitted) {
          //Thankyou
          if (scope.utm_source == 'qr') {
            scope.reviewSourceQrTracker('private feedback')
          } else {
            scope.reviewSourceTracker('private feedback')
          }
          scope.thankYouModal(scope.reviewPageSettings['ContactFromThankyouMsg']);
        }
      }
    })
  }
  thankYouModal(message) {
    var scope = this;
    let thankYouModal = scope.dialogRef.open(ThankyouModalComponent, {
      width: '430px',
      data: { message: message },
      position: { top: '20px' },
      panelClass: 'editModal',
    })
    thankYouModal.afterClosed().subscribe((result) => {
      if (result) {
        if (result.isOpenGood) {
          scope.goodModal()
        }
      }
    })
  }
  // Tracking users comming from invitations
  welcomeUserTracker() {
    var scope = this;
    this.pageService.showLoader();
    let data = {
      NId: scope.nid
    }
    if (scope.nid == "review-page" || scope.nid == "" || scope.nid == undefined) {
      return
    }
    if (scope.nid) {
      scope.http.post(environment.apiPath + 'WelcomeUser', data)
        .subscribe((res) => {
          if (res['success']) {
            this.pageService.hideLoader();
            scope.inviteData.email = res['email'];
            scope.inviteData.name = res['name'];
            scope.cdr.markForCheck();
          } else {
            this.pageService.hideLoader();
          }
        }, (err) => {
          if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
            this.pageService.showError('Oops!', err.error.errorMsg);
          }
        })
    }
  }
  clickButtonTracker(msg) {
    var scope = this;
    let data = {
      NId: scope.nid,
      pageActivity: scope.pageService.encrypt(msg)
    }
    scope.http.post(environment.apiPath + 'ClickOnButton', data)
      .subscribe((res) => {
      })
  }
  reviewSourceTracker(source) {
    var scope = this;
    let data = {
      NId: scope.nid,
      pageActivity: scope.pageService.encrypt(source)
    }
    scope.http.post(environment.apiPath + 'ClickOnReviewSite', data)
      .subscribe((res) => {
      })
  }
  // Tracking users comming from invitations
  welcomeQrUserTracker() {
    var scope = this;
    let data = {
      Slug: scope.slug,
      EmployeeName: '',
      Department: ""
    }
    if (scope.router['browserUrlTree'].queryParams['emp'] != undefined &&
      scope.router['browserUrlTree'].queryParams['emp'].length > 0) {
      data['EmployeeName'] = scope.router['browserUrlTree'].queryParams['emp']
    }

    if (scope.router['browserUrlTree'].queryParams['dept'] != undefined &&
      scope.router['browserUrlTree'].queryParams['dept'].length > 0) {
      data['Department'] = scope.router['browserUrlTree'].queryParams['dept']
    }

    scope.http.post(environment.apiPath + 'QRWelcomeUser', data)
      .subscribe((res) => {
        if (res['success']) {
          // scope.inviteData.email = res['email'];
          // scope.inviteData.name = res['name'];
          scope.cdr.markForCheck();
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  clickButtonQrTracker(msg) {
    var scope = this;
    let data = {
      Slug: scope.slug,
      pageActivity: scope.pageService.encrypt(msg)
    }
    scope.http.post(environment.apiPath + 'QRClickOnButton', data)
      .subscribe((res) => {
      })
  }
  reviewSourceQrTracker(source) {
    var scope = this;
    let data = {
      Slug: scope.slug,
      pageActivity: scope.pageService.encrypt(source)
    }
    scope.http.post(environment.apiPath + 'QRClickOnReviewSite', data)
      .subscribe((res) => {
      })
  }

  check1
  check2
  check3
  check4
  check5
  check6
  check7
  check8
  check9
  check10

  setNps(e) {
    if (e <= 1) {
      this.check1 = e;
    }
    if (e <= 2) {
      this.check2 = e;
      if (e == 2) {
        this.check1 = 1;
        this.check2 = 2;
      }
    }
    if (e <= 3) {
      this.check3 = e;
      if (e == 3) {
        this.check1 = 1;
        this.check2 = 2;
        this.check3 = 3;
      }
    }
    if (e <= 4) {
      this.check4 = e;
      if (e == 4) {
        this.check1 = 1;
        this.check2 = 2;
        this.check3 = 3;
        this.check4 = 4;
      }
    }
    if (e <= 5) {
      this.check5 = e;
      if (e == 5) {
        this.check1 = 1;
        this.check2 = 2;
        this.check3 = 3;
        this.check4 = 4;
        this.check5 = 5;
      }
    }
    if (e <= 6) {
      if (e == 6) {
        this.check1 = 6;
        this.check2 = 6;
        this.check3 = 6;
        this.check4 = 6;
        this.check5 = 6;
      }
      this.check6 = e;
    }
    if (e <= 7) {
      if (e == 7) {
        this.check1 = 6;
        this.check2 = 6;
        this.check3 = 6;
        this.check4 = 6;
        this.check5 = 6;
        this.check6 = 6;
      }
      this.check7 = e;
    }
    if (e <= 8) {

      if (e == 8) {
        this.check1 = 6;
        this.check2 = 6;
        this.check3 = 6;
        this.check4 = 6;
        this.check5 = 6;
        this.check6 = 6;
        this.check7 = 7;
        this.check8 = 8;
      }
      this.check8 = e;
    }
    if (e <= 9) {
      if (e == 9) {
        this.check1 = 9;
        this.check2 = 9;
        this.check3 = 9;
        this.check4 = 9;
        this.check5 = 9;
        this.check6 = 9;
        this.check7 = 9;
        this.check8 = 9;
      }
      this.check9 = e;
    }
    if (e <= 10) {
      if (e == 10) {
        this.check1 = 9;
        this.check2 = 9;
        this.check3 = 9;
        this.check4 = 9;
        this.check5 = 9;
        this.check6 = 9;
        this.check7 = 9;
        this.check8 = 9;
        this.check9 = 9;
      }
      this.check10 = e;
    }
  }

  unsetNps() {
    if (this.check1 == 1) {
      this.check1 = 0;
    }
  }

  // loadContentpage() {
  //   var scope = this;
  //   scope.reviewSiteList = [];

  //   scope.cdr.markForCheck();
  //   scope.loading = true;
  //   scope.http.get(environment.apiPath + 'GetReviewSource?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId)
  //     .subscribe((res) => {
  //       scope.loading = false;
  //       if (res['success']) {
  //         // tempArr = JSON.parse(JSON.stringify(res['data']));

  //         scope.cdr.markForCheck();
  //       } else {
  //         scope.pageService.showError('Oops!!!', res['errorMsg'])
  //         if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
  //           setTimeout(() => {
  //             scope.pageService.sessionExpired();
  //           }, environment.sessionTimeOut)
  //         }
  //       }
  //     }, (err) => {
  //       if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

  //         this.pageService.hideLoader();
  //         this.pageService.showError('Oops!', err.error.errorMsg);
  //       }
  //     })
  // }


  // added by unnati 
  randomMsg: any;
  thankYouMsgs;

  saveData = JSON.parse(localStorage.saveData || null) || {};

  openUrl(sitename, ReviewUrl) {
    if (this.layoutDetails['isOpenSameTab'] == false) {
      window.open(ReviewUrl, '_blank')
    }
    else if (this.layoutDetails['OpenSameTab'] == false) {
      this.totalPlatformCount = this.layoutDetails['ReviewSource'].length;
      this.openUrlCount++;
      if (this.utm_source == 'qr') {
        this.reviewSourceQrTracker(sitename)

      } else {
        this.reviewSourceTracker(sitename)
      }
      window.open(ReviewUrl, '_blank');
      // review platform tracker api end
      if (this.layoutDetails['ReviewSource'].length > 0) {
        this.layoutDetails['ReviewSource'].filter((e) => {
          if (sitename == e.SiteName) {
            this.reviewSubmitted.push(e);
          }
        })
      }
    }
    else {
      window.open(ReviewUrl, '_self')
    }

    if (this.reviewSubmitted.length > 0) {
      this.arrayreviewSubmitted = this.reviewSubmitted
    }

    let arr;
    if (this.reviewSiteList.length > 0) {
      arr = this.reviewSiteList.filter((e) => {
        return e.SiteName !== sitename
      })
    }
    this.reviewSiteList = [];
    this.reviewSiteList = arr;

    if (this.reviewSiteList.length > 0) {
      this.arrayLen = this.reviewSiteList
    }

    this.thankYouMsgs = this.reviewPageSettings["ReviewThankyouMessage"] + this.companyName,
      this.reviewPageSettings["PublicReviewAppreciateMessage"];

    const rndInt = Math.floor(Math.random() * 8) + 1
    this.randomMsg = this.thankYouMsgs[rndInt - 1];

    // this.thankYouModal(this.reviewPageSettings['PublicReviewThankYouMsg']);
    this.cdr.detectChanges();
    localStorage.saveData = JSON.stringify(this.saveData);
    this.cdr.detectChanges();

  }


  drop(event: CdkDragDrop<string[]>) {
    var scope = this;
    moveItemInArray(scope.reviewSiteList, event.previousIndex, event.currentIndex);
    scope.changePrimary(scope.reviewSiteList[0]);
  }

  //Chnage primary review site
  changePrimary(target) {
    var scope = this;
    let priorityArr = [];
    scope.reviewSiteList.forEach((alone, i) => {
      let temp = {}
      temp['Id'] = alone.id;
      temp['Priority'] = i + 1;
      temp['name'] = alone.SiteName
      priorityArr.push(temp)
    });
    let data = {
      Id: target.id,
      Key: scope.accessKey,
      IsPrimary: !target.IsPrimary,
      Priority: priorityArr
    }

    scope.pageService.showLoader();
    scope.http.post(environment.apiPath + "UpdatePrimaryReviewSource", data).
      subscribe((res) => {

        scope.pageService.hideLoader();
        if (res['success']) {
          scope.pageService.showSuccess('Updated', 'Reviewsite details updated successfully.');
          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();

          scope.loadPage();
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  isGoogleLogin: boolean;
  isFbLogin: boolean;

  show_login_status(network, status) {
    var scope = this;
    if (status) {
      // loggedIn-back
      document.querySelector(".logged-in-text." + network)['style'].display = 'block';
      document.querySelector('.logged-in-box.' + network).classList.add('loggedIn-back')
      if (network == 'Google') {
        scope.isGoogleLogin = true;

      } else if (network == 'Facebook') {
        scope.isFbLogin = true;
      }
    } else {
      document.querySelector(".logged-in-text." + network)['style'].display = 'none';
      document.querySelector('.logged-in-box.' + network).classList.remove('loggedIn-back');
      if (network == 'Google') {
        scope.isGoogleLogin = false;

      } else if (network == 'Facebook') {
        scope.isFbLogin = false;
      }
    }
  }

  redirectTo() {
    var scope = this;
    if (scope.layoutDetails == true) {
      let aloneData = scope.reviewSiteList.filter((aln) => { if (aln.IsPrimary == true) { return aln } })[0];

      let link = this.reviewSiteList[0].ReviewUrl;
      let siteName = this.reviewSiteList[0].SiteName;
      scope.openUrl(siteName, link)
    }

  }

}