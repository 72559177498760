import { Router } from '@angular/router';

import { MyModalSecondComponent } from './../wizard/my-modal-second/my-modal-second.component';
import { AlertModalComponent } from './../alert-modal/alert-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MyModalComponent } from './../wizard/my-modal/my-modal.component';
import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { MenuHorizontalService, PageConfigService } from '../../../core/_base/layout';
import { MatSnackBar, MatDialog } from '@angular/material';
import { CustomReviewsiteLinkModelComponent } from '../wizard/custom-reviewsite-link-model/custom-reviewsite-link-model.component';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'kt-review-sites',
  templateUrl: './review-sites.component.html',
  styleUrls: ['./review-sites.component.scss']
})
export class ReviewSitesComponent implements OnInit {
  profileData: {};
  profileId: any
  listOfSites = [];
  placeResult: any;
  accessKey: any;
  reviewSourceConnectedList = [];
  connectedList = [];
  disArr = [];
  searchInput = ''
  disSearchArr = [];
  socialListFromApi = [];
  Notfound: boolean = false;
  shortName = '';
  reviewPlatformPermission = 0
  viewDocumentation: boolean = true;
  isExpired: boolean = false
	public displayOnGetReviewPage: boolean = false;

  currentPlan: any;

  constructor(public http: HttpClient,
    public pageService: PageConfigService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef,
    public menuService: MenuHorizontalService,
    public router: Router) {
  }

  customImg = [];
  isGoogleAuthError = false;
  freetrialFlag: boolean = false;
  ngOnInit() {

    this.pageService.showLoader();
    if (localStorage.getItem("isExpired") == "true") {
      this.router.navigate(['/general/billing']);
      return
    }
    this.pageService.identity.subscribe((res: any) => {
      if (res == 'googleAuthError') {
        this.isGoogleAuthError = true;
      } else if (res == 'googleAuthNoError') {
        this.isGoogleAuthError = false;
      } else {
        this.isGoogleAuthError = false;
      }
    })
    var scope = this;
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    let view = JSON.parse(localStorage.getItem('view'));
    scope.currentPlan = JSON.parse(localStorage.getItem('plan'))
    scope.currentPlan = JSON.parse(localStorage.getItem('current-plan'))


    scope.freetrialFlag = false
    // if (scope.currentPlan == "Free Trial") {
    //   scope.freetrialFlag = true
    // }
    // else{
    //   scope.freetrialFlag = false
    // }
    let viewPermission;
    if (view != null) {

      viewPermission = JSON.parse(scope.pageService.decrypt(view))
    }
    scope.isExpired = JSON.parse(localStorage.getItem('isExpired'))
    if (scope.isExpired == false) {
      scope.reviewPlatformPermission = viewPermission['ReviewPlatformLimit']
      scope.pageService.callChooseProfile();
      if (scope.profileId == null || scope.profileId < 0) {
        scope.pageService.createNewProfilePopup();
      } else {
        scope.loadPage();
      }
    //  scope.pageService.checkReviewStatus();
      scope.cdr.markForCheck();

      scope.viewDocumentation = JSON.parse(localStorage.getItem('showBranding'))
      scope.pageService.getProgileId().subscribe(message => {
        if (message.id) {
          scope.profileId = JSON.parse(localStorage.getItem('ProfileId'));
          if (scope.profileId == null || scope.profileId < 0) {
            scope.pageService.createNewProfilePopup();
          } else {
            //scope.pageService.checkReviewStatus();
            scope.cdr.markForCheck();
            if (scope.router.url.includes('review-platforms')) {

              scope.loadPage();
            }
            scope.cdr.markForCheck();
          }

        }
      });
    }

    scope.pageService.setTitle('Review Platform')

  }

  showCustImg = false;
  showCustLogo = false;
  reviewPlat = true;

  loadPage() {
    var scope = this;

    scope.listOfSites = [];
    scope.connectedList = [];

    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'GetReviewSource?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId)
      .subscribe((res: any) => {
        if (res.data.length >= 1) {
          this.reviewPlat = false;
          localStorage.setItem("isConnnectedReviewPlatform", "true")
        } else if (res.data.length == 0) {
          this.reviewPlat = true;
          localStorage.setItem("isConnnectedReviewPlatform", "false")
        }
        scope.pageService.hideLoader();
        if (res['success']) {
          scope.profileData = res;

          for (let i in res['data']) {
            if (res['data'][i].imageURL != "") {
              this.showCustImg = true;
              this.showCustLogo = false;
            }
            else {
              this.showCustLogo = true;
              this.showCustImg = false;
            }
          }
          scope.connectedList = res['data'];
          scope.connectedList.forEach((aln) => {
            if (aln.isCustom == true) {
              aln.imageURL += '?lastmd' + Math.random();
            }
          })
          scope.shortName = res['shortName']
          scope.cdr.markForCheck()
          scope.loadSocialList()

        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }

      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  loadSocialList() {
    var scope = this;
    scope.listOfSites = [];
    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'GetSocialAccountList?key=' + scope.accessKey)
      .subscribe((resp) => {
        // Divide review site by category
        scope.pageService.hideLoader()
        if (resp['success']) {
          
          scope.socialListFromApi = JSON.parse(JSON.stringify(resp['data']))

          scope.socialListFromApi.forEach(element => {//loop through review site from socail account list api
            if (!scope.connectedList.some((alone) => {//check whether site is connected or not
              
              if (element.reviewSiteName != null && element.reviewSiteName.toLowerCase() == alone.reviewSiteName.toLowerCase() && alone.isCustom != true) {
                 return alone
                
              }
            })) {//add to dispplay list if the site is not connected
              
              if (!scope.listOfSites.some((alone) => { if (alone.title == element.category) { return alone } })) {//check id site is already addred to display list
                let data = {
                  title: element.category,
                  list: []
                }
                
                //check whether site is connected or not
                data.list = scope.socialListFromApi.filter((aln) => {
                  if (aln.category == element.category && !scope.connectedList.some((el) => { if (el.reviewSiteName == aln.reviewSiteName && el.isCustom !=true) { return el } })) {
                  
                    return aln;
                  }
                })
                scope.listOfSites.push(data)
              }
            }
          });

          
          scope.disArr = JSON.parse(JSON.stringify(scope.listOfSites))
          scope.cdr.markForCheck()
        } else {
          scope.pageService.showError('Oops!!!', resp['errorMsg'])
          if (resp['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || resp['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  //if userwants to add custom link
  openCustomDialog(target, targetData) {
    var scope = this;
    if (target == 'edit' || scope.reviewPlatformPermission == 0 || scope.connectedList.length < scope.reviewPlatformPermission) {
      const customLinkDialogRef = scope.dialog.open(CustomReviewsiteLinkModelComponent, {
        width: '700px',
        panelClass: 'editModal',
        disableClose: true,
        data: {
          source: target,
          targetSiteData: targetData
        }
      });

      customLinkDialogRef.afterClosed().subscribe(result => {

        if (result) {

          if (result.imgChanged == true) {
            if (result.deletedArr.length > 0) {
              scope.deleteImageApi('callUpdate', result)
            } else {
              scope.uploadImageApi(result)
            }
          } else {
            if (result.deletedArr.length > 0) {
              scope.deleteImageApi('', result);
            } else {
              let tempData = {
                SiteName: result.SiteName,
                SiteUrl: result.SiteUrl,
                Instruction: result.Instruction,
                ImageURL: result.ImageURL,
                IsCustom: true,
                CollectReview: false,
                GetReview: true,
                ProfileId: scope.profileId,
                Key: localStorage.getItem(environment.authTokenKey)
              }

              scope.pageService.showLoader()
              if (result.source == 'new') {
                scope.callLinkReviewSource(tempData, result)
              } else if (result.source == 'edit') {
                tempData['Id'] = result.id
                scope.callUpdateReviewSource(tempData, result)
              }
            }
          }
        }
      })
    } else {
      scope.pageService.showError('Oops!!!', 'Your review platform limit exceeded.')
    }

  }

  uploadImageApi(result) {
    var scope = this;

    let formData = new FormData();
    let data = {
      ResellerId: '',
      Source: 'custom-logo',
      Sitename: result.SiteName,
      Shorturl: scope.shortName,
      Imgbase64: result.ImageURL,
      Type: result.imageData["name"].split('.')[1]
    }

    scope.http.post(environment.apiPath + 'Imageupload', data)
      .subscribe((res) => {

        if (res['success'] = true) {
          let tempData = {
            SiteName: result.SiteName,
            SiteUrl: result.SiteUrl,
            Instruction: result.Instruction,
            ImageURL: res['imgName'],
            IsCustom: true,
            CollectReview: false,
            GetReview: false,
            ProfileId: scope.profileId,
            Key: localStorage.getItem(environment.authTokenKey)
          }
          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
          scope.pageService.showLoader()
          if (result.source == 'new') {
            scope.callLinkReviewSource(tempData, result)
          } else if (result.source == 'edit') {
            tempData['Id'] = result.id
            scope.callUpdateReviewSource(tempData, result)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  deleteImageApi(target, result) {
    var scope = this;
    scope.http.get(environment.apiPath + 'DeleteImage?ImageUrl=' + result.deletedArr[0].substring(0, result.deletedArr[0].indexOf('?lastmd')))
      .subscribe((res) => {

        if (res['success'] = true) {
          let tempData = {
            SiteName: result.SiteName,
            SiteUrl: result.SiteUrl,
            Instruction: result.Instruction,
            ImageURL: result.ImageURL,
            IsCustom: true,
            CollectReview: false,
            GetReview: false,
            ProfileId: scope.profileId,
            Key: localStorage.getItem(environment.authTokenKey)
          }

          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
          scope.pageService.showLoader();
          if (target == 'callUpdate') {
            scope.uploadImageApi(result)
          } else {
            if (result.source == 'new') {
              scope.callLinkReviewSource(tempData, result)
            } else if (result.source == 'edit') {
              tempData['Id'] = result.id
              scope.callUpdateReviewSource(tempData, result)
            }
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  openAlertDialog(target) {
    var scope = this;
    let tempdata = {
      targetSiteData: target,
      disableClose: true
    }
    const alertDialogRef = scope.dialog.open(AlertModalComponent, {
      width: '500px',
      panelClass: 'editModal',
      data: tempdata
    });
    alertDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.targetSiteData.isCustom == true) {
          if (result.targetSiteData.imageURL != undefined && result.targetSiteData.imageURL != null && result.targetSiteData.imageURL.length > 0) {
            scope.http.get(environment.deleteImageApiPath + 'DeleteImage?ImageUrl=' + result.targetSiteData.imageURL)
              .subscribe((res) => {

                if (res['success'] == true) {
                  scope.deleteReviewSource(result.targetSiteData)
                } else {
                  scope.deleteReviewSource(result.targetSiteData)
                }
              }, (err) => {
                if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

                  this.pageService.hideLoader();
                  this.pageService.showError('Oops!', err.error.errorMsg);
                }
              })
          } else {
            scope.deleteReviewSource(result.targetSiteData)
          }
        } else {
          scope.deleteReviewSource(result.targetSiteData)

        }
      }
    })
  }

  //Sarch socila sites
  serachSocial() {
    var scope = this;

    scope.Notfound = false;
    scope.disSearchArr = [];
    if (this.searchInput.length > 0) {//check whether search input is empty or not
      scope.listOfSites.forEach((alone, i) => {//loop through displpy list
        if (alone.title.toLocaleLowerCase().includes(this.searchInput.toLocaleLowerCase())) {
          //check category title matches with the searched text if yes add all list of tat category to searched array
          scope.disSearchArr = alone.list;
        } else {
          //searched does not match with category title then check each element of each category
          alone.list.forEach((aln) => {
            if (aln.reviewSiteName != null) {
              //check review site name of each element match with the searched value
              if (aln.reviewSiteName.toLocaleLowerCase().includes(this.searchInput.toLocaleLowerCase())) {
                if (!scope.disSearchArr.some((ele) => { if (ele.reviewSiteName == aln.reviewSiteName) { return ele } })) {//(condition for duplication)
                  //add review site to saerched array (No duplication)
                  scope.disSearchArr.push(aln)
                }
              } else {
                scope.Notfound = true;
              }
            }
          })
        }
      })
      scope.cdr.markForCheck()

    }
  }

  openUpdateGoogleDialog(target) {
    var scope = this;

    const updateGoogleDialogRef = scope.dialog.open(MyModalSecondComponent, {
      width: '700px',
      disableClose: true,
      data: {
        target: target
      }
    });
    updateGoogleDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.locationLinkUrl.toLowerCase() == result.siteData.siteURL.toLowerCase() &&
          result.collectReview == result.siteData.collectReview && result.getReview == result.siteData.getReview) {
          return;
        }
        scope.pageService.showLoader()
        let tempdata = {};
        tempdata['Id'] = result.siteData.id;
        tempdata['SiteName'] = result.siteData.reviewSiteName;
        tempdata['CollectReview'] = result.collectReview;
        tempdata['GetReview'] = result.getReview;
        tempdata['ProfileId'] = scope.profileId;
        tempdata['siteURL'] = result.locationLinkUrl;
        tempdata['Key'] = localStorage.getItem(environment.authTokenKey);
        scope.callUpdateReviewSource(tempdata, result)
      }
    })

  }

  openDialog(target, value): void {
    var scope = this;
    if (value == "connected") {
      if (target.isCustom) {
        scope.openCustomDialog('edit', target);
        return;
      }
      target.placeholderLink = target.placeholder;

      let tempData = {
        name: target.reviewSiteName,
        showMobile: true,
        showDesk: true,
        collectReview: true,
        displayOnReviewPage: true,
        isReviewScrapAvailable: target.isReviewScrapAvailable,
        instruction: target.instruction,
        siteData: target,
        value : "connected"
      }
      if (scope.profileId) {
        let tempObj = scope.connectedList.filter((alone) => {
          if (alone.reviewSiteName.toLowerCase() == target.reviewSiteName.toLowerCase()) {
            return alone;
          }
        })[0]

        if (tempObj != undefined) {
          tempData['showDesk'] = tempObj['showonDesktop']
          tempData['showMobile'] = tempObj['showonMobile']
          tempData['collectReview'] = tempObj['collectReview']
          tempData['displayOnReviewPage'] = tempObj['getReview']

          if (tempObj['reviewSiteName'].toLowerCase() != 'google') {
            tempData['siteURL'] = tempObj['siteURL']
          }
          if (target.reviewSiteName.toLowerCase() == 'google') {
            tempData['siteURL'] = tempObj['siteURL']
            scope.openUpdateGoogleDialog(target);
            return;
          }
        }
      }

      if (target.reviewSiteName.toLowerCase() == 'google') {
        tempData['mapFlag'] = true;
      } else {
        tempData['mapFlag'] = false;
      }
      
      const dialogRef = this.dialog.open(MyModalComponent, {
        width: '700px',
        data: tempData,
        panelClass: 'editModal',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          let datatosend = {};
          window.scrollTo(0, 0)
          scope.pageService.showLoader()

          //if Google Maps or services
          if (result.mapFlag) {
            if (result.placeResult != undefined) {//if business selected from google map
              scope.placeResult = result.placeResult;
              datatosend['GooglePlaceId'] = scope.placeResult.place_id;
              datatosend['GoogleCID'] = scope.placeResult.url.split('=')[1];
              datatosend['GoogleFID'] = '0x0:0x' + scope.pageService.dec2hex(datatosend['GoogleCID']);
              datatosend['SiteUrl'] = environment.writeReviewPath + datatosend['GooglePlaceId'];

            } else {
              datatosend['SiteUrl'] = 'http://search.google.com/local/writereview?';
              datatosend['BusinessName'] = result.location;
            }
          } else {//other social link selected eg. facebook ,yelp

            datatosend['SiteUrl'] = result.linkUrl;
          }
          datatosend['SiteName'] = result.name;
          datatosend['CollectReview'] = result.collectReview;
          datatosend['GetReview'] = result.getReview;
          datatosend['ProfileId'] = this.profileId;
          datatosend['Key'] = localStorage.getItem(environment.authTokenKey);
          //connect google facebook or any other link to your profile
          if (scope.connectedList.some((alone) => { if (alone.reviewSiteName.toLowerCase() == datatosend['SiteName'].toLowerCase()) { return alone; } })) {
           
            let id = scope.connectedList.filter((alone) => {
              if (alone.reviewSiteName.toLowerCase() == datatosend['SiteName'].toLowerCase()) {
                return alone;
              }
            })[0].id
            datatosend['Id'] = id
            //Update  details of review site
            datatosend['GetReview'] = result.getReview;
            scope.callUpdateReviewSource(datatosend, result)
          } else {
            //link review site
            
            scope.callLinkReviewSource(datatosend, result)
          }
        }
      });
      // }
    } else {
      if (scope.reviewPlatformPermission == 0 || scope.connectedList.length < scope.reviewPlatformPermission) {
        if (target.isCustom) {
          scope.openCustomDialog('edit', target);
          return;
        }
        let tempData = {
          name: target.reviewSiteName,
          showMobile: true,
          showDesk: true,
          collectReview: true,
          displayOnReviewPage: true,
          isReviewScrapAvailable: target.isReviewScrapAvailable,
          instruction: target.instruction,
          siteData: target
        }
        if (scope.profileId) {
          let tempObj = scope.connectedList.filter((alone) => {
            if (alone.reviewSiteName.toLowerCase() == target.reviewSiteName.toLowerCase()) {
              return alone;
            }
          })[0]

          if (tempObj != undefined) {
            tempData['showDesk'] = tempObj['showonDesktop']
            tempData['showMobile'] = tempObj['showonMobile']
            tempData['collectReview'] = tempObj['collectReview']
            tempData['displayOnReviewPage'] = tempObj['getReview']

            if (tempObj['reviewSiteName'].toLowerCase() != 'google') {
              tempData['siteURL'] = tempObj['siteURL']
            }
            if (target.reviewSiteName.toLowerCase() == 'google') {
              tempData['siteURL'] = tempObj['siteURL']
              scope.openUpdateGoogleDialog(target);
              return;
            }
          }
        }

        if (target.reviewSiteName.toLowerCase() == 'google') {
          this.pageService.showLoader();
          setTimeout(() => {
            this.pageService.hideLoader();
          }, 1500)
          tempData['mapFlag'] = true;
        } else {
          tempData['mapFlag'] = false;
        }
        const dialogRef = this.dialog.open(MyModalComponent, {
          width: '700px',
          data: tempData,
          panelClass: 'editModal',
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {

          if (result) {

            let datatosend = {};
            window.scrollTo(0, 0)
            scope.pageService.showLoader()

            //if Google Maps or services
            if (result.mapFlag) {
              if (result.placeResult != undefined) {//if business selected from google map
                scope.placeResult = result.placeResult;
                datatosend['GooglePlaceId'] = scope.placeResult.place_id;
                datatosend['GoogleCID'] = scope.placeResult.url.split('=')[1];
                datatosend['GoogleFID'] = '0x0:0x' + scope.pageService.dec2hex(datatosend['GoogleCID']);
                datatosend['SiteUrl'] = environment.writeReviewPath + datatosend['GooglePlaceId'];

              } else {
                datatosend['SiteUrl'] = 'http://search.google.com/local/writereview?';
                datatosend['BusinessName'] = result.location;
              }
            } else {//other social link selected eg. facebook ,yelp

              datatosend['SiteUrl'] = result.linkUrl;
            }

            datatosend['SiteName'] = result.name;
            datatosend['CollectReview'] = result.collectReview;
            datatosend['GetReview'] = result.getReview;
            datatosend['ProfileId'] = this.profileId;
            datatosend['Key'] = localStorage.getItem(environment.authTokenKey);

            //connect google facebook or any other link to your profile

            if (scope.connectedList.some((alone) => { if (alone.reviewSiteName.toLowerCase() == datatosend['SiteName'].toLowerCase()) { return alone; } })) {

              let id = scope.connectedList.filter((alone) => {
                if (alone.reviewSiteName.toLowerCase() == datatosend['SiteName'].toLowerCase()) {
                  return alone;
                }
              })[0].id
              datatosend['Id'] = id
              //Update  details of review site
              datatosend['GetReview'] = result.displayOnReviewPage;
              scope.callUpdateReviewSource(datatosend, result)
            } else {
              //link review site
              
              scope.callLinkReviewSource(datatosend, result)
            }
          }
        });
      } else {
        scope.pageService.showError('Oops!!!', 'Your review platform limit exceeded.')
      }
    }

  }

  //get place id from cid (only for service ara or map link)
  getPlaceIdFromCid(cid) {
    var scope = this;
    return new Promise((resolve, reject) => {
      scope.http.get(environment.apiPathForPlaceidFromCid + 'cid=' + cid + '&key=' + environment.googleMapsApiKey)
        .subscribe((resp) => {

          if (resp['status'].toLocaleLowerCase() == 'ok') {
            resolve(resp['result'].place_id)
          } else {
            reject(resp['error_message'])
          }
        }, (err) => {
          if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

            this.pageService.hideLoader();
            this.pageService.showError('Oops!', err.error.errorMsg);
          }
        })
    })


  }
  //Connect review site and store details to database
  callLinkReviewSource(datatosend, result) {
    var scope = this;
    scope.http.post(environment.apiPath + 'LinkReviewSource', datatosend)
      .subscribe(async (resp) => {  
       // scope.pageService.hideLoader();
        if (resp['success']) {
          await this.getFirstTimeReview(resp);
          localStorage.setItem("isConnnectedReviewPlatform", "true")
          scope.pageService.showSuccess('Connected', datatosend['SiteName'] + ' connected successfully.')
          scope.loadPage();
          this.menuService.updateIndentity("reviewPlatform");
          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
           this.http.get(environment.apiPath + 'FetchGoogleReview?key=' + JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
          this.pageService.showLoader();

          

        //  this.http.get(environment.apiPath + 'FetchFirstTimeReviews?key=' + resp['data'],{ observe: 'response' }).subscribe((response)=>{
				// 	debugger;
				// 		if(response.status == 200){
				// 			//	scope.pageService.checkReviewStatus();
				// 			localStorage.setItem("isConnnectedReviewPlatform", "true");
				// 			this.pageService.hideLoader();
				// 		}
				// 		else{
				// 			scope.pageService.showError('Oops', 'Something went wrong, try again!')

				// 		}
				// 	});

          //scope.pageService.checkReviewStatus();
          localStorage.setItem("isConnnectedReviewPlatform", "true");
        } else {
          scope.pageService.showError('Oops!!!', resp['errorMsg'])
          if (resp['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || resp['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  //Function to wait for FetchFirstReview API Response
  async getFirstTimeReview(resp) {
    try {
      const response = await this.http.get(environment.apiV2Path + 'reviews/fetch?key=' + resp['data'], { observe: 'response' }).toPromise();
  
      if (response.status === 200) {
        // Process the successful response
        localStorage.setItem("isConnnectedReviewPlatform", "true");
        this.pageService.hideLoader();
      } else {
        // Handle other status codes
        //this.pageService.showError('Oops', 'Something went wrong, try again!');
      }
    } catch (error) {
      // Handle errors during the HTTP request
      console.error('Error fetching data:', error);
      //this.pageService.showError('Oops', 'Something went wrong, try again!');
    }
  }

  //update details to database
  callUpdateReviewSource(datatosend, result) {
    var scope = this;
    scope.http.post(environment.apiPath + 'UpdateReviewSource', datatosend)
      .subscribe((resp) => {
        scope.pageService.hideLoader();

        if (resp['success']) {
          scope.pageService.showSuccess('Connected', datatosend['SiteName'] + ' connected successfully.')
          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
        //  scope.pageService.checkReviewStatus();
          scope.loadPage();
        } else {
          scope.pageService.showError('Oops!!!', resp['errorMsg'])
          if (resp['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || resp['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  notificationMsg: any = '';
  //delete review site 
  deleteReviewSource(target) {
    var scope = this;
    scope.pageService.showLoader()
    // var ConnectedreviewName = localStorage.getItem('platformError');
    // var isshow = ConnectedreviewName.includes(target.reviewSiteName)
    // if (isshow) {
    //  localStorage.removeItem('platformError')
    // }
    
    scope.http.get(environment.apiPath + 'DeleteReviewSource?Key=' + scope.accessKey + '&Id=' + target.id)
      .subscribe((res) => {
        scope.pageService.hideLoader();
      // added by unnati for hide header msg
        var ConnectedreviewName = localStorage.getItem('platformError');
        var isshow = ConnectedreviewName.includes(target.reviewSiteName)
        if (isshow) {
         localStorage.removeItem('platformError')

        }
        if (res['success'] == true) {
          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
          scope.pageService.showSuccess('Disconnected', target.reviewSiteName + ' disconnected successfully.')
          localStorage.setItem('hasConntectedReviewPlatform', 'false');
          this.menuService.updateIndentity('deletereviewSource');
          this.ngOnInit();
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
}