<body class="bgImg bgImgRes">
    <div class="row">
        <div class="col-md-12 col-xl-12 noAcMsgParent">
            <span class="noAcMsg">Can't find any business associated with selected account</span>
        </div>
        <div class="col-md-12 col-xl-12 noAcMsgParent">
            <button  style="text-transform: uppercase;" class="createAnotherAc btn" (click)="signWithOther()">Connect another account</button>
        </div>
    </div>
</body>
