

import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
// Layout
import { DataTableService, LayoutConfigService, MenuHorizontalService } from '../../../core/_base/layout';
import { Router } from '@angular/router';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material';
import { CustomReviewsiteLinkModelComponent } from '../wizard/custom-reviewsite-link-model/custom-reviewsite-link-model.component';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { MyModalSecondComponent } from '../wizard/my-modal-second/my-modal-second.component';
import { MyModalComponent } from '../wizard/my-modal/my-modal.component';
import { ConfrimAlertComponent } from '../confrim-alert/confrim-alert.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'kt-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.scss']
})
export class SetupAccountComponent implements OnInit {

  constructor(
    public formBuilder: UntypedFormBuilder,
    public dataService: DataTableService,
    public dialogRef: MatDialog,
    public toastr: ToastrService,
    public http: HttpClient,
    private _snackBar: MatSnackBar,
    public cdr: ChangeDetectorRef,
    public pageService: PageConfigService,
    public menuService: MenuHorizontalService,
    private layoutConfigService: LayoutConfigService,
    private router: Router) {
  }

  profileData: {};
  profileId: any
  listOfSites = [];
  placeResult: any;
  accessKey: any;
  reviewSourceConnectedList = [];
  connectedList = [];
  disArr = [];
  searchInput = ''
  disSearchArr = [];
  socialListFromApi = [];
  Notfound: boolean = false;
  shortName = '';
  reviewPlatformPermission = 0
  viewDocumentation: boolean = true;
  isExpired: boolean = false
  customImg = [];
  isGoogleAuthError = false;


  ngOnInit() {
    this.menuService.identity.subscribe((res: any) => {
      if (res.length > 20) {
        this.connectGoogle(res);
      }
    })
    this.pageService.identity.subscribe((res: any) => {
      if (res == 'googleAuthError') {
        this.isGoogleAuthError = true;
      } else if (res == 'googleAuthNoError') {
        this.isGoogleAuthError = false;
      } else {
        this.isGoogleAuthError = false;
      }
    })
    var scope = this;
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    let view = JSON.parse(localStorage.getItem('view'));
    let viewPermission;
    if (view != null) {

      viewPermission = JSON.parse(scope.pageService.decrypt(view))
    }
    scope.isExpired = JSON.parse(localStorage.getItem('isExpired'))
    if (scope.isExpired == false) {
      scope.reviewPlatformPermission = viewPermission['ReviewPlatformLimit']
      scope.pageService.callChooseProfile();
      if (scope.profileId == null || scope.profileId < 0) {
        scope.pageService.createNewProfilePopup();
      } else {
        scope.loadPage();
      }
      //scope.pageService.checkReviewStatus();
      scope.cdr.markForCheck();

      scope.viewDocumentation = JSON.parse(localStorage.getItem('showBranding'))
      scope.pageService.getProgileId().subscribe(message => {
        if (message.id) {
          scope.profileId = JSON.parse(localStorage.getItem('ProfileId'));
          if (scope.profileId == null || scope.profileId < 0) {
            scope.pageService.createNewProfilePopup();
          } else {
          //  scope.pageService.checkReviewStatus();
            scope.cdr.markForCheck();
            if (scope.router.url.includes('review-platforms')) {

              scope.loadPage();
            }
            // scope.loadPage()
            scope.cdr.markForCheck();
          }

        }
      });
    }

    //Invite Customer

    scope.createForm();
    scope.viewDocumentation = JSON.parse(localStorage.getItem('showBranding'))
    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
    scope.pageService.callChooseProfile();
    scope.loadSMSTemplates()
    scope.pageService.getProgileId().subscribe(message => {
      if (message.id) {
        scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
        if (scope.router.url.includes('invite-customer')) {
          scope.loadSMSTemplates()
        }
      }
    })
    scope.pageService.setTitle('Invite Customer')
    if (localStorage.getItem("isConnnectedReviewPlatform") == "true") {
      this.isDisable = true;
      this.isDisableMsg = false;
      this.isDisableUpload = false;
      // this.isInviteDisable = false;
    } else {
      this.isDisable = true;
      this.isDisableMsg = true;
      this.isDisableUpload = true;
      // this.isInviteDisable = false;
    }
    this.getPermission();
  }

  googleBusinessList;

  connectGoogle(googleToken) {

    this.pageService.showLoader();
    let data = {
      Key: localStorage.getItem(environment.authTokenKey),
      ProfileId: JSON.parse(localStorage.getItem('ProfileId')),
      AccessToken: googleToken,
      RedirectUrl: window.location.origin,
      profileId: JSON.parse(localStorage.getItem('ProfileId'))
    }

    this.http.post(environment.apiPath + "GetGoogleAccountList", data).subscribe((res: any) => {
      if (res.success) {
        this.pageService.hideLoader();
        this.accessToken = res.accessToken;
        this.googleBusinessList = res.googleBusinessList;
        this.checkClick();
        this.cdr.markForCheck();
      } else {
        this.pageService.hideLoader();
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  checkClick() {
    this.showReviewPlt = false;
    this.showGoogleBusinessList = true;
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }
  ngOnDestroy(): void {
    this.layoutConfigService.resetlayout2Config();
  }

  newProfileMsg = "Congratulations 🎉 the beginning of even more great things to come for you!"
  isShow = false;
  showSetupComp = true;
  showReviewPlt = false;
  showGoogleBusinessList = false;
  submit() {
    this.isShow = true;

    this._snackBar.open(this.newProfileMsg, "", {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ["snackbar"]
    });
    setTimeout(() => {
      this.isShow = false;
      this.showSetupComp = false;
      this.showReviewPlt = true;
      this.cdr.markForCheck();
      // this.dismiss();
    }, 3000)
  }

  dismiss() {
    this._snackBar.dismiss();
  }
  // connect review platform

  showCustImg = false;
  showCustLogo = false;
  reviewPlat = true;
  loadPage() {
    var scope = this;

    scope.listOfSites = [];
    scope.connectedList = [];

    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'GetReviewSource?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId)
      .subscribe((res: any) => {
        if (res.data.length >= 1) {
          this.reviewPlat = false;
          localStorage.setItem("isConnnectedReviewPlatform", "true")
        } else if (res.data.length == 0) {
          this.reviewPlat = true;
          localStorage.setItem("isConnnectedReviewPlatform", "false")
        }
        scope.pageService.hideLoader();
        if (res['success']) {
          scope.profileData = res;

          for (let i in res['data']) {
            if (res['data'][i].imageURL != "") {
              this.showCustImg = true;
              this.showCustLogo = false;
            }
            else {
              this.showCustLogo = true;
              this.showCustImg = false;
            }
          }
          // scope.connectedList=scope.profileData['socialAccountLinkedList'];
          scope.connectedList = res['data'];
          scope.connectedList.forEach((aln) => {
            if (aln.isCustom == true) {
              aln.imageURL += '?lastmd' + Math.random();
            }
          })
          scope.shortName = res['shortName']
          scope.cdr.markForCheck()
          scope.loadSocialList()

        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }

      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })

  }
  loadSocialList() {
    var scope = this;
    scope.listOfSites = [];
    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'GetSocialAccountList?key=' + scope.accessKey)
      .subscribe((resp) => {
        // Divide review site by category
        scope.pageService.hideLoader()
        if (resp['success']) {

          scope.socialListFromApi = JSON.parse(JSON.stringify(resp['data']))

          scope.socialListFromApi.forEach(element => {//loop through review site from socail account list api
            if (!scope.connectedList.some((alone) => {//check whether site is connected or not
              if (element.reviewSiteName != null && element.reviewSiteName.toLowerCase() == alone.reviewSiteName.toLowerCase()) {
                return alone
              }
            })) {//add to dispplay list if the site is not connected
              if (!scope.listOfSites.some((alone) => { if (alone.title == element.category) { return alone } })) {//check id site is already addred to display list
                let data = {
                  title: element.category,
                  list: []
                }
                //check whether site is connected or not
                data.list = scope.socialListFromApi.filter((aln) => {
                  if (aln.category == element.category && !scope.connectedList.some((el) => { if (el.reviewSiteName == aln.reviewSiteName) { return el } })) {
                    return aln;
                  }
                })
                scope.listOfSites.push(data)
              }
            }
          });


          scope.disArr = JSON.parse(JSON.stringify(scope.listOfSites))
          scope.cdr.markForCheck()
        } else {
          scope.pageService.showError('Oops!!!', resp['errorMsg'])
          if (resp['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || resp['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  //if userwants to add custom link
  openCustomDialog(target, targetData) {
    var scope = this;
    if (scope.reviewPlatformPermission == 0 || scope.connectedList.length < scope.reviewPlatformPermission) {
      const customLinkDialogRef = scope.dialogRef.open(CustomReviewsiteLinkModelComponent, {
        width: '700px',
        panelClass: 'editModal',
        disableClose: true,
        data: {
          source: target,
          targetSiteData: targetData
        }
      });
      customLinkDialogRef.afterClosed().subscribe(result => {

        if (result) {

          if (result.imgChanged == true) {
            if (result.deletedArr.length > 0) {
              scope.deleteImageApi('callUpdate', result)
            } else {
              scope.uploadImageApi(result)
            }
          } else {
            if (result.deletedArr.length > 0) {
              scope.deleteImageApi('', result);
            } else {
              let tempData = {
                SiteName: result.SiteName,
                SiteUrl: result.SiteUrl,
                Instruction: result.Instruction,
                ImageURL: result.ImageURL,
                IsCustom: true,
                CollectReview: false,
                ProfileId: scope.profileId,
                Key: localStorage.getItem(environment.authTokenKey)
              }

              scope.pageService.showLoader()
              if (result.source == 'new') {
                scope.callLinkReviewSource(tempData, result)
              } else if (result.source == 'edit') {
                tempData['Id'] = result.id
                scope.callUpdateReviewSource(tempData, result)
              }
            }
          }
        }
      })
    } else {
      scope.pageService.showError('Oops!!!', 'Your review platform limit exceeded.')
    }

  }
  uploadImageApi(result) {
    var scope = this;

    let formData = new FormData();
    let data = {
      ResellerId: '',
      Source: 'custom-logo',
      Sitename: result.SiteName,
      Shorturl: scope.shortName,
      Imgbase64: result.ImageURL,
      Type: result.imageData["name"].split('.')[1]
    }

    scope.http.post(environment.apiPath + 'Imageupload', data)
      .subscribe((res) => {

        if (res['success'] = true) {
          let tempData = {
            SiteName: result.SiteName,
            SiteUrl: result.SiteUrl,
            Instruction: result.Instruction,
            ImageURL: res['imgName'],
            IsCustom: true,
            CollectReview: false,
            ProfileId: scope.profileId,
            Key: localStorage.getItem(environment.authTokenKey)
          }

          // scope.imageData={}
          scope.pageService.showLoader()
          if (result.source == 'new') {
            scope.callLinkReviewSource(tempData, result)
          } else if (result.source == 'edit') {
            tempData['Id'] = result.id
            scope.callUpdateReviewSource(tempData, result)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  deleteImageApi(target, result) {
    var scope = this;
    scope.http.get(environment.apiPath + 'DeleteImage?ImageUrl=' + result.deletedArr[0].substring(0, result.deletedArr[0].indexOf('?lastmd')))
      .subscribe((res) => {

        if (res['success'] = true) {
          let tempData = {
            SiteName: result.SiteName,
            SiteUrl: result.SiteUrl,
            Instruction: result.Instruction,
            ImageURL: result.ImageURL,
            IsCustom: true,
            CollectReview: false,
            ProfileId: scope.profileId,
            Key: localStorage.getItem(environment.authTokenKey)
          }


          scope.pageService.showLoader();
          if (target == 'callUpdate') {
            scope.uploadImageApi(result)
          } else {
            if (result.source == 'new') {
              scope.callLinkReviewSource(tempData, result)
            } else if (result.source == 'edit') {
              tempData['Id'] = result.id
              scope.callUpdateReviewSource(tempData, result)
            }
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  openAlertDialog(target) {
    var scope = this;
    let tempdata = {
      targetSiteData: target,
      disableClose: true
    }
    const alertDialogRef = scope.dialogRef.open(AlertModalComponent, {
      width: '500px',
      panelClass: 'editModal',
      data: tempdata
    });
    alertDialogRef.afterClosed().subscribe((result) => {
      if (result) {

        // return;
        if (result.targetSiteData.isCustom == true) {
          if (result.targetSiteData.imageURL != undefined && result.targetSiteData.imageURL != null && result.targetSiteData.imageURL.length > 0) {
            scope.http.get(environment.deleteImageApiPath + 'DeleteImage?ImageUrl=' + result.targetSiteData.imageURL)
              .subscribe((res) => {

                if (res['success'] == true) {
                  scope.deleteReviewSource(result.targetSiteData)
                } else {
                  scope.deleteReviewSource(result.targetSiteData)
                }
              })
          } else {
            scope.deleteReviewSource(result.targetSiteData)
          }
        } else {
          scope.deleteReviewSource(result.targetSiteData)

        }
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }
  //Sarch socila sites
  serachSocial() {
    var scope = this;

    scope.Notfound = false;
    scope.disSearchArr = [];
    if (this.searchInput.length > 0) {//check whether search input is empty or not
      scope.listOfSites.forEach((alone, i) => {//loop through displpy list
        if (alone.title.toLocaleLowerCase().includes(this.searchInput.toLocaleLowerCase())) {
          //check category title matches with the searched text if yes add all list of tat category to searched array
          scope.disSearchArr = alone.list;
        } else {
          //searched does not match with category title then check each element of each category
          alone.list.forEach((aln) => {
            if (aln.reviewSiteName != null) {
              //check review site name of each element match with the searched value
              if (aln.reviewSiteName.toLocaleLowerCase().includes(this.searchInput.toLocaleLowerCase())) {
                if (!scope.disSearchArr.some((ele) => { if (ele.reviewSiteName == aln.reviewSiteName) { return ele } })) {//(condition for duplication)
                  //add review site to saerched array (No duplication)
                  scope.disSearchArr.push(aln)
                }
              } else {
                scope.Notfound = true;
              }
            }
          })
        }
      })
      scope.cdr.markForCheck()

    }
  }
  openUpdateGoogleDialog(target) {
    var scope = this;

    const updateGoogleDialogRef = scope.dialogRef.open(MyModalSecondComponent, {
      width: '700px',
      disableClose: true,
      data: {
        target: target
      }
    });
    updateGoogleDialogRef.afterClosed().subscribe(result => {

      if (result) {
        if (result.locationLinkUrl.toLowerCase() == result.siteData.siteURL.toLowerCase() &&
          result.collectReview == result.siteData.collectReview) {
          return;
        }
        scope.pageService.showLoader()
        let tempdata = {};
        tempdata['Id'] = result.siteData.id;
        tempdata['SiteName'] = result.siteData.reviewSiteName;
        tempdata['CollectReview'] = result.collectReview;
        tempdata['ProfileId'] = scope.profileId;
        tempdata['siteURL'] = result.locationLinkUrl;
        tempdata['Key'] = localStorage.getItem(environment.authTokenKey);
        scope.callUpdateReviewSource(tempdata, result)
      }
    })

  }

  openDialog(target, value): void {
    var scope = this;

    if (value == "connected") {
      if (target.isCustom) {
        scope.openCustomDialog('edit', target);
        return;
      }
      let tempData = {
        name: target.reviewSiteName,
        showMobile: true,
        showDesk: true,
        collectReview: true,
        isReviewScrapAvailable: target.isReviewScrapAvailable,
        instruction: target.instruction,
        siteData: target
      }
      if (scope.profileId) {
        let tempObj = scope.connectedList.filter((alone) => {
          if (alone.reviewSiteName.toLowerCase() == target.reviewSiteName.toLowerCase()) {
            return alone;
          }
        })[0]

        if (tempObj != undefined) {
          tempData['showDesk'] = tempObj['showonDesktop']
          tempData['showMobile'] = tempObj['showonMobile']
          tempData['collectReview'] = tempObj['collectReview']
          if (tempObj['reviewSiteName'].toLowerCase() != 'google') {
            tempData['siteURL'] = tempObj['siteURL']
          }
          if (target.reviewSiteName.toLowerCase() == 'google') {
            tempData['siteURL'] = tempObj['siteURL']
            scope.openUpdateGoogleDialog(target);
            return;
          }
        }
      }

      if (target.reviewSiteName.toLowerCase() == 'google') {
        tempData['mapFlag'] = true;
        tempData['newProfile'] = true;
      } else {
        tempData['mapFlag'] = false;
        tempData['newProfile'] = true;
      }
      const dialogRef = this.dialogRef.open(MyModalComponent, {
        width: '700px',
        data: tempData,
        panelClass: 'editModal',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          let datatosend = {};
          window.scrollTo(0, 0)
          scope.pageService.showLoader()
          //if Google Maps or services
          if (result.mapFlag) {
            if (result.placeResult != undefined) {//if business selected from google map
              scope.placeResult = result.placeResult;
              datatosend['GooglePlaceId'] = scope.placeResult.place_id;
              datatosend['GoogleCID'] = scope.placeResult.url.split('=')[1];
              datatosend['GoogleFID'] = '0x0:0x' + scope.pageService.dec2hex(datatosend['GoogleCID']);
              datatosend['SiteUrl'] = environment.writeReviewPath + datatosend['GooglePlaceId'];

            } else {
              datatosend['SiteUrl'] = 'http://search.google.com/local/writereview?';
              datatosend['BusinessName'] = result.location;
            }
          } else {//other social link selected eg. facebook ,yelp

            datatosend['SiteUrl'] = result.linkUrl;
          }

          datatosend['SiteName'] = result.name;
          datatosend['CollectReview'] = result.collectReview;
          datatosend['ProfileId'] = this.profileId;
          datatosend['Key'] = localStorage.getItem(environment.authTokenKey);

          //connect google facebook or any other link to your profile

          if (scope.connectedList.some((alone) => { if (alone.reviewSiteName.toLowerCase() == datatosend['SiteName'].toLowerCase()) { return alone; } })) {

            let id = scope.connectedList.filter((alone) => {
              if (alone.reviewSiteName.toLowerCase() == datatosend['SiteName'].toLowerCase()) {
                return alone;
              }
            })[0].id
            datatosend['Id'] = id
            //Update  details of review site
            scope.callUpdateReviewSource(datatosend, result)
          } else {
            //link review site
            scope.callLinkReviewSource(datatosend, result)
          }
        }
      });
      // }
    } else {
      if (scope.reviewPlatformPermission == 0 || scope.connectedList.length < scope.reviewPlatformPermission) {
        if (target.isCustom) {
          scope.openCustomDialog('edit', target);
          return;
        }
        let tempData = {
          name: target.reviewSiteName,
          showMobile: true,
          showDesk: true,
          collectReview: true,
          isReviewScrapAvailable: target.isReviewScrapAvailable,
          instruction: target.instruction,
          siteData: target
        }
        if (scope.profileId) {
          let tempObj = scope.connectedList.filter((alone) => {
            if (alone.reviewSiteName.toLowerCase() == target.reviewSiteName.toLowerCase()) {
              return alone;
            }
          })[0]

          if (tempObj != undefined) {
            tempData['showDesk'] = tempObj['showonDesktop']
            tempData['showMobile'] = tempObj['showonMobile']
            tempData['collectReview'] = tempObj['collectReview']
            if (tempObj['reviewSiteName'].toLowerCase() != 'google') {
              tempData['siteURL'] = tempObj['siteURL']
            }
            if (target.reviewSiteName.toLowerCase() == 'google') {
              tempData['siteURL'] = tempObj['siteURL']
              scope.openUpdateGoogleDialog(target);
              return;
            }
          }
        }

        if (target.reviewSiteName.toLowerCase() == 'google') {
          tempData['mapFlag'] = true;
          tempData['newProfile'] = true;
        } else {
          tempData['mapFlag'] = false;
          tempData['newProfile'] = true;
        }
        const dialogRef = this.dialogRef.open(MyModalComponent, {
          width: '700px',
          data: tempData,
          panelClass: 'editModal',
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            let datatosend = {};
            window.scrollTo(0, 0)
            scope.pageService.showLoader()

            //if Google Maps or services
            if (result.mapFlag) {
              if (result.placeResult != undefined) {//if business selected from google map
                scope.placeResult = result.placeResult;
                datatosend['GooglePlaceId'] = scope.placeResult.place_id;
                datatosend['GoogleCID'] = scope.placeResult.url.split('=')[1];
                datatosend['GoogleFID'] = '0x0:0x' + scope.pageService.dec2hex(datatosend['GoogleCID']);
                datatosend['SiteUrl'] = environment.writeReviewPath + datatosend['GooglePlaceId'];

              } else {

                datatosend['SiteUrl'] = 'http://search.google.com/local/writereview?';
                datatosend['BusinessName'] = result.location;
              }
            } else {//other social link selected eg. facebook ,yelp

              datatosend['SiteUrl'] = result.linkUrl;
            }

            datatosend['SiteName'] = result.name;
            datatosend['CollectReview'] = result.collectReview;
            datatosend['ProfileId'] = this.profileId;
            datatosend['Key'] = localStorage.getItem(environment.authTokenKey);

            //connect google facebook or any other link to your profile

            if (scope.connectedList.some((alone) => { if (alone.reviewSiteName.toLowerCase() == datatosend['SiteName'].toLowerCase()) { return alone; } })) {

              let id = scope.connectedList.filter((alone) => {
                if (alone.reviewSiteName.toLowerCase() == datatosend['SiteName'].toLowerCase()) {
                  return alone;
                }
              })[0].id
              datatosend['Id'] = id
              //Update  details of review site
              scope.callUpdateReviewSource(datatosend, result)
            } else {
              //link review site
              scope.callLinkReviewSource(datatosend, result)
            }
          }
        });
      } else {
        scope.pageService.showError('Oops!!!', 'Your review platform limit exceeded.')
      }
    }

  }
  //get place id from cid (only for service ara or map link)
  getPlaceIdFromCid(cid) {
    var scope = this;
    return new Promise((resolve, reject) => {
      scope.http.get(environment.apiPathForPlaceidFromCid + 'cid=' + cid + '&key=' + environment.googleMapsApiKey)
        .subscribe((resp) => {

          if (resp['status'].toLocaleLowerCase() == 'ok') {
            resolve(resp['result'].place_id)
          } else {
            reject(resp['error_message'])
          }
        }, (err) => {
          if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

            this.pageService.hideLoader();
            this.pageService.showError('Oops!', err.error.errorMsg);
          }
        })
    })

  }
  //Connect review site and store details to database
  callLinkReviewSource(datatosend, result) {
    var scope = this;
    scope.http.post(environment.apiPath + 'LinkReviewSource', datatosend)
      .subscribe((resp) => {

        scope.pageService.hideLoader();
        if (resp['success']) {
          localStorage.setItem("isConnnectedReviewPlatform", "true")
          scope.pageService.showSuccess('Connected', datatosend['SiteName'] + ' connected successfully.')
          scope.loadPage();
          //scope.pageService.checkReviewStatus();
          localStorage.setItem("isConnnectedReviewPlatform", "true");
        } else {
          scope.pageService.showError('Oops!!!', resp['errorMsg'])
          if (resp['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || resp['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  //update details to database
  callUpdateReviewSource(datatosend, result) {
    var scope = this;
    scope.http.post(environment.apiPath + 'UpdateReviewSource', datatosend)
      .subscribe((resp) => {
        scope.pageService.hideLoader();

        if (resp['success']) {
          scope.pageService.showSuccess('Connected', datatosend['SiteName'] + ' connected successfully.')

         // scope.pageService.checkReviewStatus();
          scope.loadPage();
        } else {
          scope.pageService.showError('Oops!!!', resp['errorMsg'])
          if (resp['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || resp['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  //delete review site 
  deleteReviewSource(target) {
    var scope = this;

    scope.pageService.showLoader()
    scope.http.get(environment.apiPath + 'DeleteReviewSource?Key=' + scope.accessKey + '&Id=' + target.id)
      .subscribe((res) => {

        scope.pageService.hideLoader();
        if (res['success'] == true) {
          scope.pageService.showSuccess('Disconnected', target.reviewSiteName + ' disconnected successfully.')
          // this.menuService.updateIndentity('deletereviewSource');
          this.ngOnInit();
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  // isShow = false;
  accountName: any;
  businessName: any;
  showLocation = false;
  showServiceArea = false;
  actBtn: any = "Show";
  enable = false;
  locationList = [];
  accountId;
  locationId;
  accessToken: any;
  showAccount = false;
  showBusiness = false;
  locationTracker: any = false;
  getLocation(id, acName, e) {

    if (acName == e.target.textContent) {
      this.accountName = e.target.textContent
    }
    this.isShow = true
    this.enable = false;
    // this.cardColor = true;
    this.locationList = [];
    this.accountId = id;
    this.pageService.showLoader();
    let locationData = {
      accessToken: this.accessToken,
      key: localStorage.getItem(environment.authTokenKey),
      accountId: id
    }

    this.http.post(environment.apiPath + 'GetGoogleLocationList', locationData).subscribe((res: any) => {
      this.pageService.hideLoader();
      if (res.success) {
        if (res.googleBusinessList.length == 0) {
          this.router.navigate(['/no-google-account']);
        } else {
          this.locationList = res.googleBusinessList;
          this.demo();
        }

      } else {
        this.router.navigate(['/reviews-platform']);
      }

      this.cdr.markForCheck();
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  demo() {
    this.showGoogleBusinessList = false;
    this.cdr.detectChanges();
    this.demo2();
  }

  inviteCustMsg = "Awesome!!! 🎉 you just one step away to send first review invitation"

  demo2() {
    this.showBusiness = true;
    this.cdr.detectChanges();
    // setTimeout(() => {
    this._snackBar.open(this.inviteCustMsg, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ["snackbar"]
    });
    // }, 100)

    setTimeout(() => {
      this.cdr.markForCheck();
      // this.dismiss()
    }, 3000)

  }

  save(id, locationName, serviceArea) {
    this._snackBar.dismiss();
    this.locationId = id;
    this.pageService.showLoader();
    let profileid = JSON.parse(localStorage.getItem('ProfileId'));
    if (!serviceArea) {
      locationName = '';
    }
    let data = {
      Key: this.accessKey,
      AccountId: this.accountId,
      LocationId: this.locationId,
      ProfileId: profileid,
      LocationName: locationName
    }
    this.http.post(environment.apiPath + 'LinkGoogle', data).subscribe((res: any) => {
      if (res.success) {
        this.pageService.hideLoader();
        this.http.get(environment.apiPath + 'FetchGoogleReview?key=' + JSON.parse(localStorage.getItem("ProfileId"))).subscribe()
       // this.pageService.checkReviewStatus();
        this.showBusiness = false;
        localStorage.setItem("isConnnectedReviewPlatform", "true")
        this.demo3()
      } else {
        if (res.status != 500 || res.statusText != "Internal Server Error") {
          this.pageService.hideLoader();
          this.pageService.showError('Oops!!!', res.errorMsg)
        }
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  demo3() {
    this.showBusiness = false;
    this.cdr.detectChanges();
    this.demo4()
  }

  demo4() {
    this.showInviteCust = true;
    this.cdr.detectChanges();
  }
  @ViewChild('uploader') uploader: HTMLInputElement
  inviteForm: UntypedFormGroup;
  model = {
    emailFlag: true,
    smsFlag: false,
    whatsAppFlag: false,
    fname: "",
    lname: "",
    email: "",
    phoneNumber: '',
    certified: false,
  };
  submittedform: boolean = false;
  isDisableUpload = false;
  isDisableMsg = false;
  isDisable = false;
  // isShow = false;
  countryCode: any;
  isInviteDisable = true;
  loading: boolean = false;
  emailSmsErrorFlag: boolean = false;
  csvRecords: any[];
  emailRegEx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  phoneRegEx = /^\+(?:[0-9] ?){6,14}[0-9]$/;


  showInviteCust = false

  smsTempLateData = [];
  // public viewDocumentation: boolean = true;


  createForm() {
    var scope = this;
    scope.inviteForm = scope.formBuilder.group({
      emailFlag: [true],
      smsFlag: [false],
      whatsAppFlag: [false],
      fname: [''],
      lname: [''],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      certified: [false, Validators.requiredTrue]
    })

    scope.inviteForm.controls.email.enable();
  }
  get form() { return this.inviteForm.controls; }
  //hide error message when enters text 
  changeCheckbox() {
    var scope = this;
    scope.emailSmsErrorFlag = false;
    scope.cdr.markForCheck();
    if (scope.model.emailFlag) {
      scope.inviteForm.controls.email.enable();
    } else {
      scope.model.email = ''
      scope.inviteForm.controls.email.disable();
    }

    if (scope.model.smsFlag) {
      if (scope.smsTempLateData.length == 0) {
        scope.opencreateSMStemplateALert();
        scope.model.smsFlag = false;
      } else {
        scope.inviteForm.controls.phoneNumber.enable();
        setTimeout(() => {
          var input = document.querySelector("#inviteText");
          var errorMsg = document.querySelector("#error-msg-phone-invite");
          var validMsg = document.querySelector("#valid-msg-phone-invite");
          let ip = localStorage.getItem('IpAddress');

          phoneText(ip, input, errorMsg, validMsg, scope)
        }, 100)


      }

    } else {
      scope.model.phoneNumber = ''
      scope.inviteForm.controls.phoneNumber.disable();
    }


  }
  opencreateSMStemplateALert() {
    var scope = this;
    var str = '<div class="text-center">To send review invitation through SMS,You need to create atleast one sms template. <br/>Template settings > SMS/Text template</div>';
    let smstempalte = scope.dialogRef.open(ConfrimAlertComponent, {
      width: '700px',
      panelClass: 'editModal',
      // position:{top:'10px'},
      disableClose: true,
      data: {
        message: str,
        title: 'Create SMS/Text Tempate',
        buttonText: 'Create template now',
        source: 'create sms'
      }
    });
    smstempalte.afterClosed().subscribe((res) => {
      if (res) {
        if (res.flag == true) {
          scope.router.navigate([res.url])
        }
      }
    })
  }
  //call when form submitted
  onsubmitForm() {
    var scope = this;
    if (localStorage.getItem("isConnnectedReviewPlatform") == "false") {
      this.pageService.showError("Opps.", "Connect review platform to start sending invitation");
      return false;
    }

    scope.submittedform = true;

    if (scope.inviteForm.invalid) {
      if (!scope.model.emailFlag && !scope.model.smsFlag) {
        scope.emailSmsErrorFlag = true;
      }
      return;
    }
    if (!scope.model.emailFlag && !scope.model.smsFlag) {
      scope.emailSmsErrorFlag = true;
      return;
    }
    if (scope.model.smsFlag && !document.querySelector("#error-msg-phone-invite").classList.contains('hide')) {
      return;
    }

    scope.callInviteUserAPi();
  }
  callInviteUserAPi() {
    var scope = this;
    let finalData = {
      FirstName: this.inviteForm.get('fname').value == null ? "" : this.inviteForm.get('fname').value,
      LastName: this.inviteForm.get('lname').value == null ? "" : this.inviteForm.get('lname').value,
      Email: scope.model.email,
      IsEmail: scope.model.emailFlag,
      IsSMS: scope.model.smsFlag,
      PhoneNumber: scope.model.phoneNumber,
      IsWhatsApp: scope.model.whatsAppFlag,
      IsOptin: false,
      Key: scope.accessKey,
      ProfileId: scope.profileId,
      forceResend:true
    }
    let intlObj = scope.dataService.getData('IntlObj')

    if (scope.model.smsFlag) {
      finalData['CountryCode'] = this.countryCode;
    }

    // InviteCustomer

    this.pageService.showLoader();
    scope.http.post(environment.apiPath + 'InviteCustomer', finalData)
      .subscribe((res: any) => {
        // scope.loading = false;
        scope.cdr.markForCheck();

        if (res['success']) {
          this._snackBar.open("Customer invited successfully.", "Dismiss", {
            duration: undefined,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ["snackbar"]
          });

          setTimeout(() => {
            this._snackBar.dismiss()
          }, 3000)


          this.pageService.hideLoader();
          this.router.navigate(['/dashboard']);
          // scope.inviteForm.re();
          scope.submittedform = false;
          scope.model = {
            emailFlag: true,
            smsFlag: false,
            whatsAppFlag: false,
            fname: '',
            lname: '',
            email: '',
            phoneNumber: '',
            certified: false,
          };
          this.loadSMSTemplates();
          this.isInviteDisable = false;
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == 'email address already invited' || res['errorMsg'].toLocaleLowerCase() == 'phone number already invited') {
            if (res['errorMsg'].toLocaleLowerCase() == 'email address already invited') {
              scope.openMessageAlert('email');
            } else {
              scope.openMessageAlert('sms');
            }
          }
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })

  }
  openMessageAlert(target) {
    var scope = this;
    var title = '';
    if (target == 'email') {
      title = 'Email address already invited';
    } else if (target == 'sms') {
      title = 'Phone number already invited';
    }
    var str = '<div class="text-center ">To send review invitation again, activate customer from <br/><b>Invited cutomers</b></div>';
    let finalData = {
      FirstName: this.inviteForm.get('fname').value == null ? "" : this.inviteForm.get('fname').value,
      LastName: this.inviteForm.get('lname').value == null ? "" : this.inviteForm.get('lname').value,
      Email: scope.model.email,
      IsEmail: scope.model.emailFlag,
      IsSMS: scope.model.smsFlag,
      PhoneNumber: scope.model.phoneNumber,
      IsWhatsApp: scope.model.whatsAppFlag,
      IsOptin: false,
      Key: scope.accessKey,
      ProfileId: scope.profileId,
      forceResend:true
    }
    let inactiveAlert = scope.dialogRef.open(ConfrimAlertComponent, {
      width: '700px',
      panelClass: 'editModal',
      disableClose: true,
      data: {
        message: str,
        title: title,
        buttonText: 'Active now',
        source: 'invite customer duplicate',
        model:finalData
      }
    });
    inactiveAlert.afterClosed().subscribe((res) => {
      if (res) {
        if (res.flag == true) {
          scope.router.navigate([res.url])
        }
      }
    })
  }
  loadSMSTemplates() {
    var scope = this;
    scope.smsTempLateData = [];
    this.inviteForm.reset();
    this.inviteForm.controls['emailFlag'].setValue(true);
    this.inviteForm.controls['smsFlag'].setValue(false);
    this.model.smsFlag = false;
    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'getSMStemplateDetails?key=' + scope.accessKey +
      '&ProfileId=' + scope.profileId)
      .subscribe((response: any) => {
        scope.pageService.hideLoader();

        if (response['success']) {
          scope.smsTempLateData = response['data'];
          let country = response.country;
          let accountCountry = localStorage.getItem("currentCountry");

          //added new appsumo condition
          let planName = localStorage.plan;
          if (planName == "\"Grab Your Reviews Tier 2\"" || planName == "\"Grab Your Reviews Tier 3\"" || planName == "\"Grab Your Reviews Tier 1\"") {
            this.isShow = true;
            this.countryCode = '+1';
          }

          else if (accountCountry.toLocaleUpperCase() == "IN") {
            this.isShow = false;
            this.inviteForm.controls.phoneNumber.disable()
            this.cdr.markForCheck();
          } else {
            if (country.toLocaleUpperCase() == "US" || country.toLocaleUpperCase() == "UK" || country.toLocaleUpperCase() == "CA" || country.toLocaleUpperCase() == "AU" || country.toLocaleUpperCase() == "GB") {
              this.isShow = true;
              if (country == "US" || country == "CA") {
                this.countryCode = '+1';
                this.cdr.markForCheck();
              } else if (country == "IN") {
                this.countryCode = '+91';
                this.cdr.markForCheck();
              } else if (country == "UK" || country == "GB") {
                this.countryCode = '+44';
                this.cdr.markForCheck();
              } else if (country == "AU") {
                this.countryCode = '+61';
                this.cdr.markForCheck();
              }

              this.cdr.markForCheck();
            } else {
              this.isShow = false;
              this.cdr.markForCheck();
            }
          }

        } else {
          scope.pageService.showError('Oops!!!', response['errorMsg']);
          if (response['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || response['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }

        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  //Your CSV must contain the headers Firstname, Lastname, Email, and Phonenumber(only when you send sms invitation) in order to be accepted.


  arrayImport: any = [];


  resetmodel() {
    var scope = this;
    scope.submittedform = false;
    scope.model = {
      emailFlag: true,
      smsFlag: false,
      whatsAppFlag: false,
      fname: '',
      lname: '',
      email: '',
      phoneNumber: '',
      certified: false,
    };
    scope.createForm();
  }
  //Open list of customers
  openList() {
    this.router.navigate(['/get-reviews/invited-customer-list'])
  }

  checkTC(v) {


    if (v.checked) {
      this.isInviteDisable = false;
      this.isDisable = false;
    } else {
      this.isDisable = true;
      this.isInviteDisable = true;
    }
  }

  showCount: any = false;
  getPermission() {
    this.pageService.getPermissions().then((res: any) => {
      if (res.isReseller) {
        this.showCount = true;
      } else {
        this.showCount = false;
      }
    })
  }
}
