<div class="col-xl-12 ">
	<div class='headerParts row'>
		<img [src]="'../../../../../../assets/media/social-logo/'+navData.target.reviewSiteName+'.png'" />
		<h1 mat-dialog-title class='margin-lr-5'>{{navData.name}}</h1>
	</div>
</div>
<div class='col-xl-12'>
	<div class='col-12 text-1-2x margin-tb-10 text-bold'>
		Paste below your Google call-to-action URL
    </div>
	<div class='col-12 margin-tb-10'>
		<input class='form-control' [(ngModel)]="locationLinkUrl" autofocus='off' disabled/>
	</div>
	<!-- <div class='col-12' *ngIf='navData.target.isReviewScrapAvailable==true'>
		<mat-slide-toggle [(ngModel)]="collectReview">Collect review from this channel</mat-slide-toggle>
	</div> -->
	<div class="col-12">
		<div class="row">
			<div class='col-xl-6' *ngIf='navData.target.isReviewScrapAvailable==true'>
				<mat-slide-toggle [(ngModel)]="collectReview">Fetch review from this channel</mat-slide-toggle>
			</div>
			<div class='col-xl-6' *ngIf='navData.target.isReviewScrapAvailable==true'>
				<mat-slide-toggle [(ngModel)]="displayOnReviewPage">
					Display on review page g<i class="fa fa-info" aria-hidden="true" 
					ngbTooltip="Show this platform to customer to leave reviews." placement="top"></i>
				</mat-slide-toggle>
			</div>
		</div>
	</div>
	<div class='col-12 noteColor margin-tb-10 padding-15'>
		<b>Important note:</b>&nbsp;As of April 12, 2018, Google’s <a href='https://support.google.com/contributionpolicy/answer/7411351'>review content policy states</a>:
		"Don’t discourage or prohibit negative reviews or selectively solicit positive
		reviews from customers."
	</div>
	<div class='col-12' *ngIf='navData.target.isReviewScrapAvailable==false'>
		Note: Reviews will not be fetched from this source, but you can collect it on this source.
    </div>
    <div mat-dialog-actions class='text-center'>
        <button  class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"  (click)="onSubmitMapLink()" >Continue</button>
        <button mat-button (click)="dialogRef.close()">Cancel</button>
      </div>

</div>
