<kt-portlet>
	<kt-portlet-body>

		<!--begin: Wizard -->
		<div #wizard class="kt-grid kt-wizard-v1 kt-wizard-v1--white" id="kt_wizard_v1" data-ktwizard-state="step-first">
			<div class="kt-grid__item">
				<!--begin: Form Wizard Nav -->
				<div class="kt-wizard-v1__nav">
					<div class="kt-wizard-v1__nav-items">
						<a class="kt-wizard-v1__nav-item" href="javascript:;" data-ktwizard-type="step" data-ktwizard-state="current">
							<div class="kt-wizard-v1__nav-body">
								<div class="kt-wizard-v1__nav-icon">
									<!-- <i class="flaticon-bus-stop"></i> -->
									<i class="fa fa-building"></i>
								</div>
								<div class="kt-wizard-v1__nav-label">
									Setup Your Company Profile
								</div>
							</div>
						</a></div>
				</div>
				<!--end: Form Wizard Nav -->
			</div>
			<div class="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
				<!--begin: Form Wizard Form-->
				<form class="kt-form" id="kt_form">
					<!--begin: Form Wizard Step 1-->
					<form [formGroup]="updateForm">
						<div class="kt-wizard-v1__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
							<!-- <div class="kt-heading kt-heading--md" style="text-align: center;font-size: 25px;">Setup Your Company Profile</div> -->
							<div class="kt-form__section kt-form__section--first">
								<div class="kt-wizard-v1__form">
									<div class="row">
										<div class="col-xl-4">
											<div class="form-group">
												<div>
													<mat-label>Person Name</mat-label>
													<input #personInput type="text" class="form-control" formControlName="PersonName" name="contactPersonName" [(ngModel)]="step1Model.PersonName" placeholder="Person Name">
												</div>
											</div>
										</div>
										<div class="col-xl-4">
											<div class="form-group">
												<div>
													<mat-label>Company Name *</mat-label>
													<input formControlName='CompanyName' type="text" class="form-control" name="CompanyName" [(ngModel)]="step1Model.CompanyName" placeholder="Company Name" (blur)='generteShortName()'>
												</div>
												<mat-error *ngIf="submittedform1 && updateForm.controls.CompanyName.errors">
													<strong *ngIf='submittedform1 && updateForm.controls.CompanyName.errors.required'>*Required</strong>
												</mat-error>
											</div>
										</div>
										<div class="col-xl-4">
											<div class="form-group">
												<div>
													<mat-label>URL Shortname *</mat-label>
													<input formControlName='ShortName' type="text" class="form-control" 
													name="ShortName" [(ngModel)]="step1Model.ShortName"
													[ngClass]="{'loadingTextbox': loadText,'rightTextbox': rightIcon,
													'wrongTextbox': wrongIcon}"
													 placeholder="Shortname" (blur)='validateUrl()'>
												</div>
												<mat-error *ngIf="submittedform1 && updateForm.controls.ShortName.errors">
													<strong *ngIf='submittedform1 && updateForm.controls.ShortName.errors.required'>*Required</strong>
												</mat-error>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-xl-4">
											<div class="form-group">
												<div>
													<mat-label>Email Address *</mat-label>
													<input formControlName='Email' type="text" class="form-control" name="email" [(ngModel)]="step1Model.Email" placeholder="Email">
												</div>
												<mat-error *ngIf="submittedform1 && updateForm.controls.Email.errors">
													<strong *ngIf='submittedform1 && updateForm.controls.Email.errors.required'>*Required</strong>
													<strong *ngIf='submittedform1 && updateForm.controls.Email.errors.email'>*Email Invalid</strong>
												</mat-error>
											</div>
										</div>

										<div class="col-xl-4">
											<div class="form-group">
												<mat-label style="width:100%;">Phone Number</mat-label>
												<div>
													<input formControlName="PhoneNumber" type="tel" class="form-control" id='setupInputText' name="PhoneNumber" [(ngModel)]="step1Model.PhoneNumber" placeholder="Phone No">
												</div>
												<mat-error>
													<strong id="valid-msg-phone-setup" style="color:#0abb87 !important" class="hide">✓ Valid</strong>
													<strong id="error-msg-phone-setup" class="hide"></strong>
												</mat-error>
											</div>
										</div>
										<div class="col-xl-4">
											<div class="form-group">
												<div>
													<mat-label>Industry*</mat-label>
													<select class="form-control" formControlName="Industry" name="Industry" [(ngModel)]="step1Model.Industry">
														<option value="">Select Industry</option>
														<option [value]="item" *ngFor="let item of industryList">{{item}}</option>
													</select>
													<mat-error *ngIf="submittedform1 && updateForm.controls.Industry.errors">
														<strong *ngIf='submittedform1 && updateForm.controls.Industry.errors.required'>*Required</strong>
													</mat-error>
												</div>
												<!-- <mat-error *ngIf="submittedform1 && updateForm.controls.Industry,errors">
													<strong *ngIf='submittedform1 && updateForm.controls.Industry.errors.required'>*Required</strong>
												</mat-error> -->
											</div>
										</div>
									</div>

									<div class="row">  
										<div class="col-xl-4">
											<!-- <mat-form-field> -->
												<!-- <mat-label>Country</mat-label> -->
					
												<div class="form-group">
													
													<mat-label >Country</mat-label>
													<mat-select class="form-control"   formControlName="Country"   (selectionChange)="selectCountry($event)"  name="Country">
												 		<mat-option  *ngFor='let item of countryList'   [value]='item.code'  >
														 
																{{item.name}}
															 
															</mat-option>
													</mat-select>						
													</div>
												
												<mat-error *ngIf="isControlHasError('Country','required')">
													<!-- <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong> -->
													<strong> Country required</strong>
												</mat-error>
											<!-- </mat-form-field> -->
										</div>
									</div>
									<div class="row">
										<div class="col-12" style="text-align: center;">
											<button class="btn btn-primary" (click)="submitData()">Setup Profile</button>
										</div>
									</div>

								</div>
							</div>
							<!-- 	<div class='customBtn'>
							<button mat-raised-button color="accent" (click)='onsubmit1()'>Next</button>
						</div> -->
						</div>
					</form>
				</form>
				<!--end: Form Wizard Form-->
			</div>
		</div>
		<!--end: Wizard -->


	</kt-portlet-body>
</kt-portlet>
