<div class='row  no-padding'>
	<div class='col-md-9 col-sm-12 col-xs-12'>
        <mat-label style="margin-left: 1%;">Subject</mat-label>
            <div class="row" style="margin-left: 0%;">
                <div class='col-md-9 col-sm-12 col-xs-12'>
                    <input type="text" [(ngModel)]="templateSubject" class="form-control subjectLine" name="subject" placeholder="Subject line" />

                </div>
                <div class='col-md-3 col-sm-6 col-xs-6  text-right margin-tb-5'>
                    <span class="primary-color preview-span cursor-pointer" (click)='openPreview()'>
                        Preview Email</span>
                </div>
            </div>
			<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10 smily-parent'>
				<ejs-richtexteditor [(ngModel)]="templateHtml" [enableAutoUrl]='false' #editor [class]='"editorClass editorClass"' [toolbarSettings]="tools">
				</ejs-richtexteditor>
			</div>
			<div class='col-md-12 col-sm-12 col-xs-12 text-center'>
				<button [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)="updateTemplate()">
					Save
                </button>
			</div>
	</div>
    <div class="col-md-3 col-sm-12 col-xs-12">
        <div class='col-12 margin-tb-5 notes roundedBox-radius padding-10 notes-back-color'>
            <span class="text-bold">Template placeholders</span>
            <br />
            You can use the following placeholders in our invitation templates for service reviews.
            <br />
            <div>
                <div class="input-group mt-2">
                    <input class="form-control border-right-0" value="{firstname}" readonly>
                    <span class="input-group-append bg-white">
                        <button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{firstname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
                    </span>
                </div>
                &nbsp;The first name of the customer.
            </div>
            <div>
                <div class="input-group mt-2">
                    <input class="form-control border-right-0" value="{name}" readonly>
                    <span class="input-group-append bg-white">
                        <button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{name}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
                    </span>
                </div>
                &nbsp;The full name of the customer.
            </div>
            <div>
                <div class="input-group mt-2">
                    <input class="form-control border-right-0" value="{companyname}" readonly>
                    <span class="input-group-append bg-white">
                        <button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{companyname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
                    </span>
                </div>
                &nbsp;The name of the company.
            </div>
            <div>
                <div class="input-group mt-2">
                    <input class="form-control border-right-0" value="{ownername}" readonly>
                    <span class="input-group-append bg-white">
                        <button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{ownername}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
                    </span>
                </div>
                &nbsp;The name of the company.
            </div>
            
            <div>
                <div class="input-group mt-2">
                    <input class="form-control border-right-0" value="{yourfeeddback}" readonly>
                    <span class="input-group-append bg-white">
                        <button class="btn border border-left0" type="button" ngxClipboard [cbContent]="'{yourfeeddback}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
                    </span>
                </div>
                &nbsp;The feedback from reviewer.
            </div>

            <div>
                <div class="input-group mt-2">
                    <input class="form-control border-right-0" value="{companyresponse}" readonly>
                    <span class="input-group-append bg-white">
                        <button class="btn border border-left0" type="button" ngxClipboard [cbContent]="'{companyresponse}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
                    </span>
                </div>
                &nbsp;The response from company.
            </div>


            <!-- <br />
            <span class="text-bold">Layout placeholders</span>
            <br />
            You can use the following placeholders in our invitation templates
            to put review layout.
            <br />
            <div>
                <div class="input-group mt-2">
                    <input class="form-control border-right-0" value="{thumbslayout}" readonly>
                    <span class="input-group-append bg-white">
                        <button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{thumbslayout}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
                    </span>
                </div>
                &nbsp;Thumbs layout.
            </div>
            <div>
                <div class="input-group mt-2">
                    <input class="form-control border-right-0" value="{facelayout}" readonly>
                    <span class="input-group-append bg-white">
                        <button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{facelayout}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
                    </span>
                </div>
                &nbsp;Face layout.
            </div> -->
        </div>
    </div>
</div>
