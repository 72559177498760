<!--begin::Head-->

<!--end::Head-->

<!--begin::Body-->

<style>
	.notice-verifyAc {
	background: #F4DEDE !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
	border-left: 5px solid red !important;
	box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%) !important;
	padding-top: 10px !important;
	padding-bottom: 5px !important;
}
</style>

<div class="kt-login__body">
	<!--begin::Signin-->

	<div class="kt-login__form">
		
		<!-- <div class="col-12 m-3 p-1 text-center text-1-2x danger-back-color roundedBox-radius">
			Currently we are adding new features,<br /> Kindly try login/signup after sometime
		</div> -->
		<div class="kt-login__title">
			<h3>Sign In</h3>
		</div>
		<div *ngIf="isPasswordError" class="alert alert-danger">
			{{errMsg}} <a (click)="resetPass()" class="resendLink">Resend password</a>
		</div>

		<div *ngIf="isMailError" class="alert alert-danger">
			{{errMsg}} <a (click)="resendVerificationMail()" class="resendLink">Resend Email</a>
		</div>

		<div *ngIf="isWrongMailError" class="alert alert-danger">
			{{errMsg}}
		</div>
		<!-- <kt-auth-notice></kt-auth-notice> -->

		<!--begin::Form-->
		<form class="kt-form" [formGroup]="loginForm" autocomplete="off">
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off" [(ngModel)]="loginData.Email" />
					<mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off" [(ngModel)]="loginData.Password" />
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<!--begin::Action-->
			<div class="kt-login__actions">
				<a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot">
					{{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}?
				</a>
				<button (click)="submit()" 
				[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary">
					{{ 'AUTH.LOGIN.BUTTON' | translate }}</button>
			</div>
			<!--end::Action-->
		</form>
		<div class="kt-login__head text-center text-1-2x" *ngIf='loaded && isSignupLink'>
			<span class="kt-login__signup-label">Don't have an account yet?</span>&nbsp;&nbsp;
			<a (click)='openReg()' class="kt-link kt-login__signup-link cursor-pointer">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</a>
		</div>
		<!--end::Form-->

		<!--begin::Divider-->
		<!-- <div class="kt-login__divider">
			<div class="kt-divider">
				<span></span>
				<span>OR</span>
				<span></span>
			</div>
		</div> -->
		<!--end::Divider-->

		<!--begin::Options-->
		<!-- <div class="kt-login__options">
			<a href="https://www.facebook.com/keenthemes/" target="_blank" class="btn btn-primary kt-btn">
				<i class="fab fa-facebook-f"></i>
				Facebook
			</a>

			<a href="https://twitter.com/keenthemes/" target="_blank" class="btn btn-info kt-btn">
				<i class="fab fa-twitter"></i>
				Twitter
			</a>

			<a href="javascript:;" class="btn btn-danger kt-btn">
				<i class="fab fa-google"></i>
				Google
			</a>
		</div> -->
		<!--end::Options-->
	</div>
	<!--end::Signin-->
</div>

<!--end::Body-->
<script>
	gr('track', 'conversion', { email: this.loginData.Email });
	///// gr('track', 'conversion', console.log(this.loginData.Email));
	
</script>