import { PageConfigService } from './core/_base/layout/services/page-config.service';
import { MatDialog } from '@angular/material';
import { environment } from './../environments/environment';
import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { ChooseProfileComponent } from './views/pages/choose-profile/choose-profile.component';
import { Title } from '@angular/platform-browser';
import { style } from '@angular/animations';
import { MenuHorizontalService } from './core/_base/layout/services/menu-horizontal.service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import{Keepalive} from '@ng-idle/keepalive';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'Grab Your Reviews';
	loader: boolean;
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	brandData: any;

	idleState = 'Not started.';
	timedOut = false;
	lastPing?: Date = null;

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService,
		private router: Router,
		private layoutConfigService: LayoutConfigService,
		private splashScreenService: SplashScreenService,
		public http: HttpClient,
		public dialogRef: MatDialog,
		public pagService: PageConfigService,
		public titleService: Title,
		public passData: MenuHorizontalService,
		private idle:Idle,
		private keepalive: Keepalive,
		private httpModule: HttpClientModule
	) {
		// register translations
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
		
		 // sets an idle timeout of 5 seconds, for testing purposes.
		 idle.setIdle(300);
		 // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
		 idle.setTimeout(1800);
		 // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
		 idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
	 
		 idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
		 idle.onTimeout.subscribe(() => {
			if(this.router.url != '/auth/login' && this.router.url != '/auth/register' && this.router.url != '/auth/forgot-password'
				&& this.router.url != '/auth/verification'){
			console.log(this.lastPing, 'Timed out');
			console.log(this.router.url);
		   //this.idleState = 'Timed out!';
		   this.timedOut = true;
			this.logout();
			
			}
			else{
				//console.log('Else part');
				this.reset();
			}
		 });
		 idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
		 idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
	 
		 // sets the ping interval to 15 seconds
		 keepalive.interval(60);
	 
		 keepalive.onPing.subscribe(() =>  this.lastPing = new Date());
		 
		 this.reset();
	}

	reset() {
		//console.log('Reset called to start idle timout');
		
		this.idle.watch();
		this.idleState = 'Started.';
		this.timedOut = false;
	  }

	  logout(){
		var accessKey = localStorage.getItem(environment.authTokenKey);
		let data={Key:accessKey
		}
		localStorage.clear();
		//location.reload();

		this.http.post(environment.apiPath + 'Logout',data)
			///// this.http.get(environment.apiPath + 'test')
			.subscribe((res: any) => {
				debugger;
				if (res['success']) {
					setTimeout(() => {
						//this.router.navigate(['/auth/login'])
						location.reload();
				}, 500);
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					//this.pageService.showError('Oops!', err.error.errorMsg);
				}
			});

		setTimeout(() => {
				//this.router.navigate(['/auth/login'])
				location.reload();
		}, 500);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		var scope = this;
		if (localStorage.getItem(environment.authTokenKey) != null && localStorage.getItem(environment.authTokenKey).length > 0) {

			scope.pagService.getPermissions();

		}

		if (localStorage.getItem("isExpired") != null || localStorage.getItem("isExpired") != undefined) {
			if (localStorage.getItem("hasCreatedProfile") == "true" && localStorage.getItem("hasOnboarding") == "false") {
				// if (window.location.hash != '' || window.location.hash != null || window.location.hash != undefined) {
				// 	// this.pagService.showLoader();
				// 	let splitHash = window.location.href.split('?');
				// 	for (let i in splitHash) {
				// 		if (splitHash[i].includes("code")) {
				// 			let split_access = splitHash[i].split('&scope=');

				// 			let finalToken = split_access[0].split("code=")[1];

				// 			let removeVal = finalToken.split('%2F')

				// 			// return
				// 			this.http.get(environment.apiPath + 'GoogleAuthentication?key=' + JSON.parse(localStorage.getItem(environment.authTokenKey)) + '&code=' + removeVal[0] + '/' + removeVal[1] + '&redirectURL=' + window.location.origin + '&profileid=' + JSON.parse(localStorage.getItem('ProfileId'))).subscribe((res: any) => {
				// 				localStorage.setItem('googleSignInToken', res.accessToken);
				// 				if (localStorage.getItem('hasOnboarding') == 'true') {
				// 					setTimeout(() => {
				// 						this.router.navigate(['/google-account-details']);
				// 					}, 1500)
				// 					return
				// 				} else if (localStorage.getItem('hasOnboarding') == 'false') {
				// 					setTimeout(() => {
				// 						this.router.navigate(['/onboarding/review-site'])
				// 					}, 1500)
				// 					return
				// 				}
				// 			})
				// 			// if (localStorage.getItem('hasOnboarding') == 'true') {
				// 			// 	setTimeout(() => {
				// 			// 		this.router.navigate(['/google-account-details']);
				// 			// 	}, 1500)
				// 			// 	return
				// 			// } else if (localStorage.getItem('hasOnboarding') == 'false') {
				// 			// 	setTimeout(() => {
				// 			// 		this.router.navigate(['/onboarding/review-site'])
				// 			// 	}, 1500)

				// 			// 	return
				// 			// }
				// 		}
				// 	}
				// }
				this.router.navigate(['/onboarding/review-site'])
				return
			} else if (localStorage.getItem("hasCreatedProfile") == "false" && localStorage.getItem("hasOnboarding") == "false") {
				this.router.navigate(['/onboarding/setup-profile'])
				return
			}
		}


		scope.pagService.setAppComponentObj(scope);
		//call user permissions api


		// enable/disable loader

		if (top.location != window.location) {
			scope.loader = scope.layoutConfigService.getConfig('loader.disable');
		} else {
			scope.loader = scope.layoutConfigService.getConfig('loader.enabled');
		}

		const routerSubscription = scope.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				scope.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);
				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		scope.unsubscribe.push(routerSubscription);

		scope.http.get(environment.ipAddressApiPath)
			.subscribe(data => {
				localStorage.setItem('IpAddress', data['ip'])
			})

	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}