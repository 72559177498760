<div class='col-12 padding-15'>
	<div class='row'>
		<div class='col-md-11 text-1-2x text-bold form-control no-border'>
			Disconnect {{targetSiteData.reviewSiteName}} from {{systemName}}
		</div>
		<div class='col-md-1 text-1-2x text-center'>
			<i class="fa fa-times cursor-pointer" (click)='dialogRef.close()'></i>
		</div>
	</div>
</div>
<div class='col-12 padding-lr-15 '>
    <div class='col-12 text-1-2x margin-tb-5'>
		<div class=''>Disconnecting {{targetSiteData.reviewSiteName}} will :</div>
	</div>
	<div class='col-12 padding-lr-15 margin-10'>
		<i class="fas fa-chevron-right primary-color"></i>
		<span class='gray-color'>&nbsp;&nbsp;Stop review collection from {{targetSiteData.reviewSiteName}}</span>
	</div>
	<div class='col-12 padding-lr-15 margin-10'>
		<i class="fas fa-chevron-right primary-color"></i>
		<span class='gray-color'>&nbsp;&nbsp;Delete old {{targetSiteData.reviewSiteName}} reviews from {{systemName}}</span>
	</div>
	<div class='col-12 margin-tb-10 text-1-2x'>
		Are you sure you still want to disconnect {{targetSiteData.reviewSiteName}}?
	</div>
	<div class='row margin-tb-10 text-right' >
		<button mat-button class='margin-lr-5' (click)="dialogRef.close()">Cancel</button>
		<button class="btn danger-back-color btn-elevate kt-login__btn-primary margin-lr-5"
		(click)="onSubmit()">Disconnect</button>
	</div>
</div>
