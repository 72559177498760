<!-- begin:: Header Mobile -->
<div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
	<div class="kt-header-mobile__logo">
		<a href="javascript:;" routerLink="/" *ngIf='loaded==true && brandLogo.length==0 && brandName.length==0'>
			<img alt="logo" class='brandLogo-mobile' src='../../../../assets/media/logos/logo.svg' />
		</a>
		<div *ngIf='loaded==true && (brandLogo.length>0 || brandName.length >0)'>
			<a href="javascript:;" class="kt-login__logo" *ngIf='brandLogo.length>0'>
				<img class='brandLogo-mobile' [src]="brandLogo" width="150px" [alt]="brandName">
			</a>
			<h2 *ngIf='brandLogo.length==0' style="color: #fff !important;">{{brandName}}</h2>
		</div>
		<a routerLink="/">
			<!-- <img alt="logo" [attr.src]="headerLogo"/> -->

		</a>
	</div>,
	<div class="kt-header-mobile__toolbar">
		<button [hidden]="!asideDisplay" class="kt-header-mobile__toggler kt-header-mobile__toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
		<button class="kt-header-mobile__toggler" id="kt_header_mobile_toggler"><span></span></button>
		<button (click)='logout()' class="kt-header-mobile__topbar-toggler" id="kt_header_mobile_topbar_toggler" style="position: relative;
		margin-top: 10px;">
			<span class="kt-svg-icon" [inlineSVG]="'./assets/media/icons/svg/Navigation/Sign-out.svg'"></span>
		</button>
	</div>
</div>
<!-- end:: Header Mobile -->
