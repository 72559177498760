<div class='col-12 padding-12'>
	<mat-tab-group class='customTab' (selectedTabChange)='innerTabChanged($event)'>
		<mat-tab class='text-bold' *ngFor="let item of emailTempLateData;let i=index" [label]="item.emailName">
			<div class='row padding-10 '>
				<div class='col-md-11 col-sm-6 col-xs-6 text-right text-1x margin-tb-5'>
					Your Timezone<br /><b>{{timezone}}</b>&nbsp;
					<a class='cursor-pointer primary-color' (click)='openSettings()'>
						<i class='far fa-edit'></i>
					</a>
				</div>

				<div class='col-md-1 col-sm-6 col-xs-6  text-right margin-tb-5'>
					<button class="btn danger-back-color btn-elevate kt-login__btn-primary text-center " [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': deleteLoading}" (click)='openConfirmAlert(item.id)'>
						<i class='far fa-trash-alt' style="position: relative;left: 15%;"></i></button>
				</div>

				<div class='row padding-tb-5'>
					<div class='col-md-3 col-sm-12 col-xs-12'>
						<mat-label>Email Name</mat-label><br />
						<input name='emailname' [(ngModel)]="item.emailName" class="form-control emailName {{'emailName'+i}}" type="text" (ngModelChange)='textEntered("edit")'>

					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<mat-label>Send Interval</mat-label>
						<select [(ngModel)]="item.sendInterval" class='form-control ' placeholder='Sent' (ngModelChange)='intervalChanged(item.sendInterval,i)'>
							<option value="assoonaspossible" selected>As soon as possible</option>
							<option value="1">1 Days</option>
							<option value="2">2 Days</option>
							<option value="3">3 Days</option>
							<option value="4">4 Days</option>
							<option value="5">5 Days</option>
							<option value="6">6 Days</option>
							<option value="7">7 Days</option>
							<option value="8">8 Days</option>
							<option value="9">9 Days</option>
							<option value="10">10 Days</option>
							<option value="11">11 Days</option>
							<option value="12">12 Days</option>
							<option value="13">13 Days</option>
							<option value="14">14 Days</option>
							<option value="15">15 Days</option>
							<option value="custom">Custom</option>
						</select>

					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<div *ngIf='item.sendInterval=="custom"'>
							<mat-label>Days&nbsp;</mat-label><br />
							<input class="ui-timepicker-input timepicker form-control sentTime" [(ngModel)]="item.sendIntervalDays" type="number">
						</div>
					</div>
					<div class='col-md-2 col-sm-12 col-xs-12' [ngStyle]="{'padding-right' : item.sendInterval == 'custom' ? '0px' : item.sendInterval == 'assoonaspossible' ? '123px' : '0px'}">
						<div *ngIf='item.sendInterval!="assoonaspossible"'>
							<mat-label>Time&nbsp;</mat-label><br />
							<input [id]='"timepicker"+i' name='time' [(ngModel)]="item.sentTime" class="ui-timepicker-input timepicker form-control sentTime {{'sentTime'+i}}" type="text">
						</div>
					</div>
					<!-- <div class='col-md-2 col-sm-12 col-xs-12'>
						<label> </label>
						<mat-checkbox [(ngModel)]="item.sentWeekend" class="sentCheck" (ngModelChange)='sentOnWeekendChecked(item)'>Do not sent on weekend </mat-checkbox>
					</div> -->
					<div class='col-md-3 col-sm-12 col-xs-12'>
						<div class='notes roundedBox-radius padding-10 notes-back-color'>
							<span class="text-bold">Notes :</span>
							There may be 1 hour difference while sending Review Request.
						</div>
					</div>
				</div>
				<div class='row  no-padding'>
					<div class='col-md-9 col-sm-12 col-xs-12'>
						<div class='col-md-3 col-sm-12 col-xs-12'>
							<mat-label>Subject</mat-label>
						</div>
						<div class="row">
							<div class='col-md-9 col-sm-12 col-xs-12'>
								<input type="text" (ngModelChange)='textEntered("edit")' class="form-control subjectLine 
								{{'subjectLine'+i}}" name="subject" placeholder="Subject line" [(ngModel)]="item.subjectLine" />

							</div>
							<div class='col-md-3 col-sm-6 col-xs-6  text-right margin-tb-5'>
								<span class="primary-color preview-span cursor-pointer" (click)='openPreview(item)'>
									Preview Email</span>
							</div>
						</div>

						<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10 smily-parent'>
							<ejs-richtexteditor [enableAutoUrl]='false' (change)='textEntered("edit",i,item)' #editor [class]='"editorClass editorClass"+i' [toolbarSettings]="tools" [(ngModel)]="item.emailHTML">
							</ejs-richtexteditor>
						</div>
						<div class='col-md-12 col-sm-12 col-xs-12 text-center'>
							<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)='onEmailtemplateSubmit(item,"edit",i)' [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">
								Save</button>
						</div>
					</div>
					<div class='col-md-3 col-sm-12 col-xs-12 margin-tb-5'>
						<div class='col-12 margin-tb-5 notes roundedBox-radius padding-10 notes-back-color'>
							<span class="text-bold">Template placeholders</span>
							<br />
							You can use the following placeholders in our invitation templates for service reviews.
							<br />
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{firstname}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{firstname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The first name of the customer.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{name}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{name}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The full name of the customer.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{companyname}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{companyname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The name of the company.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{ownername}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{ownername}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The name of the company.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{reviewurl}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left0" type="button" ngxClipboard [cbContent]="'{reviewurl}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The one-click review link.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{reviewurlpositive}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left0" type="button" ngxClipboard [cbContent]="'{reviewurlpositive}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;Review link to positive feedback.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{reviewurlnegative}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left0" type="button" ngxClipboard [cbContent]="'{reviewurlnegative}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;Review link to negative feedback.
							</div>


							<!-- <br />
							<span class="text-bold">Layout placeholders</span>
							<br />
							You can use the following placeholders in our invitation templates
							to put review layout.
							<br />
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{thumbslayout}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{thumbslayout}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;Thumbs layout.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{facelayout}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{facelayout}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;Face layout.
							</div> -->
						</div>
					</div>
				</div>

			</div>
		</mat-tab>
		<mat-tab class='text-bold' label='Create new template' *ngIf='emailTempLateData.length<5'>
			<div class='col-12 padding-10'>
				<div class='col-md-12 col-sm-6 col-xs-6 text-right text-1x margin-tb-5'>
					Your Timezone<br /><b>{{timezone}}</b>&nbsp;
					<a href='/get-reviews/invitation-settings?from=edit_timezone'>
						<i class='far fa-edit'></i>
					</a>
				</div>
				<div class='row'>
					<div class='col-md-3 col-sm-12 col-xs-12'>
						<mat-label>Email Name</mat-label><br />
						<input name='emailname' [(ngModel)]="newTemplate.emailName" (ngModelChange)='textEntered("new")' class="form-control emailNameNew" type="text">
					</div>
					<div class='col-md-2 col-sm-12 col-xs-12'>
						<mat-label>Send Interval</mat-label>
						<select [(ngModel)]="newTemplate.sendInterval" class='form-control ' (ngModelChange)='intervalChanged(newTemplate.sendInterval,"new")' placeholder='Sent'>
							<option value="assoonaspossible" selected>As soon as possible</option>
							<option value="1">1 Days</option>
							<option value="2">2 Days</option>
							<option value="3">3 Days</option>
							<option value="4">4 Days</option>
							<option value="5">5 Days</option>
							<option value="6">6 Days</option>
							<option value="7">7 Days</option>
							<option value="8">8 Days</option>
							<option value="9">9 Days</option>
							<option value="10">10 Days</option>
							<option value="11">11 Days</option>
							<option value="12">12 Days</option>
							<option value="13">13 Days</option>
							<option value="14">14 Days</option>
							<option value="15">15 Days</option>
							<option value="custom">Custom</option>
						</select>
					</div>

					<div class='col-md-2 col-sm-12 col-xs-12'>
						<div *ngIf='newTemplate.sendInterval=="custom"'>
							<mat-label>Days&nbsp;</mat-label><br />
							<input class="ui-timepicker-input timepicker form-control sentTime" [(ngModel)]="newTemplate.sendIntervalDays" type="number">
						</div>
					</div>
					<div class='col-md-2 col-sm-12 col-xs-12' [ngStyle]="{'padding-right' : newTemplate.sendInterval == 'custom' ? '0px' : newTemplate.sendInterval == 'assoonaspossible' ? '123px' : '0px'}">
						<div *ngIf='newTemplate.sendInterval!="assoonaspossible"'>
							<mat-label>Time&nbsp;</mat-label><br />
							<input id='timepickerNew' class='form-control ' name='time' [(ngModel)]="newTemplate.Time" class="ui-timepicker-input timepicker" type="text">
						</div>
					</div>
					<!-- <div class='col-md-3 col-sm-12 col-xs-12' style="padding-right: 123px;">
						<div *ngIf='newTemplate.sendInterval!="assoonaspossible"'>
							<mat-label>Time&nbsp;</mat-label>
							<input id='timepickerNew' class='form-control ' name='time' [(ngModel)]="newTemplate.Time" class="ui-timepicker-input timepicker" type="text">
						</div>
					</div> -->
					<!-- <div class='col-md-2 col-sm-12 col-xs-12'>
						<label> </label>
						<mat-checkbox [(ngModel)]="newTemplate.sentWeekend" class="sentCheck" (ngModelChange)='sentOnWeekendChecked(newTemplate)'>Do not sent on weekend </mat-checkbox>
					</div> -->
					<div class='col-md-3 col-sm-12 col-xs-12'>
						<div class='notes roundedBox-radius padding-10 notes-back-color'>
							<span class="text-bold">Notes :</span>
							There may be 1 hour difference while sending Review Request.
						</div>
					</div>
				</div>
				<div class='row  no-padding'>
					<div class='col-md-9 col-sm-12 col-xs-12'>
						<div class='col-md-3 col-sm-12 col-xs-12'>
							<mat-label>Subject</mat-label>
						</div>

						<div class='col-md-9 col-sm-12 col-xs-12'>
							<input type="text" (ngModelChange)='textEntered("new")' class="form-control subjectLineNew" name="subject" placeholder="Subject line" [(ngModel)]="newTemplate.subjectLine" />

						</div>
						<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10'>
							<ejs-richtexteditor [enableAutoUrl]='true' #editor class='editorClassNew' (ngModelChange)='textEntered("new")' [toolbarSettings]="tools" [(ngModel)]="newTemplate.emailHTML">
							</ejs-richtexteditor>

						</div>
						<div class='col-md-12 col-sm-12 col-xs-12 text-center'>
							<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)='onEmailtemplateSubmit(newTemplate,"new")' [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">
								Create</button>
						</div>
					</div>
					<div class='col-md-3 col-sm-12 col-xs-12 margin-tb-5'>
						<div class='col-12 margin-tb-5 notes roundedBox-radius padding-10 notes-back-color'>
							<span class="text-bold">Template placeholders</span>
							<br />
							You can use the following placeholders in our invitation templates for service reviews.
							<br />
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{firstname}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{firstname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The first name of the customer.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{name}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{name}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The full name of the customer.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{companyname}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{companyname}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The name of the company.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{ownername}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{ownername}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The name of the company.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{reviewurl}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{reviewurl}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;The one-click review link.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{reviewurlpositive}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left0" type="button" ngxClipboard [cbContent]="'{reviewurlpositive}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;Review link to positive feedback.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{reviewurlnegative}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left0" type="button" ngxClipboard [cbContent]="'{reviewurlnegative}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;Review link to negative feedback.
							</div>
							<br />
							<!-- <span class="text-bold">Layout placeholders</span>
							<br />
							You can use the following placeholders in our invitation templates
							to put review layout.
							<br />
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{thumbslayout}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{thumbslayout}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;Thumbs layout.
							</div>
							<div>
								<div class="input-group mt-2">
									<input class="form-control border-right-0" value="{facelayout}" readonly>
									<span class="input-group-append bg-white">
										<button class="btn border border-left-0" type="button" ngxClipboard [cbContent]="'{facelayout}'" ngbTooltip="Copied to clipboard!" triggers="click:blur"><i class="far fa-copy"></i></button>
									</span>
								</div>
								&nbsp;Face layout.
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
