import { DataTableService } from './../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';
// import { environment } from '../../../../environments/environment';

@Component({
  selector: 'kt-choose-profile',
  templateUrl: './choose-profile.component.html',
  styleUrls: ['./choose-profile.component.scss']
})
export class ChooseProfileComponent implements OnInit {
  profileList:any;
  profile:any
  constructor(public dialogRef:  MatDialogRef<ChooseProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public navData: any,
    public pageService:PageConfigService,
    public http:HttpClient,
    public dataService:DataTableService,
    public cdr:ChangeDetectorRef) { }

  ngOnInit() {
    var scope=this;
    scope.loadList();
  }
  loadList(){
    var scope = this;
    
    scope.cdr.markForCheck()
  }
  changeProfile(target) {
		
		
    this.pageService.setProfileId(target);
    
  }
  onSubmit(){
    this.dialogRef.close({target:true});
  }
}
