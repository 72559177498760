import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { environment } from './../../../../environments/environment';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { Component, OnInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ConfrimAlertComponent } from '../confrim-alert/confrim-alert.component';
import { DomSanitizer } from '@angular/platform-browser';
import { data } from 'jquery';

@Component({
  selector: 'kt-my-reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.scss']
})
export class MyReviewsComponent implements OnInit {
  // @ViewChild('')
  reviewList = [];
  displayList = [];
  addReviewForm: UntypedFormGroup;
  replyForm: UntypedFormGroup;
  profileId: any;
  accessKey: any;
  averageRating: any;
  TotalRatinginPercentage = 0;
  reviewRating = 0;
  collectionSize = 0;
  pageSize = 20;
  page = 1;
  searchInput = '';
  sendReply = '';
  rating = 5;
  reviewSourceArray = [];
  viewDocumentation: boolean = true;
  platforms = 'all';
  sentiments = 'All';
  showplatforms = [];
  platformsName = [];
  totalreview = 1;
  avgplatform;
  TotalPlatformRating = 0;
  totalHasReviewsinPlatform = 0;
  totalaverageRating: any;
  // startIndex:number=0;
  // endIndex:number=9;
  searchby: string = '';
  enableAutoResponse:boolean= false;
  autoResponseConfirmMessage:string = "";
  @ViewChild('templateautoresponseconfirmbox') templateautoresponseconfirmbox: TemplateRef<any> | undefined;
  constructor(public http: HttpClient,
    public pageService: PageConfigService,
    public cdr: ChangeDetectorRef,
    public router: Router,
    public modalService: BsModalService,
    private fb: UntypedFormBuilder, public datepipe: DatePipe,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute) {

  }

  showloader = false;
  hideloader = false;
  openRespondModel = false;
  comment: any;
  review: any;

  positiveReview = ["Thank you for your kind words.",
    "Awesome review!",
    "Thanks for the review",
    "Great feedback!",
    "We’re happy to hear we hit the mark for you."
  ]
  getDay: any;
  getMonth: any;
  getYear: any;
  currentCountry: any = "";

  hasSentiment: boolean = false;

  ngOnInit() {
    var scope = this;
    this.totalHasReviewsinPlatform = 0;
    if (localStorage.getItem("isExpired") == "true") {
      this.router.navigate(['/general/billing']);
      return
    }

    //add hasSentiment code
    let view = JSON.parse(localStorage.getItem('view'));
	if(view){
    let viewPermission = JSON.parse(scope.pageService.decrypt(view));
    scope.hasSentiment = viewPermission['HasSentiments'];
	}
    // add condition for currentCountry india then hide genrate button div in html section
    this.currentCountry = localStorage.getItem("currentCountry");

    // this.setDob = this.datepipe.transform(userdate, 'MM/dd/yyyy');
    this.getDay = parseInt(this.datepipe.transform(new Date(), 'dd'));
    this.getMonth = new Date().getMonth() + 1;
    this.getYear = new Date().getFullYear();
    this.replyForm = new UntypedFormGroup({
      reply: new UntypedFormControl("", Validators.required)
    })

    scope.hideloader = true;
    if (scope.router['browserUrlTree'].queryParams.platforms != undefined) {
      scope.platforms = scope.router['browserUrlTree'].queryParams.platforms;
    }
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'));
    this.accessKey = localStorage.getItem(environment.authTokenKey)
    scope.pageService.callChooseProfile();
    if (scope.profileId == null || scope.profileId < 0) {
      scope.pageService.createNewProfilePopup();
      return;
    } else {
      //  scope.pageService.checkReviewStatus();
      scope.cdr.markForCheck();

      //use Query perameter
      this.route.queryParams.subscribe(params => {
        this.searchby = params.searchby;
      }
      );
      if (this.searchby) {

        this.searchInput = this.searchby;
      } else {
        this.searchInput = '';
      }
      scope.loadPage();

    }


    scope.pageService.getProgileId().subscribe(message => {
      if (message.id) {
        scope.profileId = JSON.parse(localStorage.getItem('ProfileId'));
        if (scope.profileId == null || scope.profileId < 0) {
          scope.cdr.markForCheck();
          scope.pageService.createNewProfilePopup();

          return;
        } else {
          //  scope.pageService.checkReviewStatus();
          if (scope.router.url.includes('my-reviews')) {
            this.showloader = true;
            scope.averageRating = 0;
            scope.TotalRatinginPercentage = 0
            scope.loadPage();
          }

        }

      }
    });
    scope.viewDocumentation = JSON.parse(localStorage.getItem('showBranding'))
    scope.cdr.markForCheck();
    scope.pageService.setTitle('My Reviews')
    // add review form
    this.addReviewForm = this.fb.group({
      name: ['', Validators.required],
      review: [''],
      date: ['', Validators.required],
      ReviewRating: ['']
    })
  }

  showpopup: any;
  hasShowOnWidget: any;
  id: any;
  pendingReponseCredit;
  arrayLen;
  loadPage() {
    var scope = this;

    if (this.showloader == true || this.hideloader == true) scope.pageService.showLoader()
    scope.http.get(environment.apiPath + 'GetMyReviews?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId + '&Page=' + scope.page + '&PageSize=' + scope.pageSize + '&source=' + this.platforms + '&name=' + this.searchInput + '&sentiment=' + this.sentiments)
      .subscribe((res: any) => {
        // added star

        scope.layoutDetails = res;
        scope.pendingReponseCredit = res.pendingReponseCredit;

        if (res.pendingReponseCredit > 0) {
          this.arrayLen = res.pendingReponseCredit
        }


        if (scope.layoutDetails['Logo'] != undefined && scope.layoutDetails['Logo'] != null &&
          scope.layoutDetails['Logo'].length > 0) {
          scope.layoutDetails['Logo'] = scope.layoutDetails['Logo'] + '?lastmd=' + Math.random()
        }
        if (scope.layoutName == 'stars') {
          if (scope.thresold == '5') {
            scope.clickButtonTracker('5 star')
          } else if (scope.thresold == '4') {
            scope.clickButtonTracker('4 star')
          } else if (scope.thresold == '3') {
            scope.clickButtonTracker('3 star')
          } else if (scope.thresold == '2') {
            scope.clickButtonTracker('2 star')
          } else if (scope.thresold == '1') {
            scope.clickButtonTracker('1 star')
          }
        }
        if (res['success']) {

          this.totalHasReviewsinPlatform = 0;
          this.pageService.hideLoader()
          //scope.pageService.hideLoader()
          scope.reviewList = JSON.parse(JSON.stringify(res['data']));
          this.platformsName = [];
          let sum = 0;
          for (let i in res['reviewSites']) {
            let data = {
              siteName: res['reviewSites'][i].siteName,
              averageRating: res['reviewSites'][i].averageRating,
              rating: parseFloat(JSON.parse(JSON.stringify(res['reviewSites'][i].averageRating))).toFixed(1),
            }

            if (res.reviewSites[i].totalReviews != 0) {
              this.totalHasReviewsinPlatform++;
            }
            this.platformsName.push(data);
            if (this.showloader == true || this.hideloader == true) scope.pageService.hideLoader();
          }
          this.platformsName.forEach((data) => {
            sum += Number(data.rating)
          })
          scope.collectionSize = res['total'];
          scope.totalaverageRating = res['totalAvg'];
		  this.enableAutoResponse =  res['autoResponse'];
          scope.pagination(scope.reviewList);

          if (scope.reviewList.length > 0) {
            // let totalReview = scope.reviewList.length;
            // scope.reviewList.forEach((alone) => {
            //   sum += alone.rating
            // })

            let avg = sum / this.totalHasReviewsinPlatform;
            let percentage = (avg * 100) / 5;
            // scope.TotalRatinginPercentage = JSON.parse(JSON.stringify(percentage));
            scope.TotalRatinginPercentage = JSON.parse(JSON.stringify(percentage));
            scope.cdr.markForCheck();
          }

          // id: 6
          // profileId: 20269
          // reviewSource: "Google"
          // date: "2019-12-19T06:36:37"
          // rating: 5
          // reviewDetails: "Done some great work for me over the years if you are looking for a company to help you at busy time this is the one for you"
          // reviewUrl: "https://www.google.com/maps/contrib/112275711974483058506/reviews"
          // isStream: false
          // reviewBy: "Bill Amos"
          // profileImgUrl: "htt
        } else {

          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
        //scope.searchReview();
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
    // if(scope.router.['browseTree'].platforms != undefined){
    //   //scope.platforms=scope.queryParams['browseTree'].platforms;
    // }

  }

  ratingChange() {
    // Math.ceil(alone.rating)<=scope.rating
  }

  demo() {
    this.collectionSize = 1;
  }


  selectPlatform(value) {
    if (this.router['browserUrlTree'].queryParams.platforms != undefined) {
      this.router.navigateByUrl('/my-reviews')
    }
    this.platforms = value;
    this.loadPage();
    this.collectionSize = 1;
  }

  selectSentiment(value) {
    this.sentiments = value;
    this.loadPage();
    this.collectionSize = 1;
  }

  searchReview() {
    var scope = this;
    if (this.collectionSize > 0 && this.pageSize) {
      this.loadPage();
    }
    let tempArr = [];
    let listArr = JSON.parse(JSON.stringify(scope.reviewList));

    if (scope.platforms != "all") {
      listArr = listArr.filter(ele => {
        if (ele.reviewSource.toLocaleLowerCase() == scope.platforms.toLocaleLowerCase()) {
          return ele;
        }
      });
    }
    if (scope.sentiments != "all") {
      listArr = listArr.filter(ele => {
        if (ele.reviewSource.toLocaleLowerCase() == scope.sentiments.toLocaleLowerCase()) {
          return ele;
        }
      });
    }

    if (scope.searchInput.length > 0) {
      listArr.forEach((alone) => {

        if ((alone.reviewBy != null && alone.reviewBy.toLocaleLowerCase().includes(scope.searchInput.toLocaleLowerCase())) ||
          (alone.reviewDetails != null && alone.reviewDetails.toLocaleLowerCase().includes(scope.searchInput.toLocaleLowerCase())) ||
          (alone.reviewSource != null && alone.reviewSource.toLocaleLowerCase().includes(scope.searchInput.toLocaleLowerCase()))
        ) {
          tempArr.push(alone);
        }
      })
      scope.pagination(JSON.parse(JSON.stringify(tempArr)))
    } else {
      scope.pagination(JSON.parse(JSON.stringify(listArr)))
    }

  }

  pagination(arr) {

    var scope = this;
    //scope.collectionSize = Math.ceil(arr.length);
    scope.cdr.markForCheck();
    let startIndex = JSON.parse(JSON.stringify((scope.pageSize * scope.page) - scope.pageSize));
    let endIndex = JSON.parse(JSON.stringify((scope.pageSize * scope.page) - 1));
    scope.displayList = scope.splitArr(JSON.parse(JSON.stringify(arr)), startIndex, endIndex);
    scope.cdr.markForCheck();
  }
  splitArr(arr, startIndex, endIndex) {
    var tempArr = [];

    let listArr = JSON.parse(JSON.stringify(arr))
    listArr.forEach((alone, i) => {
      if (i >= startIndex && i <= endIndex) {
        tempArr.push(alone)
      }
    })
    return JSON.parse(JSON.stringify(listArr));
  }

  reviewId;
  templateName;
  modalRef: BsModalRef;

  // comment:any;
  openModal(template: TemplateRef<any>, data) {
    this.reviewId = data.reviewId;
    this.comment = data.reply;
    this.templateName = template;
    this.review = data.reviewDetails;
    let modelData = {
      name: data.reviewBy,
      rating: data.rating,
      profileImgUrl: data.profileImgUrl,
      review: data.reviewDetails,
      genreviewId: data.id
    }

    if (data.isReplayToOurSys && data.reviewSource.toLocaleLowerCase() == "google") {
      this.modalRef = this.modalService.show(template, {
        initialState: modelData
      });
    }
    // else {
    //   window.open(data.reviewUrl, "_blank")
    // }
    else if (data.isReplayToOurSys && data.reviewSource.toLocaleLowerCase() == "facebook") {
      this.modalRef = this.modalService.show(template, {
        initialState: modelData
      });
    }
    else {
      window.open(data.reviewUrl, "_blank")
    }
  }

  loading: any = false;
  autoenableresponseloading: any = false;
  sReply(v) {

    // this.pageService.showLoader();
    this.loading = true;
    let data = {
      key: localStorage.getItem(environment.authTokenKey),
      profileId: JSON.parse(localStorage.getItem('ProfileId')),
      reviewId: this.reviewId,
      comment: v
    }

    this.loading = false;
    this.http.post(environment.apiPath + 'RespondReviews', data).subscribe((res: any) => {
      if (res.success) {
        this.loading = false;
        this.modalRef.hide();
        this.pageService.showSuccess("Success", "Reply sent successfully")
        this.ngOnInit();
      } else {
        this.loading = false;
        this.pageService.hideLoader()
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  // added by unnati genrateRespond

  genrateRespond(genreviewId) {
    if (this.review != null) {
    if (this.pendingReponseCredit > 0) {
      var scope = this;
      scope.pageService.showLoader();
      scope.http.get(environment.apiPath + 'GenerateResponse?Key=' + scope.accessKey + '&ReviewID=' + genreviewId)
        .subscribe((res) => {
          scope.pageService.hideLoader();
          if (res['success']) {
            this.pendingReponseCredit = res['pendingCredits'];
            this.comment = res['reviewRespond'];
          }
        }, (err) => {
          if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

              this.pageService.hideLoader();
              this.pageService.showError('Oops!', err.error.errorMsg);
            }
          })
      }
      else {
        scope.pageService.hideLoader();
        this.pageService.showError('Oops!', "Purchase more response credits.");
      }
    }
    else {
      this.pageService.showError('Oops!', "Response can't be genrated.");
    }

  }

  openReviewModel(templateManualForm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateManualForm, {
    });
  }


  openUnlockSentiment(templateUnlockSentiment: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateUnlockSentiment, {

    });
  }

  Upgradeunlock() {
    this.router.navigate(['/general/billing']);
    this.modalRef.hide();
  }

  // add manual review
  isSubmit = false;
  addReview() {
    this.loading = true;
    this.isSubmit = true;
    if (this.addReviewForm.invalid) {
      this.loading = false;
      return;
    }
    this.pageService.showLoader();
    let date = this.addReviewForm.controls['date'].value
    let data = {
      key: this.accessKey,
      profileId: this.profileId,
      name: this.addReviewForm.controls['name'].value,
      comment: this.addReviewForm.controls['review'].value,
      reviewdate: date.month + "-" + date.day + "-" + date.year,
      ReviewRating: this.addReviewForm.controls['ReviewRating'].value,
    }


    this.http.post(environment.apiPath + 'AddManualReview', data).subscribe((res: any) => {
      if (res.success) {
        this.loading = false;
        this.pageService.hideLoader();
        this.closeReview();
        this.loadPage();
      } else {
        this.loading = false;
        this.pageService.hideLoader();
        this.addReviewForm.untouched;
      }
    }, (err) => {
      this.loading = false;
      this.pageService.hideLoader();
      this.addReviewForm.untouched;
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  closeReview() {
    this.isSubmit = false;
    this.addReviewForm.reset();
    this.addReviewForm.untouched;
    this.modalRef.hide();
    this.cdr.markForCheck();
  }
  setThanksMsg(msg) {
    this.comment = msg;
  }

  model;
  selectToday() {
    const now = new Date();
    this.model = { month: now.getMonth() + 1, day: now.getDate(), year: now.getFullYear() };
  }

  searchReviewByName() {
    this.loadPage()

  }

  clearSearch(e) {
    if (e.target.value.length == 0) {
      this.loadPage();
    }
  }

  handleCredentialResponse() {
    alert();
  }

  //toggle
  changeToggle(val, data) {
    var scope = this;
    if (val.checked) {
      //  val.source.checked = this.hasShowOnWidget
      scope.toggleSubscription(data, val)
      scope.cdr.markForCheck()

      // let confirmDialog = scope.dialog.open(ConfrimAlertComponent, {
      //   width: '700px',
      //   panelClass: 'editModal',
      //   disableClose: true,
      //   data: {
      //     message: "This review will display on all review widgets. Do you want to continue ?",
      //     buttonText: 'Continue',
      //     source: 'hasShowOnWidget'
      //   }
      // });
      // confirmDialog.afterClosed().subscribe(result => {
      //   if (result.flag) {

      //   }
      // })
    }
    else {

      //  val.source.checked = this.hasShowOnWidget
      scope.toggleSubscription(data, val)
      scope.cdr.markForCheck()

      // let confirmDialog = scope.dialog.open(ConfrimAlertComponent, {
      //   width: '700px',
      //   panelClass: 'editModal',
      //   disableClose: true,
      //   data: {
      //     message: "This review will not display on all review widgets. Do you want to continue ?",
      //     buttonText: 'Continue',
      //     source: 'hasShowOnWidget'
      //   }
      // });
      // confirmDialog.afterClosed().subscribe(result => {
      //   if (result.flag) {

      //   }

      // })
    }
  }

  toggleSubscription(tarid, val) {
    var scope = this;
    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'UpdateReviewsSetting?Key=' + scope.accessKey + '&Id=' + tarid + '&hasShowonWidget=' + val.checked)
      .subscribe((res) => {
        scope.pageService.hideLoader();
        if (res['success']) {
          scope.loadPage()
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  /// delete manually
  deleteLoading: boolean = false;
  openDeleteModal(target) {
    var scope = this;
    let confirmDialog = scope.dialog.open(ConfrimAlertComponent, {
      width: '700px',
      panelClass: 'editModal',
      // position:{top:'10px'},
      disableClose: true,
      data: {
        message: 'Are you sure you want to delete this review?',
        buttonText: 'Delete anyway',
        source: 'delete template'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {

      if (result.flag) {
        scope.deleteTemplate(target)
      }
    })
  }
  deleteTemplate(targetid) {
    var scope = this;
    scope.deleteLoading = true;
    scope.http.get(environment.apiV2Path + 'DeleteManualReview?Key=' + scope.accessKey + '&Id=' + targetid)
      .subscribe((res) => {
        scope.deleteLoading = false;
        if (res['success']) {
          scope.pageService.showSuccess('Deleted', 'Review deleted successfully.');
          scope.loadPage()
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  //added start

  layoutDetails = {};
  layoutName = '';
  thresold: any;
  nid = '';

  sanitizeHtml(html) {
    var scope = this;
    return scope.sanitizer.bypassSecurityTrustHtml(html)
  }

  clickButtonTracker(msg) {
    var scope = this;
    let data = {
      NId: scope.nid,
      pageActivity: scope.pageService.encrypt(msg)
    }
    scope.http.post(environment.apiPath + 'ClickOnButton', data)
      .subscribe((res) => {
      })
  }


  ShowConfirmBox():void{
	if(this.enableAutoResponse){
		this.autoResponseConfirmMessage = "Enabling AI-powered auto response will automatically respond to newly published Google and Facebook reviews.";
	}
	else
	{
		this.autoResponseConfirmMessage = "Disabling AI-powered auto response prevents it from automatically responding to newly published Google and Facebook reviews.";
	}


	this.dialog.open(this.templateautoresponseconfirmbox, {
		width: '900px',
	});
  }

  closeAutoEnableConfirmation():void{
	this.enableAutoResponse = !this.enableAutoResponse;
	this.dialog.closeAll();
  }

  SetAutoRespond(): void {
	this.autoenableresponseloading = true;

	this.http.get(environment.apiPath + `SetAutoRespond?Key=${this.accessKey}&ProfileId=${this.profileId}&HasAutoRespond=${this.enableAutoResponse}`)
		.subscribe(
			(res) => {
				this.autoenableresponseloading = false;
				if (res['success']) {
					this.dialog.closeAll();
					this.pageService.showSuccess('Updated', "Details has been updated successfully!");
				}
			},
			(error) => {
				this.autoenableresponseloading = false;
				this.pageService.showError('Oops!', error.error.errorMsg);
			}
		);
}
}
