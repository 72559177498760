import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../../environments/environment';
import { PageConfigService } from '../../../core/_base/layout/services/page-config.service';
import { UsersDataSource } from './../../../core/auth/_data-sources/users.datasource';

@Component({
  selector: 'kt-set-limit-profile',
  templateUrl: './set-limit-profile.component.html',
  styleUrls: ['./set-limit-profile.component.scss']
})
export class SetLimitProfileComponent implements OnInit {

  headerTitle = '';
  email: number = 0;
  sms: number = 0;
  accessKey: any;
  currentPlan = '';
  buttonDisable: boolean = true;
  profileId: any;
  public displayOnEmail: boolean = true;
  public displayOnsms: boolean = true;
  profileData: any = {};
  source = '';
  showEmail: boolean = true;
  showSMS: boolean = true;
  fromSubmitted: boolean = false;

  setlimtForm: any = UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public navData: any,
    public dialogRef: MatDialogRef<SetLimitProfileComponent>,
    public cdr: ChangeDetectorRef,
    public http: HttpClient,
    public formBuiledr: UntypedFormBuilder,
    public pageService: PageConfigService) {


  }

  ngOnInit() {
    var scope = this;
    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    scope.currentPlan = JSON.parse(localStorage.getItem('current-plan')).toLocaleLowerCase();
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))

    //  limit for validations
    // this.setlimtForm = new FormGroup({
    //   emailtextlimit: new FormControl(['', [Validators.required]]),
    //   smstextlimit: new FormControl(['', [Validators.required]])
    // })

    this.setlimtForm = this.formBuiledr.group({
      displayOnEmail: [false],
      displayOnsms : [false],
      emailtextlimit: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      smstextlimit: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    })
 
    scope.source = scope.navData.source;
    scope.headerTitle = scope.navData.userdata.companyName;
    if (scope.source == 'edit') {
      scope.profileId = scope.navData.userdata.profileId;
      scope.email = scope.navData.userdata.emailLimit;
      scope.sms = scope.navData.userdata.smsLimit;
      scope.displayOnsms = scope.navData.userdata.isSMS;
      scope.displayOnEmail = scope.navData.userdata.isEmail;
      scope.showSMS = scope.navData.userdata.isSMS;
      scope.showEmail = scope.navData.userdata.isEmail;
    }

    // sms load
    if (scope.displayOnsms == true) {
      scope.showSMS = true
    }
    else {
      scope.showSMS = false
    }
    // email load
    if (scope.displayOnEmail == true) {
      scope.showEmail = true
    }
    else {
      scope.showEmail = false
    }
  }

  increaseEmail() {
    var scope = this;
    scope.buttonDisable = false;
      scope.email += 1;
    
    scope.cdr.markForCheck()
  }
  decreaseEmail() {
    var scope = this;
    scope.buttonDisable = false;
    if (scope.email === 0 || scope.email <= 0) return;
    scope.email -= 1;
    
    scope.cdr.markForCheck()
  }
  increaseSms() {
    var scope = this;
    scope.buttonDisable = false;
    scope.sms += 1;
    
    scope.cdr.markForCheck()
  }
  decreaseSms() {
    var scope = this;
    scope.buttonDisable = false;
    if (scope.sms === 0 || scope.sms <= 0) return;
    scope.sms -= 1;
    
    scope.cdr.markForCheck()
  }
  
  //save set limit email /sms 
  isSubmit = false;
  loading: any = false;

  onSubmit() {
    this.loading = true;
    this.isSubmit = true;
    this.fromSubmitted = true;

    if (this.setlimtForm.invalid) {
      this.loading = false;
      return;
    }
    this.fromSubmitted = false;
    this.pageService.showLoader();
    let data = {
      key: this.accessKey,
      profileId: this.profileId,
      HasEmail: this.displayOnEmail,
      HasSMS: this.displayOnsms,
      SMSLimit: this.sms,
      EmailLimit: this.email,
    }
    this.http.post(environment.apiPath + 'SetEmailAndSMSLimit', data).subscribe((res: any) => {
      this.loading = false;;
      if (res['success']) {
        this.pageService.showSuccess('Updated', 'Set Limit details updated.');
        this.setlimtForm.reset();
        this.closeModal({ flag: true })
      } else {
        this.pageService.showError('Oops!!!', res['errorMsg']);
        if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
          setTimeout(() => {
            this.pageService.sessionExpired();
          }, environment.sessionTimeOut)
        }
      }
    }, (err) => {
      this.loading = false;
      this.pageService.hideLoader();
      this.setlimtForm.untouched;
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  closeModal(data) {
    if (data != undefined) {
      this.isSubmit = false;
      this.setlimtForm.reset();
      this.setlimtForm.untouched;
      this.dialogRef.close(data)
    } else {
      this.isSubmit = false;
      this.setlimtForm.reset();
      this.setlimtForm.untouched;
      this.dialogRef.close()
    }
  }

  // hidden by default email
  changeEmailToggle() {
    var scope = this;
    scope.showEmail = scope.displayOnEmail;
    if (scope.displayOnEmail == true) {
      scope.showEmail = true
    }
    else {
      scope.showEmail = false
    }
    if (scope.showEmail == false) {
      scope.email = 0;
    }
  }

  // hidden by default sms
  changeSmsToggle() {
    var scope = this;
    // scope.showSMS = !scope.showSMS;
    scope.showSMS = scope.displayOnsms;
    if (scope.displayOnsms == true) {
      scope.showSMS = true
    }
    else {
      scope.showSMS = false
    }

    if (scope.showSMS == false) {
      scope.sms = 0;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}