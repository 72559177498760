<!-- <mat-card class='col-sm-12 verErrorMsg  no-margin-botton noticeCard text-1-1x noticeAlert' *ngIf="notificationMsg">
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12">
			{{notificationMsg}}
			<span class="btnAlert" (click)="Reconnect()">Reconnect</span>
		</div>
	</div>
</mat-card> -->

<mat-card class='padding-20'>
	<div class='row '>
		<div class='col-md-12 col-sm-12 col-xs-12 text-bold text-1-3x'>Company Settings</div>
	</div>
	<form [formGroup]="generalSettingForm">
		<div class='row padding-10'>
			<div class='col-md-12 col-sm-12 col-xs-12' *ngIf="profileData!=undefined">
				<div class='row margin-tb-10'>
					<div class="col-md-6 col-sm-12 col-xs-12 form-group">
						<div>
							<mat-label class='text-bold'>Person Name</mat-label>
							<input type="text" class="form-control" name="ConatctPersonName" placeholder="Person Name"
								formControlName="ConatctPersonName" [(ngModel)]="model.conatctPersonName" />
						</div>
					</div>
					<div class="col-md-6 col-sm-12 col-xs-12 form-group">
						<div>
							<mat-label class='text-bold'>Company Name* </mat-label>
							<input type="text" class="form-control" name="CompanyName" placeholder="Company Name"
								formControlName="CompanyName" [(ngModel)]="model.companyName"
								(blur)='generteShortName()' />
						</div>
						<mat-error *ngIf="submittedform && generalSettingForm.controls.CompanyName.errors">
							<strong
								*ngIf='submittedform && generalSettingForm.controls.CompanyName.errors.required'>*Required</strong>
						</mat-error>
					</div>
				</div>
				<div class="row margin-tb-15">
					<div class="col-md-12 col-sm-12 col-xs-12 form-group">
						<mat-label class='text-bold tooltip-cust'>
							Unique Profile Name*
							<i class="fa fa-info" aria-hidden="true"
								ngbTooltip="Your unique webpage name example {{uniqueUrl}}/YourUniqueProfileName"
								placement="top"></i>
						</mat-label>
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon3">{{uniqueUrl}}/</span>
							</div>
							<input type="text" class="form-control" name="ShortName" formControlName="ShortName"
								placeholder="Unique Profile Name" [(ngModel)]="model.shortName" [ngClass]="{'loadingTextbox': loadText,'rightTextbox': rightIcon,
							'wrongTextbox': wrongIcon}" (ngModelChange)='validateUrl()' autocomplete="off" />
						</div>
						<!-- <div>
							<mat-label class='text-bold'>Unique Profile Name*</mat-label>
							<input type="text" class="form-control" name="ShortName" formControlName="ShortName" placeholder="Unique Profile Name" [(ngModel)]="model.shortName" [ngClass]="{'loadingTextbox': loadText,'rightTextbox': rightIcon,
							'wrongTextbox': wrongIcon}" (ngModelChange)='validateUrl()' autocomplete="off" />
						</div> -->
						<mat-error *ngIf="submittedform && generalSettingForm.controls.ShortName.errors">
							<strong
								*ngIf='submittedform && generalSettingForm.controls.ShortName.errors.required'>*Required</strong>
						</mat-error>
					</div>
				</div>
				<div class='row margin-tb-15'>
					<div class="col-sm-12 col-xs-12 form-group"
						[ngClass]="{'col-md-4':isShowAlphaNumeric == true,'col-md-6':isShowAlphaNumeric==false}">
						<div>
							<mat-label class='text-bold'>Email Address*</mat-label>
							<input type="text" class="form-control" name="Email" formControlName="Email"
								placeholder="Email Address" [(ngModel)]="model.email" />
						</div>
						<mat-error *ngIf="submittedform && generalSettingForm.controls.Email.errors">
							<strong
								*ngIf='submittedform && generalSettingForm.controls.Email.errors.required'>*Required</strong>
							<strong *ngIf='submittedform && generalSettingForm.controls.Email.errors.email'>*Invalid
								email address</strong>
						</mat-error>
					</div>
					<div class="col-sm-12 col-xs-12 form-group"
						[ngClass]="{'col-md-4':isShowAlphaNumeric == true,'col-md-6':isShowAlphaNumeric==false}">
						<div>
							<mat-label class='text-bold'>Phone No</mat-label>
							<input formControlName="PhoneNumber" type="tel" class="form-control"
								id='generalSettingInput' name="PhoneNumber" [(ngModel)]="model.phoneNumber"
								placeholder="Phone No">
						</div>
						<mat-error>
							<strong id="valid-msg-phone-general" style="color:#0abb87 !important" class="hide">✓
								Valid</strong>
							<strong id="error-msg-phone-general" class="hide"></strong>
						</mat-error>
					</div>
					<div class="col-md-4 col-sm-12 col-xs-12 form-group" *ngIf="isShowAlphaNumeric" style="display: none;">
						<div>
							<mat-label class='text-bold tooltip-cust'>
								SMS Sender Name*
								<i class="fa fa-info" aria-hidden="true"
									ngbTooltip="Your sender name for SMS review request" placement="top"></i>
							</mat-label>
							<input type="text" class="form-control" name="Alpha Numeric Sender Id"
								formControlName="AlphaNumericSenderId" placeholder="YOUR-CMP-NAME" maxlength="11"
								[(ngModel)]="model.alphaNumericSenderId" />
						</div>
						<mat-error *ngIf="submittedform && generalSettingForm.controls.AlphaNumericSenderId.errors">
							<strong
								*ngIf='submittedform && generalSettingForm.controls.AlphaNumericSenderId.errors.required'>*Required</strong>
							<strong
								*ngIf='submittedform && generalSettingForm.controls.AlphaNumericSenderId.errors.pattern'>*Sender
								Id must be combination of alpha (a-z, A-Z) and numeric (0-9) </strong>
						</mat-error>
					</div>
				</div>
				<div class='row margin-tb-10'>
					<div class="col-md-4 col-sm-12 col-xs-12  form-group">
						<div class="form-group">
							<mat-label class='text-bold'>Country</mat-label>
							<mat-select class="form-control" formControlName="Country"
								(selectionChange)="selectCountry($event.value)" name="Country"
								[(ngModel)]="model.Country">
								<mat-option *ngFor='let item of countryList' [value]='item.name'>
									{{item.name}}
								</mat-option>
							</mat-select>
						</div>
						<mat-error *ngIf="isControlHasError('country','required')">
							<strong> Country required</strong>
						</mat-error>
					</div>
					<div class="col-md-4 col-sm-12 col-xs-12 form-group">
						<div>
							<mat-label class='text-bold'>Timezone: *</mat-label>
							<!-- <br /> -->
							<ng-select2 class='timeZone' name='timeZone' [value]='model.timeZone' [data]="timeZoneList"
								cssImport='true'>
							</ng-select2>
						</div>
						<mat-error *ngIf="submittedform && timezoneReq">
							<strong *ngIf='submittedform && timezoneReq'>*Required</strong>
						</mat-error>
					</div>
					<div class="col-md-4 col-sm-12 col-xs-12 form-group">
						<div>
							<mat-label class='text-bold'>Industry*</mat-label>
							<select class="form-control" formControlName="Industry" name="Industry"
								[(ngModel)]="model.industry">
								<option value=''>Select Industry</option>
								<option [value]="item" *ngFor="let item of industryList">{{item}}</option>
							</select>
						</div>
						<mat-error *ngIf="submittedform && generalSettingForm.controls.Industry.errors">
							<strong
								*ngIf='submittedform && generalSettingForm.controls.Industry.errors.required'>*Required</strong>
						</mat-error>
					</div>
				</div>

				<div class='row margin-tb-10' *ngIf="shouldShowSMSTwilioNumber">

					<div class="col-md-4 col-sm-12 col-xs-12 form-group">
						<div>
							<mat-label class='text-bold' style="float: left;width: 100%;">SMS Send From Phone No*</mat-label>
							<ng-container *ngIf="fetchTwilioNumbers == false">
								<select class="form-control" formControlName="fromPhoneNumber" name="fromPhoneNumber"
									[(ngModel)]="model.fromPhoneNumber" disabled style="float: left;width: 60%;">
									<option [ngValue]="null">Select SMS Phone No</option>
									<option [ngValue]="''">Select SMS Phone No</option>
									<option [ngValue]="undefined">Select SMS Phone No</option>
									<option value="{{model.fromPhoneNumber}}">{{model.fromPhoneNumber}}</option>
								</select>
								<button type="button" style="float: right;width: 30%;"
									class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"
									(click)='GetActivePhoneNumberList(true)'
									[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': fetchLoading}">
									Fetch</button>
							</ng-container>
							<ng-container *ngIf="fetchTwilioNumbers == true">
								<select class="form-control" formControlName="fromPhoneNumber" name="fromPhoneNumber"
									[(ngModel)]="model.fromPhoneNumber">
									<option [ngValue]="null">Select SMS Phone No</option>
									<option [value]="item" *ngFor="let item of activePhoneNumberList">{{item}}</option>
								</select>
							</ng-container>

						</div>
						<mat-error *ngIf="submittedform && generalSettingForm.controls.fromPhoneNumber.errors" style="width: 100%;float:left">
							<strong
								*ngIf='submittedform && generalSettingForm.controls.fromPhoneNumber.errors.required'>*Required</strong>
						</mat-error>
					</div>
				</div>

				<div class="row ">
					<div class="col-12 form-group">
						<mat-label class='text-bold '>Logo <span style="color: #666;font-size: 80%;">(Ideal width:
								400px)</span></mat-label>

						<div class="margin-tb-5">
							<div class="kt-avatar kt-avatar--outline" id="kt_user_avatar_2">
								<div class="kt-avatar__holder businessLogoImg"
									style="background-image: url(/assets/media/users/default.jpg)"></div>
								<label class="kt-avatar__upload" data-toggle="kt-tooltip" title=""
									data-original-title="Change avatar">
									<i class="fa fa-pen"></i>
									<input #businessImg type="file" name="profile_avatar" accept=".png, .jpg, .jpeg"
										(change)="onFilesAdded($event)">
								</label>
								<span class="kt-avatar__cancel business-logo-cancel" data-toggle="kt-tooltip" title=""
									data-original-title="Cancel avatar" (click)='deleteImg()'>
									<i class="fa fa-times"></i>
								</span>
							</div>
						</div>
						<mat-error *ngIf="imageError">
							<strong *ngIf='imageError'>*Maximum 1 MB image size allowed. </strong>
						</mat-error>
					</div>
				</div>
				<div class='col-12 no-padding  margin-tb-10'>
					<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)='onSubmit()'
						[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}">
						Update</button>
				</div>
			</div>
		</div>
	</form>
</mat-card>
