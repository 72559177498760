import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../../environments/environment';
import { PageConfigService } from './../../../../../core/_base/layout/services/page-config.service';
import { PreviewTemplateComponent } from '../../../preview-template/preview-template.component';
import { MatTabGroup, MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-private-feedback-template-page',
  templateUrl: './private-feedback-template-page.component.html',
  styleUrls: ['./private-feedback-template-page.component.scss']
})
export class PrivateFeedbackTemplatePageComponent implements OnInit {

  constructor(public http: HttpClient, public dialog: MatDialog,
    public pageService: PageConfigService,
    public router: Router) {

      
      
     }

  key: any;
  profileId: any;

  templateSubject: any;
  templateHtml: any;
  templateId: any;

  public tools: object = {
		type: 'MultiRow',
		items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', '-',
			'LowerCase', 'UpperCase', '|', 'OrderedList', 'UnorderedList', '|', '-',
			'Indent', 'Outdent', '|', 'createLink', '|', 'ClearFormat',
		]
	};
  
  ngOnInit() {
    var scope = this;
    this.key = localStorage.getItem(environment.authTokenKey);
    this.profileId = JSON.parse(localStorage.getItem("ProfileId"));
    this.getTemplate();
    scope.pageService.getProgileId().subscribe(message => {

      if (message.id) {
        scope.profileId = JSON.parse(localStorage.getItem('ProfileId'));
        if (scope.router.url.includes('template-settings')) {
          this.getTemplate();
        }

      }
    });
  }

  getTemplate() {
    this.pageService.showLoader()
    this.http.get(environment.apiPath + 'GetPrivatefeedbackTemplate?key=' + this.key + '&ProfileId=' + this.profileId).subscribe((res: any) => {
      if (res.success) {
        this.templateHtml = res.data[0].emailHTML;
        this.templateSubject = res.data[0].subject;
        this.templateId = res.data[0].id;
        this.pageService.hideLoader();
      } else {
        if(res.status != 500 || res.statusText != "Internal Server Error"){
        this.pageService.hideLoader();
        this.pageService.showError('Opps!!', res.errorMsg);
        }
      }
    },(err) =>{
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

      this.pageService.hideLoader();
      this.pageService.showError('Oops!',err.error.errorMsg);
      }
    })
  }

  loading: any;
  updateTemplate() {
    this.loading = true;
    let data = {
      id: this.templateId,
      key: this.key,
      EmailHTML: this.templateHtml,
      Subject: this.templateSubject
    }

    this.http.post(environment.apiPath + 'UpdatePrivateFeedbackTemplate', data).subscribe((res: any) => {
      if (res.success) {
        this.loading = false;
        this.ngOnInit();
        this.pageService.showSuccess('Success', 'Private Feedback template updated')
      }
    },(err) =>{
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

      this.pageService.showError('Oops!',err.error.errorMsg);
      }
    })
  }

  openPreview() {
    var scope = this;
    let data = {
      emailHTML: this.templateHtml
    }
    // target.emailHTML.replace(/{starslayout}/g,scope.starStr);
    // scope.templateData.emailHTML.replace(/{thumbslayout}/g,scope.thubmsStr);
    // scope.templateData.emailHTML.replace(/{facelayout}/g,scope.faceStr);
    let confirmDialog = scope.dialog.open(PreviewTemplateComponent, {
      width: '700px',
      panelClass: 'editModal',
      // position:{top:'10px'},
      // disableClose: true,
      data: {
        template: JSON.stringify(data)
      }
    });
  }
}
