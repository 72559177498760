
<div class='col-12 text-1-5x'>
	Reviews
	<span style="float: right;font-size: 14px;" *ngIf='viewDocumentation'><a
			href="https://grabyourreviews.zendesk.com/hc/en-us/articles/360043765514-Monitoring-and-Responding-to-Reviews"
			target="_blank"><i class="fa fa-question-circle"></i> View Help Documentation</a></span>
</div>
<div class="row">
	<div class="col-md-3 col-sm-12 col-xs-12" style="min-height: 40px;">
		<mat-card class='padding-10'>
			<div class=' text-center text-1-5x'>
				Average Rating {{totalaverageRating}}
			</div>
			<div class=" text-center text-2-5x">

				<div class='stars-outer'>
					<div class='stars-inner' [style.width.%]="TotalRatinginPercentage"></div>
				</div>
			</div>
		</mat-card>
	</div>
	<div class="col-md-9 col-sm-12 col-xs-12">
		<div class="row ">
			<mat-card class="col-md-3 col-sm-12 col-xs-12 text-center" *ngFor="let item of platformsName;"
				style="min-height:40px; border: 1px solid #e2e5ec; padding: 6px 10px;  border-radius: 4px; margin:0px 10px 10px;">
				<div><img [src]='"../../../../assets/media/social-logo/"+item.siteName+".png"' width="35px"
						style="padding-bottom:5px;" />

					<h5 style="margin-bottom: 0px;">Avg Rating {{item.rating}}</h5>
				</div>
				<div class=" text-center text-1-5x">
					<div class='stars-outer'>
						<div class='stars-inner' [style.width.%]="item.averageRating * 20"></div>
					</div>
				</div>
			</mat-card>
		</div>
	</div>
</div>

<div class="row padding-10">
	<div class='col-md-3'>
		<input class="form-control" (keyup)="clearSearch($event)" type='text' [(ngModel)]="searchInput"
			placeholder='Search By Name Or Review Details'>
	</div>
	<div class='col-md-2'>
		<button class="btn btn-primary" (click)="searchReviewByName()">Search &nbsp;<i class="fa fa-search"
				aria-hidden="true"></i></button>
	</div>
	<div class='col-md-2'>
		<select class='form-control ' [(ngModel)]="platforms" (change)="selectPlatform($event.target.value)">
			<option value="all">All</option>
			<option *ngFor="let item of platformsName" [value]="item.siteName">{{item.siteName}}</option>
		</select>
	</div>
	<div class='col-md-2'>
		<select class='form-control ' [(ngModel)]="sentiments" [value]="sentiments"
			(change)="selectSentiment($event.target.value)">
			<option value="All" selected>All Sentiment</option>
			<option value="positive">Positive</option>
			<option value="negative">Negative</option>
			<option value="neutral">Neutral</option>
			<option value="mixed">Mixed </option>
		</select>
	</div>
	<div class="col-md-3" style="text-align: center;">
		<button class="btn btn-primary" style="margin-bottom: 10px;" (click)="openReviewModel(templateManualForm)">
			<i class="fa fa-star" aria-hidden="true"></i> Add review manually</button>
	</div>

</div>


<div class="row padding-10">
	<div class='col-6 padding-tb-10'>
		<div class="text-left"><b style="padding-right: 10px;font-size: 16px;">Enable Auto Respond</b>
			<mat-slide-toggle id="enableAutoResponse" name="enableAutoResponse" (change)="ShowConfirmBox()"
				[(ngModel)]="enableAutoResponse">
			</mat-slide-toggle>
		</div>
	</div>

</div>

<div class="row padding-10">
	<div class='col-8'>
		<ngb-pagination [collectionSize]="collectionSize" [(page)]="page" (pageChange)='searchReview()'
			[pageSize]="pageSize" [maxSize]='6'></ngb-pagination>
	</div>
	<div class='flexClass text-right col-4'>
		<div class='text-1-2x'>Show &nbsp;</div>
		<div>
			<select class='form-control' [(ngModel)]="pageSize" (ngModelChange)='searchReview();demo();'>
				<option value='10'>10</option>
				<option value='20'>20</option>
				<option value='50'>50</option>
				<option value='100'>100</option>
			</select>
		</div>
	</div>
</div>
<div class="row padding-10">
	<div class="col-md-12 col-sm-12 col-xs-12 kt-section ">
		<div class="kt-section__content">
			<div class='row text-center'>
				<div class='col-12' *ngFor="let item of displayList">
					<mat-card class='margin-10'>
						<div class='col-12'>
							<div class='col-12 flexClass no-padding'>
								<div class='col-md-6 col-sm-12 col-xs-12 flexClass text-left no-padding'>
									<div class='text-center no-padding'>

										<img class='profile-logo' src='{{item.profileImgUrl}}'
											onerror="this.src='../../../../assets/media/images/no-review-profile.png';"
											*ngIf='item.profileImgUrl!=undefined && item.profileImgUrl!=null && item.profileImgUrl.length>0' />
										<img class='profile-logo'
											src='../../../../assets/media/images/no-review-profile.png'
											*ngIf='item.profileImgUrl == null || item.profileImgUrl ==""' />
									</div>
									<div class='col-8 text-left'>
										<span class='text-1-2x'><b>{{item.reviewBy}}</b></span><br />
										<span class='text-12px'>{{item.date}}</span>
									</div>
								</div>

								<div class="col-md-6 col-sm-12 col-xs-12 text-right  buttonDiv">
									<!-- added unnati  Unlock Sentiment-->
									<!-- [ngStyle]="{'filter' : item.reviewSentiment == 'Positive'? 'blur(3px)' : 'blur(0px)'}" -->
									<a class="sentimentOverAlllable sentimentPositiveCls cursor-pointer"
										*ngIf="hasSentiment==false"
										(click)="openUnlockSentiment(templateUnlockSentiment)"><i class="fa fa-lock"
											aria-hidden="true"></i> &nbsp;Unlock Sentiment </a>

									<a class="sentimentOverAlllable" [ngClass]="{'sentimentPositiveCls': item.reviewSentiment =='Positive',
												'sentimentNegativeCls': item.reviewSentiment =='Negative',
												'sentimentNeutralCls': item.reviewSentiment =='Neutral',
												'sentimentMixCls': item.reviewSentiment =='Mixed'}" *ngIf="hasSentiment==true"> {{item.reviewSentiment}}
									</a>
									&nbsp; &nbsp;
									<mat-slide-toggle (change)="changeToggle($event,item.id)"
										[(ngModel)]="item.hasShowOnWidget"
										matTooltip="Enable or Disable review to display on website widgets">
										&nbsp;
									</mat-slide-toggle>
									&nbsp; &nbsp;
									<div class='btn iconBtn cursor-pointer btnWarn' (click)="openDeleteModal(item.id)"
										*ngIf="item.reviewSource =='Other'">
										<i class="far fa-trash-alt"></i>
									</div>
									<!-- [checked]="item.hasShowOnWidget" -->
									<!-- openModal(template,item) -->

									<!-- <div class='notes roundedBox-radius'>
										<i class="fas fa-info" ></i>
									</div> -->
								</div>
							</div>
							<!-- <div class=' col-12 flexClass no-padding'> -->
							<div class='col-12 text-left margin-tb-10 no-padding text-1x '>
								<!-- {{item.reviewDetails}} -->
								<!-- <div class='col-md-10 col-sm-12 col-xs-12 flexClass text-left no-padding'> -->
								<span [innerHtml]="item.reviewDetails">
								</span>

								<div class="reply" *ngIf="item.reply">
									<span><b>Reply from owner at </b> {{item.replyDate}}</span>
									<br>
									<span>{{item.reply}}</span>
								</div>
								<!-- </div> -->
								<!-- delete manually added -->
								<!-- *ngIf="item.reviewSource =='grabyourreview'" -->
								<!-- <div class='col-md-2 col-sm-12 col-xs-12 text-right buttonDiv' *ngIf="item.reviewSource =='Other'">
									<button class="btn cursor-pointer btnWarn" (click)="openDeleteModal(item.id)">
										<i class="fa fa-trash"></i> Delete</button>
								</div> -->
							</div>
							<div class='col-12 flexClass no-padding'>
								<div class='col-md-10 col-sm-12 col-xs-12 flexClass text-left no-padding'>
									<img class='reviewsource-logo'
										[src]='"../../../../assets/media/social-logo/"+item.reviewSource+".png"'
										*ngIf="item.reviewSource !='Other'" />

									<div class='stars-outer star-size'>
										<div class='stars-inner star-size' [style.width.%]="item.rating * 20"></div>
									</div>
									<!-- <star-rating [value]="item.rating" checkedcolor="#f8ce0b" uncheckedcolor="#d2d2d2" size="25px" readonly="true"></star-rating>  template,item open model-->
								</div>

								<div class='col-md-2 col-sm-12 col-xs-12 text-right buttonDiv'
									*ngIf="item.reviewSource !='Other' && item.reviewUrl == '' && item.reviewUrl == null && item.reply == null || item.reply == ''">
									<a class='padding-tb-5 padding-lr-10 roundedBox-radius primary-back-color'
										style="cursor: pointer;" (click)="openModal(template,item)"><i
											class="fas fa-share"></i>&nbsp;Respond</a>
								</div>

								<!-- <div class='col-md-2 col-sm-12 col-xs-12 text-right buttonDiv' *ngIf="item.reviewSource =='Other'">
									<button class="btn cursor-pointer btnWarn" (click)="openDeleteModal(item.id)">
										<i class="fa fa-trash"></i> Delete</button>
								</div> -->
							</div>
							<!--  added unnati sentiment   -->
							<div *ngIf="hasSentiment==true">
								<div *ngFor="let word of item.wordSentiment | slice:0:1;let i = index">
									<hr>
									<div class="col-12 flexClass text-left no-padding">
										<div class="col-md-12">
											<div class="form-group">
												<span class="text-1-2x" *ngIf="word.sentiment=='positive' ||
										word.sentiment=='negative'||
										word.sentiment=='neutral'||
										word.sentiment=='mixed'">Mentions</span>
												<!--Word Data [ngStyle]="{'filter' : item.reviewSentiment == 'Positive' || item.reviewSentiment == 'Negative' || item.reviewSentiment == 'Neutral' || item.reviewSentiment == 'Mixed'? 'blur(3px)' : 'blur(0px)'}" -->
												<div class='sentiment-list cursor-pointer padding-tb-5 margin-tb-10'>
													<div *ngFor="let word of item.wordSentiment;let i = index;"
														style="display: inline-block;">
														&nbsp;
														<span [ngClass]="{'sentimentPositiveWord':word.sentiment =='positive',
													'sentimentNegativeWord':word.sentiment =='negative',
													'sentimentNeutralWord':word.sentiment =='neutral',
													'sentimentMixWord':word.sentiment =='mixed'}" style="font-size: 13px;font-weight: normal;">
															{{word.word}}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-card>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row padding-10">
	<div class='col-8'>
		<ngb-pagination [collectionSize]="collectionSize" [(page)]="page" (pageChange)='searchReview()'
			[pageSize]="pageSize" [maxSize]='6'></ngb-pagination>
	</div>
	<div class='flexClass text-right col-4'>
		<div class='text-1-2x'>Show &nbsp;</div>
		<div>
			<select class='form-control' [(ngModel)]="pageSize" (ngModelChange)='searchReview();demo();'>
				<option value='10'>10</option>
				<option value='20'>20</option>
				<option value='50'>50</option>
				<option value='100'>100</option>
			</select>
		</div>
	</div>
</div>

<ng-template #template>
	<div class="modal-header">
		<img class='profile-logo' src='{{modalService.config.initialState.profileImgUrl}}'
			onerror="this.src='../../../../assets/media/images/no-review-profile.png';"
			*ngIf='modalService.config.initialState.profileImgUrl!=undefined && modalService.config.initialState.profileImgUrl!=null && modalService.config.initialState.profileImgUrl.length>0' />
		<img class='profile-logo' src='../../../../assets/media/images/no-review-profile.png'
			*ngIf='modalService.config.initialState.profileImgUrl==null' />
		<h4 class="modal-title pull-left padding-10">{{ modalService.config.initialState.name }}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" [formGroup]="replyForm">

		<span *ngIf="modalService.config.initialState.review">
			<span class="grey-color text-c1"><b>Review</b></span>
			<br>
			<span class="grey-color text-c1">{{modalService.config.initialState.review}}</span>
			<br><br>
		</span>
		<div class="row padding-bottom-10">
			<div class="col-md-2">
				<span class="grey-color text-c1"><b>Reply</b></span>
			</div>

			<!-- *ngIf='currentCountry != "IN"' -->
			<div class="col-md-10 text-right">
				<div class="row text-right">
					<div class="col-md-12">
						<span *ngIf='pendingReponseCredit == 0 ' class="responsePendingRed">Buy Response Credits</span>
						<span *ngIf='pendingReponseCredit > 0' class="responsePendingGreen">
							{{pendingReponseCredit}} response available
						</span>
						<button class="btn btnGenWarn text-left"
							(click)="genrateRespond(modalService.config.initialState.genreviewId)">Generate Reply
							<!-- <sup>Beta</sup> -->
						</button>
					</div>
				</div>
			</div>
		</div>
		<textarea formControlName="reply" [(ngModel)]="comment" [innerHtml]="comment"
			[ngClass]="{errorTextBox: fromSubmitted && replyForm.controls.reply.errors?.required}"></textarea>
		<!-- <mat-error *ngIf="fromSubmitted && replyForm.controls.reply.errors">
			<strong *ngIf='fromSubmitted && replyForm.controls.reply.errors.required'>*Required</strong>
		</mat-error> -->
		<br>
		<div *ngIf="modalService.config.initialState.rating > 2">
			<span class="badge badge-secondary msg" *ngFor="let item of positiveReview"
				(click)="setThanksMsg(item)">{{item}}</span>
		</div>
		<br>

		<button class="btn btn-primary" (click)="sReply(comment)"
			[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}"
			style="float: right;">Send Reply</button>
	</div>
</ng-template>

<ng-template #templateManualForm>
	<div class="modal-header">
		<img style="margin-left: 25%;width: 50%;height: auto;" src='../../../../assets/media/images/new-review-1.png' />
	</div>
	<div class="modal-body">
		<div [formGroup]="addReviewForm">

			<div class="form-group">
				<ngb-rating [max]="5" [(rate)]="ReviewRating" formControlName="ReviewRating"
					[readonly]="false"></ngb-rating>
			</div>

			<div class="form-group">
				<input type="text" formControlName="name" class="form-control" placeholder="Enter customer name">
				<mat-error *ngIf="isSubmit && addReviewForm.controls.name.errors">
					<strong *ngIf="isSubmit && addReviewForm.controls.name.errors.required">*Required</strong>
				</mat-error>
			</div>
			<div class="form-group">
				<textarea formControlName="review" class="form-control" placeholder="Enter feedback"></textarea>
			</div>
			<div class="form-group">
				<div class="input-group">
					<!-- [maxDate]="{year: getYear, month: getMonth, day: getDay}" -->
					<input class="form-control" placeholder="mm-dd-yyyy" name="dp" formControlName="date"
						[maxDate]="{month: getMonth, day: getDay,year: getYear}" [(ngModel)]="model"
						ngxDaterangepickerMd ngbDatepicker #da="ngbDatepicker" (click)="da.toggle()" [readonly]="true">

					<div class="input-group-append">
						<button class="btn btn-outline-secondary" (click)="da.toggle()" type="button"><i
								class="fa fa-calendar" aria-hidden="true"></i></button>
					</div>

				</div>
				<mat-error *ngIf="isSubmit && addReviewForm.controls.date.errors">
					<strong *ngIf="isSubmit && addReviewForm.controls.date.errors.required">*Required</strong>
				</mat-error>
				<!-- <mat-error *ngIf="isSubmit && addReviewForm.controls.date.errors">
					<strong *ngIf="isSubmit && addReviewForm.controls.date.errors.pattern">*Enter proper date</strong>
				</mat-error> -->
			</div>

			<div style="text-align: end;">
				<button class="btn btn-primary" (click)="addReview()"
					[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}">Add
					review</button>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<button class="btn btn-secondary" (click)="closeReview()">Close</button>
			</div>
		</div>
	</div>
</ng-template>


<ng-template #templateUnlockSentiment>

	<div class="modal-body">
		<div>
			<img style="width: 100%;" src='../../../../assets/media/sentiment-img/Pop-pop-03-01.svg' />
		</div>
		<br />
		<div style="text-align: end;">
			<button class="btn btnWarn" (click)="Upgradeunlock()">Upgrade to access</button>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<button class="btn btn-secondary" (click)="closeReview()">Close</button>
		</div>
	</div>
</ng-template>


<ng-template #templateautoresponseconfirmbox>
	<div class="col-12">
		<div class="row padding-15 text-center">
			<h4 class="col-12">
				Confirmation <i style="float: right" class="fa fa-times cursor-pointer" (click)="closeAutoEnableConfirmation()"></i>
			</h4>
		</div>
		<div class="col-12">
			<mat-divider></mat-divider>
		</div>
		<div class="row padding-15" style="display: unset;">

			<div class="col-12">
				<div class="row">
					<div class='col-12 padding-tb-10'>
						<p class=" text-1-2x">{{autoResponseConfirmMessage}}</p>
					</div>
				</div>

				<div class="row">
					<div class="col-12"><br />
						<mat-divider></mat-divider>
						<br />
					</div>
				</div>
				<div class="row">
					<div class='col-12 text-right' mat-dialog-actions>
						<button class="btn btn-primary btn-elevate kt-login__btn-primary" type="button" (click)="SetAutoRespond()"
								[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': autoenableresponseloading}">
								Ok</button>
						<button mat-button type="button" (click)="closeAutoEnableConfirmation()">Cancel</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
