<!-- begin: Header  -->
<!--START A2P Notice-->
<mat-card class=' text-1-2x offernotice'  *ngIf="showTwilioWarning() && IsDashboard()">
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12 noticeSen" style="font-size: 14px;">
			We have stopped sending SMS review request due to A2P Regulations,
			integrate your own Twilio account to continue SMS review request.
			   <button type="button" (click)="redirect('/general/twilio-integration')" class="btnOffer" >Connect now</button>
		</div>
	</div>
</mat-card>
<!--END A2P Notice-->

<!--START Jobber Offer Notice-->
<!-- <mat-card class=' text-1-2x offer' *ngIf="isFreeTrial">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 noticeSen" style="font-size: 17px;">
            <span style="font-size: 26px;">🎁 </span>New Year Offer: Save 40% for 3 Months! Code: <b style="color:#00149a;font-size: 20px;">WCNY24</b>
               <button type="button" (click)="redirect('/general/billing')" class="btnOffer" >Avail Offer</button>
        </div>
    </div>
</mat-card> -->
<!--END Jobber Offer Notice-->

<!--Show A2P 10DLC Registration Notice-->

<!-- <mat-card *ngIf="showA2PWarning() && IsDashboard()"  class=' text-1-2x offernotice'>
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12">
			Please note that if A2P 10DLC compliance is not achieved, sending SMS review requests will be stopped from 15th June.
			<button type="button" (click)="redirect('/general/A2P-Brand-Registration')" class="btnOffer" >Register now</button>
		</div>
	</div>
</mat-card> -->
<!--END Cybermonth Offer Notice-->



<mat-card class=' text-1-2x text-center  noticeCard notice-review' *ngIf="isFetching">
	<div class="row">
		<div class="loading col-md-11 col-sm-12 col-xs-12" style="text-align: left;">
			<!-- <i class="fas fa-exclamation-triangle text-1-5x danger-color"></i> -->
			{{reviewFetchMsg}}
		</div>
		<div class='col-md-1 text-1-2x text-right gray-color'>
			<i class="fa fa-times cursor-pointer" (click)='closeFetching()'></i>
		</div>
	</div>

</mat-card>
<mat-card class='col-sm-12 verErrorMsg  no-margin-botton noticeCard text-1-1x noticeAlert' *ngIf="!isVerify">
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12">
			Please check your email  <span style="text-decoration: underline;color: cornflowerblue;">{{email}}</span>  to verify your account.
			<span class="btnLink" (click)='updateEmail()'>Change email</span>
			<span class="btnAlert" (click)='sendEmailAgain()'>Resend email</span>
		</div>
	</div>
</mat-card>

<!-- review platform not connected notice -->
<mat-card class=' text-1-2x noticeWarning' *ngIf="isWarning">
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12 noticeSen">
			Connect review platform to send review invitation. <span class="btnWarn" (click)="connectPlatform()">Connect Review Platform</span>
		</div>
	</div>

</mat-card>
<!-- payment error notice -->
<!-- *ngIf="paymentErrorMsg" -->
<mat-card class='col-sm-12 verErrorMsg  no-margin-botton noticeCard text-1-1x noticeAlert' *ngIf="paymentErrorMsg">
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12">
			{{paymentErrorMsg}}
			<span class="btnAlert" (click)='updateCard(contentModal)'>Update Card</span>
		</div>
		<!-- <div class='col-md-1 text-1-2x text-right gray-color'>
			<i class="fa fa-times cursor-pointer" (click)='closeVeriFy()'></i>
		</div> -->
	</div>
</mat-card>
<!-- payment error notice done -->

<mat-card class='verErrorMsg  no-margin-botton text-1-1x text-center noticeCard noticeAlert' *ngIf="isExpired">
	<div class="row">
		<div class="col-md-11 col-sm-12 col-xs-12" style="text-align: left;">
			<!-- <i class="fas fa-exclamation-triangle text-1-5x danger-color"></i> -->
			Your plan has been expired.
			<span class="btnAlert" (click)='upgradePlan()'>Upgrade your plan</span>
		</div>
	</div>
</mat-card>

<div ktHeader #ktHeader class="kt-header kt-grid__item" [ngClass]="htmlClassService.getClasses('header', true)" id="kt_header">
	<ngb-progressbar class="kt-loading-bar" *ngIf="(loader.progress$|async) > 0" [value]="loader.progress$|async" height="3px"></ngb-progressbar>

	<!-- begin: Header Menu -->
	<kt-menu-horizontal *ngIf="menuHeaderDisplay"></kt-menu-horizontal>
	<!-- end: Header Menu -->

	<!-- begin:: Header Topbar -->
	<!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
	<div *ngIf="!menuHeaderDisplay"></div>
	<kt-topbar style="width: 5%;"></kt-topbar>
	<!-- end:: Header Topbar -->
</div>
<!-- end: Header -->

<!-- Email Notice -->

<mat-card class=' text-1-2x noticeAlert' *ngIf="showEmailCountNotice">
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12 noticeSen" *ngIf="!freeTrialNotice">
			Add more email to continue getting reviews.<span class="btnAlert" (click)="upgradeAc('email')">Upgrade</span>
		</div>
		<div class="col-md-12 col-sm-12 col-xs-12 noticeSen" *ngIf="freeTrialNotice">
			You have exceeded your email limit, upgrade your account.<span class="btnAlert" (click)="openPlans()">Upgrade</span>
		</div>
	</div>
</mat-card>

<!-- End email notice -->

<!-- SMS notice -->
<mat-card class=' text-1-2x noticeAlert' *ngIf="showSmsCountNotice">
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12 noticeSen" *ngIf="!freeTrialNotice">
			Add more SMS to continue getting reviews.<span class="btnAlert" (click)="upgradeAc('sms')">Upgrade</span>
		</div>
		<div class="col-md-12 col-sm-12 col-xs-12 noticeSen" *ngIf="freeTrialNotice">
			You have exceeded your sms limit, upgrade your account.<span class="btnAlert" (click)="openPlans()">Upgrade</span>
		</div>
	</div>

</mat-card>

<!-- End SMS notice -->

<!--Review Platform Error-->
<mat-card class='col-sm-12 verErrorMsg  no-margin-botton noticeCard text-1-1x noticeAlert' *ngIf="isShowHead">
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12">
			{{notificationMsg}}
			<span class="btnAlert" (click)="Reconnect()">Reconnect</span>
		</div>
	</div>
</mat-card>
<!--End Review Platform Error-->

<ng-template #contentModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-primary-title">Add new card</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
		  <span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body centered">
		<div class="row mb-2 text-1x">
			<div class="col-md-12 col-sm-6 col-xs-6 text-left">
				<span *ngIf="accountCountry.toLocaleLowerCase()=='in'">
					We accept <img src='../../../../../../assets/media/icons/visa-and-mastercard.png' width='70' />
				</span>
				<span *ngIf="accountCountry.toLocaleLowerCase()=='us'">
					We accept <img src='../../../../../../assets/media/icons/cardtypes.png' width='300' />
				</span>
			</div>
		</div>
		<div id="card-element">
			<!-- A Stripe Element will be inserted here. -->
		</div>
		<mat-error *ngIf="formSubmitted && invalidCard">
			<strong class="danger-color ">Invalid card type</strong>
		</mat-error>
	</div>
	<div class="" id="card-errors" role="alert"></div>
	<div class='flexClass nowrap modal-body centered'>
		<mat-checkbox name='invite_cust_terms' [(ngModel)]="terms"></mat-checkbox>
		&nbsp;<label for='invite_cust_terms'>I have read the <a href="https://grabyourreviews.com/terms-of-service/" target="_blank">Terms & services</a>.</label>
	</div>
	<mat-error *ngIf="formSubmitted && !terms" style="margin: -2% 4%;">
		<strong class="danger-color ">You must agree to the terms & conditions.</strong>
	</mat-error>
	<div class="text-right modal-body centered">
		<button class='btn primary-back-color text-color-light red margin-lr-5 cursor-pointer ' [disabled]="!terms" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}" (click)='submitForm()'>Submit</button>
	</div>
  </ng-template>
