import { Component, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ConfrimAlertComponent } from '../confrim-alert/confrim-alert.component';

@Component({
  selector: 'kt-private-feedback',
  templateUrl: './private-feedback.component.html',
  styleUrls: ['./private-feedback.component.scss']
})
export class PrivateFeedbackComponent implements OnInit {

  constructor(public http: HttpClient,
    public modalService: BsModalService,
    public pageService: PageConfigService,
    public cdr: ChangeDetectorRef,
    public router: Router,
    public dialog: MatDialog,) {
  }

  key: any;
  profileId: any;
  reviewId;
  templateName;
  modalRef: BsModalRef;
  comment
  replyForm: UntypedFormGroup;
  fromSubmitted = false;
  loading: any = false;
  isFeedback
  negativeFeedbackArr: any = [];
  FeedId: any;
  collectionSize = 0;
  pageSize = 10;
  page = 1;
  searchInput = '';

  ngOnInit() {
    if (localStorage.getItem("isExpired") == "true") {
      this.router.navigate(['/general/billing']);
      return
    }
    this.key = localStorage.getItem(environment.authTokenKey)
    this.profileId = JSON.parse(localStorage.getItem("ProfileId"))
    this.negativeFeedback();

    this.replyForm = new UntypedFormGroup({
      reply: new UntypedFormControl("", Validators.required)
    });

    this.pageService.getProgileId().subscribe(message => {
      if (message.id) {
        this.profileId = JSON.parse(localStorage.getItem('ProfileId'))
        if (this.router.url.includes('private-feedback')) {
          this.negativeFeedback()
        }
      }
    })
  }

  reviewRespons = ["Thank you for your kind words.",
    "Awesome review!",
    "Thanks for the review",
    "Sorry ! for your inconvinence",
    "We will take care of it!",
    "Great feedback!",
  ]

  negativeFeedback() {
    this.pageService.showLoader();
    this.negativeFeedbackArr = [];
    this.http.get(environment.apiPath + 'GetNegativeFeedback?key=' + this.key + '&ProfileId=' + this.profileId + '&Page=' + this.page + '&Pagesize=' + this.pageSize).subscribe((res: any) => {

      if (res.data.length > 0) {
        this.isFeedback = false;
        this.collectionSize = res.total
        this.negativeFeedbackArr = res.data;
        this.FeedId = res.data[0].id;
        this.pageService.hideLoader();
      } else {
        this.isFeedback = true
        this.pageService.hideLoader();
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.isFeedback = true;
        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  demo() {
    this.collectionSize = 1;
  }

  searchReview(e) {
    this.page = e;
    this.negativeFeedback();
    // this.page++
    // this.negativeFeedback
  }

  searchAllReview(e) {
    this.pageSize = e;
    this.negativeFeedback();
  }

  openModal(template: TemplateRef<any>, data, type) {
    this.reviewId = data.id;
    // this.comment = data.reply;
    this.templateName = template;

    let modelData;

    if (type == 'showDetails') {
      modelData = data
    } else {
      modelData = {
        name: data.name,
        review: data.feedBack,
      }
    }


    this.modalRef = this.modalService.show(template, {
      initialState: modelData
    });
  }

  setThanksMsg(msg) {
    this.comment = msg;
  }

  sReply() {
    this.fromSubmitted = true
    if (this.replyForm.invalid) {
      return;
    }

    this.loading = true
    let data = {
      key: this.key,
      id: this.reviewId,
      comment: this.comment
    }

    this.http.post(environment.apiPath + 'ReplyNegetiveFeedback', data).subscribe((res: any) => {
      if (res.success) {
        this.loading = false;
        this.comment = '';
        this.replyForm.reset()
        this.modalRef.hide();
      } else {
        this.pageService.showError("Error", res.errorMsg)
      }
      this.negativeFeedback();
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  searchFeedbackByName() {
    this.pageService.showLoader();
    this.negativeFeedbackArr = [];
    this.http.get(environment.apiPath + 'GetNegativeFeedback?key=' + this.key + '&ProfileId=' + this.profileId + '&Page=' + this.page + '&Pagesize=' + this.pageSize + '&name=' + this.searchInput).subscribe((res: any) => {

      if (res.data.length > 0) {
        this.isFeedback = false;
        this.collectionSize = res.total
        this.negativeFeedbackArr = res.data;
        this.pageService.hideLoader();
      } else {
        this.pageService.showError('Oops!!', 'No feedback found')
        this.pageService.hideLoader();
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }

  clearSearch(e) {
    if (e.target.value.length == 0) {
      this.negativeFeedback();
    }
  }


  // added unnati
  MoveReview(target) {
    var scope = this;
    let confirmDialog = scope.dialog.open(ConfrimAlertComponent, {
      width: '700px',
      panelClass: 'editModal',
      disableClose: true,
      data: {
        message: "Do you want to convert this feedback to a review ?",
        buttonText: 'Yes',
        source: 'hasShowOnWidget'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result.flag) {
        scope.MoveReviewToPlat(target)
      }
    })
  }

  MovePlat: boolean = false;
  MoveReviewToPlat(targetid) {
    var scope = this;
    scope.MovePlat = true;
    var datatosend = {
      Key: scope.key,
      id: targetid
    }
    
    scope.http.post(environment.apiPath + 'ConvertFeedbacktoReview', datatosend)
      .subscribe((res) => {
        scope.MovePlat = false;
        if (res['success']) {
          scope.pageService.showSuccess('sucess', 'Moved Review to platform.');

         // 2nd popup added by unnati 
          let confirmDialog = scope.dialog.open(ConfrimAlertComponent, {
            width: '700px',
            panelClass: 'editModal',
            disableClose: true,
            data: {
              message: "Awesome! Review has sucessfully moved to My Review.",
              buttonText: 'OK',
              source: 'addusersucess'
            }
          })
        //automatically close popup in 5sec added by unnati
          confirmDialog.afterOpened().subscribe(_ => {
            setTimeout(() => {
              confirmDialog.close();
            }, 5000)
          })
          confirmDialog.afterClosed().subscribe(result => {
          })
          this.negativeFeedback();
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  // MoveReviewCongrate(target) {
  //   var scope = this;
  //   let confirmDialog = scope.dialog.open(ConfrimAlertComponent, {
  //     width: '700px',
  //     panelClass: 'editModal',
  //     disableClose: true,
  //     data: {
  //       message: "Yup! now feedback has been converted to review you can see in the my review.",
  //       buttonText: 'OK',
  //       source: 'hasShowOnWidget'
  //     }
  //   });
  //   confirmDialog.afterClosed().subscribe(result => {
  //     if (result.flag) {
  //       scope.MoveReviewToPlat(target)
  //     }
  //   })
  // }

 
}