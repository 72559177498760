<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
		<!--begin::Content-->
		<div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
			<div class="kt-grid__item text-center">
				<a href="javascript:;" class="kt-login__logo" *ngIf='loaded && brandLogo.length==0 && brandName.length==0'>
					<img src="./assets/media/logos/logo.svg" width="150" alt="">
				</a>
				<div *ngIf='brandLogo.length>0 || brandName.length >0'>
					<a href="javascript:;" class="kt-login__logo" *ngIf='loaded && brandLogo.length>0'>
						<img [src]="brandLogo" style="width: auto;
						max-height: 100px" [alt]="brandName">
					</a>
					<h2 *ngIf='brandLogo.length==0'>{{brandName}}</h2>
				</div>

			</div>

			<router-outlet></router-outlet>
		</div>
		<!--end::Content-->

		<!--begin::Aside-->
		<div *ngIf='!hideAside' class=" kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside no-padding">

			<!-- <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
				<div class="kt-grid__item kt-grid__item--middle">
					<h3 class="kt-login__title ">Welcome to {{systemName}}!</h3>
				</div>
			</div>
			<div class="kt-grid__item">
				<div class="kt-login__info">
					
					<div class="kt-login__menu">
						<a href="javascript:;" class="kt-link">Privacy</a>
						<a href="javascript:;" class="kt-link">Legal</a>
						<a href="javascript:;" class="kt-link">Contact</a>
					</div>
					<div class="kt-login__copyright">
						© {{today|date:'yyyy'}} {{systemName}}
					</div>
				</div>
			</div> -->
			<div class='col-md-12 col-sm-12  col-xs-12 slideParent' style="background : linear-gradient(121deg, rgba(72, 90, 184, 1) 0%, rgba(118, 28, 179, 1) 44.3%, rgba(46, 49, 108, 1) 100%);" >
				<img class='slide-gif' src='../../../../assets/media/images/Login dashboard PNG-05.png'>
				<!-- <ngb-carousel>
					<ng-template ngbSlide>
						<img src='../../../../assets/media/images/stream-reviews-to-your-website.png' width="100%" alt="Random third slide">
						
					</ng-template>
					<ng-template ngbSlide>
						<img src='../../../../assets/media/images/person-standing-pointing-reviews.png' width="100%" alt="Random second slide">
						
					</ng-template>
					
				</ngb-carousel> -->
			</div>

		</div>
		<!--begin::Aside-->


	</div>
</div>
