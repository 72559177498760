<div class="col-12">
	<div class="row padding-15 text-center">
		<div class="col-12 text-1-2x text-bold">
			{{ headerTitle }}
			<i style="float: right;" class="fa fa-times cursor-pointer" (click)="closeModal(undefined)"></i>
		</div>
	</div>
	<div class="col-12">
		<mat-divider></mat-divider>
	</div>
	<div class="row text-center pt-3" *ngIf="source == 'email' || source == 'sms'">
		<div class="col-12 ">
			<mat-radio-button class="example-margin" [checked]="oneTimeCheckBox" [disabled]="navData.isDisableOption"
				(change)="changeCheckBox('onetime')">One time</mat-radio-button>
			<mat-radio-button class="example-margin" [checked]="recurringCheckBox" [disabled]="navData.isDisableOption"
				(change)="changeCheckBox('recurring')">Recurring</mat-radio-button>
			<div style="font-size: 12px;" class="mt-2" *ngIf="isRecurring">*Disable recurring to use this feature</div>
		</div>
	</div>
	<div class="row padding-15" *ngIf="source == 'email'">
		<div class="col-12 m-2 text-center">
			<!-- How many? -->
			<div class="text-center flexClass mt-2">
				<div class="
						minusButton
						roundedBox-radius
						iconBtn
						primary-back-color
						margin-lr-5
						cursor-pointer
					" (click)="decreaseQty()">
					<i class="fas fa-minus"></i>
				</div>
				<div class="quanitityClass">
					{{ qty }}
				</div>
				<div class="
						plusButton
						roundedBox-radius
						iconBtn
						primary-back-color
						margin-lr-5
						cursor-pointer
					" (click)="increaseQty()">
					<i class="fa fa-plus"></i>
				</div>
			</div>
			<div class="text-center mt-2">
				<!-- <span *ngIf="currency == 'usd' && qty > 0" style="font-size: 18px;"> -->
				<span *ngIf="qty > 0" style="font-size: 18px;">${{ emailPrice * qty / 100}}<span *ngIf="recurringCheckBox"
						style="font-size: 14px;">/month</span></span>

				<span *ngIf="currency == 'inr' && qty > 0" style="font-size: 18px;">₹{{ 400 * qty / 100}}<span
						*ngIf="recurringCheckBox" style="font-size: 14px;">/month</span></span>
			</div>

			<!-- <div class="text-center flexClass mt-3" style="font-size: 12px">
				*Your registered credit card will be charged immediately <span *ngIf="recurringCheckBox"
					style="padding-left: 0.5%;"> and added to your future invoices</span>.
			</div> -->
		</div>

		<!-- added new payment method and total of email  by unnati-->
		<div class='row padding-15' style="margin-top: -5% !important;">
			<div class='col-md-12 col-sm-12 col-xs-12 mt-3 text-center'>
				<div class="mb-1 text-1-2x text-bold text-center">
					Payment Method
				</div>
				<div>
					<mat-radio-group [(ngModel)]='cardRadioButton' (ngModelChange)='radioButtonChange()'>
						<mat-radio-button value="1" *ngIf='!hideexistingRadio'>Use existing card</mat-radio-button>
						<mat-radio-button value="2">Add new card</mat-radio-button>
					</mat-radio-group>
					<div *ngIf='cardRadioButton=="1"'>
						<div class="row">
							<div class="col-12">
								<b>Card:</b> {{cardNumber}}
							</div>
							<div class="col-12">
								<b>Expiry Date:</b> {{expMonth}}/{{expYear}}
							</div>
						</div>
					</div>
					<div *ngIf='cardRadioButton=="2"'>
						<div class="row mb-2 text-1x">
							<div class="col-md-12 col-sm-6 col-xs-6">
								We accept <img src='../../../../../../assets/media/icons/visa-and-mastercard.png'
									width='100' />
							</div>
						</div>
						<div class="mb-3 text-1x">
							New payment method
						</div>
						<div class="row" style="margin: 0% 0.5%;">
							<div class="col-12">
								<label class="f-b">Card Number</label>
								<div id="cardNumber"></div>
							</div>
							<div class="col-6">
								<label class="f-b">Expiry Date</label>
								<div id="cardExpiryDate"></div>
							</div>
							<div class="col-6">
								<label class="f-b">CVC</label>
								<div id="cardCvv"></div>
							</div>
						</div>

						<mat-error *ngIf="formSubmitted && invalidCard">
							<strong class="danger-color ">Invalid card type</strong>
						</mat-error>
					</div>
					<div id="card-errors" role="alert"></div>
				</div>
				<br />
				<div class="notes roundedBox-radius padding-10 notes-back-color" *ngIf='cardRadioButton=="2"'>
					<b>NOTE: </b>This will be your default source for all future payments.
				</div>
			</div>
			<div class="col-12 m-2 text-center">
				<button class="btn primary-back-color margin-lr-5 cursor-pointer" [ngClass]="{
					'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable':
						loading
				}" [disabled]="buttonDisable" (click)="submit()">
					Purchase
				</button>
			</div>
		</div>
	</div>
	<div class="row padding-15" *ngIf="source == 'sms'">
		<div class="col-12 m-2 text-center">
			<!-- How many? -->
			<div class="text-center flexClass mt-2">
				<div class="
						minusButton
						roundedBox-radius
						iconBtn
						primary-back-color
						margin-lr-5
						cursor-pointer
					" (click)="decreaseQty()">
					<i class="fas fa-minus"></i>
				</div>
				<div class="quanitityClass">
					{{ qty }}
				</div>
				<div class="
						plusButton
						roundedBox-radius
						iconBtn
						primary-back-color
						margin-lr-5
						cursor-pointer
					" (click)="increaseQty()">
					<i class="fa fa-plus"></i>
				</div>
			</div>
			<div class="text-center mt-2">
				<!-- <span *ngIf="currency == 'usd' && qty > 0" style="font-size: 18px;"> -->
				<span *ngIf="qty > 0" style="font-size: 18px;">${{ smsPrice * qty / 100}}<span *ngIf="recurringCheckBox"
						style="font-size: 14px;">/month</span></span>
				<span *ngIf="currency == 'inr' && qty > 0" style="font-size: 18px;">₹{{ 50 * qty / 100}}<span
						*ngIf="recurringCheckBox" style="font-size: 14px;">/month</span></span>
			</div>
			<!-- <div class="text-center flexClass mt-3" style="font-size: 12px">
				*Your registered credit card will be charged immediately <span *ngIf="recurringCheckBox"
					style="padding-left: 0.5%;"> and added to your future invoices</span>.
			</div> -->
		</div>

		<div class='row padding-15' style="margin-top: -5% !important;">
			<div class='col-md-12 col-sm-12 col-xs-12 mt-3 text-center'>
				<div class="mb-1 text-1-2x text-bold text-center">
					Payment Method
				</div>
				<div>
					<mat-radio-group [(ngModel)]='cardRadioButton' (ngModelChange)='radioButtonChange()'>
						<mat-radio-button value="1" *ngIf='!hideexistingRadio'>Use existing card</mat-radio-button>
						<mat-radio-button value="2">Add new card</mat-radio-button>
					</mat-radio-group>
					<div *ngIf='cardRadioButton=="1"'>
						<div class="row">
							<div class="col-12">
								<b>Card:</b> {{cardNumber}}
							</div>
							<div class="col-12">
								<b>Expiry Date:</b> {{expMonth}}/{{expYear}}
							</div>
						</div>
					</div>
					<div *ngIf='cardRadioButton=="2"'>
						<div class="row mb-2 text-1x">
							<div class="col-md-12 col-sm-6 col-xs-6">
								We accept <img src='../../../../../../assets/media/icons/visa-and-mastercard.png'
									width='100' />
							</div>
						</div>
						<div class="mb-3 text-1x">
							New payment method
						</div>
						<div class="row" style="margin: 0% 0.5%;">
							<div class="col-12">
								<label class="f-b">Card Number</label>
								<div id="cardNumber"></div>
							</div>
							<div class="col-6">
								<label class="f-b">Expiry Date</label>
								<div id="cardExpiryDate"></div>
							</div>
							<div class="col-6">
								<label class="f-b">CVC</label>
								<div id="cardCvv"></div>
							</div>
						</div>

						<mat-error *ngIf="formSubmitted && invalidCard">
							<strong class="danger-color ">Invalid card type</strong>
						</mat-error>
					</div>

					<div id="card-errors" role="alert"></div>

				</div>
				<br />
				<div class="notes roundedBox-radius padding-10 notes-back-color" *ngIf='cardRadioButton=="2"'>
					<b>NOTE: </b>This will be your default source for all future payments.
				</div>
			</div>

			<div class="col-12 m-2 text-center">
				<button class="btn primary-back-color margin-lr-5 cursor-pointer" [ngClass]="{
				'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable':
					loading
			}" [disabled]="buttonDisable" (click)="submit()">
					Purchase
				</button>
			</div>
		</div>
	</div>
	<div class="row padding-15" *ngIf="source == 'profile'">
		<div class="col-12 m-2 text-center text-1-5x">
			Add more company profiles
		</div>
		<div class="col-12 m-2 text-center" *ngIf="isAgency == true">
			10-24 seats $20/m per seat<br />
			25-49 seats $18/m per seat<br />
			50-99 seats $16/m per seat<br />
			100-249 seats $13/m per seat<br />
			250-499 seats $10/m per seat<br />
			500 and above seats $8/m per seat<br />
		</div>
		<div class="col-12 m-2 text-center" *ngIf="currentPlan == 'large'">
			$27/mo for each additional business/location
		</div>
		<div class="col-12 m-2 text-center">
			How many?
			<div class="text-center flexClass mt-2">
				<div class="
						minusButton
						roundedBox-radius
						iconBtn
						primary-back-color
						margin-lr-5
						cursor-pointer
					" (click)="decreaseQty()">
					<i class="fas fa-minus"></i>
				</div>
				<div class="quanitityClass">
					{{ qty }}
				</div>
				<div class="
						plusButton
						roundedBox-radius
						iconBtn
						primary-back-color
						margin-lr-5
						cursor-pointer
					" (click)="increaseQty()">
					<i class="fa fa-plus"></i>
				</div>
			</div>
		</div>
		<div class="col-12 text-center flexClass mt-3" style="font-size: 12px;color: #ff006a;" *ngIf="isMinProfileMsg">
			*You reached minimum profile limit.
		</div>
		<div class="col-12 text-center flexClass mt-3" style="font-size: 12px;color: #ff006a;" *ngIf="isMaxProfileMsg">
			*You reached maximum used profiles.
		</div>
		<div class="col-12 m-2 text-center">
			<button class="btn primary-back-color margin-lr-5 cursor-pointer" [ngClass]="{
					'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable':
						loading
				}" [disabled]="isMinProfileMsg" (click)="submit()">
				Update
			</button>
		</div>
	</div>
	<div class="row padding-15" *ngIf="source == 'users'">
		<div class="col-12 m-2 text-center text-1-5x">Add more users</div>
		<div class="col-12 m-2 text-center">$10 per user / month</div>
		<div class="col-12 m-2 text-center">
			How many?
			<div class="text-center flexClass mt-2">
				<div class="
						minusButton
						roundedBox-radius
						iconBtn
						primary-back-color
						margin-lr-5
						cursor-pointer
					" (click)="decreaseQty()">
					<i class="fas fa-minus"></i>
				</div>
				<div class="quanitityClass">
					{{ qty }}
				</div>
				<div class="
						plusButton
						roundedBox-radius
						iconBtn
						primary-back-color
						margin-lr-5
						cursor-pointer
					" (click)="increaseQty()">
					<i class="fa fa-plus"></i>
				</div>
			</div>
		</div>
		<div class="col-12 m-2 text-center">
			<button class="btn primary-back-color margin-lr-5 cursor-pointer" [ngClass]="{
					'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable':
						loading
				}" [disabled]="qty > 0 ? false : true" (click)="submit()">
				Purchase
			</button>
		</div>
	</div>
</div>