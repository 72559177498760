import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Inject, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'kt-thankyou-modal',
  templateUrl: './thankyou-modal.component.html',
  styleUrls: ['./thankyou-modal.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ThankyouModalComponent implements OnInit {
  msg='';
  constructor(@Inject(MAT_DIALOG_DATA) public navData: any,
            public cdr:ChangeDetectorRef,
            public sanitizer:DomSanitizer) { }

  ngOnInit() {
    var scope = this;
    scope.msg=scope.navData.message;
  }
  sanitizeHtml(html) {
    var scope = this;

    return scope.sanitizer.bypassSecurityTrustHtml(html)
  }
}
