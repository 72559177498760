<div class="kt-login__body">
	<!--begin::Signin-->

	<div class="kt-login__form">
		<div class="kt-login__title">
			<h3> Welcome Sumo-ling!</h3>
		</div>
		<!--begin::Form-->
		<!-- <div *ngIf="planName" class="planName" hidden>Your current selected plan is {{planName}}</div> -->
		<form class="kt-form" [formGroup]="appsumoFormGroup" autocomplete="off">
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" formControlName="Email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" autocomplete="off" readonly />
					<mat-error *ngIf="isSubmit && appsumoFormGroup.controls.Email.errors">
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.Email.errors.required">Email is required</strong>
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.Email.errors.email">Required Valid Email </strong>
					</mat-error>
				</mat-form-field>

			</div>

			<div class="form-group">
				<mat-form-field>
					<mat-label>First Name</mat-label>
					<input matInput formControlName="FirstName" type="text" placeholder="First Name" autocomplete="off" />
					<mat-error *ngIf="isSubmit && appsumoFormGroup.controls.FirstName.errors">
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.FirstName.errors.required"> First Name is required</strong>
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.FirstName.errors.maxlength"> First Name is required</strong>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="form-group">
				<mat-form-field>
					<mat-label>Last Name</mat-label>
					<input matInput formControlName="LastName" type="text" placeholder="Last Name" autocomplete="off" />
					<mat-error *ngIf="isSubmit && appsumoFormGroup.controls.LastName.errors">
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.LastName.errors.required"> Last Name is required </strong>
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.LastName.errors.maxlength"> First Name is required</strong>
					</mat-error>
				</mat-form-field>

			</div>

			<div class="form-group">
				<mat-form-field>
					<mat-label>Country</mat-label>
					<mat-select formControlName="Country">
						<mat-option *ngFor='let item of countryList' [value]='item.code'>{{item.name}}</mat-option>
					</mat-select>
					<mat-error *ngIf="isSubmit && appsumoFormGroup.controls.Country.errors">
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.Country.errors.required">Country is required </strong>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="form-group">
				<mat-form-field>
					<mat-label>TimeZone</mat-label>
					<mat-select formControlName="Timezone" [(ngModel)]="timezone">
						<mat-option *ngFor='let item of timeZoneList' [value]='item.id'>{{item.text}}</mat-option>
					</mat-select>
					<mat-error *ngIf="isSubmit && appsumoFormGroup.controls.Timezone.errors">
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.Timezone.errors.required"> Timezone is required</strong>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" formControlName="Password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" autocomplete="off" />
					<mat-error *ngIf="isSubmit && appsumoFormGroup.controls.Password.errors">
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.Password.errors.required">
							Password is required
						</strong>
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.Password.errors.minlength">
							Password must be at least 4 characters
						</strong>
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.Password.errors.maxlength">
							Password must be at least 100 characters
						</strong>
					</mat-error>
				</mat-form-field>

			</div>

			<div class="form-group">
				<mat-form-field>
					<mat-label>Conform Password</mat-label>
					<input matInput type="password" formControlName="ConfirmPassword" placeholder="Confirm Password" autocomplete="off" />
					<mat-error *ngIf="isSubmit && appsumoFormGroup.controls.ConfirmPassword.errors">
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.ConfirmPassword.errors.required">
							Confirm password is required </strong>
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.ConfirmPassword.errors.minlength">
							Confirm password must be at least 4 characters
						</strong>
						<strong *ngIf="isSubmit && appsumoFormGroup.controls.ConfirmPassword.errors.maxlength">
							Confirm password must be at least 100 characters
						</strong>
					</mat-error>

					<mat-error *ngIf="isSubmit && isConformedPassword">
						<strong>
							Password and Confirm Password must be match.
						</strong>
					</mat-error>
				</mat-form-field>

			</div>
			<!--begin::Action-->
			<div class="kt-login__actions text-center">
				<button id="kt_login_signin_submit" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}" (click)="registerAppSumoUser()" class="btn btn-primary">
					Get Started</button>
			</div>
			<!--end::Action-->
		</form>

		<!--end::Form-->
	</div>
	<!--end::Signin-->
</div>
