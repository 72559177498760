import { LayoutConfigService } from '../../../../core/_base/layout/services/layout-config.service';
import { PageConfigService } from '../../../../core/_base/layout/services/page-config.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import {  HttpClient } from '@angular/common/http';

function capterratracking(){
 
    var capterra_vkey = 'a0b66e2a35906d6f4d65c61858843e66',
    capterra_vid = '2148788',
    capterra_prefix = (('https:' == document.location.protocol)
      ? 'https://ct.capterra.com' : 'http://ct.capterra.com');
    var ct = document.createElement('script');
    ct.type = 'text/javascript';
    ct.async = true;
    ct.src = capterra_prefix + '/capterra_tracker.js?vid='
      + capterra_vid + '&vkey=' + capterra_vkey;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ct, s);
  
}

@Component({
  selector: 'kt-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  constructor(public http:HttpClient,public router:Router,public pageService:PageConfigService,
    public layoutConfigService:LayoutConfigService) { 
   
      
    
  }

  ngOnInit() {
    capterratracking();
    var scope = this;
    
    scope.layoutConfigService.setConfig({self: {layout: 'blank'}});
    // scope.layoutConfigService.setConfig({aside:{self:{display: false}},self: {layout: 'blank'}}, true);
    scope.pageService.showHideAsideAuth();
    scope.callVerify()
  }
  callVerify(){
    var scope = this;
    let key=scope.router['browserUrlTree'].queryParams.key;
    scope.pageService.showLoader();
    scope.http.post(environment.apiPath+'Verification',{key:key})
    .subscribe((res) => {
      
      scope.pageService.hideLoader();
      if(res['success']){
        document.querySelector('.verifyIcon')['src']='../../../../assets/media/custom-icons/positive.png'
        document.querySelector('.verifyMainText').innerHTML='Congratulations'
        document.querySelector('.verifySubText').innerHTML='Your account has been verified.'

        localStorage.setItem('IsVerify',JSON.stringify(true))
        
        this.pageService.setIsVerify()
      }else{
        document.querySelector('.verifyIcon')['src']='../../../../assets/media/custom-icons/negative.png'
        document.querySelector('.verifyMainText').innerHTML='Oops!'
        document.querySelector('.verifySubText').innerHTML='Something went wrong. Click on resend verification code after sign in.'
        localStorage.setItem('IsVerify',JSON.stringify(false))
        this.pageService.setIsVerify()
      }
    },(err) =>{
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
        this.pageService.showError('Oops!',err.error.errorMsg);
      }
    })
  }
  submit(){
    this.router.navigate(['/auth/login']);
  }
}
