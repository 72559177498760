import { CustomFavicon } from './../../../../../../favicon.config';
import { MatDialog } from '@angular/material';
import { DataTableService } from './datatable.service';
import { environment } from './../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
// RxJS
import { Subject, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';// Object-Path
import * as objectPath from 'object-path';
// Lodash
import { merge } from 'lodash';
//Toaster
import { ToastrService, ToastRef } from 'ngx-toastr';

import * as CryptoJS from 'crypto-js'
import { ChooseProfileComponent } from '../../../../views/pages/choose-profile/choose-profile.component';
import { AddProfileComponent } from '../../../../views/pages/add-profile/add-profile.component';
import { NgxFaviconService } from 'ngx-favicon';
import { Title } from '@angular/platform-browser';
declare function loadLiveChat(): any;

// import myJSON from "../../../../../assets/js/permission.json"
import { data } from 'jquery';

@Injectable()
export class PageConfigService {
	// Public properties
	public CustomFavicon: typeof CustomFavicon = CustomFavicon;
	onConfigUpdated$: Subject<any>;
	pageConfig: any;
	profileSubj = new Subject<any>();
	routerSub = new Subject<any>();
	profileListSub = new Subject<any>();
	httpOptions: any;
	profileId = 0;
	authComponentObj = {};
	appComponentObj = {};
	profileListFlaguSbj = new Subject<any>();
	checkReviewSub = new Subject<any>();
	timeZoneList = [
		{ "id": "Dateline Standard Time", "text": "(UTC-12:00) International Date Line West" },
		{ "id": "UTC-11", "text": "(UTC-11:00) Coordinated Universal Time-11" },
		{ "id": "Aleutian Standard Time", "text": "(UTC-10:00) Aleutian Islands" },
		{ "id": "Hawaiian Standard Time", "text": "(UTC-10:00) Hawaii" },
		{ "id": "Marquesas Standard Time", "text": "(UTC-09:30) Marquesas Islands" },
		{ "id": "Alaskan Standard Time", "text": "(UTC-09:00) Alaska" },
		{ "id": "UTC-09", "text": "(UTC-09:00) Coordinated Universal Time-09" },
		{ "id": "Pacific Standard Time (Mexico)", "text": "(UTC-08:00) Baja California" },
		{ "id": "UTC-08", "text": "(UTC-08:00) Coordinated Universal Time-08" },
		{ "id": "Pacific Standard Time", "text": "(UTC-08:00) Pacific Time (US & Canada)" },
		{ "id": "US Mountain Standard Time", "text": "(UTC-07:00) Arizona" },
		{ "id": "Mountain Standard Time (Mexico)", "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan" },
		{ "id": "Mountain Standard Time", "text": "(UTC-07:00) Mountain Time (US & Canada)" },
		{ "id": "Central America Standard Time", "text": "(UTC-06:00) Central America" },
		{ "id": "Central Standard Time", "text": "(UTC-06:00) Central Time (US & Canada)" },
		{ "id": "Easter Island Standard Time", "text": "(UTC-06:00) Easter Island" },
		{ "id": "Central Standard Time (Mexico)", "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey" },
		{ "id": "Canada Central Standard Time", "text": "(UTC-06:00) Saskatchewan" },
		{ "id": "SA Pacific Standard Time", "text": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco" },
		{ "id": "Eastern Standard Time (Mexico)", "text": "(UTC-05:00) Chetumal" },
		{ "id": "Eastern Standard Time", "text": "(UTC-05:00) Eastern Time (US & Canada)" },
		{ "id": "Haiti Standard Time", "text": "(UTC-05:00) Haiti" },
		{ "id": "Cuba Standard Time", "text": "(UTC-05:00) Havana" },
		{ "id": "US Eastern Standard Time", "text": "(UTC-05:00) Indiana (East)" },
		{ "id": "Turks and Caicos Standard Time", "text": "(UTC-05:00) Turks and Caicos" },
		{ "id": "Paraguay Standard Time", "text": "(UTC-04:00) Asuncion" },
		{ "id": "Atlantic Standard Time", "text": "(UTC-04:00) Atlantic Time (Canada)" },
		{ "id": "Venezuela Standard Time", "text": "(UTC-04:00) Caracas" },
		{ "id": "Central Brazilian Standard Time", "text": "(UTC-04:00) Cuiaba" },
		{ "id": "SA Western Standard Time", "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan" },
		{ "id": "Pacific SA Standard Time", "text": "(UTC-04:00) Santiago" },
		{ "id": "Newfoundland Standard Time", "text": "(UTC-03:30) Newfoundland" },
		{ "id": "Tocantins Standard Time", "text": "(UTC-03:00) Araguaina" },
		{ "id": "E. South America Standard Time", "text": "(UTC-03:00) Brasilia" },
		{ "id": "SA Eastern Standard Time", "text": "(UTC-03:00) Cayenne, Fortaleza" },
		{ "id": "Argentina Standard Time", "text": "(UTC-03:00) City of Buenos Aires" },
		{ "id": "Greenland Standard Time", "text": "(UTC-03:00) Greenland" },
		{ "id": "Montevideo Standard Time", "text": "(UTC-03:00) Montevideo" },
		{ "id": "Magallanes Standard Time", "text": "(UTC-03:00) Punta Arenas" },
		{ "id": "Saint Pierre Standard Time", "text": "(UTC-03:00) Saint Pierre and Miquelon" },
		{ "id": "Bahia Standard Time", "text": "(UTC-03:00) Salvador" },
		{ "id": "UTC-02", "text": "(UTC-02:00) Coordinated Universal Time-02" },
		{ "id": "Mid-Atlantic Standard Time", "text": "(UTC-02:00) Mid-Atlantic - Old" },
		{ "id": "Azores Standard Time", "text": "(UTC-01:00) Azores" },
		{ "id": "Cabo Verde Standard Time", "text": "(UTC-01:00) Cabo Verde Is." },
		{ "id": "Coordinated Universal Time", "text": "(UTC) Coordinated Universal Time" },
		{ "id": "GMT Standard Time", "text": "(UTC+00:00) Dublin, Edinburgh, Lisbon, London" },
		{ "id": "Greenwich Standard Time", "text": "(UTC+00:00) Monrovia, Reykjavik" },
		{ "id": "Sao Tome Standard Time", "text": "(UTC+00:00) Sao Tome" },
		{ "id": "Morocco Standard Time", "text": "(UTC+01:00) Casablanca" },
		{ "id": "W. Europe Standard Time", "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
		{ "id": "Central Europe Standard Time", "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
		{ "id": "Romance Standard Time", "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
		{ "id": "Central European Standard Time", "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
		{ "id": "W. Central Africa Standard Time", "text": "(UTC+01:00) West Central Africa" },
		{ "id": "Jordan Standard Time", "text": "(UTC+02:00) Amman" },
		{ "id": "GTB Standard Time", "text": "(UTC+02:00) Athens, Bucharest" },
		{ "id": "Middle East Standard Time", "text": "(UTC+02:00) Beirut" },
		{ "id": "Egypt Standard Time", "text": "(UTC+02:00) Cairo" },
		{ "id": "E. Europe Standard Time", "text": "(UTC+02:00) Chisinau" },
		{ "id": "Syria Standard Time", "text": "(UTC+02:00) Damascus" },
		{ "id": "West Bank Gaza Standard Time", "text": "(UTC+02:00) Gaza, Hebron" },
		{ "id": "South Africa Standard Time", "text": "(UTC+02:00) Harare, Pretoria" },
		{ "id": "FLE Standard Time", "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
		{ "id": "Jerusalem Standard Time", "text": "(UTC+02:00) Jerusalem" },
		{ "id": "Russia TZ 1 Standard Time", "text": "(UTC+02:00) Kaliningrad" },
		{ "id": "Sudan Standard Time", "text": "(UTC+02:00) Khartoum" },
		{ "id": "Libya Standard Time", "text": "(UTC+02:00) Tripoli" },
		{ "id": "Namibia Standard Time", "text": "(UTC+02:00) Windhoek" },
		{ "id": "Arabic Standard Time", "text": "(UTC+03:00) Baghdad" },
		{ "id": "Turkey Standard Time", "text": "(UTC+03:00) Istanbul" },
		{ "id": "Arab Standard Time", "text": "(UTC+03:00) Kuwait, Riyadh" },
		{ "id": "Belarus Standard Time", "text": "(UTC+03:00) Minsk" },
		{ "id": "Russia TZ 2 Standard Time", "text": "(UTC+03:00) Moscow, St. Petersburg" },
		{ "id": "E. Africa Standard Time", "text": "(UTC+03:00) Nairobi" },
		{ "id": "Iran Standard Time", "text": "(UTC+03:30) Tehran" },
		{ "id": "Arabian Standard Time", "text": "(UTC+04:00) Abu Dhabi, Muscat" },
		{ "id": "Astrakhan Standard Time", "text": "(UTC+04:00) Astrakhan, Ulyanovsk" },
		{ "id": "Azerbaijan Standard Time", "text": "(UTC+04:00) Baku" },
		{ "id": "Russia TZ 3 Standard Time", "text": "(UTC+04:00) Izhevsk, Samara" },
		{ "id": "Mauritius Standard Time", "text": "(UTC+04:00) Port Louis" },
		{ "id": "Saratov Standard Time", "text": "(UTC+04:00) Saratov" },
		{ "id": "Georgian Standard Time", "text": "(UTC+04:00) Tbilisi" },
		{ "id": "Volgograd Standard Time", "text": "(UTC+04:00) Volgograd" },
		{ "id": "Caucasus Standard Time", "text": "(UTC+04:00) Yerevan" },
		{ "id": "Afghanistan Standard Time", "text": "(UTC+04:30) Kabul" },
		{ "id": "West Asia Standard Time", "text": "(UTC+05:00) Ashgabat, Tashkent" },
		{ "id": "Russia TZ 4 Standard Time", "text": "(UTC+05:00) Ekaterinburg" },
		{ "id": "Pakistan Standard Time", "text": "(UTC+05:00) Islamabad, Karachi" },
		{ "id": "Qyzylorda Standard Time", "text": "(UTC+05:00) Qyzylorda" },
		{ "id": "India Standard Time", "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
		{ "id": "Sri Lanka Standard Time", "text": "(UTC+05:30) Sri Jayawardenepura" },
		{ "id": "Nepal Standard Time", "text": "(UTC+05:45) Kathmandu" },
		{ "id": "Central Asia Standard Time", "text": "(UTC+06:00) Astana" },
		{ "id": "Bangladesh Standard Time", "text": "(UTC+06:00) Dhaka" },
		{ "id": "Omsk Standard Time", "text": "(UTC+06:00) Omsk" },
		{ "id": "Myanmar Standard Time", "text": "(UTC+06:30) Yangon (Rangoon)" },
		{ "id": "SE Asia Standard Time", "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta" },
		{ "id": "Altai Standard Time", "text": "(UTC+07:00) Barnaul, Gorno-Altaysk" },
		{ "id": "W. Mongolia Standard Time", "text": "(UTC+07:00) Hovd" },
		{ "id": "Russia TZ 6 Standard Time", "text": "(UTC+07:00) Krasnoyarsk" },
		{ "id": "Novosibirsk Standard Time", "text": "(UTC+07:00) Novosibirsk" },
		{ "id": "Tomsk Standard Time", "text": "(UTC+07:00) Tomsk" },
		{ "id": "China Standard Time", "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
		{ "id": "Russia TZ 7 Standard Time", "text": "(UTC+08:00) Irkutsk" },
		{ "id": "Malay Peninsula Standard Time", "text": "(UTC+08:00) Kuala Lumpur, Singapore" },
		{ "id": "W. Australia Standard Time", "text": "(UTC+08:00) Perth" },
		{ "id": "Taipei Standard Time", "text": "(UTC+08:00) Taipei" },
		{ "id": "Ulaanbaatar Standard Time", "text": "(UTC+08:00) Ulaanbaatar" },
		{ "id": "Aus Central W. Standard Time", "text": "(UTC+08:45) Eucla" },
		{ "id": "Transbaikal Standard Time", "text": "(UTC+09:00) Chita" },
		{ "id": "Tokyo Standard Time", "text": "(UTC+09:00) Osaka, Sapporo, Tokyo" },
		{ "id": "North Korea Standard Time", "text": "(UTC+09:00) Pyongyang" },
		{ "id": "Korea Standard Time", "text": "(UTC+09:00) Seoul" },
		{ "id": "Russia TZ 8 Standard Time", "text": "(UTC+09:00) Yakutsk" },
		{ "id": "Cen. Australia Standard Time", "text": "(UTC+09:30) Adelaide" },
		{ "id": "AUS Central Standard Time", "text": "(UTC+09:30) Darwin" },
		{ "id": "E. Australia Standard Time", "text": "(UTC+10:00) Brisbane" },
		{ "id": "AUS Eastern Standard Time", "text": "(UTC+10:00) Canberra, Melbourne, Sydney" },
		{ "id": "West Pacific Standard Time", "text": "(UTC+10:00) Guam, Port Moresby" },
		{ "id": "Tasmania Standard Time", "text": "(UTC+10:00) Hobart" },
		{ "id": "Russia TZ 9 Standard Time", "text": "(UTC+10:00) Vladivostok" },
		{ "id": "Lord Howe Standard Time", "text": "(UTC+10:30) Lord Howe Island" },
		{ "id": "Bougainville Standard Time", "text": "(UTC+11:00) Bougainville Island" },
		{ "id": "Russia TZ 10 Standard Time", "text": "(UTC+11:00) Chokurdakh" },
		{ "id": "Magadan Standard Time", "text": "(UTC+11:00) Magadan" },
		{ "id": "Norfolk Standard Time", "text": "(UTC+11:00) Norfolk Island" },
		{ "id": "Sakhalin Standard Time", "text": "(UTC+11:00) Sakhalin" },
		{ "id": "Central Pacific Standard Time", "text": "(UTC+11:00) Solomon Is., New Caledonia" },
		{ "id": "Russia TZ 11 Standard Time", "text": "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky" },
		{ "id": "New Zealand Standard Time", "text": "(UTC+12:00) Auckland, Wellington" },
		{ "id": "UTC+12", "text": "(UTC+12:00) Coordinated Universal Time+12" },
		{ "id": "Fiji Standard Time", "text": "(UTC+12:00) Fiji" },
		{ "id": "Kamchatka Standard Time", "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old" },
		{ "id": "Chatham Islands Standard Time", "text": "(UTC+12:45) Chatham Islands" },
		{ "id": "UTC+13", "text": "(UTC+13:00) Coordinated Universal Time+13" },
		{ "id": "Tonga Standard Time", "text": "(UTC+13:00) Nuku'alofa" },
		{ "id": "Samoa Standard Time", "text": "(UTC+13:00) Samoa" },
		{ "id": "Line Islands Standard Time", "text": "(UTC+14:00) Kiritimati Island" }
	]
	brandData: any;

	identity = new BehaviorSubject('');


	/**
	 * Service Constructor
	 *
	 * @param router: Router
	 */
	constructor(private router: Router,
		public http: HttpClient,
		public dataService: DataTableService,
		public toaster: ToastrService,
		public dialogRef: MatDialog,
		private ngxFaviconService: NgxFaviconService<CustomFavicon>,
		public titleService: Title,
		// private gtmService: GoogleTagManagerService,
	) {


		// register on config changed event and set default config

		this.onConfigUpdated$ = new Subject();
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			})
		};

		this.identity.next('initial_value');
	}

	setDefaultFavicon(): void {
		this.ngxFaviconService.setDefaultFavicon();
	}

	setCustomFavicon(faviconName: CustomFavicon): void {
		this.ngxFaviconService.setCustomFavicon(faviconName);
	}

	setFaviconByUrl(faviconUrl: string): void {
		if (faviconUrl == 'grabFav') {

			this.ngxFaviconService.setFaviconByUrl('../../../../../assets/media/logos/GYR-favicon-32w.png');
		} else {
			this.ngxFaviconService.setFaviconByUrl(faviconUrl);

		}
	}

	setLiveChat() {
		// Show on live only
		loadLiveChat();
	}


	getTimeZone() {
		return this.timeZoneList
	}

	//Sudcriber for profile dropdown
	getProfileDetails() {
		return new Promise((resolve, reject) => {
			let key = localStorage.getItem(environment.authTokenKey)
			let profileId = JSON.parse(localStorage.getItem('ProfileId'))
			let GRProfileDetail: any = JSON.parse(localStorage.getItem("GRProfileDetail"));
			if (GRProfileDetail) {
				if (GRProfileDetail['success']) {
					localStorage.setItem('platformError', GRProfileDetail.platformError);
					localStorage.setItem('TimeZone', JSON.stringify(GRProfileDetail['data'].timeZone))
					localStorage.setItem('profileCountry', GRProfileDetail.data.countryCode);
					localStorage.setItem('personName', GRProfileDetail.data.personName);
					localStorage.setItem('phoneNumber', GRProfileDetail.data.phoneNumber);
					resolve(GRProfileDetail['data'])
				} else {
					resolve(GRProfileDetail['errorMsg']);
					this.sessionExpired();
				}
			}
			else {
				let data={
					Key:key,
					ProfileId:profileId
				};
				this.http.post(environment.apiPath + 'GetProfileDetails',data)
					.subscribe((res: any) => {
						localStorage.setItem("GRProfileDetail", JSON.stringify(res));
						if (res['success']) {
							localStorage.setItem('platformError', res.platformError);
							localStorage.setItem('TimeZone', JSON.stringify(res['data'].timeZone))
							localStorage.setItem('profileCountry', res.data.countryCode);
							localStorage.setItem('personName', res.data.personName);
							localStorage.setItem('phoneNumber', res.data.phoneNumber);
							resolve(res['data'])
						} else {
							resolve(res['errorMsg']);
							this.sessionExpired();
						}
					})
			}

		})
	}

	refreshProfileDetails() {
		return new Promise((resolve, reject) => {
		let key = localStorage.getItem(environment.authTokenKey)
		let profileId = JSON.parse(localStorage.getItem('ProfileId'))
		localStorage.removeItem("GRProfileDetail");
		let data={
			Key:key,
			ProfileId:profileId
		};
	    this.http.post(environment.apiPath + 'GetProfileDetails',data)
			.subscribe((res: any) => {
				localStorage.setItem("GRProfileDetail", JSON.stringify(res));
				if (res['success']) {
					localStorage.setItem('platformError', res.platformError);
					localStorage.setItem('TimeZone', JSON.stringify(res['data'].timeZone))
					localStorage.setItem('profileCountry', res.data.countryCode);
					localStorage.setItem('personName', res.data.personName);
					localStorage.setItem('phoneNumber', res.data.phoneNumber);
					resolve(res['data'])
				} else {
					this.sessionExpired();
					resolve(res['data'])
				}
			})
		})
	}

	setProfileListSubscriber() {
		let key = localStorage.getItem(environment.authTokenKey)
		let GRProfileList: any = JSON.parse(localStorage.getItem("GRProfileList"));
		if (GRProfileList) {
			if (GRProfileList.success) {
				this.dataService.setData('ProfileList', GRProfileList.data)
				this.profileListSub.next(true);
			}
		}
		else {
			let data={
				Key:key
			};
			this.http.post(environment.apiPath + 'GetProfileList',data)
				.subscribe((res) => {
					localStorage.setItem("GRProfileList", JSON.stringify(res));
					if (res['success']) {
						this.dataService.setData('ProfileList', res['data'])
						this.profileListSub.next(true);
					}
				})
		}

	}

	// checkReviewStatus() {
	// 	return new Promise((resolve, reject) => {
	// 		var scope = this;
	// 		let key = localStorage.getItem(environment.authTokenKey);
	// 		let profileId = JSON.parse(localStorage.getItem('ProfileId'));

	// 		this.http.get(environment.apiPath + 'CheckReviewStatus?Key=' + key + '&ProfileId=' + profileId)
	// 			.subscribe((res: any) => {
	// 				if (res['success']) {
	// 					localStorage.setItem("profileCountry", res['country'])
	// 					if (res['status'].toLocaleLowerCase() == 'pending' ||
	// 						res['status'].toLocaleLowerCase() == 'maintenance' ||
	// 						res['status'].toLocaleLowerCase() == 'invalid_url') {
	// 						scope.checkReviewSub.next({ isFetch: true, status: res['status'], target: res['source'] });
	// 					} else if (res['status'].length == 0 ||
	// 						res['status'].toLocaleLowerCase() == 'complete') {
	// 						scope.checkReviewSub.next({ isFetch: false });
	// 					}
	// 					resolve(res);
	// 				} else {
	// 					scope.checkReviewSub.next({ isFetch: false, });
	// 					reject(res['errorMsg'])
	// 				}
	// 			})
	// 	})
	// }

	getFetchReviewFlag() {
		var scope = this;
		return scope.checkReviewSub.asObservable();
	}

	getProfileListObserver(): Observable<any> {
		return this.profileListSub.asObservable();
	}

	setProfileId(pr) {
		this.profileId = pr;
		localStorage.setItem('ProfileId', JSON.stringify(pr))
		this.profileSubj.next({ id: pr });
	}

	clearProfileId() {
		this.profileSubj.next();
	}

	getProgileId(): Observable<any> {
		return this.profileSubj.asObservable();
	}

	getProfileIdFromService() {
		return this.profileId
	}

	//Sudcriber for account verification
	setIsVerify() {
		let local = JSON.parse(localStorage.getItem('IsVerify'))
		this.profileSubj.next({ isVerify: local });
	}

	getIsVerify(): Observable<any> {
		return this.profileSubj.asObservable();
	}

	//Sudcriber for router change to set default profile
	setRouterFlag() {
		this.routerSub.next({ routerFlag: true });
	}

	getRouterFlag(): Observable<any> {
		return this.routerSub.asObservable();
	}

	/**
	 * Get current page config based on route
	 */
	getCurrentPageConfig(path?: string): any {
		let configPath = this.cleanUrl(this.router.url);
		if (path) {
			configPath += '.' + path;
		}
		// get page config by path
		return objectPath.get(this.pageConfig, configPath);
	}

	/**
	 * Set existing config with a new value
	 * @param value: any
	 * @param sav: boolean?
	 */
	setConfig(value: any, save?: boolean): void {
		this.pageConfig = merge(this.pageConfig, value);
		if (save) {
			// not implemented
		}
		// fire off an event that all subscribers will listen
		this.onConfigUpdated$.next(this.pageConfig);
	}

	/**
	 * Load confgis
	 *
	 * @param config: any
	 */
	loadConfigs(config: any) {
		this.pageConfig = config;
		this.onConfigUpdated$.next(this.pageConfig);
	}

	/**
	 * Remove unnecessary params from URL
	 * @param url
	 */
	cleanUrl(url: string): string {
		// remove first route (demo name) from url router
		if (new RegExp(/^\/demo/).test(url)) {
			const urls = url.split('/');
			urls.splice(0, 2);
			url = urls.join('/');
		}

		if (url.charAt(0) == '/') {
			url = url.substr(1);
		}

		let finalUrl = url.replace(/\//g, '.');
		if (finalUrl.indexOf('?') !== -1) {
			finalUrl = finalUrl.substring(0, finalUrl.indexOf('?'));
		}

		return finalUrl;
	}

	showLoader() {
		
		document.querySelector('.loaderChild')['style'].display = 'block';
	}

	hideLoader() {
		
		document.querySelector('.loaderChild')['style'].display = 'none';
	}

	showSsoLoader() {
		document.querySelector('.sso-loader')['style'].display = 'block';
		document.querySelector('.loaderChild')['style'].display = 'none';
	}

	hideSsoLoader() {
		document.querySelector('.sso-loader')['style'].display = 'none';
	}

	dec2hex(str) { // .toString(16) only works up to 2^53
		var dec = str.toString().split(''), sum = [], hex = [], i, s
		while (dec.length) {
			s = 1 * dec.shift()
			for (i = 0; s || i < sum.length; i++) {
				s += (sum[i] || 0) * 10
				sum[i] = s % 16
				s = (s - sum[i]) / 16
			}
		}
		while (sum.length) {
			hex.push(sum.pop().toString(16))
		}
		return hex.join('')
	}

	//convert hexadecimal to decimal
	hex2dec(s) {
		var i, j, digits = [0], carry;
		for (i = 0; i < s.length; i += 1) {
			carry = parseInt(s.charAt(i), 16);
			for (j = 0; j < digits.length; j += 1) {
				digits[j] = digits[j] * 16 + carry;
				carry = digits[j] / 10 | 0;
				digits[j] %= 10;
			}
			while (carry > 0) {
				digits.push(carry % 10);
				carry = carry / 10 | 0;
			}
		}
		return digits.reverse().join('');
	}

	//Toaster messages
	showSuccess(title, message) {
		this.toaster.success(message, title);
	}

	showError(title, message) {
		this.toaster.error(message, title)
	}

	showWarning(title, message) {
		this.toaster.warning(message, title)
	}

	showInfo(title, message) {
		this.toaster.info(message, title);
	}

	showSimple(title, message) {
		this.toaster.show(message, title)
	}

	//session Expired function
	sessionExpired() {
		if(localStorage.getItem("GRDetail"))
		{

		}
		else 
		{
			localStorage.clear();
			location.reload();
		}
		
	}

	//Auth component desgn change
	setauthComponentObj(target) {
		this.authComponentObj = target;
	}

	showHideAsideAuth() {
		this.authComponentObj['asideHideFunction']();
	}

	//choose Profilealert from app.component file
	setAppComponentObj(target) {
		this.appComponentObj = target;
	}

	callChooseProfile() {
		if (this.router.url.includes('agency')) {
			//hide
			this.setShowListFlag(false);
		} else {
			//show
			this.setShowListFlag(true);
		}
	}

	setTitle(str) {
		var scope = this;
		scope.titleService.setTitle(str)
	}

	createNewProfilePopup() {
		var scope = this;
		let profileId = JSON.parse(localStorage.getItem('ProfileId'))
		scope.openNewProfileDialog()
	}

	openNewProfileDialog(): void {
		var scope = this;
		let view = JSON.parse(localStorage.getItem('view'));
		let viewPermission = JSON.parse(scope.decrypt(view))

		let profilePlatformPermission = viewPermission['ProfileCount'];
		let profileLIst = JSON.parse(JSON.stringify(scope.dataService.getData('ProfileList')))
		if (profilePlatformPermission)
			if (profileLIst.length < profilePlatformPermission) {
			} else {
				if (profilePlatformPermission == 0) {
				} else {
					scope.showError('Oops!!!', 'Upgrade account to add company')
				}
			}
	}

	newProfilePop() {
		var scope = this
		let email = localStorage.getItem('adminemail');
		let profileid = JSON.parse(localStorage.getItem("ProfileId"))
		const dialogRef = scope.dialogRef.open(AddProfileComponent, {
			width: '600px',
			panelClass: 'AddProfile-nopadding',
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				scope.setProfileId(result['profileId'])
				scope.router.navigate(['/review-platforms'])
			}
		});
	}

	setShowListFlag(target) {
		this.profileListFlaguSbj.next({ showFlag: target })
	}

	getShowListFlag() {
		return this.profileListFlaguSbj.asObservable();
	}

	//Emcryption decription
	encrypt(msg) {
		var scope = this;
		var salt = CryptoJS.lib.WordArray.random(environment.saltSize / 8);
		var key = CryptoJS.PBKDF2(environment.chipherKey, salt, {
			keySize: environment.keySize / 32,
			iterations: environment.iterations
		});
		var iv = CryptoJS.lib.WordArray.random(environment.ivSize / 8);
		var encrypted = CryptoJS.AES.encrypt(msg, key, {
			iv: iv,
			padding: CryptoJS.pad.Pkcs7,
			mode: CryptoJS.mode.CBC
		});

		var encryptedHex = scope.base64ToHex(encrypted.toString());
		var temp = salt + iv + encryptedHex;
		var base64result = scope.hexToBase64(temp);

		return base64result;
	}

	decrypt(transitmessage) {
		var scope = this;
		var hexResult = scope.base64ToHex(transitmessage)
		var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
		var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
		var encrypted = scope.hexToBase64(hexResult.substring(96));

		var key = CryptoJS.PBKDF2(environment.chipherKey, salt, {
			keySize: environment.keySize / 32,
			iterations: environment.iterations
		});

		var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
			iv: iv,
			padding: CryptoJS.pad.Pkcs7,
			mode: CryptoJS.mode.CBC
		})

		return decrypted.toString(CryptoJS.enc.Utf8);
	}

	hexToBase64(str) {
		return btoa(String.fromCharCode.apply(null,
			str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
		);
	}

	base64ToHex(str) {
		if (str) {
			for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
				var tmp = bin.charCodeAt(i).toString(16);
				if (tmp.length === 1) tmp = "0" + tmp;
				hex[hex.length] = tmp;
			}
			return hex.join("");
		}
		else {
			return "";
		}

	}

	//User permissions
	getPermissions() {

		return new Promise((resolve, reject) => {
			var scope = this;
			let accessKey = localStorage.getItem(environment.authTokenKey);
			if (scope.router.url.includes('verification')) {
				return;
			}

			let GRDetail = localStorage.getItem('GRDetail');
			if (GRDetail == null || GRDetail == undefined) {
				let data={Key:accessKey};
				scope.http.post(environment.apiV2Path + 'GetDetails',data)
					.subscribe((res: any) => {
						if (res['success']) {
							localStorage.setItem('GRDetail', JSON.stringify(res['data']));
							localStorage.setItem('IsVerify', JSON.stringify(res['data']['isVerified']));
							localStorage.setItem('user-access', res['data']['userAccess']);
							localStorage.setItem('plan', JSON.stringify(res['data']['plan']));
							// localStorage.setItem('IsReseller', JSON.stringify(false));
							localStorage.setItem('IsReseller', JSON.stringify(res['data']['isReseller']));
							localStorage.setItem('isAgency', JSON.stringify(res['data']['isAgency']));
							localStorage.setItem('showBranding', JSON.stringify(res['data']['showBranding']));
							localStorage.setItem('current-plan', JSON.stringify(res['data']['plan']));
							localStorage.setItem('view', JSON.stringify(res['data'].view));
							localStorage.setItem('isExpired', JSON.stringify(res['data']['isExpired']));
							localStorage.setItem('account', JSON.stringify(res['data']['account']));
							localStorage.setItem('currentCountry', res['data']['country']);
							localStorage.setItem("isSMSAllowed", res['data']['isSMSAllowed']);
							// localStorage.setItem('brand-info',res.data.brandInfo)
							// localStorage.setItem("hasCreatedProfile", res.data.hasCreatedProfile);
							localStorage.setItem("hasOnboarding", res.data.hasOnboarding)
							// only set branding when it's GYR
							let data = {
								brandLogo: res['data']['brandInfo']['logoUrl'],
								brandName: res['data']['brandInfo']['brandName'],
								domainName: res['data']['brandInfo']['domainName']
							}
							localStorage.setItem('brand-info', JSON.stringify(data))
							// if(localStorage.getItem("brand-info") == null){
							// let data = {
							// 	brandLogo: res['data']['brandInfo']['logoUrl'],
							// 	brandName: res['data']['brandInfo']['brandName']
							// }
							//
							// }
							// plan
							if (!res['data']['isExpired']) {
								let permissionView: any = this.decrypt(res.data.view);
								let js = JSON.parse(permissionView)
								localStorage.setItem('isApiAccess', res.data.isApiAccess);
							}
							if (res['data']['showBranding'] == true) {
								//Zendesk live chat

								scope.setLiveChat();
								scope.titleService.setTitle('Grab Your Reviews')
								scope.setFaviconByUrl('grabFav')
							} else {
								// if (localStorage.getItem('brand-info') != null) {
								// 	scope.brandData = JSON.parse(localStorage.getItem('brand-info'))
								// 	if (scope.brandData['brandName'].length > 0) {
								// 		scope.titleService.setTitle(scope.brandData['brandName'])
								// 	}
								// 	if (scope.brandData['brandLogo'].length > 0) {
								// 		// scope.setFaviconByUrl(scope.brandData['brandLogo'])
								// 	}

								// }
							}
							resolve(res['data'])
							scope.setIsVerify();
							// isVerified: true
							// userAccess: "{'LandingPage':true,'Widgets':true,'Invites':true,'Reporting':true}"
							// plan: "Free"
							// isReseller: true
						} else {
							reject(res['errorMsg']);
							if (res['errorMsg'] == environment.sessionTimeOutMsg
								|| res['errorMsg'] == environment.userNotFound) {
								setTimeout(() => {
									scope.sessionExpired();
								}, environment.sessionTimeOut)
							}
						}
					})
			}
			else 
			{
				resolve(JSON.parse(GRDetail))
			}

		})

	}

	googleAuthError(newId: string) {
		this.identity.next(newId)
	}

	setBranding() {
		this.identity.next("branding done")
	}

	checkBilling(v: string) {
		this.identity.next("update subscription");
	}

	setReseller(value) {
		this.identity.next(value)
	}

	redirectDirect(value) {
		this.identity.next(value);
	}

	createProfileJson(profileId) {
		let key = localStorage.getItem(environment.authTokenKey);
		return this.http.get(environment.apiPath + 'CreateProfileData?key=' + key + '&EncodeProfileId=' + profileId).pipe();
	}

	deleteProfileJson(profileId) {
		let key = localStorage.getItem(environment.authTokenKey);
		return this.http.get(environment.apiPath + 'DeleteProfileData?key=' + key + '&EncodeProfileId=' + profileId).pipe();
	}

	paymentDone(e) {
		this.identity.next(e);
	}
}
