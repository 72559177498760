import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { environment } from './../../../../environments/environment';
import { DataTableService } from './../../../core/_base/layout/services/datatable.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfrimAlertComponent } from '../confrim-alert/confrim-alert.component';

@Component({
  selector: 'kt-add-user-model',
  templateUrl: './add-user-model.component.html',
  styleUrls: ['./add-user-model.component.scss']
})
export class AddUserModelComponent implements OnInit {
  addUserForm: UntypedFormGroup
  fromSubmitted: boolean = false;
  profileList: any;
  loading: boolean = false;
  source = '';
  userDataModel = {
    FirstName: '',
    LastName: '',
    Email: '',
    HasApi: false,
    HasSSO: true,
    canLogin: true,
    LandingPage: true,
    Widgets: true,
    Invites: true,
    Reporting: true,
    ProfileId: [],
  };
  userPermissions = {};
  profileErr: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public navData: any,
    public dialogRef: MatDialogRef<AddUserModelComponent>,
    public dialog: MatDialog,
    public formBuiledr: UntypedFormBuilder,
    public dataService: DataTableService,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    public pageService: PageConfigService,
    public cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    var scope = this;


    // registering controls for validations
    scope.addUserForm = scope.formBuiledr.group({
      FirstName: ['', Validators.required],
      LastName: [''],
      Email: ['', [Validators.required, Validators.email]],
      HasApi: [false],
      HasSSO: [false],
      canLogin: [true],
      LandingPage: [true],
      Widgets: [true],
      Invites: [true],
      Reporting: [false],
      ProfileId: ['', Validators.required],
    })
    //nav data
    scope.source = scope.navData.source;
    if (scope.source == 'edit') {
      // id: 10035
      // firstName: "Divya"
      // lastName: "Chavda"
      // email: "divya123@yopmail.com"
      // canLogin: false
      scope.userDataModel.FirstName = scope.navData.userdata['firstName'];
      scope.userDataModel.LastName = scope.navData.userdata['lastName'];
      scope.userDataModel.Email = scope.navData.userdata['email'];
      scope.userDataModel.canLogin = scope.navData.userdata['canLogin'];
      scope.userDataModel.HasApi = scope.navData.userdata['hasApi'];
      scope.userDataModel.HasSSO = scope.navData.userdata['hasSSO'];

      scope.navData.userdata['profileList'].forEach(element => {
        scope.userDataModel.ProfileId.push(element.profileId)
      });
      if (scope.navData.userdata['permissions'] != undefined) {

        scope.userPermissions = JSON.parse(JSON.stringify(scope.navData.userdata['permissions']))

        scope.userDataModel.LandingPage = scope.userPermissions['LandingPage'];
        scope.userDataModel.Widgets = scope.userPermissions['Widgets'];
        scope.userDataModel.Invites = scope.userPermissions['Invites'];
        scope.userDataModel.Reporting = scope.userPermissions['Reporting'];
      }
    }

    scope.cdr.markForCheck();
    //Get profile list from data service
    scope.profileList = scope.dataService.getData('ProfileList');
  }
  //call when form submitted
  onSubmit() {
    var scope = this;

    scope.fromSubmitted = true;
    scope.profileErr = false;
    if (scope.addUserForm.invalid) {
      return;
    }
    if (scope.userDataModel.ProfileId.length > 0) {
      if (scope.userDataModel.ProfileId.length == 1 && scope.userDataModel.ProfileId[0] == '00') {

        scope.profileErr = true;
        return;
      }
    }
    if (scope.source == 'new') {
      scope.callAddApi()
    } else if (scope.source == 'edit') {
      scope.callUpdateApi()
    }
  }
  //call adduser api for insert user into databse
  email : any;
  callAddApi() {
    var scope = this;
    let finalData = {};
    finalData['FirstName'] = scope.userDataModel.FirstName;;
    finalData['LastName'] = scope.userDataModel.LastName;
    finalData['Email'] = scope.userDataModel.Email;
    finalData['HasApi'] = scope.userDataModel.HasApi;
    finalData['CanLogin'] = scope.userDataModel.canLogin;
    finalData['HasSSO'] = scope.userDataModel.HasSSO;
    finalData['ProfileId'] = scope.userDataModel.ProfileId.filter((aln) => { if (aln != '00') { return aln } });
    finalData['Key'] = localStorage.getItem(environment.authTokenKey);
    let permissions = {
      LandingPage: scope.userDataModel.LandingPage,
      Widgets: scope.userDataModel.Widgets,
      Invites: scope.userDataModel.Invites,
      Reporting: scope.userDataModel.Reporting,
    }
    finalData['Permission'] = JSON.stringify(permissions)

     scope.email = scope.userDataModel.Email
    scope.loading = true;
    scope.http.post(environment.apiPath + 'CreateUserAccount', finalData)
      .subscribe((res) => {
        scope.loading = false;
        
        if (res['success']) {
          scope.pageService.showSuccess('Added', 'User added.')
          scope.addUserForm.reset();
          scope.closeModal({ flag: true })
 
          ///popup added unnati

          let confirmDialog = scope.dialog.open(ConfrimAlertComponent, {
            width: '700px',
            panelClass: 'editModal',
            // position: { top: '10px' },
            disableClose: true,
            data: {
              message: 'Set password email has been sent to the ' + scope.email,
              buttonText: 'Ok',
              source: 'addusersucess'
            }
          });
          confirmDialog.afterClosed().subscribe(result => {
          })

        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg']);
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  //callUpdateApi
  callUpdateApi() {
    var scope = this;
    let finalData = {};
    finalData['FirstName'] = scope.userDataModel.FirstName;;
    finalData['LastName'] = scope.userDataModel.LastName;
    finalData['Email'] = scope.userDataModel.Email;
    finalData['HasApi'] = scope.userDataModel.HasApi;
    finalData['CanLogin'] = scope.userDataModel.canLogin;
    finalData['HasSSO'] = scope.userDataModel.HasSSO;
    finalData['ProfileId'] = scope.userDataModel.ProfileId.filter((aln) => { if (aln != '00') { return aln } });
    finalData['Key'] = localStorage.getItem(environment.authTokenKey);
    finalData['Id'] = scope.navData.userdata['id'];
    let permissions = {
      LandingPage: scope.userDataModel.LandingPage,
      Widgets: scope.userDataModel.Widgets,
      Invites: scope.userDataModel.Invites,
      Reporting: scope.userDataModel.Reporting,
    }
    finalData['Permission'] = JSON.stringify(permissions)

    scope.loading = true;
    scope.http.post(environment.apiPath + 'UpdateUserAccount', finalData)
      .subscribe((res) => {

        scope.loading = false;;
        if (res['success']) {
          scope.pageService.showSuccess('Updated', 'User details updated.')
          scope.addUserForm.reset();
          scope.closeModal({ flag: true })
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg']);
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      },(err) =>{
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.showError('Oops!',err.error.errorMsg);
        }
      })
  }
  //close popup
  closeModal(data) {
    if (data != undefined) {
      this.dialogRef.close(data)
    } else {
      this.dialogRef.close()
    }
  }
  changeCheckbox() { }
}
