<div class="kt-subheader-search">
	<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
		<h3 class="kt-subheader-search__title">
			Recent Bookings
			<span class="kt-subheader-search__desc">Onling Bookings Management</span>
		</h3>
		<form class="kt-form">
			<div class="kt-grid kt-grid--desktop kt-grid--ver-desktop">
				<div class="kt-grid__item kt-grid__item--middle">
					<div class="row kt-margin-r-10">
						<div class="col-lg-6">
							<div class="kt-input-icon kt-input-icon--pill kt-input-icon--right">
								<input type="text" class="form-control form-control-pill" placeholder="Booking Number">
								<span class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="la la-puzzle-piece"></i></span></span>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="kt-input-icon kt-input-icon--pill kt-input-icon--right">
								<input type="text" class="form-control form-control-pill" placeholder="From">
								<span class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="la la-calendar-check-o"></i></span></span>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="kt-input-icon kt-input-icon--pill kt-input-icon--right">
								<input type="text" class="form-control form-control-pill" placeholder="To">
								<span class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="la la-calendar-check-o"></i></span></span>
							</div>
						</div>
					</div>
				</div>
				<div class="kt-grid__item kt-grid__item--middle">
					<button type="button" class="btn btn-pill btn-upper btn-bold btn-font-sm kt-subheader-search__submit-btn">Search Bookings</button>
					<a href="javascript:;" class="kt-subheader-search__link kt-link">Advance Search</a>
				</div>
			</div>
		</form>
	</div>
</div>
