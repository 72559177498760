import { environment } from './../../../../environments/environment';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';


import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kt-bad-modal',
  templateUrl: './bad-modal.component.html',
  styleUrls: ['./bad-modal.component.scss']
})
export class BadModalComponent implements OnInit {
  badForm: UntypedFormGroup
  layoutData = {};
  isShowPublicMsg: any;
  reviewSiteList = [];
  companyName = '';
  dataModel = {
    message: '',
    name: '',
    email: '',

  };
  PrivateNamePlaceholderMsg;
  PrivateEmailPlaceholderMsg;
  PrivateWhatToDoPlaceHolderMsg;
  PrivatePhoneNumberPlaceholderMsg;
  Rating = 0;
  submittedFlag: boolean = false;
  loading: boolean = false;
  HasNameRequired;
  HasEmailRequired;
  HasPhoneNumberRequired;
  PrivatePostAReviewTextMsg;
  PrivatePostClickhereText;
  HasNameEnable;
  HasEmailEnable;
  HasPhoneNumberEnable;
  PrivateSubmitButtonTextMsg;
  PrivateCloseButtonTextMsg;

  constructor(public dialogRef: MatDialogRef<BadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public navData: any,
    public cdr: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public http: HttpClient,
    public pageService: PageConfigService,
    private sanitizer: DomSanitizer) { }
  ngOnInit() {
    var scope = this;

    scope.badForm = scope.formBuilder.group({
      message: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required, [Validators.email]],
      // phoneNumber : ['', Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')],
      //    PhoneNumber: ['',Validators.pattern("^[0-9]{8,10}$")],
      phoneNumber: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(11),
          Validators.pattern("^[0-9]{8,12}$"),
        ])],
      Rating: ['']
    })
    scope.companyName = scope.navData['CompanyName']
    scope.layoutData = scope.navData['ReviewPageSettings'];
    scope.PrivateNamePlaceholderMsg = scope.layoutData['PrivateNamePlaceholderMsg'];
    scope.PrivateEmailPlaceholderMsg = scope.layoutData['PrivateEmailPlaceholderMsg'];
    scope.PrivateWhatToDoPlaceHolderMsg = scope.layoutData['PrivateWhatToDoPlaceHolderMsg'];
    scope.PrivatePhoneNumberPlaceholderMsg = scope.layoutData['PrivatePhoneNumberPlaceholderMsg'];
    scope.HasNameRequired = scope.layoutData['HasNameRequired'];
    scope.HasEmailRequired = scope.layoutData['HasEmailRequired'];
    scope.HasPhoneNumberRequired = scope.layoutData['HasPhoneNumberRequired'];
    scope.PrivatePostAReviewTextMsg = scope.layoutData['PrivatePostAReviewTextMsg'];
    scope.PrivatePostClickhereText = scope.layoutData['PrivatePostClickhereText'];

    scope.PrivateCloseButtonTextMsg = scope.layoutData['PrivateCloseButtonTextMsg'];
    scope.PrivateSubmitButtonTextMsg = scope.layoutData['PrivateSubmitButtonTextMsg'];
    scope.HasNameEnable = scope.layoutData['HasNameEnable'];
    scope.HasEmailEnable = scope.layoutData['HasEmailEnable'];
    scope.HasPhoneNumberEnable = scope.layoutData['HasPhoneNumberEnable'];

    scope.dataModel.email = scope.navData['email'];
    scope.dataModel.name = scope.navData['name'];
    scope.isShowPublicMsg = scope.navData['hidePublicReviewMessage']
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.badForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  sanitizeHtml(html) {
    var scope = this;

    return scope.sanitizer.bypassSecurityTrustHtml(html)
  }
  closeDialog() {
    this.dialogRef.close({ isOpenGood: true })
  }

  isShowError: boolean = false;

  isMobileNumberError: boolean = false;

  submit() {
    var scope = this;
    scope.submittedFlag = true;
    if (scope.HasEmailRequired == true || scope.HasPhoneNumberRequired == true) {
      if (this.badForm.controls.message.value != 'name' && this.badForm.controls.message.value != '' &&
        this.badForm.controls.phoneNumber.value == '' && this.badForm.controls.email.value == '') {
        this.isShowError = true;
        return
      }
    }

    // if (this.badForm.controls.phoneNumber.value.length == 12) {
    //   let tempVal = this.badForm.controls.phoneNumber.value;
    //   let result = tempVal.toString().startsWith('0');
    //   if (!result) {
    //     this.isMobileNumberError = true;
    //     this.isShowError = false;
    //     this.badForm.controls.phoneNumber.setErrors({ 'incorrect': true });
    //     return
    //   } else {
    //     this.isMobileNumberError = false;
    //     this.badForm.controls.phoneNumber.valid
    //   }
    // } else if (this.badForm.controls.phoneNumber.value.length < 12) {
    //   let tempVal = this.badForm.controls.phoneNumber.value;
    //   let result = tempVal.toString().startsWith('0');
    //   if (!result) {
    //     this.isMobileNumberError = false;
    //     this.badForm.controls.phoneNumber.valid
    //   } else {
    //     this.isMobileNumberError = true;
    //     this.isShowError = false;
    //     this.badForm.controls.phoneNumber.setErrors({ 'incorrect': true });
    //     return
    //   }
    // }
    
    if (this.badForm.controls.message.value == '') {
      if (this.badForm.controls.message.invalid) {
        return;
      }
    }
    if (scope.HasEmailRequired == true && scope.HasEmailEnable == true) {
      if (this.badForm.controls.email.invalid) {
        return;
      }
    }
    if (scope.HasNameRequired == true && scope.HasNameEnable == true) {
      if (this.badForm.controls.name.invalid) {
        return;
      }
    }
    if (scope.HasPhoneNumberRequired == true && scope.HasPhoneNumberEnable == true) {
      if (this.badForm.controls.phoneNumber.invalid) {
        return;
      }
    }

    let data = {
      Name: scope.dataModel.name,
      Email: scope.dataModel.email,
      Message: scope.dataModel.message,
      Phone: this.badForm.controls.phoneNumber.value,
      Rating: this.badForm.controls['Rating'].value,
    };
    if (scope.navData['NId'] != undefined && scope.navData['NId'] != null && scope.navData['NId'].length > 0) {
      data['Slug'] = null;
      data['NId'] = scope.navData['NId'];
    } else {
      data['Slug'] = scope.navData['slug'];
      data['NId'] = null;
    }

    let finalData = {
      Data: scope.pageService.encrypt(JSON.stringify(data))
    }
    scope.loading = true;
    scope.http.post(environment.apiPath + 'SendPrivateFeedback', finalData)
      .subscribe((res) => {

        scope.loading = false;
        if (res['success']) {
          scope.dialogRef.close({ isSubmitted: true })
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
}
