<mat-card class=' text-1-2x text-center  noticeCard' style="background:#e7ffab !important;font-size: 1rem; 
margin: 0 !important;
box-shadow: none; padding: 10px !important;" *ngIf="isDisable">
	<div class="row">
		<div class="col-md-11 col-sm-12 col-xs-12">
			<!-- Connect review platform to start send invitation -->
			<i class="fas fa-exclamation-triangle text-1-5x danger-color" style="margin-right: 5px;"></i>
			Something went wrong connect your administrator.
			 <!-- <a [routerLink]="['/review-platforms']" routerLinkActive="router-link-active">Connect Review Platform</a> -->
		</div>
	</div>

</mat-card>
<div class='row text-center padding-30' *ngIf='pageFound==true'>
	<mat-card class='col-md-6 col-sm-12 col-xs-12 no-padding'>
		<div class='col-md-12 col-sm-12 col-xs-12 text-left'>
			<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10 text-center logoParent' *ngIf='logo!=null && logo!=undefined && logo.length>0'>
				<img class='optInLogo' [src]="logo" height="auto" style="max-width: 35%;">
			</div>
			<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10 text-center logoParent text-1-5x text-bold' *ngIf='logo==undefined || logo==null || logo.length==0'>
				{{companyName}}
			</div>

			<div class='row margin-10 '>
				<div class='col-md-4 col-sm-12 col-xs-12 margin-tb-10 logoParent text-1-2x text-bold'>
					Invite Customers
				</div>
				<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10'>
					<mat-divider></mat-divider>
				</div>
				<form [formGroup]="optInForm">
					<div class='row padding-10'>
						<div class='col-md-12 col-sm-12 col-xs-12'>
							<div class='row' *ngIf="!isShow">
								<div class='col-md-4 col-sm-6 col-xs-6'  *ngIf="isEmailFlag">
									<mat-checkbox formControlName="emailFlag" color='primary' [(ngModel)]="model.emailFlag" (ngModelChange)='changeCheckbox()'>Email</mat-checkbox>
								</div>
								<div class='col-md-4 col-sm-6 col-xs-6' *ngIf='isSMS'>
									<mat-checkbox formControlName="smsFlag" color='primary' [(ngModel)]="model.smsFlag" (ngModelChange)='changeCheckbox()'>SMS/Text</mat-checkbox>
									<!-- /Whatsapp -->
								</div>

							</div>
							<mat-error *ngIf="submittedform && emailSmsErrorFlag && !isShow">
								<strong>Choose review invite method</strong>
							</mat-error>
							<div class='row margin-tb-10'>
								<div class="col-md-12 col-sm-12 col-xs-12">
									<div>
										<mat-label class='text-bold'>First Name</mat-label>
										<input type="text" class="form-control" name="firstName" placeholder="First Name" formControlName="fname" [(ngModel)]="model.fname" />
									</div>
								</div>
							</div>
							<div class='row margin-tb-10'>
								<div class="col-md-12 col-sm-12 col-xs-12">
									<div>
										<mat-label class='text-bold'>Last Name (optional)</mat-label>
										<input type="text" class="form-control" name="lastName" placeholder="Last Name" formControlName="lname" [(ngModel)]="model.lname" />
									</div>
								</div>
							</div>
							<div class='row margin-tb-10' *ngIf="model.emailFlag && isEmailFlag">
								<div class="col-md-12 col-sm-12 col-xs-12">
									<div>
										<mat-label class='text-bold'>Email (required)</mat-label>
										<input type="text" class="form-control" name="email" formControlName="email" placeholder="Email Address" [(ngModel)]="model.email" />
									</div>
									<mat-error *ngIf="submittedform && form.email.errors">
										<strong *ngIf='submittedform && form.email.errors.required'>*Required</strong>
										<strong *ngIf='submittedform && form.email.errors.email'>*Invalid email address</strong>
									</mat-error>
								</div>
							</div>
							<div class='row margin-tb-10' *ngIf="!isShow && model.smsFlag || model.whatsAppFlag">
								<div class="col-md-12 col-sm-12 col-xs-12 form-group">
									<div>
										<mat-label class='text-bold'>Phone Number (required)</mat-label>
										<div class='row'>
											<div class='col-md-12 col-sm-12 col-xs-12'>
												<div class="input-group mb-3">
													<div class="input-group-prepend">
														<span class="input-group-text" id="basic-addon1">{{defaultCountryCode}}</span>
													</div>
													<input type="text" class="form-control" placeholder="Phone Number" formControlName="phoneNumber" [(ngModel)]="model.phoneNumber">
												</div>
											</div>
										</div>
									</div>
									<mat-error *ngIf="submittedform && form.phoneNumber.errors">
										<strong *ngIf='submittedform && form.phoneNumber.errors.required'>*Required</strong>
									</mat-error>
									<mat-error>
										<strong id="valid-msg-phone-invite" style="color:#0abb87 !important" class="hide">✓ Valid</strong>
										<strong id="error-msg-phone-invite" class="hide"></strong>
									</mat-error>
								</div>
							</div>
							
							<div class='col-md-12 col-sm-12 col-xs-12'>
								<div class='flexClass nowrap'>
									<mat-checkbox class='checkbox-white-space-no-wrap' color='primary' formControlName="certified" [(ngModel)]="model.certified">

									</mat-checkbox>
									&nbsp;I certify that all customers have opted in to receive these communications.
									I further certify that I am an authorized representative of {{companyName}} and
									I understand and accept the Email/SMS service provider's terms of service.
									<!-- /Whatsapp -->
								</div>

								<mat-error *ngIf="submittedform && form.certified.errors">
									<strong *ngIf='submittedform && form.certified.errors.required'>*Required</strong>
								</mat-error>
							</div>
							<div class='col-md-12 col-sm-12 col-xs-12 padding-lr-20 text-center margin-tb-10'>
								<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)='onsubmitForm()' [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}" [disabled]="isDisable">
									Send</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</mat-card>

</div>
