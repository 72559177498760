import { DataTableService } from './../../../core/_base/layout/services/datatable.service';
import { DataTableDataSource } from './../../partials/content/widgets/general/data-table/data-table.data-source';
// Angular
import { AfterViewInit, Component, OnInit, ChangeDetectorRef } from '@angular/core';
// Layout
import { LayoutConfigService, ToggleOptions } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'kt-brand',
	templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, AfterViewInit {
	// Public properties
	headerLogo: string;
	headerStickyLogo: string;
	isLogo: any;
	brandLogo = '';
	brandName = ''
	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-aside--minimize',
		togglerState: 'kt-aside__brand-aside-toggler--active'
	};
	loaded: boolean = false;
	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param htmlClassService: HtmlClassService
	 */
	constructor(private layoutConfigService: LayoutConfigService,
		public dataService: DataTableService,
		public htmlClassService: HtmlClassService,
		public cdr: ChangeDetectorRef,
		public router: Router,
		public http: HttpClient,
		public pageService: PageConfigService,
		public titleService: Title) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */

	ngOnInit(): void {
		var scope = this;
		this.isLogo = JSON.parse(localStorage.getItem('isLogo'));
		this.headerLogo = this.layoutConfigService.getLogo();
		this.headerStickyLogo = this.layoutConfigService.getStickyLogo();

		scope.dataService.getBrandbserver().subscribe((res) => {
			scope.loaded = true;
			if (res == true) {
				let brandData = JSON.parse(localStorage.getItem('brand-info'))//scope.dataService.getData('brand-info')
				scope.brandLogo = brandData['brandLogo'];
				scope.brandName = brandData['brandName'];
				scope.cdr.markForCheck();
			}
		})


		if (localStorage.getItem('brand-info') != null) {
			scope.loaded = true;
			let brandData = JSON.parse(localStorage.getItem('brand-info'))//scope.dataService.getData('brand-info')

			scope.brandLogo = brandData['brandLogo'];
			scope.brandName = brandData['brandName'];
			scope.cdr.markForCheck();
		} else {
			scope.loaded = true;
			var url = window.location.href
			var arr = url.split("/");
			var result = arr[0] + "//" + arr[2];
			let currentDomainUrl = result.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
			
			scope.cdr.markForCheck();			
			scope.http.get(environment.apiPath + 'GetBranding?DomainName=' + currentDomainUrl)
				.subscribe((res) => {

					scope.loaded = true;
					if (res['success']) {
						let brandData = JSON.parse(scope.pageService.decrypt(res['data']))

						let temp = {
							brandName: brandData['BrandName'],
							brandLogo: brandData['LogoUrl'],
							currentDomain: currentDomainUrl
						}
						scope.brandLogo = brandData['LogoUrl'];
						scope.brandName = brandData['BrandName'];
						scope.cdr.markForCheck()
						scope.dataService.setData('brand-info', JSON.stringify(temp))
						localStorage.setItem('IsSignUpLink', JSON.stringify(false))
						if (scope.brandName.length > 0) {
							scope.titleService.setTitle(scope.brandName)
						}
					} else {
						scope.brandLogo = '/assets/media/logos/logo.svg';
						scope.brandName = 'Grab Your Reviews';
						localStorage.setItem('IsSignUpLink', JSON.stringify(true))
						scope.titleService.setTitle('Grab Your Reviews')
						scope.pageService.setFaviconByUrl('grabFav')
					}
				}, (err) => {
					if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

					this.pageService.hideLoader();
					this.pageService.showError('Oops!', err.error.errorMsg);
					}
				})

		}

		// }

	}

	/**
	 * On after view init
	 */
	ngAfterViewInit(): void {
	}
}
