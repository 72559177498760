<!-- begin:: Brand -->
<div class="kt-aside__brand kt-grid__item" id="kt_aside_brand">
	<div class="kt-aside__brand-logo">
		<a href="javascript:;" routerLink="/" *ngIf='loaded && brandLogo.length==0 && brandName.length==0'>
			<img alt="logo" class='brandLogo' src='../../../../assets/media/logos/logo.svg' />
		</a>
		<div *ngIf='loaded && (brandLogo.length>0 || brandName.length >0)'>
			<!--  *ngIf='brandLogo.length>0' -->
			<a href="javascript:;" class="kt-login__logo" *ngIf='brandLogo.length>0'>
				<img class='brandLogo' src="{{brandLogo}}"  [alt]="brandName">
			</a>
			<h2 *ngIf='brandLogo.length==0' style="color: #fff !important;">{{brandName}}</h2>
		</div>
	</div>
	<div class="kt-aside__brand-tools" *ngIf="!isLogo">
		<button ktToggle [options]="toggleOptions" class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler">
			<span [inlineSVG]="'./assets/media/icons/svg/Navigation/Angle-double-left.svg'"></span>
			<span [inlineSVG]="'./assets/media/icons/svg/Navigation/Angle-double-right.svg'"></span>
		</button>
	</div>
</div>
<!-- end:: Brand -->
