import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataTableService } from '../../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from '../../../../core/_base/layout/services/page-config.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { Login } from '../../../../core/auth';

@Component({
  selector: 'kt-appsumo-signin',
  templateUrl: './appsumo-signin.component.html',
  styleUrls: ['./appsumo-signin.component.scss']
})
export class AppsumoSigninComponent implements OnInit {
  countryList = [];
  timeZoneList = [];
  timezone: string;
  appsumoFormGroup: any = UntypedFormGroup;
  isSubmit = false;
  isConformedPassword = false;
  email: any;

  constructor(public dataservice: DataTableService,
    public pageService: PageConfigService,
    public cdr: ChangeDetectorRef,
    public http: HttpClient,
    public route: ActivatedRoute,
    private store: Store<AppState>,
    public router: Router,
    public form: UntypedFormBuilder) {

    this.appsumoFormGroup = this.form.group({
      FirstName: ['', [Validators.required, Validators.maxLength(30)]],
      Email: ['', [Validators.required, Validators.email]],
      LastName: ['', [Validators.required, Validators.maxLength(30)]],
      Country: ['', Validators.required],
      Timezone: ['', Validators.required],
      Password: ['', [Validators.required, Validators.minLength(4),
      Validators.maxLength(100)]],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(4),
      Validators.maxLength(100)]],
    })
  }

  key: any;
  planName: any;

  loading: boolean = false;

  ngOnInit() {
    this.key = window.location.href.split("appsumo&q=")[1];
   // console.log(this.key)
    if (this.key == undefined || this.key == null || this.key == "") {
      this.router.navigate(['error/403'])
      return
    } else {
      this.getAppSumoDetail();
    }

    this.countryList = this.dataservice.countryList;
    this.timeZoneList = this.pageService.timeZoneList;
    this.timezone = this.getCurrentTimeZone();

    if (this.getCurrentTimeZone().length > 0) {
      this.timezone = this.getCurrentTimeZone()
      let tempTimeZone = this.timezone;
      if (this.timezone != '') {
        for (var i = 0; i < this.timeZoneList.length; i++) {
          if (this.timeZoneList[i].id.toLocaleLowerCase() == tempTimeZone.toLocaleLowerCase()) {
            this.timezone = this.timeZoneList[i].id;
            break
          } else {
            this.timezone = '';
          }
        }
      }
    }
    else {
      this.timezone = 'GMT Standard Time'
    }
  }

  getCurrentTimeZone() {
    let date = new Date();
    let str = date.toString()
    return str.substring(str.indexOf('(') + 1, str.indexOf(')'))
  }

  getAppSumoDetail() {
    this.pageService.showLoader()
    this.http.get(environment.apiV2Path + 'GetUserDetails?key=' + this.key).subscribe((res: any) => {
      //console.log(res)
      if (res.success) {
        this.pageService.hideLoader();
        this.appsumoFormGroup.controls.Email.setValue(res.data.email);
        this.planName = res.data.planId;
      } else {
        this.pageService.hideLoader();
        this.pageService.showError('Opps', res.errorMsg);
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
        this.pageService.hideLoader();
      this.pageService.showError('Opps', err.error.errorMsg)
      }
    })
  }

  registerAppSumoUser() {
    this.isSubmit = true;
    this.loading = true;
    if (this.appsumoFormGroup.invalid) {
      this.loading = false;
      return
    }
    if (this.appsumoFormGroup.controls.Password.value != this.appsumoFormGroup.controls.ConfirmPassword.value) {
      this.loading = false;
      this.isConformedPassword = true;
      this.pageService.hideLoader();
      return
    }
    this.isConformedPassword = false;

    let data = {
      SumoKey: this.key,
      Key: environment.Key,
      TimeZone: this.appsumoFormGroup.controls.Timezone.value,
      FirstName: this.appsumoFormGroup.controls.FirstName.value,
      LastName: this.appsumoFormGroup.controls.LastName.value,
      Email: this.appsumoFormGroup.controls.Email.value,
      Password: this.appsumoFormGroup.controls.Password.value,
      Country: this.appsumoFormGroup.controls.Country.value,
    }

    this.http.post(environment.apiV2Path + 'Registration', data).subscribe((user: any) => {

      if (user['success']) {
        this.loading = false;
        this.store.dispatch(new Login({ authToken: user.key }));
        localStorage.setItem('adminemail', this.appsumoFormGroup.controls.Email.value);
        localStorage.setItem(environment.authTokenKey, user.key);
        localStorage.setItem('IsVerify', JSON.stringify(user['isVerify']));
        // localStorage.setItem('IsReseller', JSON.stringify(user['isReseller']));
        this.pageService.getPermissions();
        this.pageService.setIsVerify()
        localStorage.setItem("hasCreatedProfile", user["hasCreatedProfile"]);
        localStorage.setItem("hasOnboarding", user['hasOnboarding'])
        if (!user['hasOnboarding']) {
          this.router.navigate(['/onboarding/setup-profile'])
        }
      } else {
        this.loading = false;
        this.cdr.detectChanges();
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
        this.loading = false;
      this.cdr.detectChanges();
      this.pageService.showError('Opps', err.error.errorMsg);
      }
    })
  }
}
