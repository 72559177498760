import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { GoogleAuthService } from 'ng-gapi';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { MyModalComponent } from './../wizard/my-modal/my-modal.component';
import { MenuHorizontalService } from '../../../core/_base/layout/services/menu-horizontal.service';

@Component({
	selector: 'kt-google-account-details',
	templateUrl: './google-account-details.component.html',
	styleUrls: ['./google-account-details.component.scss']
})

export class GoogleAccountDetailsComponent implements OnInit {
	data: any;
	businessListing = [];
	locationList = [];
	accessToken: any;
	dropdown1 = false;
	accountId;
	locationId;
	showAccount = false;
	showBusiness = false;
	modalRef: BsModalRef;
	isShow = false;
	accountName: any;
	businessName: any;
	showLocation = false;
	showServiceArea = false;
	actBtn: any = "Show";
	locationTracker: any = false;
	enable = false;
	refreshAccessToken;

	constructor(public gAuth: GoogleAuthService,
		public http: HttpClient,
		public dialog: MatDialog,
		public menuService: MenuHorizontalService,
		public router: Router,
		public notification: PageConfigService,
		public cdr: ChangeDetectorRef,
		public modalService: BsModalService,
		public pageService: PageConfigService) {

	}

	ngOnInit() {
		
		this.pageService.showLoader();
		this.data = localStorage.getItem('googleSignInToken')
		if (this.data == null || this.data == '' || this.data == undefined) {
			this.router.navigate(['/dashboard']);
		} else {
			this.getAccountList();
		}
	}

	serviceAreaShow() {
		if (this.actBtn == "Hide") {
			this.actBtn = "Show";
			this.showServiceArea = false;
		} else {
			this.showServiceArea = true;
			this.actBtn = "Hide"
		}
		this.cdr.detectChanges();
	}

	setLocation(id, busName, e) {
		this.locationId = id;
		if (busName == e.target.textContent) {
			this.businessName = e.target.textContent
		}
		this.enable = true;
	}

	back() {
		this.showAccount = true;
		this.showBusiness = false;
		this.showLocation = false;
	}
	
	linkGoogle(acData) {
		this.notification.showLoader();
		let profileid = JSON.parse(localStorage.getItem('ProfileId'));
		let data = {
			Key: localStorage.getItem(environment.authTokenKey),
			AccountId: this.accountId,
			LocationId: this.accountId + '/' + acData.name,
			ProfileId: profileid,
			LocationName: acData.title,
			HasOnboarding: true,
			// RefreshAccessToken: localStorage.getItem('googleRefreshToken'),
			accessToken: localStorage.getItem("googleSignInToken"),
			WriteURL: acData.metadata.newReviewUri
		}
		// return
		this.http.post(environment.apiPath + 'LinkGoogle', data).subscribe((res: any) => {
			if (res.success) {
				this.notification.hideLoader();
				localStorage.removeItem('googleSignInToken');
				localStorage.removeItem('googleRefreshToken');
				this.http.get(environment.apiPath + 'FetchGoogleReview?key=' + JSON.parse(localStorage.getItem("ProfileId"))).subscribe()
				this.notification.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
				//this.notification.checkReviewStatus();
				this.menuService.updateIndentity("reviewPlatform");
				this.router.navigate(['/review-platforms']);
				localStorage.setItem("isConnnectedReviewPlatform", "true")
			} else {
				if (res.status != 500 || res.statusText != "Internal Server Error") {
					this.notification.hideLoader();
					this.notification.showError('Oops!!!', res.errorMsg)
				}
			}
		}, (err) => {
			if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
				this.pageService.hideLoader();
				this.pageService.showError('Oops!', err.error.errorMsg);
			}
		})
	}

	signWithOther() {
		///this.router.navigate(['/review-platforms']);
		let tempData = { mapFlag: true }
		const dialogRef = this.dialog.open(MyModalComponent, {
			width: '700px',
			data: tempData,
			panelClass: 'editModal',
			disableClose: true
		});
	}

	showNotice: boolean = false;
	async getAccountList() {
		// this.showReviewPlt = false;
		this.pageService.showLoader();
		let httpHeaders = new HttpHeaders();
		httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('googleSignInToken'));
		this.http.get('https://mybusinessaccountmanagement.googleapis.com/v1/accounts', { headers: httpHeaders }).subscribe(async (res: any) => {
			for (let i in res.accounts) {
				const data: any = await this.http.get('https://mybusinessbusinessinformation.googleapis.com/v1/' + res.accounts[i].name + '/locations?pageSize=0100&readMask=name,title,storefrontAddress,websiteUri,metadata&readMask=name,title,storefrontAddress,websiteUri,metadata', { headers: httpHeaders }).toPromise();
				if (data.locations) {
					this.accountId = res.accounts[i].name;
					// for(let j in data.locations){
					// 	this.googleBusinessList.push(data.locations[]);
					// }
					for (let i in data.locations) {
						this.businessListing.push(data.locations[i]);
					}
				}
			}
			let tempData = { showGoogleBusinessList: true }
			if (this.businessListing.length > 0) {
				tempData;
				this.showNotice = false;
				this.showAccount = true;
				this.showBusiness = false;
				// this.pageService.hideLoader();
				this.cdr.detectChanges();
			} else {
				this.showNotice = true;
				tempData;
				this.cdr.detectChanges();
			}
			//this.pageService.hideLoader();
		}, (err) => {
			// this.showReviewPlt = true;
			this.pageService.showLoader();
			let key = localStorage.getItem(environment.authTokenKey)
			let profileId = JSON.parse(localStorage.getItem('ProfileId'))
			let accessToken = localStorage.getItem("googleSignInToken")
			this.http.get(environment.apiPath + 'RevokeGoogleAccess?Key=' + key + '&ProfileId=' + profileId + '&accessToken=' + accessToken)
				.subscribe((res: any) => {
					if (res['success']) {
						this.pageService.hideLoader();
						if (err.error.error.status = 'PERMISSION_DENIED') {
							//this.pageService.showError('Error', 'Something went wrong');
							let tempData = { isErrorPop: true }
							const dialogRef = this.dialog.open(MyModalComponent, {
								width: '700px',
								data: tempData,
								panelClass: 'editModal',
								disableClose: true
							});
							// this.pageService.hideLoader();
							this.router.navigate(['/review-platforms']);
						}
					}
				})
			// this.router.navigate(['/no-google-account']);
		})
		this.pageService.hideLoader();
	}

	refreshToken: any;
	getAccount(acData) {
		this.pageService.showLoader();
		let httpHeaders = new HttpHeaders();
		httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('googleSignInToken'));
		this.http.get('https://mybusinessbusinessinformation.googleapis.com/v1/' + acData.name + '/locations?readMask=name,title,storefrontAddress,websiteUri,metadata', { headers: httpHeaders }).subscribe((res: any) => {
			if (res.locations) {
				this.businessListing.push(res.locations[0]);
			}
			this.cdr.detectChanges();
		}, (err) => {
			this.pageService.hideLoader();
			this.pageService.showError('Error', 'Something went wrong');
		})
	}

	connectAccount(e) {
		this.pageService.showLoader()
		this.linkGoogle(e);
		// let data = {
		// 	"client_id": "128406289351-rr08pponvupvj9cumrnrpskekf3gm0kv.apps.googleusercontent.com",
		// 	"refresh_token": localStorage.getItem('googleSignInToken'),
		// 	"redirect_uri": window.location.origin,
		// 	"client_secret": "H4NvJp4zEkCWPDgfCpL5QlFk",
		// 	"grant_type": "refresh_token"
		// }
		// this.http.post("https://oauth2.googleapis.com/token", data).subscribe((res: any) => {
		// 	if (res) {
		// 		this.refreshAccessToken = res.access_token;

		// 	} else {
		// 		this.pageService.hideLoader();
		// 		this.pageService.showError('Error', 'Something went wrong');
		// 	}
		// }, (err) => {
		// 	this.pageService.hideLoader();
		// 	this.pageService.showError('Error', 'Something went wrong');
		// })
	}
}
