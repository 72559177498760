import { ConfrimAlertComponent } from './../../../confrim-alert/confrim-alert.component';
import { PageConfigService } from './../../../../../core/_base/layout/services/page-config.service';
import { MatTabGroup, MatDialog } from '@angular/material';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
	selector: 'kt-whtsapp-template-page',
	templateUrl: './whtsapp-template-page.component.html',
	styleUrls: ['./whtsapp-template-page.component.scss']
})
export class WhtsappTemplatePageComponent implements OnInit {
	@ViewChild(MatTabGroup) tabGroup: MatTabGroup;
	errorFlag: boolean = false;
	profileId: any;
	accessKey: string;
	opnedInnerTabIndex: 0;
	smsTempLateData = [];
	loading: boolean = false;
	deleteLoading: boolean = false;
	textCount: 0;
	currentCursorPosition = { start: 0, end: 0 }
	oldTime: any;
	openEmoji: boolean = false
	lastSelectedTabIndex = 0;
	newTemplate = {
		sendInterval: 'assoonaspossible',
		sentWeekend: false,
		Time: "",
		templateTitle: '',
		message: ''
	}
	timezone = ''
	constructor(public pageService: PageConfigService,
		public http: HttpClient,
		public cdr: ChangeDetectorRef,
		public dialog: MatDialog,
		public router: Router) {



	}
	openSettings() {
		this.router.navigate(['/company-setting'])
	}
	ngOnInit() {
		var scope = this;
		scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
		scope.accessKey = localStorage.getItem(environment.authTokenKey);
		scope.pageService.callChooseProfile();
		scope.loadSMSTemplates();
		scope.loadTimeZone();
		scope.pageService.getProgileId().subscribe(message => {

			if (message.id) {
				scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
				if (scope.router.url.includes('template-settings')) {
					scope.loadTimeZone();
					scope.loadSMSTemplates();
				}
			}
		});
		scope.pageService.setTitle('WhatsApp Template')
	}
	loadTimeZone() {
		this.timezone = JSON.parse(localStorage.getItem('TimeZone'));
		this.cdr.markForCheck();
	}
	textareaChanged(event, targetClass) {

		if (document.querySelector('.' + targetClass) != null) {
			if (event.length >= 145) {
				document.querySelector('.' + targetClass)['style'].color = '#ff3636';
			} else {
				document.querySelector('.' + targetClass)['style'].color = '#808080';
			}
		}

	}
	intervalChanged(target, source) {
		var scope = this;

		scope.cdr.markForCheck()
		setTimeout(() => {
			if (target != 'assoonaspossible') {
				if (source == 'new') {

					timePicker(scope, '#timepickerSmsNew');
				} else {

					timePicker(scope, '#timepickerSms' + JSON.stringify(source));
				}
			}
			scope.cdr.markForCheck()
		}, 200)
	}
	innerTabChanged(event) {
		var scope = this;

		scope.openEmoji = false;
		scope.opnedInnerTabIndex = event.index;
		if (event.tab.textLabel == 'Create new template') {
			timePicker(scope, '#timepickerSmsNew');
			scope.loadTextArea('')
		} else {
			timePicker(scope, '#timepickerSms' + JSON.stringify(event.index));
			scope.loadTextArea('message' + JSON.stringify(event.index));
			scope.oldTime = scope.smsTempLateData[event.index].sentTime
		}
	}
	//Get Email template list for tab-2 (Email Template & frequency) 
	loadSMSTemplates() {
		var scope = this;

		scope.smsTempLateData = [];

		scope.pageService.showLoader();
		// document.querySelector('.tableRow')['style'].display='none'
		scope.http.get(environment.apiPath + 'getWhatsAppTemplateDetails?key=' + scope.accessKey +
			'&ProfileId=' + scope.profileId)
			.subscribe((response) => {
				scope.pageService.hideLoader()


				if (response['success']) {
					scope.smsTempLateData = response['data'];
					scope.cdr.markForCheck();
					setTimeout(() => {
						scope.tabGroup.selectedIndex = scope.lastSelectedTabIndex;
						scope.cdr.markForCheck();
						setTimeout(() => {
							// timePicker(scope, '#timepickerSmsNew');
							// scope.loadTextArea('messageNew');
							// if (document.querySelector('#timepickerSms0') != null) {
							// 	timePicker(scope, '#timepickerSms0');
							// 	scope.loadTextArea('message0');
							// }
							if (document.querySelector('#timepickerSms' + JSON.stringify(scope.tabGroup.selectedIndex)) != null) {
								timePicker(scope, '#timepickerSms' + JSON.stringify(scope.tabGroup.selectedIndex));
								scope.loadTextArea('message' + JSON.stringify(scope.tabGroup.selectedIndex));
							}
							if (document.querySelector('#timepickerSmsNew') != null) {
								timePicker(scope, '#timepickerSmsNew');
								scope.loadTextArea('messageNew');
							}
						}, 200)
					}, 200)
				} else {
					scope.pageService.showError('Oops!!!', response['errorMsg'])
					if (response['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || response['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
				// Response
				// { 
				// id: 20788
				// emailName: "First review request"
				// sendInterval: "2"
				// subjectLine: "Would you recommend {companyname}"
				// emailHTML: "<p><span>Hi {name},</span></p><p ><span >Would you mind leaving us a quick review by clicking the button above? It really helps us and can be a big help to your neighbors. Thanks, really appreciate it!</span></p><p>{sendername},{companyname}</p>"
				// unsubscibelinktext: null
				// sendWeekend: true
				// sentTime: "08:00 AM"
				// isShowLogo: false
				// isLogo: false
				// }
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}
	//on submit email template page
	onSMStemplateSubmit(item, target, index) {
		var scope = this;

		scope.errorFlag = false;
		if (target == 'new') {
			//set Time
			if (item.sendInterval != 'assoonaspossible') {
				item.Time = document.querySelector('#timepickerSmsNew')['value'];
			}

			//validations
			if (item.templateTitle == null || item.templateTitle.length == 0) {
				document.querySelector('.templateTitleNew').classList.add('errorTextBox');
				scope.errorFlag = true;
			}
			if (item.message == null || item.message.length == 0) {
				document.querySelector('.messageNew').classList.add('errorTextBox');
				scope.errorFlag = true;
			}
		} else if (item.sendInterval != 'assoonaspossible' && target == 'edit') {
			//set Time
			item.Time = document.querySelector('#timepickerSms' + JSON.stringify(index))['value'];

			//Validation
			if (item.templateTitle == null || item.templateTitle.length == 0) {
				document.querySelector('.templateTitle' + JSON.stringify(index)).classList.add('errorTextBox');
				scope.errorFlag = true;
			}
			if (item.message == null || item.message.length == 0) {
				document.querySelector('.message' + JSON.stringify(index)).classList.add('errorTextBox');
				scope.errorFlag = true;
			}
		}
		//if error
		if (scope.errorFlag) {
			return;
		}

		//Successfully validated
		scope.loading = true;
		let finalData = {
			Id: item.id,
			TemplateTitle: item.templateTitle,
			SendInterval: item.sendInterval,
			Message: item.message,
			SentWeekend: item.sentWeekend,
			Key: scope.accessKey
		};
		let Time;
		if (target == 'edit') {
			if (item.sendInterval == 'assoonaspossible') {
				Time = ''
				finalData['SentTime'] = '';
			} else {
				Time = document.querySelector('#timepickerSms' + JSON.stringify(index))['value'];
				finalData['SentTime'] = Time;
			}


			if (scope.oldTime.toLocaleLowerCase() == Time.toLocaleLowerCase()) {
				finalData['HasUpdateSentTime'] = false
			} else {
				finalData['HasUpdateSentTime'] = true;
			}
			scope.updateSMSTemplate(finalData)
		} else if (target == 'new') {
			if (item.sendInterval == 'assoonaspossible') {
				Time = ''
				finalData['SentTime'] = '';
			} else {
				Time = document.querySelector('#timepickerSmsNew')['value'];
				finalData['SentTime'] = Time;
			}

			scope.createSMSTemplate(finalData)
		}
	}
	createSMSTemplate(finalData) {
		var scope = this;

		delete finalData['Id'];
		finalData['ProfileId'] = scope.profileId;
		scope.http.post(environment.apiPath + 'CreateWhatsAppTemplate', finalData)
			.subscribe((response) => {

				this.loading = false;
				if (response['success']) {
					scope.pageService.showSuccess('Created', 'SMS template created successfully.')
					scope.resetNewTemplate()
					scope.loadSMSTemplates();
				} else {
					scope.pageService.showError('Oops!!!', response['errorMsg'])
					if (response['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || response['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}
	updateSMSTemplate(finalData) {
		var scope = this;
		scope.http.post(environment.apiPath + 'UpdateWhatsAppTemplate', finalData)
			.subscribe((response) => {

				scope.lastSelectedTabIndex = scope.tabGroup.selectedIndex;
				this.loading = false;
				if (response['success']) {
					scope.pageService.showSuccess('Updated', 'SMS template details updated.')
					scope.resetNewTemplate()
					scope.loadSMSTemplates();
				} else {
					scope.pageService.showError('Oops!!!', response['errorMsg'])
					if (response['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || response['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}
	//open confirm alert
	openConfirmAlert(target) {
		var scope = this;

		let confirmDialog = scope.dialog.open(ConfrimAlertComponent, {
			width: '700px',
			panelClass: 'editModal',
			// position:{top:'10px'},
			disableClose: true,
			data: {
				message: 'Are you sure you want to delete this whatsapp template? It will also remove pending customer invitation associated with this template.',
				buttonText: 'Delete anyway',
				source: 'delete template'
			}
		});
		confirmDialog.afterClosed().subscribe(result => {

			if (result.flag) {
				scope.deleteTemplate(target)
			}
		})
	}
	deleteTemplate(targetid) {
		var scope = this;

		scope.deleteLoading = true;
		scope.http.get(environment.apiPath + 'DeleteWhatsAppTemplate?Key=' + scope.accessKey + '&Id=' + targetid)
			.subscribe((res) => {
				scope.deleteLoading = false;
				if (res['success']) {
					scope.pageService.showSuccess('Deleted', 'Template deleted Successfully.');
					scope.resetNewTemplate()
					scope.loadSMSTemplates()
				} else {
					scope.pageService.showError('Oops!!!', res['errorMsg'])
					if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}
	textEntered(target) {
		if (target == 'new') {
			//existing email forms
			document.querySelector('.templateTitleNew').classList.remove('errorTextBox');
			document.querySelector('.messageNew').classList.remove('errorTextBox');
			this.cdr.markForCheck();
		} else if (target == 'edit') {

			// create new form
			document.querySelector('.templateTitle').classList.remove('errorTextBox');
			document.querySelector('.messageTextArea').classList.remove('errorTextBox');
			this.cdr.markForCheck();
		}
	}
	resetNewTemplate() {
		this.newTemplate = {
			sendInterval: 'assoonaspossible',
			sentWeekend: false,
			Time: "",
			templateTitle: '',
			message: ''
		}
		this.cdr.markForCheck()
	}
	openEmojiPop() {
		this.openEmoji = !this.openEmoji
	}
	focusEvent(event) {

		// this.loadTextArea();
	}
	addEmoji(event, item) {
		var scope = this;


		item.message = item.message.substring(scope.currentCursorPosition.start, 0)
			+ event.emoji.native
			+ item.message.substring(scope.currentCursorPosition.start)
		// item.message += event.emoji.native;
		scope.cdr.markForCheck();
		// const { message } = this;
		// const text =${message}${event.emoji.native}`
	}
	loadTextArea(targetClass) {
		var scope = this;

		document.getElementsByClassName(targetClass)[0].addEventListener("click", function () {
			var input = document.getElementsByClassName(targetClass)[0];
			var inputContent = input['value'].length;
			// You may want to focus the textbox in case it's not
			input['focus']();
			var result = scope.getInputSelection(input);
			scope.currentCursorPosition = result;
			// var resultSpan = document.getElementById("result");

		}, false);
	}
	getInputSelection(el) {
		var start = 0, end = 0, normalizedValue, range,
			textInputRange, len, endRange;

		if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
			start = el.selectionStart;
			end = el.selectionEnd;
		} else {
			range = document['selection'].createRange();

			if (range && range.parentElement() == el) {
				len = el.value.length;
				normalizedValue = el.value.replace(/\r\n/g, "\n");

				// Create a working TextRange that lives only in the input
				textInputRange = el.createTextRange();
				textInputRange.moveToBookmark(range.getBookmark());

				// Check if the start and end of the selection are at the very end
				// of the input, since moveStart/moveEnd doesn't return what we want
				// in those cases
				endRange = el.createTextRange();
				endRange.collapse(false);

				if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
					start = end = len;
				} else {
					start = -textInputRange.moveStart("character", -len);
					start += normalizedValue.slice(0, start).split("\n").length - 1;

					if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
						end = len;
					} else {
						end = -textInputRange.moveEnd("character", -len);
						end += normalizedValue.slice(0, end).split("\n").length - 1;
					}
				}
			}
		}

		return {
			start: start,
			end: end
		};
	}
}
