<div class="col-12">
    <div class="row padding-15 text-center">
        <div class="col-12 text-1-2x text-bold">
            {{ headerTitle }}
            <i style="float: right" class="fa fa-times cursor-pointer" (click)="closeModal(undefined)"></i>
            <!--  -->
            <!-- <sub style="font-size: 70%; margin-left: -40%;" class="btnWarn col-12 text-1-2x text-center gray-color" >Set Limit </sub> -->
        </div>
        <!-- padding-left: 20%; btnWarn-->
        <span class="m-0 gray-color " style="font-size: 16px;font-weight: bold; ">Set Limit</span>

    </div>
    <div class="col-12">
        <mat-divider></mat-divider>
    </div>
    <div class="row padding-15">
        <div class='col-12 padding-tb-10'>
            <form [formGroup]="setlimtForm">
                <div class="row">
                    <div class="col-6">
                        <div class="text-center">Email</div>
                        <div class="col-12 m-2 text-center">
                            <mat-slide-toggle (change)='changeEmailToggle()' [checked]="true"
                                [(ngModel)]="displayOnEmail" formControlName="displayOnEmail">
                            </mat-slide-toggle>
                            <div class="text-center flexClass mt-2" [ngClass]="{'disableDiv': !displayOnEmail}">
                                <div class="minusButton roundedBox-radius iconBtn primary-back-color margin-lr-5 cursor-pointer"
                                    (click)="decreaseEmail()">
                                    <i class="fas fa-minus"></i>
                                </div>
                                <input type="number" class="inputEmailsms" [(ngModel)]="email" autocomplete="off"
                                    formControlName="emailtextlimit" 
                                    [ngClass]="{'disableInput': !displayOnEmail}" id="email" min="0">

                                <!-- <div class="quanitityClass">
                                    {{ email }}
                                </div> -->
                                <div class="plusButton roundedBox-radius iconBtn primary-back-color margin-lr-5 cursor-pointer"
                                    (click)="increaseEmail()">
                                    <i class="fa fa-plus"></i>
                                </div>
                                <mat-error *ngIf="fromSubmitted && setlimtForm.controls.emailtextlimit.errors">
                                    <strong
                                        *ngIf="fromSubmitted && setlimtForm.controls.emailtextlimit.errors.required">
                                        Email Limit is required
                                    </strong>

                                    <strong *ngIf="email.length > 0 ">set unlimited to zero value Email </strong>

                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="text-center">SMS</div>
                        <div class="col-12 m-2 text-center">
                            <mat-slide-toggle (change)='changeSmsToggle()' [checked]="true"
                                [(ngModel)]="displayOnsms" formControlName="displayOnsms">
                            </mat-slide-toggle>
                            <div class="text-center flexClass mt-2" style="padding-bottom: 3%;"
                                [ngClass]="{'disableDiv': !displayOnsms}">
                                <div class="minusButton roundedBox-radius iconBtn primary-back-color margin-lr-5 cursor-pointer"
                                    (click)="decreaseSms()">
                                    <i class="fas fa-minus"></i>
                                </div>
                                <input type="number" class="inputEmailsms" [(ngModel)]="sms" autocomplete="off" id="sms"
                                    formControlName="smstextlimit" [ngClass]="{'disableInput': !displayOnsms}" min="0">
                                <!-- <div class="quanitityClass">
                               {{ sms }}  
                            </div> -->
                                <div class="plusButton roundedBox-radius iconBtn primary-back-color margin-lr-5 cursor-pointer"
                                    (click)="increaseSms()">
                                    <i class="fa fa-plus"></i>
                                </div>
                                <mat-error *ngIf="fromSubmitted && setlimtForm.controls.smstextlimit.errors">
                                    <strong *ngIf="fromSubmitted && setlimtForm.controls.smstextlimit.errors.required">
                                        Sms Limit is required
                                    </strong>
                                    <strong *ngIf="sms.length > 0 ">set unlimited to zero value SMS</strong>

                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="notes roundedBox-radius padding-10 notes-back-color">
                <strong>Note</strong>: To set unlimited Email & SMS limit set values to zero.
            </div>
        </div>
        <div class='col-12 text-center' mat-dialog-actions>
            <button class="btn btn-primary btn-elevate kt-login__btn-primary"
                [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}"
                (click)="onSubmit()">Save</button>
            <button mat-button (click)="closeModal(undefined)">Cancel</button>
        </div>
    </div>
</div>