<div class="kt-widget1">
	<ng-container *ngFor="let item of data">
		<ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item}"></ng-container>
	</ng-container>
</div>

<ng-template #itemTemplate let-item="item">
	<div class="kt-widget1__item">
		<div class="kt-widget1__info">
			<h3 class="kt-widget1__title">{{item.title}}</h3>
			<span class="kt-widget1__desc">{{item.desc}}</span>
		</div>
		<span class="kt-widget1__number" [ngClass]="item.valueClass">{{item.value}}</span>
	</div>
</ng-template>
