import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import {  Appearance, } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { GoogleMapsAPIWrapper } from '@agm/core';
import { GoogleAuthService } from 'ng-gapi';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
import { data } from 'jquery';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MenuHorizontalService } from './../../../../core/_base/layout/services/menu-horizontal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
declare function initMap(any1): any
declare function loginFb(): any;
declare function getfbAccessToken(): any;
declare function intitFb(): any;
@Component({
	selector: 'kt-my-modal',
	templateUrl: './my-modal.component.html',
	styleUrls: ['./my-modal.component.scss']
})

export class MyModalComponent implements OnInit {
	@Input() adressType: string;
	@Output() setAddress: EventEmitter<any> = new EventEmitter();
	@ViewChild('addresstext', { static: true }) addresstext: any;
	@ViewChild('agmMap') agmMap: any;

	// addresstext:HTMLInputElement
	autocompleteInput: string;
	queryWait: boolean;
	public appearance = Appearance;
	public zoom: number;
	public latitude: number;
	public longitude: number;
	public selectedAddress: PlaceResult;
	public locationText = ''
	closeResult: string;
	public linkUrl = '';
	public invalidUrl: boolean = false;
	public submitted: boolean = false;
	public typed: boolean = false;
	public mapFlag: boolean = false;
	public customMapFlag: boolean = false;
	public locationLink = '';
	public placeRe: any;
	public isPrimary: boolean = false;
	public skipInst: boolean = false;
	public collectReview: boolean = false;
	public displayOnReviewPage: boolean = false;
	public showMobile: boolean = true;
	public locationTypeFlag: boolean = false;
	public isReviewScrapAvailable: boolean = false;
	public siteData: any;
	public siteCategory :any;
	public value = '';
	public instruction = '';
	googleCode: any;
	public isErrorPop: boolean = false;
	public urlRegEx = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
	// public urlRegEx=/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

	facebookData = {
		sharingEnabled: false,
		fbPage: '',
		template: '',
		postPerDay: '0',
		excludeStar: ['1'],
		excludeReviewSource: [''],
		excludeNoTextReview: false,
	};

	constructor(
		public dialogRef: MatDialogRef<MyModalComponent>,
		public menuService: MenuHorizontalService,
		@Inject(MAT_DIALOG_DATA) public navData: any,
		public googleMapApiWrapper: GoogleMapsAPIWrapper, public gAuth: GoogleAuthService,
		public snackBar: MatSnackBar,
		public pageService: PageConfigService,
		@Inject(DOCUMENT) private oc, private elementRef: ElementRef,
		public http: HttpClient,
		public cdr: ChangeDetectorRef,
		public router: Router,
		public dialog: MatDialog,) {
		// loadAuto()

	}
	
	access_token = '';
	isScrap: any = false;
	currentPlan: any;
	freetrialFlag: boolean = false;
	showGoogleClickhere : boolean = true;
	isAgency: boolean = false; 
	isReseller:boolean=false;
	ngOnInit() {
		//Set Default Map Values
		var scope = this
		this.cdr.markForCheck();
		scope.currentPlan = JSON.parse(localStorage.getItem('current-plan'));
		scope.isAgency = JSON.parse(localStorage.getItem('isAgency'));
		scope.isReseller=JSON.parse(localStorage.getItem('IsReseller'));

		
		// if (scope.currentPlan == "Free Trial") {
		// 	scope.freetrialFlag = true
		// }
		// else {
		// 	scope.freetrialFlag = false
		// }

		if(scope.isAgency == true || scope.currentPlan == "Grab Your Reviews Tier 1" || "Grab Your Reviews Tier 2" || "Grab Your Reviews Tier 3"){
			scope.showGoogleClickhere = true;
		}
		else {
			scope.showGoogleClickhere = false;
		}

		scope.zoom = 8;
		scope.latitude=40.7128;
		scope.longitude=74.0060;
		scope.step1 = true;
		scope.mapFlag = scope.navData.mapFlag;
		scope.value = scope.navData.value;
		scope.collectReview = scope.navData.collectReview
		scope.displayOnReviewPage = scope.navData.displayOnReviewPage;
		scope.showMobile = scope.navData.showMobile
		scope.siteData = scope.navData.siteData;
		scope.siteCategory =scope.navData.siteData.category;
		scope.isReviewScrapAvailable = scope.navData.isReviewScrapAvailable
		scope.instruction = scope.navData.instruction
		scope.isScrap = scope.navData.isScrap;
		scope.isErrorPop = scope.navData.isErrorPop;
		if (scope.navData.name == "Facebook" && scope.siteData.isActive) {
			scope.stepFB1 = false;
			scope.freetrialFlag = false
			scope.isShow = true
		} else if (scope.navData.name != "Facebook") {
			scope.stepFB1 = false;
		} else {
			scope.stepFB1 = true;
		}
		
		if (!scope.mapFlag) {
			if (scope.navData.siteURL != undefined) {
				scope.linkUrl = scope.navData.siteURL
			}
		}
		if (scope.navData.name == "Google" && (this.oc.location.host != "app.grabyourreviews.com" && this.oc.location.host != "localhost:4200")) { //disable Google Login for Agency
		 	this.checkForAc();
		}
		intitFb();
	}

	step1 = false;
	step2 = false;
	stepFB1 = false;

	isShowMap: boolean = true;
	checkForAc() {

		var scope = this;
		scope.isScrap = true;
		scope.isShowMap = false;
		
		setTimeout(() => {
			if (scope.isScrap) {
				initMap(scope)
			}
		}, 1000)
	}
	listingAccess = true;
	next = false;
	dropdown1 = false;
	businessListing = [];

	nextCheck() {
		this.dropdown1 = true;
	}

	noGoogleListing() {
		this.step1 = false;
		this.dialog.closeAll();
		
		this.gAuth.getAuth().subscribe((auth) => {
			auth.grantOfflineAccess().then(res => {
				//Save code
				if (res.code) {
					this.googleCode = res.code
					this.getGoogleToken(this.googleCode)
				}
			});
		});
	}


	checkForFBAc() {
		this.stepFB1 = false
	}

	setAdd(result) {
		var scope = this;

		this.placeRe = result;
	}

	validate() {
		var scope = this;

		scope.typed = true;
		if (!scope.urlRegEx.test(scope.linkUrl)) {
			scope.invalidUrl = true;
		} else {
			scope.invalidUrl = false
		}
	}
	reset() {
		this.typed = false;

		this.locationTypeFlag = false;
	}
	//validation for custom map link 
	locationTyped() {
		this.locationTypeFlag = true;
	}

	linkError: boolean = false;
	congratsFlag: boolean = false
	div1: boolean = true;
	onSubmit() {
		var scope = this;
		scope.typed = true;
		if(scope.value == "connected") {
			scope.congratsFlag = false;
			if(scope.congratsFlag == false){
				let tempdata = {
					mapFlag: this.mapFlag,
					linkUrl: this.linkUrl,
					name: this.navData.name,
					skipInst: this.skipInst,
					showMobile: this.showMobile,
					collectReview: this.collectReview,
					getReview: this.displayOnReviewPage,
					isPrimary: this.isPrimary
				}
				scope.dialogRef.close(tempdata);
			}
			// scope.dialogRef.close();
		  } else {
			scope.congratsFlag = true;
		  }
		scope.div1 = false;
		if (scope.linkUrl.length > 0 && !scope.urlRegEx.test(scope.linkUrl)) {
			scope.invalidUrl = true;
			return;
		}
		else if (scope.navData.name.toLocaleLowerCase() == "playstore") {
			if (!scope.linkUrl.includes("https://play.google.com/store/apps/details?id=")) {
				scope.invalidUrl = true;
				return;
			}
		} else if (!scope.linkUrl.includes(scope.navData.name.toLocaleLowerCase().replace(/ /g, ''))) {

		}
		if (this.siteData.placeholderLink.toLocaleLowerCase() == this.linkUrl.toLocaleLowerCase()) {
			this.linkError = true;
			scope.congratsFlag = false;
			scope.div1 = true;
			this.cdr.detectChanges();
			return
		}
		else {
			var u = this.linkUrl.toLocaleLowerCase()
			if (u.includes("{") || u.includes("[") || u.includes("(")) {
				this.linkError = true;
				scope.congratsFlag = false;
				scope.div1 = true;
				this.cdr.detectChanges();
				return
			}
			else {
				this.linkError = false;
				this.cdr.detectChanges();
			}
		}
	}

	onSubmitCongrats() {
		var scope = this;
		scope.congratsFlag = true;
		let tempdata = {
			mapFlag: this.mapFlag,
			linkUrl: this.linkUrl,
			name: this.navData.name,
			skipInst: this.skipInst,
			showMobile: this.showMobile,
			collectReview: this.collectReview,
			getReview: this.displayOnReviewPage,
			isPrimary: this.isPrimary
		}
		scope.dialogRef.close(tempdata);
		// /scope.dialogRef.close();
	}

	onSubmitMap() {
	
		var tempData = {
			mapFlag: this.mapFlag,
			placeResult: undefined,
			location: '',
			name: this.navData.name,
			skipInst: this.skipInst,
			showMobile: this.showMobile,
			collectReview: this.collectReview,
			isPrimary: this.isPrimary,
			getReview: this.displayOnReviewPage
		}

		if (!this.customMapFlag && this.placeRe == undefined) {
			this.snackBar.open('Please select business', 'OK', {
				duration: 3000
			})
			return;
		} else if (!this.customMapFlag && this.placeRe != undefined && !this.placeRe.url.includes('cid')) {
			this.snackBar.open('Invalid business', 'OK', {
				duration: 3000
			})
		} else if (!this.customMapFlag) {
			tempData['placeResult'] = this.placeRe;
		}
		if (this.customMapFlag) {
			if (this.locationLink.length == 0) {
				return;
			} else {
				tempData['location'] = this.locationLink;
			}
		}
		// connect map
		//Validate address

		this.dialogRef.close(tempData)
	}

	customMapFalse() {
		this.customMapFlag = false;
		this.ngOnInit()
	}
	customMap() {
		this.customMapFlag = true;
	}
	onNoClick() {
		this.dialogRef.close()
	}

	accessKey: any;
	profileId: any;
	googleData = {
		sharingEnabled: false,
		id: '',
		gAcc: '',
		gLocation: '',
		template: '',
		postPerDay: '0',
		excludeStar: ['1'],
		excludeReviewSource: [''],
		excludeNoTextReview: false,
	};

	googleAccList = [];
	connectedList = [];
	isConnectedGoogle: boolean = false

	loadGoogleData(target) {
		var scope = this;
		let data = {
			Key: scope.accessKey,
			ProfileId: scope.profileId,
			ReviewSource: 'Google',
			AccessToken: '',
			redirecturl: window.location.origin
		}
		if (target == 'with') {
			data['AccessToken'] = scope.googleCode;
		}

		let finalData = { Data: scope.pageService.encrypt(JSON.stringify(data)) };

		scope.pageService.showLoader();
		scope.http.post(environment.apiPath + 'GetSocialSharingDetails', finalData)
			.subscribe((res) => {
				scope.pageService.hideLoader();
				scope.cdr.markForCheck();
				if (res['success']) {
					let googleData = JSON.parse(scope.pageService.decrypt(res['data']))
					scope.googleAccList = googleData['GoogleBusinessList'];
					scope.googleData.postPerDay = googleData['PostPerDay'];
					scope.googleData.template = googleData['PostTemplate'];
					scope.connectedList = googleData['ConnectedReviewSites'];
					scope.googleData.excludeNoTextReview = googleData['ExcludeNoTextReviews'];
					scope.googleData.id = googleData['Id'];
					scope.googleData.gAcc = googleData['AccountId'];
					scope.googleData.gLocation = googleData['LocationId'];
					scope.cdr.markForCheck();
					if (googleData['ExcludeReviewSites'] != null) {
						scope.googleData.excludeReviewSource = googleData['ExcludeReviewSites'].split(',');
					}
					if (googleData['ExcludeStarRating'] != null) {
						scope.googleData.excludeStar = googleData['ExcludeStarRating'].split(',')
					}

					scope.cdr.markForCheck();

					scope.googleData.sharingEnabled = googleData['IsActive'];
					scope.isConnectedGoogle = googleData['IsActive'];
					scope.cdr.markForCheck();
				} else {
					scope.pageService.showError('Oops!!!', res['errorMsg'])
					if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

					this.pageService.hideLoader();
					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	resourceId = 0;
	pageList = [];

	getGoogleToken(code) {
		this.http.get(environment.apiPath + 'GoogleAuthentication?key=' + localStorage.getItem(environment.authTokenKey) + '&code=' + code + '&redirectURL=' + window.location.origin + '&profileid=' + JSON.parse(localStorage.getItem('ProfileId'))).subscribe((res: any) => {
			localStorage.setItem('googleSignInToken', res.accessToken);
			if (localStorage.getItem('hasOnboarding') == 'true') {
				this.pageService.showLoader();
				setTimeout(() => {
					// this.router.navigate(['/google-account-details']);
					this.pageService.showLoader();
					let currentUrl = '/google-account-details';
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigate([currentUrl]);
					});
					this.pageService.hideLoader();
				}, 500)
				return
			} else if (localStorage.getItem('hasOnboarding') == 'false') {
				this.pageService.showLoader();
				setTimeout(() => {
					this.router.navigate(['/onboarding/review-site'])
					window.location.reload();
				}, 1000)
				return
			}
		})
	}

	//facebook show hide div
	isShow: any = false;
	toggleDisplay() {
		var scope = this;
		scope.isShow = true;
	}
	noFacebookListing() {
	
		var scope = this;
		loginFb().then((res) => {
			// localStorage.setItem('FacebooksignInToken', scope.access_token);
			
			if (res == true) {
				scope.access_token = getfbAccessToken();
				scope.cdr.markForCheck()
				scope.loadFacebookPage('with-access-token');
			} else {
				scope.pageService.showError('Oops!!!', 'Facebook not connected. Please try again.')
			}
		})
	}
	facebookPageList = [];
	data: any;

	loadFacebookPage(target) {
		var scope = this;
		if (target == 'with-access-token') {
			data['AccessToken'] = scope.access_token;
			localStorage.setItem('FacebooksignInToken', scope.access_token);
			if (localStorage.getItem('hasOnboarding') == 'true') {

				if (this.access_token.length > 0) {
					this.dialog.closeAll();
					let fbData = {
						AccessToken: this.access_token,
						Key: localStorage.getItem(environment.authTokenKey)
					}
					this.router.navigate(['/facebook-account-details'], {
						state: { example: fbData }
					})
				}

				this.pageService.showLoader();
				setTimeout(() => {
					this.router.navigate(['/facebook-account-details']);
					this.pageService.hideLoader();
				}, 500)
				return
			} else if (localStorage.getItem('hasOnboarding') == 'false') {
				this.pageService.showLoader();
				setTimeout(() => {
					this.router.navigate(['/onboarding/review-site'])
					window.location.reload();
				}, 1000)
				return
			}

			// if (this.access_token.length > 0) {
			// 	this.dialog.closeAll();
			// 	let fbData = {
			// 		AccessToken: this.access_token,
			// 		Key: localStorage.getItem(environment.authTokenKey)
			// 	}
			// 	this.router.navigate(['/facebook-account-details'], {
			// 		state: { example: fbData }
			// 	})
			// }
		}

	}

	onReconnect() {
		//open google reconnection
		let tempData = { mapFlag: true }
		const dialogRef = this.dialog.open(MyModalComponent, {
			width: '700px',
			data: tempData,
			panelClass: 'editModal',
			disableClose: true
		});
	}

	PlanName: any;
	modalRef: BsModalRef;

	onUpgrade() {
		this.router.navigate(['/general/billing']);
		this.dialogRef.close();
		///	this.router.navigate(['/general/billing'], { queryParams: this.PlanName });

	}

}
