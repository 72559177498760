<div class="parent">
	<div [ngStyle]="{'background-image' : isShow ? 'url(../../../../../../assets/media/celebrate.gif)' : ''}" *ngIf="showSetupComp">
		<div class="row" style="justify-content: space-around;">
			<img src="../../../../assets/media/logos/Grab-Your-Review-LOGO-old.png">

			<form>
				<div class="row">
					<div class="col-12 setup-profile-heading">
						Setup Company Pofile
					</div>
					<div class="col-md-6">
						<label>Your name</label>
						<input type="text" class="form-control" placeholder="First name">
					</div>
					<div class="col-md-6">
						<label>Company name</label>
						<input type="text" class="form-control" placeholder="Last name">
					</div>
					<div class="col-md-6">
						<label>Email</label>
						<input type="text" class="form-control" placeholder="First name">
					</div>
					<div class="col-md-6">
						<label>Url Shortname</label>
						<input type="text" class="form-control" placeholder="Last name">
					</div>
					<div class="col-md-6">
						<label>Phone Number</label>
						<input type="text" class="form-control" placeholder="First name">
					</div>
					<div class="col-md-6">
						<label>Country</label>
						<input type="text" class="form-control" placeholder="Last name">
					</div>
					<div class="col-md-12">
						<label>Industry</label>
						<input type="text" class="form-control" placeholder="Last name">
					</div>
					<div class="col-12" style="text-align: right;">
						<button type="button" class="btn btn-primary" (click)="submit()">Next <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
					</div>

				</div>
			</form>
		</div>
	</div>

	<!-- Connect review platform *ngIf="showReviewPlt"-->
	<div *ngIf="showReviewPlt">
		<div>
			<div style="text-align: right; width: 100%;" *ngIf='viewDocumentation'><a href="https://grabyourreviews.zendesk.com/hc/en-us/articles/360043714234-Connect-Review-Platforms" target="_blank"><i class="fa fa-question-circle"></i> View Help Documentation</a></div>
			<mat-card>
				<div class='col-12 text-bold text-1-5x text-center'>
					Review Platforms
				</div>
				<div class='col-12 text-1-2x text-center gray-color'>
					Connect a platform you’d like to get a review from
				</div>
				<div class='row margin-tb-10'>
					<div class='col-2'></div>
					<div class='col-8'>
						<input class="form-control" type='text' [(ngModel)]="searchInput" placeholder='Search review platforms' (ngModelChange)='serachSocial()'>
					</div>
					<div class='col-2'></div>
				</div>
				<div class="note" *ngIf="isGoogleAuthError">
					Enable cookies to connect google my business. Follow this
					<a style="text-decoration: underline;" href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">
						step by step guide
					</a>
					to enable cookies in browser.
				</div>
				<div class='contentModel padding-10'>
					<div class='row' *ngIf='connectedList.length>0'>
						<div class='col-12 padding-tb-10 padding-lr-10 text-1-2x ' [innerHTML]='"Connected"'></div>
						<div class='col-12 padding-lr-5 flexClass'>
							<div class='imgBoxParent col-lg-3 col-md-4 col-sm-6 col-xs-12 ' *ngFor="let item of connectedList">
								<div class='imgBox roundedBox-border roundedBox-radius padding-tb-5 margin-tb-10  
					cursor-pointer {{item.reviewSiteName}}' (click)='openDialog(item,"connected")'>
									<img [src]="'/assets/media/social-logo/'+item.reviewSiteName+'.png'" class='cursor-pointer' *ngIf='item.isCustom==false' />

									<span *ngIf='item.isCustom == true '>
										<img [src]="item.imageURL" class='cursor-pointer' *ngIf="showCustImg" />
										<div id="profileImage" *ngIf="showCustLogo">
											<b class="custContentName" id="demo">{{item.reviewSiteName}}</b>
										</div>
									</span>



									<div class='text-1-3x reviewSiteText cursor-pointer' [innerHTML]='item.reviewSiteName'></div>

								</div>
								<div class='closeIcon text-center text-1-3x'>
									<i class="fa fa-trash danger-color margin-lr-5 cursor-pointer" (click)='openAlertDialog(item)'></i>
								</div>

							</div>
						</div>
						<div class='col-12 padding-tb-10'>
							<mat-divider></mat-divider>
						</div>
					</div>
					<div *ngIf="disSearchArr.length==0 && !Notfound">
						<div class='row' *ngFor="let temp of disArr">
							<div class='col-12 padding-tb-10 padding-lr-10 text-1-2x ' [innerHTML]='temp.title'></div>
							<div class='col-12 padding-lr-5 flexClass'>
								<div class='col-lg-3  col-md-4 col-sm-6 col-xs-12 ' *ngFor="let item of temp.list">
									<div class='imgBox roundedBox-border roundedBox-radius padding-tb-5 margin-tb-10 cursor-pointer {{item.reviewSiteName}}' (click)='openDialog(item,"notconnected")'>
										<img class='cursor-pointer' [src]="'/assets/media/social-logo/'+item.reviewSiteName+'.png'" />
										<div class='text-1-3x reviewSiteText cursor-pointer' [innerHTML]='item.reviewSiteName'></div>
									</div>
								</div>
							</div>
							<div class='col-12 padding-tb-10'>
								<mat-divider></mat-divider>
							</div>
						</div>
					</div>

					<div class='row' *ngIf="disSearchArr.length>0 ">
						<div class='col-12 padding-lr-5 flexClass'>
							<div class='col-lg-3  col-md-4 col-sm-6 col-xs-12 ' *ngFor="let item of disSearchArr">
								<div class='imgBox roundedBox-border roundedBox-radius padding-tb-5 margin-tb-10 
					cursor-pointer {{item.reviewSiteName}}' (click)='openDialog(item,"notconnected")'>
									<img class='' [src]="'/assets/media/social-logo/'+item.reviewSiteName+'.png'" />
									<div class='text-1-3x reviewSiteText cursor-pointer' [innerHTML]='item.reviewSiteName'></div>
								</div>
							</div>
						</div>
					</div>
					<div class='row'>
						<div class='col-12 padding-lr-5 flexClass'>
							<div class='col-lg-3  col-md-4 col-sm-6 col-xs-12 '>
								<div class='cursor-pointer imgBox roundedBox-border roundedBox-radius padding-tb-5 margin-tb-10 customLink' (click)='openCustomDialog("new",undefined)'>
									<!-- <img [src]="'/assets/media/social-logo/'+item.reviewSiteName+'.png'" /> -->
									<div class='text-1-3x reviewSiteText customLink primary-color'>Custom link</div>
								</div>
							</div>
						</div>
					</div>
					<div class='row padding-20' *ngIf='disSearchArr.length==0 && Notfound'>
						We don't support this site - please add it to our &nbsp;<a href=''>feedback forum </a>&nbsp;
						and we'll let you know once it's added!
					</div>
				</div>
			</mat-card>
		</div>
	</div>

	<div *ngIf="showGoogleBusinessList">
		<div class="card-align">
			<span class='gray-color text-1x'>Choose Account</span>
			<br>
			<div *ngFor="let i of googleBusinessList" (click)="getLocation(i.id,i.accountName,$event)" style="padding-top: 10px;">
				<div class="card" style="width: 100%;cursor: pointer;" [ngClass]="{'select': i.accountName == accountName,'text-white': i.accountName == accountName}">
					<div class="card-body">
						<span class="card-text" style="float: left;">{{i.accountName}}</span>
						<i class="arrow right"></i>
					</div>
				</div>
				<br>
			</div>
		</div>
	</div>

	<div *ngIf="showBusiness">
		<div class="card-align">
			<span class='gray-color text-1x'>Choose Business</span>
			<br>
			<div *ngFor="let i of locationList" style="padding-top: 10px;">
				<div class="card" style="width: 100%;cursor: pointer;" [ngClass]="{'select': i.accountName == accountName,'text-white': i.accountName == accountName}">
					<div class="card-body">
						<span class="card-text" style="float: left;">{{i.locationName}}</span>
						<button class="btn btn-outline-primary connect" (click)="save(i.id,i.locationName,i.isServiceArea)">Connect</button>
					</div>
				</div>
				<br>
			</div>
		</div>
	</div>

	<div *ngIf="showInviteCust">
		<div class='row margin-tb-10' *ngIf='viewDocumentation'>
			<div class="col-1">
		
			</div>
			<div class='col-10'>
				<div style="text-align: right; width: 100%;"><a href="https://grabyourreviews.zendesk.com/hc/en-us/articles/360043714674-Invite-Customer" target="_blank"><i class="fa fa-question-circle"></i> View Help Documentation</a></div>
			</div>
			<div class="col-1">
		
			</div>
		</div>
		
		<div class='row'>
		
			<div class='col-1'></div>
			<div class='col-10 card padding-20 '>
				<div class='row'>
					<div class='col-md-6 col-sm-6 col-xs-12 text-bold text-1-3x'>Invite Customer</div>
					<div class='col-md-6 col-sm-6 col-xs-12 text-right flexClass nowrap'>					
					</div>
				</div>
				<mat-divider class='margin-tb-10'></mat-divider>
				<form [formGroup]="inviteForm">
					<div class='row padding-10'>
						<div class='col-md-8 col-sm-12 col-xs-12'>
							<div class='row form-group' *ngIf="isShow">
								<div class='col-md-4 col-sm-6 col-xs-6'>
									<mat-checkbox formControlName="emailFlag" [(ngModel)]="model.emailFlag" (ngModelChange)='changeCheckbox()'>Email</mat-checkbox>
								</div>
								<div class='col-md-4 col-sm-6 col-xs-6'>
									<mat-checkbox formControlName="smsFlag" [(ngModel)]="model.smsFlag" (ngModelChange)='changeCheckbox()'>SMS/Text</mat-checkbox>
									<!-- SMS/Text/Whatsapp -->
								</div>
		
							</div>
							<mat-error *ngIf="submittedform && emailSmsErrorFlag && isShow">
								<strong>Atleast one selected</strong>
							</mat-error>
							<div class='row margin-tb-10'>
								<div class="col-12 form-group">
									<div>
										<mat-label class='text-bold'>First Name</mat-label>
										<input type="text" class="form-control" name="firstName" placeholder="First Name" formControlName="fname" [(ngModel)]="model.fname" />
									</div>
								</div>
							</div>
							<div class='row margin-tb-10'>
								<div class="col-12 form-group">
									<div>
										<mat-label class='text-bold'>Last Name </mat-label>
										<input type="text" class="form-control" name="lastName" placeholder="Last Name" formControlName="lname" [(ngModel)]="model.lname" />
									</div>
								</div>
							</div>
							<div class='row margin-tb-10' *ngIf="model.emailFlag">
								<div class="col-12 form-group">
									<div>
										<mat-label class='text-bold'>Email (required)</mat-label>
										<input type="text" class="form-control" name="email" formControlName="email" placeholder="Email Address" [(ngModel)]="model.email" />
									</div>
									<mat-error *ngIf="submittedform && form.email.errors">
										<strong *ngIf='submittedform && form.email.errors.required'>*Required</strong>
										<strong *ngIf='submittedform && form.email.errors.email'>*Invalid email address</strong>
									</mat-error>
								</div>
							</div>
							<!--  -->
							<div class='row margin-tb-10' *ngIf="isShow && model.smsFlag || model.whatsAppFlag">
								<div class="col-md-12 col-sm-12 col-xs-12 form-group">
									<div>
										<mat-label class='text-bold'>Phone Number (required)</mat-label>
										<div class='row'>
											<div class='col-md-12 col-sm-12 col-xs-12'>
												<div class="input-group mb-3">
													<div class="input-group-prepend">
														<span class="input-group-text" id="basic-addon1">{{countryCode}}</span>														
													</div>
													<input type="text" class="form-control" placeholder="Phone Number" formControlName="phoneNumber" [(ngModel)]="model.phoneNumber">
												</div>
											</div>
										</div>
									</div>
									<mat-error *ngIf="submittedform && form.phoneNumber.errors">
										<strong *ngIf='submittedform && form.phoneNumber.errors.required'>*Required</strong>
									</mat-error>
									<mat-error>
										<strong id="valid-msg-phone-invite" style="color:#0abb87 !important" class="hide">✓ Valid</strong>
										<strong id="error-msg-phone-invite" class="hide"></strong>
									</mat-error>
								</div>
							</div>
							<div class='col-12 '>
								<div class='flexClass nowrap'>
									<mat-checkbox name='invite_cust_terms' (change)="checkTC($event)" formControlName="certified" [(ngModel)]="model.certified"></mat-checkbox>
									&nbsp;<label for='invite_cust_terms'>I certify that all
										customers have opted in to receive these communications</label>
								</div>
								<mat-error *ngIf="submittedform && form.certified.errors">
									<strong *ngIf='submittedform && form.certified.errors.required'>*Required</strong>
								</mat-error>
							</div>
							<div class='col-12 padding-lr-20 text-center margin-tb-10'>
								<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)='onsubmitForm()' [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}" [disabled]="isInviteDisable">
									Invite Customer</button>
							</div>
						</div>
						<div class='col-md-4 col-sm-12 col-xs-12'>
							<div class='noteColor padding-10 margin-tb-5'>Add individual customers one-by-one. They'll be scheduled to
								receive whichever campaign you select (Email,SMS,etc.)
							</div>
						</div>		
					</div>
				</form>
			</div>
			<div class='col-1'></div>
		</div>
		
	</div>
</div>
