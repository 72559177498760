
// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatProgressSpinnerModule, MatCardModule, MatTableModule, MatDatepickerModule, MatDividerModule, MatRadioModule, MatCheckboxModule, MatFormFieldModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
// Angular in memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// Hammer JS
// import 'hammerjs';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from "./views/theme/theme.module";
// Partials
import { PartialsModule } from './views/partials/partials.module';
// Layout Services
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { MockLocationStrategy } from '@angular/common/testing'
import { MatChipsModule } from '@angular/material/chips';

// import { AngularGradientProgressbarModule } from "angular-gradient-progressbar";
// import { NgCircleProgressModule } from 'ng-circle-progress';

// import the CircularGaugeModule for the Circular Gauge component
import { CircularGaugeModule } from '@syncfusion/ej2-angular-circulargauge';
import { LegendService } from '@syncfusion/ej2-angular-circulargauge';
import { CircularGaugeAllModule } from '@syncfusion/ej2-angular-circulargauge';

import {
	DataTableService,
	FakeApiService,
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService
} from './core/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/layout.config';
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

//Toaster

import { ToastrModule } from 'ngx-toastr';
// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{ name: 'typescript', func: typescript },
		{ name: 'scss', func: scss },
		{ name: 'xml', func: xml },
		{ name: 'json', func: json }
	];
}
// import { NgChartjsModule } from 'ng-chartjs';
// let iconClasses = {
// 	error: 'toast-error',
// 	info: 'toast-info',
// 	success: 'toast-success',
// 	warning: 'toast-warning'
//   };
import { NgxFaviconModule } from 'ngx-favicon';
import { customFavicons } from '../../favicon.config';
import { UpgradePlanComponent } from './views/pages/General-settings/billing/upgrade-plan/upgrade-plan.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ClientReviewModule } from './views/pages/client-review/client-review.module';
import { LandingPageModule } from './views/pages/Landing-page/landing-page.module';

@NgModule({
    declarations: [AppComponent, UpgradePlanComponent],
    imports: [
        MatDatepickerModule,
        ReactiveFormsModule,
        MatChipsModule,
        // AngularGradientProgressbarModule,
        // NgCircleProgressModule,
        BrowserAnimationsModule,
        FormsModule,
        MatRadioModule,
        MatCheckboxModule,
        MatFormFieldModule,
        BrowserModule,
        MatDividerModule,
        AppRoutingModule,
        HttpClientModule,
        CircularGaugeModule ,
        CircularGaugeAllModule,
        environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
            passThruUnknownUrl: true,
            dataEncapsulation: false
        }) : [],
        NgxPermissionsModule.forRoot(),
        PartialsModule,
        LandingPageModule,
        CoreModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            enableHtml: true
        }),
        // NgChartjsModule.registerPlugin(),
        ClientReviewModule,
        NgxFaviconModule.forRoot({
            faviconElementId: 'favicon',
            defaultUrl: 'favicon.ico',
            custom: customFavicons,
        }),
        OverlayModule,
        StoreModule.forRoot(reducers, { metaReducers,
           runtimeChecks:{
            strictStateImmutability:false,
            strictActionImmutability: false
           }
         }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        StoreDevtoolsModule.instrument(),
        AuthModule.forRoot(),
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        MatCardModule,
        InlineSVGModule.forRoot(),
        ThemeModule,
        MatTableModule,
        NgIdleKeepaliveModule.forRoot()

    ],
    exports: [MatTableModule,],
    providers: [
        AuthService,
        LayoutConfigService,
        LayoutRefService,
        MenuConfigService,
        PageConfigService,
        KtDialogService,
        DataTableService,
        SplashScreenService,
        LegendService,
        // { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: GestureConfig
        },
        {
            // layout config initializer
            provide: APP_INITIALIZER,
            useFactory: initializeLayoutConfig,
            deps: [LayoutConfigService], multi: true
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: { languages: hljsLanguages }
        },
        // {provide:LocationStrategy,useClass:PathLocationStrategy },
        // template services
        SubheaderService,
        MenuHorizontalService,
        MenuAsideService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
