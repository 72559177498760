import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataTableService } from './../../../../../src/app/core/_base/layout/services/datatable.service';
import { PageConfigService } from './../../../../../src/app/core/_base/layout/services/page-config.service';
import { environment } from './../../../../../src/environments/environment';

@Component({
  selector: 'kt-confrim-alert',
  templateUrl: './confrim-alert.component.html',
  styleUrls: ['./confrim-alert.component.scss']
})
export class ConfrimAlertComponent implements OnInit {
  message='';
  buttonText='';;
  source='';
  title='';
  closeText='';
  model = null;
  loading: boolean = false;
  constructor(public dialogRef:  MatDialogRef<ConfrimAlertComponent>,
              @Inject(MAT_DIALOG_DATA) public navData: any,
              public http: HttpClient,
		public pageService: PageConfigService,
		public dataService: DataTableService,
    public cdr: ChangeDetectorRef) { }

  ngOnInit() {
    var scope = this;
    
    scope.source=scope.navData.source;
    scope.message=scope.navData.message;
    scope.buttonText=scope.navData.buttonText;
    scope.closeText=scope.navData.closeText;
    scope.model=scope.navData.model;
    if(scope.navData.title){
      scope.title=scope.navData.title;
    }
  }

  onSubmit(){
    let data={
      url:'',
      flag:true
    }
    if(this.source=='invite customer duplicate'){
      //data["url"]='/get-reviews/invited-customer-list';
      data.flag = false;
      let scope = this;
      scope.http.post(environment.apiPath + 'InviteCustomer', this.model)
			.subscribe((res: any) => {
				scope.loading = false;
				scope.cdr.markForCheck();

				if (res['success']) {
					scope.pageService.showSuccess('Invited', 'Customer invited successfully.');
				} else {
					scope.pageService.showError('Oops!!!', res['errorMsg'])
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

					this.pageService.hideLoader();
					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})

    }else if(this.source=='create sms'){
      data["url"]='/customization-options/template-settings';
    }
    this.dialogRef.close(data)
  }


    //close popup
  closeModal() {
    this.dialogRef.close()
  }

  sampleCsv(){
    // let c = new InviteCustomerComponent();
    // c.sampleDownloaCalled();
  }
}
