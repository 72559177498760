<!-- <script type="text/javascript">

    // Replace the text on the right hand side of the '=' with the id of your button.
    // Make sure you do not remove the single quotes.

    var buttonId = 'kt_login_signin_submit' // Change this

    // DO NOT CHANGE THE CODE BELOW
    function trackingListener() {
      var capterra_vkey = 'a0b66e2a35906d6f4d65c61858843e66',
      capterra_vid = '2148788',
      capterra_prefix = (('https:' == document.location.protocol)
        ? 'https://ct.capterra.com' : 'http://ct.capterra.com');

      var ct = document.createElement('script');
      ct.type = 'text/javascript';
      ct.async = true;
      ct.src = capterra_prefix + '/capterra_tracker.js?vid='
        + capterra_vid + '&vkey=' + capterra_vkey;

      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ct, s);
    };

    var button = document.getElementById(buttonId);
    button.addEventListener(
      'click',
      trackingListener
    );
  </script> -->
<!-- added by unnati -->
  <script>
	window.addEventListener('load',function(){
	if(window.location.href.indexOf('/register') != -1)
	{
	for(var i=0;i<document.querySelectorAll('#kt_login_signin_submit').length;i++)
	{
		document.querySelectorAll('#kt_login_signin_submit')[i].onclick = function(){
		gtag('event', 'conversion', {'send_to': 'AW-10992960526/F_7jCMCJ5uIDEI6I7fko'});
	  }
	}
	}
	});
	</script>

<!--begin::Body-->
<div class="kt-login__body">
	<!--begin::Signin-->

	<div class="kt-login__form" *ngIf='validToken==false && thankyouFlag==false'>
		<div class="text-center">
			<span class='text-1-3x'>Oops, looks like you are not invited.</span>
			<br />
			<span class="cursor-pointer text-1-1x">
				Fill up the below form to get an invitation if seats are available.</span>
		</div>
		<kt-auth-notice></kt-auth-notice>

		<form class="kt-login__form kt-form" [formGroup]="inviteForm" autocomplete="off">
			<div class="form-group">
				<mat-form-field>
					<mat-label>First Name</mat-label>
					<input matInput type="text" placeholder="First Name" formControlName="firstName" [(ngModel)]="inviteData.FirstName" />
					<mat-error *ngIf="isInviteControlHasError('firstName','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isInviteControlHasError('firstName','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isInviteControlHasError('firstName','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>Last Name</mat-label>
					<input matInput type="text" placeholder="Last Name" [(ngModel)]="inviteData.LastName" formControlName="lastName" />
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off" [(ngModel)]="inviteData.Email" />
					<mat-error *ngIf="isInviteControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isInviteControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isInviteControlHasError('email','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isInviteControlHasError('email','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>Company Name</mat-label>
					<input matInput type="text" placeholder="Company Name" [(ngModel)]="inviteData.CompanyName" formControlName="companyName" />
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-checkbox name="agree" formControlName="agree">
					I agree the&nbsp;
				</mat-checkbox>
				<span class='mat-checkbox-layout'>
					<a href="https://grabyourreviews.com/terms-of-service/" target="_blank">Terms & services</a>
				</span>

			</div>
			<div class="kt-login__actions">
				<button routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}</button>
				<button (click)="submitInvite()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading==true}">
					Get Invitation</button>
			</div>
		</form>
	</div>

	<div class="kt-login__form" *ngIf='validToken==true && thankyouFlag==false && isEmailVerification==false'>
		<!-- <div class="col-12 m-3 p-1 text-center text-1-2x danger-back-color roundedBox-radius">
			Currently we are adding new features,<br /> Kindly try login/signup after sometime
		</div> -->
		<div class="kt-login__title">
			<h3>{{ 'AUTH.REGISTER.TITLE' | translate }}</h3>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<!-- <ng-container *ngIf="userData.TimeZone == 'India Standard Time'">
			<div class="card p-3 text-center text-1-3x danger-color" >
				Currently we do not support registration from your country, please contact support team.
			</div>
		</ng-container> -->
		<!--<ng-container *ngIf="userData.TimeZone != 'India Standard Time'"></ng-container>-->
		<ng-container>
			<form class="kt-login__form kt-form" [formGroup]="registerForm" autocomplete="off">
				<div class="form-group">
					<mat-form-field>
						<mat-label>First Name </mat-label>
						<input matInput type="text" placeholder="First Name" formControlName="firstName" [(ngModel)]="userData.FirstName" pattern="[a-zA-Z ]*"/>
						<mat-error *ngIf="isControlHasError('firstName','required')">
							<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('firstName','minlength')">
							<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('firstName','maxlength')">
							<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 40</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('firstName','pattern')">
							<!-- <strong>{{ 'AUTH.VALIDATION.pattern' | translate }}</strong> -->
						 <strong class="text-danger">Please enter name proper</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field>
						<mat-label>Last Name</mat-label>
						<input matInput type="text" placeholder="Last Name" [(ngModel)]="userData.LastName" [ngModelOptions]="{standalone: true}" />
					</mat-form-field>
				</div>
				<div class="form-group">

					<mat-form-field>
						<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
						<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off" [(ngModel)]="userData.Email" email/>
						<mat-error *ngIf="isControlHasError('email','required')">
							<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('email','email')">
							<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('email','minlength')">
							<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('email','maxlength')">
							<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('email','pattern')">
							<!-- <strong>{{ 'AUTH.VALIDATION.pattern' | translate }}</strong> -->
						 <strong class="text-danger">Please provide a valid email address</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group margin-tb-10 phone-group">
					<mat-label style="width:100%;">Phone Number</mat-label>
					<div class="flexClass">
						<!-- <input type="tel" formControlName="PhoneNumber" class=" reg-phone"> -->
						<input matInput formControlName="PhoneNumber" type="tel" class=" reg-phone" id='registerPhoneText' name="PhoneNumber" [(ngModel)]="userData.PhoneNumber">
					</div>
					<p id="error-msg-phone-register" style="color: #fd397a;font-weight: bold;"></p>
					<p id="valid-msg-phone-register" style="color: #11ab2b;font-weight: bold;"></p>
					<mat-error *ngIf="isControlHasError('PhoneNumber','required')" style="font-size: 12px;">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<!-- <mat-error *ngIf="registerForm.controls.PhoneNumber.errors.pattern" style="font-size: 12px;">
						<strong>Enter Valid number</strong>
					</mat-error> -->

					<!-- <mat-error *ngIf="isControlHasError('PhoneNumber','maxlength')" style="font-size: 12px;">
						<strong>Enter Valid number</strong>
					</mat-error> -->
				</div>
				<div class="form-group">
					<mat-form-field>
						<mat-label>Country</mat-label>
						<mat-select formControlName="country" [(ngModel)]="userData.Country">
							<mat-option *ngFor='let item of countryList' [value]='item.code'>{{item.name}}</mat-option>
						</mat-select>
						<mat-error *ngIf="isControlHasError('country','required')">
							<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field>
						<mat-label>Timezone</mat-label>

						<mat-select formControlName="TimeZone" [(ngModel)]="userData.TimeZone">
							<mat-option *ngFor='let item of timeZoneList' [value]='item.id'>{{item.text}}</mat-option>
						</mat-select>
						<mat-error *ngIf="isControlHasError('TimeZone','required')">
							<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field>
						<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
						<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off" [(ngModel)]="userData.Password" />
						<mat-error *ngIf="isControlHasError('password','required')">
							<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('password','minlength')">
							<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('password','maxlength')">
							<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('password','pattern')">
							<strong>{{ 'AUTH.VALIDATION.PASSWORD_PATTERN' | translate }}</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field>
						<mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
						<input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}" formControlName="confirmPassword" autocomplete="off" />
						<mat-error *ngIf="isControlHasError('confirmPassword','required')">
							<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
							<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
						</mat-error>
						<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
							<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
						</mat-error>
						<mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
							<strong>Passsword and Confirm Password didn't match.</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-checkbox name="agree" formControlName="agree">
						I agree the &nbsp;

					</mat-checkbox>
					<span class='mat-checkbox-layout'>
						<a href="https://grabyourreviews.com/terms-of-service/" target="_blank">Terms & services</a>
					</span>
				</div>
				<div class="kt-login__actions">
					<a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot">
						{{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
					</a>
					<button routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}</button>
					<button (click)="submit()" [disabled]="loading" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading==true}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
				</div>
			</form>
		</ng-container>


	</div>
	<!-- -->
	<div class="kt-login__form" *ngIf='thankyouFlag==true'>
		<div class='row text-center'>
			<div class="col-md-12 col-sm-12 col-xs-12 margin-tb-10">
				<img src='../../../../../assets/media/custom-icons/positive.png' width="100">
			</div>
			<div class="col-md-12 col-sm-12 col-xs-12 text-bold text-1-3x">
				Congratulations
			</div>
			<div class="col-md-12 col-sm-12 col-xs-1  text-1-2x">
				You have been added to our beta tester list. You will receive an email for invitation once seats are available.
				<br />
				<br />
				Thank you!
			</div>
		</div>
	</div>

	<div *ngIf="isEmailVerification==true" style="text-align: center;">
		<div class="row">
			<div class="col-12">
				<img src="../../../../../assets/media/email-verification.png">
			</div>
			<div class="col-12">
				<h3 style="text-transform: none !important;">Check your inbox</h3>
			</div>
			<div class="col-12">
				<p>We sent an email with a confirmation link on <b>{{emailAddress}}</b></p>
			</div>
			<div class="col-12">
				<div class="row" style="display: inline-flex;
				align-items: center;
				width: 100%;">
					<div class="col-6">
						<a style="text-decoration: underline;cursor: pointer;color: #368cd7;" (click)="sendEmailAgain()">Resend verification email</a>
					</div>
					<div class="col-6" style="margin-top: 2%;">
						<!-- [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading==true}" -->
						<button routerLink="/auth/login" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" >Login</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
