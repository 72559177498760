<div class="card-align">
	<span class='gray-color text-1x'>Choose Page</span>
	<br>
	<div *ngFor="let i of facebookPageList" style="padding-top: 10px;">
		<div class="card" style="width: 100%;cursor: pointer;" (click)="submit(i)">
			<div class="card-body">
				<span class="card-text" style="float: left;">{{i.pageName}}</span>
				<i class="arrow right"></i>
			</div>
		</div>
		<br>
	</div>
</div>

