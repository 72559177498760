<div class='row text-center'>
	<div class='col-md-12 col-sm-12 col-xs-12 text-left'>

		<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10 text-center logoParent' *ngIf='logo!=null && logo.length>0'>
			<img [src]='logo' height="auto" class='optInLogo'>
		</div>
		<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10 text-center logoParent text-1-2x text-bold' 
		*ngIf='logo==null || logo.length==0'>
			{{ companyName}}
		</div>

		<div class='row margin-10 '>
			<div class='col-md-4 col-sm-12 col-xs-12 margin-tb-10 logoParent text-1-3x text-bold'>
				Invite Customer
			</div>
			
			<div class='col-md-12 col-sm-12 col-xs-12 margin-tb-10'>
				<mat-divider></mat-divider>
			</div>
			<form [formGroup]="inviteForm">
				<div class='row padding-10'>
					<div class='col-md-12 col-sm-12 col-xs-12'>
					<div class='row form-group' *ngIf="isShow">
							<div class='col-md-4 col-sm-6 col-xs-6' *ngIf="isEmailFlag">
								<mat-checkbox formControlName="emailFlag" [(ngModel)]="model.emailFlag" (ngModelChange)='changeCheckbox()'>Email</mat-checkbox>
							</div>
							<div class='col-md-4 col-sm-6 col-xs-6' *ngIf="isSMSFlag">
								<mat-checkbox formControlName="smsFlag" [(ngModel)]="model.smsFlag" (ngModelChange)='changeCheckbox()'>SMS/Text</mat-checkbox>
								<!-- SMS/Text/Whatsapp -->
							</div>
						</div>
						<mat-error *ngIf="submittedform && emailSmsErrorFlag && isShow">
							<strong>Atleast one selected</strong>
						</mat-error>
						<div class='row margin-tb-10'>
							<div class="col-md-12 col-sm-12 col-xs-12">
								<div>
									<mat-label class='text-bold'>First Name</mat-label>
									<input type="text" class="form-control" name="firstName" placeholder="First Name" formControlName="fname" [(ngModel)]="model.fname" />
								</div>
							</div>
						</div>
						<div class='row margin-tb-10'>
							<div class="col-md-12 col-sm-12 col-xs-12">
								<div>
									<mat-label class='text-bold'>Last Name (optional)</mat-label>
									<input type="text" class="form-control" name="lastName" placeholder="Last Name" formControlName="lname" [(ngModel)]="model.lname" />
								</div>
							</div>
						</div>
						<div class='row margin-tb-10' *ngIf="model.emailFlag && isEmailFlag">
							<div class="col-md-12 col-sm-12 col-xs-12">
								<div>
									<mat-label class='text-bold'>Email (required)</mat-label>
									<input type="text" class="form-control" name="email" formControlName="email" placeholder="Email Address" [(ngModel)]="model.email" />
								</div>
								<mat-error *ngIf="submittedform && inviteForm.controls.email.errors">
									<strong *ngIf='submittedform && inviteForm.controls.email.errors.required'>*Required</strong>
									<strong *ngIf='submittedform && inviteForm.controls.email.errors.email'>*Invalid email address</strong>
								</mat-error>
							</div>
						</div>
						<!-- <div class='row margin-tb-10' *ngIf="model.smsFlag || model.whatsAppFlag">
							<div class="col-md-12 col-sm-12 col-xs-12 ">
								<div>
									<mat-label class='text-bold'>Phone Number (required)</mat-label>
									<div class='row'>
										<div class='col-6'>
											<input type="tel" id='inviteText' class="form-control" name="phoneNumber" placeholder="Phone Number" formControlName="phoneNumber" [(ngModel)]="model.phoneNumber" />
										</div>
										
									</div>
								</div>
								<mat-error *ngIf="submittedform && inviteForm.controls.phoneNumber.errors">
									<strong *ngIf='submittedform && inviteForm.controls.phoneNumber.errors.required'>*Required</strong>
								</mat-error>
								<mat-error>
									<strong id="valid-msg-phone-invite" style="color:#0abb87 !important" class="hide">✓ Valid</strong>
									<strong id="error-msg-phone-invite" class="hide"></strong>
								</mat-error>
							</div>
						</div> -->

						<div class='row margin-tb-10' *ngIf="isShow && model.smsFlag || model.whatsAppFlag">
							<div class="col-md-12 col-sm-12 col-xs-12 form-group">
								<div>
									<mat-label class='text-bold'>Phone Number (required)</mat-label>
									<div class='row'>
										<div class='col-md-12 col-sm-12 col-xs-12'>
											<div class="input-group mb-3">
												<div class="input-group-prepend">
													<span class="input-group-text" id="basic-addon1">{{countryCode}}</span>
												</div>
												<input type="text" class="form-control" placeholder="Phone Number" formControlName="phoneNumber" [(ngModel)]="model.phoneNumber">
											</div>
										</div>
									</div>
								</div>
								<mat-error *ngIf="submittedform && inviteForm.controls.phoneNumber.errors">
									<strong *ngIf='submittedform && inviteForm.controls.phoneNumber.errors.required'>*Required</strong>
								</mat-error>
								<mat-error>
									<strong id="valid-msg-phone-invite" style="color:#0abb87 !important" class="hide">✓ Valid</strong>
									<strong id="error-msg-phone-invite" class="hide"></strong>
								</mat-error>
							</div>
						</div>


						<div class='col-md-12 col-sm-12 col-xs-12'>
							<div class='flexClass nowrap'>
								<mat-checkbox class='checkbox-white-space-no-wrap' formControlName="certified" [(ngModel)]="model.certified">

								</mat-checkbox>
								&nbsp;I certify that all customers have opted in to receive these communications.
								I further certify that I am an authorized representative of {{companyName}} and
								I understand and accept the Email/SMS service provider's terms of service.
								<!-- /Whatsapp -->
							</div>

							<mat-error *ngIf="submittedform && inviteForm.controls.certified.errors">
								<strong *ngIf='submittedform && inviteForm.controls.certified.errors.required'>*Required</strong>
							</mat-error>
						</div>
						<div class='col-md-12 col-sm-12 col-xs-12 padding-lr-20 text-center margin-tb-10'>
							<!-- (click)='onsubmitForm()'[disabled]="isDisable" -->
							<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5 disabled"  [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}"  >
								Send</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
