import { environment } from './../../../../../environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'kt-custom-reviewsite-link-model',
  templateUrl: './custom-reviewsite-link-model.component.html',
  styleUrls: ['./custom-reviewsite-link-model.component.scss']
})
export class CustomReviewsiteLinkModelComponent implements OnInit {
  @ViewChild('linkImg') linkImg: any
  customForm: UntypedFormGroup;
  formSubmitted: boolean = false;
  invalidURL: boolean = false;
  targetSiteData: any;
  model = {
    logoImg: '',
    LinkText: '',
    URL: '',
    Instruction: ''
  }
  imageData: any;
  deletedImageArr = [];
  imageError:boolean=false;
  urlRegEx = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  // public urlRegEx=/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
  public tools: object = {
    items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', '-',
      'LowerCase', 'UpperCase', '|', 'OrderedList', 'UnorderedList', '|', 'CreateLink', '-',
      'Indent', 'Outdent', '|', 'FullScreen', '|', 'ClearFormat',
    ]
  };
  constructor(public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomReviewsiteLinkModelComponent>,
    @Inject(MAT_DIALOG_DATA) public navData: any,
    public cdr: ChangeDetectorRef) { }

  ngOnInit() {

    var scope = this;

    scope.targetSiteData = scope.navData.targetSiteData
    
    scope.customForm = scope.formBuilder.group({
      logoImg: [''],
      LinkText: ['', Validators.required],
      URL: ['', Validators.required],
      Instruction: ['']
    })
    if (scope.targetSiteData != undefined) {
      scope.model.LinkText = scope.targetSiteData.reviewSiteName;
      scope.model.URL = scope.targetSiteData.siteURL;
      scope.model.Instruction = scope.targetSiteData.instruction;
      if (scope.targetSiteData.imageURL != undefined &&
        scope.targetSiteData.imageURL != null &&
        scope.targetSiteData.imageURL.length > 0) {
        var preview = document.querySelector('.businessLogoImg');
        preview['style'].backgroundImage = 'url(' + scope.targetSiteData.imageURL + ')';
        document.querySelector('.custom-logo-cancel')['style'].display = 'flex';
      }else{
        document.querySelector('.custom-logo-cancel')['style'].display = 'none';
      }

      scope.model.logoImg = scope.targetSiteData.imageURL
    }
    scope.cdr.markForCheck()
  }
  onSubmitCustom() {
    var scope = this;


    scope.formSubmitted = true;

    if (scope.customForm.invalid) {
      return;
    }
    if (!scope.urlRegEx.test(scope.customForm.value.URL)) {
      scope.invalidURL = true;
      return;
    }
    if(scope.imageError==true){
      return;
    }
    scope.closeModal()
  }
  textEntered() {
    this.invalidURL = false;
  }
  //Delete Image
  deleteImg() {
    var scope = this;
    
    scope.deletedImageArr.push(scope.model.logoImg);
    var preview = document.querySelector('.businessLogoImg');
    preview['style'].backgroundImage = 'url(/assets/media/users/default.jpg)';
    scope.model.logoImg = '';
    document.querySelector('.custom-logo-cancel')['style'].display = 'none';
  }
  onFilesAdded(event) {
    var scope = this;
    
    scope.imageError=false;
    var preview = document.querySelector('.businessLogoImg');

    var reader = new FileReader();

    reader.addEventListener("load", function (resp) {

      preview['style'].backgroundImage = 'url(' + resp.currentTarget['result'] + ')';
      scope.customForm.value.logoImg = resp.currentTarget['result'];

      document.querySelector('.custom-logo-cancel')['style'].display = 'flex';
      scope.model.logoImg = resp.currentTarget['result']
    }, false);

    if (event.target.files[0]) {

      if (/\.(jpe?g|png|gif)$/i.test(event.target.files[0].name)) {
        if (event.target.files[0].size <= 1000000) {
          reader.readAsDataURL(event.target.files[0]);
          scope.imageData = event.target.files[0];
        }else{
          scope.imageError=true;
          // alert('image size exceeded')
        }
        
      } else {
        alert('Invalid Image')
      }
    }
  }
  closeModal() {
    var scope = this;

    let data = {
      SiteName: scope.customForm.value.LinkText,
      SiteUrl: scope.customForm.value.URL,
      Instruction: scope.customForm.value.Instruction,
      ImageURL: scope.model.logoImg,
      deletedArr: scope.deletedImageArr,
      imgChanged: false,
      source: scope.navData.source,
      imageData:{}
    }
    if (scope.imageData) {
      data['imageData']=scope.imageData
      // data['ImageURL'] = scope.imageData;
      data['imgChanged'] = true;
    }
    if (scope.navData.source == 'new') {
      data['name'] = 'customLink'
    } else if (scope.navData.source == 'edit') {
      data['name'] = scope.targetSiteData.reviewSiteName.split(' ')[0];
      data['id'] = scope.targetSiteData.id
    }
    scope.dialogRef.close(data)
  }
}
