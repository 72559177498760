import { MatCardModule } from '@angular/material/card';
import { OptinpageComponent } from './optinpage.component';
import { ReviewRequestFormComponent } from './review-request-form/review-request-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule, MatCheckboxModule, MatFormFieldModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    OptinpageComponent,
    ReviewRequestFormComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    RouterModule.forChild([
      {
        path: '',
        component: OptinpageComponent,
      },
      {
        path: '**',
        component: OptinpageComponent,
      },
    ]),
  ]
})
export class OptinpageModule { }
