<!-- <mat-card class=' text-1-2x text-center  noticeCard' style="background:#e7ffab !important;font-size: 1rem; 
margin: 0 !important;
box-shadow: none; padding: 10px !important;" *ngIf="isDisable">
	<div class="row">
		<div class="col-md-11 col-sm-12 col-xs-12">
			<i class="fas fa-exclamation-triangle text-1-5x danger-color" style="margin-right: 5px;"></i>
			Connect review platform to start send invitation, <a [routerLink]="['/review-platforms']" routerLinkActive="router-link-active" >Connect Review Platform</a>
		</div>
	</div>

</mat-card> -->

<div class='row'>
	<div class='col-md-12 padding-tb-5 text-1-2x text-right'>Share this URL with your customers to invite customers:
		<span class='primary-color cursor pointer text-bold'><a [href]='optinUrl' target='_blank'>{{optinUrl}}</a></span>
	</div>
	<div class='col-md-5 col-sm-12 col-xs-12'>
		<mat-card class="padding-20 margin-10">
			<div class='row margin-tb-5'>
				<div class='col-md-6 col-sm-12 col-xs-12 text-1-2x'>
					Authentication
				</div>
				<div class='col-md-6 col-sm-12 col-xs-12 text-right'>
					<mat-slide-toggle [(ngModel)]="authToggle" (change)='changeToggle($event)'> </mat-slide-toggle>
				</div>
			</div>
			<mat-accordion class="example-headers-align" multi="true">
				<mat-expansion-panel class='padding-tb-10' *ngIf='authToggle==true' [expanded]="authToggle==true?true:false" (opened)="setStep(0)" hideToggle="true">

					<div class='margin-tb-5'>
						<mat-label>Username</mat-label>
						<input class='form-control' [ngClass]="{'errorTextBox': submittedFlag && (authDataModel.username && authDataModel.username.length==0)}" placeholder="Username" [(ngModel)]="authDataModel.username">
					</div>
					<div class='margin-tb-5'>
						<mat-label>Password</mat-label>
						<input class='form-control' type="text" min="1" placeholder="Password" [(ngModel)]="authDataModel.password" [ngClass]="{'errorTextBox': submittedFlag && (authDataModel.password && authDataModel.password.length==0)}">
					</div>
					<div class='margin-tb-5 text-center'>
						<button class="btn btn-primary btn-elevate kt-login__btn-primary " 
						[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': btnLoader}" (click)="submitAuthentication()">Save</button>
					</div>
					<!-- <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                  </mat-action-row> -->
				</mat-expansion-panel>
				<mat-expansion-panel [expanded]="true" (opened)="setStep(1)" hideToggle="true">
					<mat-expansion-panel-header>
						<mat-panel-title>
							Magic link
						</mat-panel-title>
						<mat-panel-description class='text-right'>
							<i class="fas fa-magic gray-color"></i>
							<!-- <mat-icon>star_half</mat-icon> -->
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class='margin-tb-5 row'>
						<div class='col-md-11 col-sm-12 col-xs-12'>
							<textarea class='form-control' style="min-height: 120px;" type="text" min="1" placeholder="text" [(ngModel)]="magicLink" readonly></textarea>
						</div>
						<div class='col-md-1 col-sm-12 col-xs-12 text-center no-padding'>
							<button mat-icon-button ngxClipboard [cbContent]="magicLink" (click)="tooltip.show()" class='text-1-5x'>
								<i class="far fa-copy"></i>
							</button>
							<span matTooltip="Copied to clipboard" #tooltip="matTooltip"></span>
							<!-- <i class="far fa-copy text-1-5x cursor-pointer" (click)=''></i> -->
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-card>
	</div>
	<div class='col-md-7 col-sm-12 col-xs-12'>
		<mat-card class="padding-20 margin-10">
			<kt-review-request-form [logo]='logo' [companyName]='companyName'></kt-review-request-form>
		</mat-card>
	</div>
</div>
