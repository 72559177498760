<!-- begin:: Content Head -->
<div class="kt-subheader kt-grid__item" [ngClass]="{'kt-container--clear': clear}" [hidden]="subheaderService.disabled$ | async" id="kt_subheader">
	<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
		<h3 *ngIf="title" class="kt-subheader__title">{{title}}</h3>
		<h4 *ngIf="desc" class="kt-subheader__desc">{{desc}}</h4>
		<div class="kt-subheader__breadcrumbs" *ngIf="breadcrumbs.length > 0">
			<span class="kt-subheader__separator" [hidden]="true"></span>
			<a class="kt-subheader__breadcrumbs-home"><i class="flaticon2-shelter"></i></a>
			<ng-container *ngFor="let item of breadcrumbs">
				<span class="kt-subheader__breadcrumbs-separator"></span>
				<a [routerLink]="item.page" [queryParams]="item.queryParams" class="kt-subheader__breadcrumbs-link">
					{{item.title}}
				</a>
			</ng-container>
			<!-- <span class="kt-subheader__breadcrumbs-link kt-subheader__breadcrumbs-link--active">Active link</span> -->
		</div>
	</div>
	<div class="kt-subheader__toolbar">
		<div class="kt-subheader__wrapper">
			<a class="btn kt-subheader__btn-daterange" id="kt_dashboard_daterangepicker" data-placement="left" ngbTooltip="Select dashboard daterange">
				<span class="kt-subheader__btn-daterange-title" id="kt_dashboard_daterangepicker_title">Today</span>&nbsp;
				<span class="kt-subheader__btn-daterange-date" id="kt_dashboard_daterangepicker_date">{{today | date:'MMM dd'}}</span>
				<span class="kt-svg-icon kt-svg-icon--sm"
					[inlineSVG]="'./assets/media/icons/svg/Communication/Chat-check.svg'"></span>
			</a>
		
			<div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline" data-placement="left" ngbTooltip="Quick actions">
				<a ngbDropdownToggle href="javascript:;" class="btn btn-icon">
					<span class="kt-svg-icon kt-svg-icon--success kt-svg-icon--md" [inlineSVG]="'./assets/media/icons/svg/Files/File-plus.svg'"></span>
				</a>
				<div ngbDropdownMenu class="dropdown-menu">
					<a class="dropdown-item"><i class="la la-plus"></i> New Report</a>
					<a class="dropdown-item"><i class="la la-user"></i> Add Customer</a>
					<a class="dropdown-item"><i class="la la-cloud-download"></i> New Download</a>
					<div class="dropdown-divider"></div>
					<a class="dropdown-item"><i class="la la-cog"></i> Settings</a>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end:: Content Head -->
