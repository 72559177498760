<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
	<div class="kt-header__topbar-item cursor-pointer"  (click)='logout()' data-placement="bottom" 
	ngbTooltip="Sign out">
		<span class="kt-header__topbar-icon">
			<span class="kt-svg-icon" [inlineSVG]="'./assets/media/icons/svg/Navigation/Sign-out.svg'"></span>
		</span>
	</div>
	<kt-user-profile></kt-user-profile>
	<!--end: User bar -->
</div>
<!-- end:: Header Topbar -->
