<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
	<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
		<div class="kt-footer__copyright" *ngIf='showBranding==true'>
			{{today | date:'yyyy'}}&nbsp;&copy;&nbsp;
			<a href="https://grabyourreviews.com/" target="_blank" class="kt-link">{{systemName}}</a>
		</div>
	</div>
</div>
<!-- end:: Footer -->
