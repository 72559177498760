import { Router } from '@angular/router';
import { MatTabGroup } from '@angular/material';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ClientReviewComponent } from '../../client-review/client-review.component';

@Component({
  selector: 'kt-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ReviewPageComponent implements OnInit {
  @ViewChild('invitationTabGroup') tabGroup: MatTabGroup;

  @ViewChild(ClientReviewComponent) child: ClientReviewComponent;

  reviewPageSettingModel = {
    headerFontColor: '',
    headerBackgroundColor: '',
    headerTitle: '',
    headerBody: '',
    messageforPublicReview: '',
    welcomemessageforPublicReview: '',
    publicReviewThankYouMsg: '',
    publicPostClickhereText: '',
    publicLeaveYourReviewMsg: '',
    publicPostAReviewTextMsg: '',
    publicReviewThankyouMessage: '',
    publicReviewAppreciateMessage: '',
    publicBadExperienceTextMsg: '',
    publicGoodExperienceTextMsg: '',
    publicExtremelyLikely: '',
    publicNotLikelyatAll: '',
    publicBadFaceTextMsg: '',
    publicGoodFaceTextMsg: '',
    publicOkayFaceTextMsg: '',
    messageforPrivateReview: '',
    contactFromThankyouMsg: '',
    privatePostAReviewTextMsg: '',
    privateWhatToDoPlaceHolderMsg: '',
    privateNamePlaceholderMsg: '',
    hasNameRequired: '',
    hasNameEnable: '',
    privatePhoneNumberPlaceholderMsg: '',
    hasPhoneNumberRequired: '',
    hasPhoneNumberEnable: '',
    privateCloseButtonTextMsg: '',
    privateSubmitButtonTextMsg: '',
    privateEmailPlaceholderMsg: '',
    hasEmailRequired: '',
    hasEmailEnable: '',

    privatePostClickhereText: '',
    hidePublicReviewMsg: false
    // formType: ""
  }
  layoutUrl = '';
  profileId: any;
  accessKey: string;
  loading: boolean = false;
  fromSubmitted: boolean = false;
  reviewSettingForm: UntypedFormGroup;

  thumbsThresholdArr = [
    'All to private feedback',
    'Thumbs up to review',
    'All to review',
  ]
  starsThresholdArr = [
    'All to private feedback',
    '5 star to review',
    '4 star or more to review',
    '3 star or more to review',
    '2 star or more to review',
    'All to review',
  ];
  heartsThresholdArr = [
    'All to private feedback',
    '5 heart to review',
    '4 heart or more to review',
    '3 heart or more to review',
    '2 heart or more to review',
    'All to review',
  ];
  facesThresholdArr = [
    'All to private feedback',
    'Happy face to review',
    'Happy or neutral face to review',
    'All to review',
  ];
  npsThreshholddArr = [
    'All to private feedback',
    '9 or more to review',
    '6 or more to review',
    'All to review',
  ];
  platformThreshholddArr = [
    'All to review platforms'
  ]
  layoutList = [];
  seletedFunnel= 'Thumbs';
  selectedFunnelId = 0;
  valueOfThreshold = 'Thumbs up to review'
  reviewSiteList = []
  public tools: object = {
    items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', '-',
      'LowerCase', 'UpperCase', '|', 'OrderedList', 'UnorderedList', '|', 'CreateLink', '-',
      'Indent', 'Outdent', '|', 'SourceCode', 'ClearFormat',
    ]
  };
  hasMultiLocation: boolean = false;
  contentTabFlag: boolean = true
  reviewSettingTabFlag: boolean = false;
  autoRedirection: boolean = false;
  opensameTab: boolean = false;
  hidePublicReviewMsg: any = false;

  constructor(public cdr: ChangeDetectorRef,
    public pageService: PageConfigService,
    public http: HttpClient,
    public formBuilder: UntypedFormBuilder,
    public router: Router) {
  }

  ngOnInit() {
    debugger;
    var scope = this;
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    scope.pageService.callChooseProfile();

    //Call the Get Layout Setting API
    scope.http.get(environment.apiPath + 'GetLayoutList?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId)
      .subscribe((res) => {
        scope.pageService.hideLoader();
        if (res['success']) {
          scope.layoutList = res['data'];
          if (scope.hasMultiLocation == false) {
            scope.layoutList = scope.layoutList.filter((alone) => { if (alone.layoutName.toLocaleLowerCase() != 'location') { return alone } })
          }
          scope.cdr.markForCheck();
        } else {

        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      });
    scope.loadContentpage();
    scope.loadReviewPageData();
    scope.loadReviewPageSettings();
    scope.hasMultiLocation=JSON.parse(localStorage.getItem('hasMultiLocation'))
    scope.pageService.getProgileId().subscribe(message => {
      if (message.id) {
        scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
        scope.tabGroup.selectedIndex = 0;

        scope.cdr.markForCheck();
        if (scope.router.url.includes('review-page')) {
          scope.loadContentpage();
          scope.loadReviewPageData();
          scope.loadReviewPageSettings();

        }

      }
    });


    scope.reviewSettingForm = scope.formBuilder.group({
      headerFontColor: ['', Validators.required],
      headerBackgroundColor: ['', Validators.required],
      headerTitle: ['', Validators.required],
      headerBody: ['', Validators.required],
      messageforPublicReview: ['', Validators.required],
      welcomemessageforPublicReview: ['', Validators.required],
      publicReviewThankYouMsg: ['', Validators.required],
      publicPostClickhereText: ['', Validators.required],
      publicLeaveYourReviewMsg: ['', Validators.required, Validators.maxLength(25)],
      publicPostAReviewTextMsg: ['', Validators.required],
      publicReviewThankyouMessage: ['', Validators.required],
      publicReviewAppreciateMessage: ['', Validators.required],
      publicBadExperienceTextMsg: ['', Validators.required, Validators.maxLength(25)],
      publicGoodExperienceTextMsg: ['', Validators.required, Validators.maxLength(25)],
      publicExtremelyLikely: ['', Validators.required],
      publicNotLikelyatAll: ['', Validators.required],
      publicBadFaceTextMsg: ['', Validators.required, Validators.maxLength(25)],
      publicGoodFaceTextMsg: ['', Validators.required, Validators.maxLength(25)],
      publicOkayFaceTextMsg: ['', Validators.required, Validators.maxLength(25)],
      messageforPrivateReview: ['', Validators.required],
      contactFromThankyouMsg: ['', Validators.required],
      privatePostAReviewTextMsg: ['', Validators.required],
      privateWhatToDoPlaceHolderMsg: ['', Validators.required],
      privateNamePlaceholderMsg: ['', Validators.required],
      hasNameRequired: [''],
      hasNameEnable: [''],
      privatePhoneNumberPlaceholderMsg: ['', Validators.required],
      hasPhoneNumberRequired: [''],
      hasPhoneNumberEnable: [''],
      privateCloseButtonTextMsg: ['', Validators.required],
      privateSubmitButtonTextMsg: ['', Validators.required],
      privatePostClickhereText: ['', Validators.required],
      privateEmailPlaceholderMsg: ['', Validators.required],
      hasEmailRequired: [''],
      hasEmailEnable: [''],

      // formType: [""]
    })

    scope.pageService.setTitle('Review Page')

  }

  //tab Change event
  tabChanged(event) {
    var scope = this;
    //this.child.parseData()
    this.child.loadPage();
    // this.child.loadContentpage();
    // this.child(scope.slug, 'nidwithoutmodel');
    if (event.index == 0) {
      scope.contentTabFlag = true;
    } else if (event.index == 1) {
      scope.reviewSettingTabFlag = true;
      scope.cdr.markForCheck();
      document.querySelector('.review-setting-editor')['ej2_instances'][0].refreshUI();
    }
  }
  //Review Page settings Functions (Tab1 -layout selection)
  loadReviewPageData() {
    var scope = this;

    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'GetProfileReviewlayOut?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId)
      .subscribe((res) => {
        scope.pageService.hideLoader();
        if (res['success']) {
          this.dataDis = res['data'];
          // let url = res['data'].landingPageUrl
          // let urlArray = url.split('/')
          // let data = urlArray[urlArray.length-1]
          // let dataValues = data;
          // localStorage.setItem("companyName", JSON.stringify(dataValues));
          scope.seletedFunnel = res['data'].layoutName;
		  this.selectedFunnelId = res['data'].layoutId;
          scope.autoRedirection = res['data'].autoRedirection;
          scope.opensameTab = res['data'].isOpenSameTab;
          scope.hidePublicReviewMsg = res['data'].hidePublicReviewMessage;
         // scope.hasMultiLocation = res['data'].isMultilocation;
          if (res['data'].threshold == null) {
            if (scope.seletedFunnel.toLocaleLowerCase() == 'thumbs') {
              scope.valueOfThreshold = 'Thumbs up to review';
            } else if (scope.seletedFunnel.toLocaleLowerCase() == 'stars') {
              scope.valueOfThreshold = '4 star or more to review';
            } else if (scope.seletedFunnel.toLocaleLowerCase() == 'faces') {
              scope.valueOfThreshold = 'Happy or neutral face to review';
            } else if (scope.seletedFunnel.toLocaleLowerCase() == 'location') {
              scope.valueOfThreshold = ''
            } else if (scope.seletedFunnel.toLocaleLowerCase() == 'nps') {
              scope.valueOfThreshold = '10 to review';
            }
            else if (scope.seletedFunnel.toLocaleLowerCase() == 'review platforms') {
              scope.valueOfThreshold = 'All to review platforms';
            }
          } else {
            scope.valueOfThreshold = res['data'].threshold;
          }
          scope.layoutUrl = res['data'].landingPageUrl;
          scope.cdr.markForCheck();
          this.child.ngOnInit();
          this.child.loadPage();
          // this.child.parseData()
          // this.child.loadContentpage();

        }

      })

    scope.cdr.markForCheck();
  }

  changeFunnel(target) {
    var scope = this;
    scope.seletedFunnel = target;
    for (let i in scope.layoutList) {
      if (scope.layoutList[i].layoutName == scope.seletedFunnel) {
        scope.selectedFunnelId = scope.layoutList[i].layoutId
      }
    }

    if (scope.seletedFunnel.toLocaleLowerCase() == 'thumbs') {
      scope.valueOfThreshold = 'Thumbs up to review'
    } else if (scope.seletedFunnel.toLocaleLowerCase() == 'stars') {
      scope.valueOfThreshold = '4 star or more to review'
    } else if (scope.seletedFunnel.toLocaleLowerCase() == 'faces') {
      scope.valueOfThreshold = 'Happy or neutral face to review'
    } else if (scope.seletedFunnel.toLocaleLowerCase() == 'location') {
      scope.valueOfThreshold = ''
    } else if (scope.seletedFunnel.toLocaleLowerCase() == 'nps') {
      scope.valueOfThreshold = '6 or more to review';
    }
    else if (scope.seletedFunnel == 'Review Platforms') {
      scope.valueOfThreshold = 'All to review platforms';
    }
    scope.cdr.markForCheck();
    scope.updateReviewpageData()
  }
  changeThreshold() {
    var scope = this;

    scope.updateReviewpageData()
  }
  changeAuto() {
    var scope = this;

    scope.updateReviewpageData()
  }
  changeOpenTabStatus() {
    var scope = this;

    scope.updateReviewpageData()
  }

  drop(event: CdkDragDrop<string[]>) {
    var scope = this;

    moveItemInArray(scope.reviewSiteList, event.previousIndex, event.currentIndex);

    scope.changePrimary(scope.reviewSiteList[0]);
  }
  //Chnage primary review site
  changePrimary(target) {
    var scope = this;
    let priorityArr = [];
    scope.reviewSiteList.forEach((alone, i) => {
      let temp = {}
      temp['Id'] = alone.id;
      temp['Priority'] = i + 1;
      temp['name'] = alone.reviewSiteName
      priorityArr.push(temp)
    });
    let data = {
      Id: target.id,
      Key: scope.accessKey,
      IsPrimary: !target.isPrimary,
      Priority: priorityArr
    }

    scope.pageService.showLoader();
    scope.http.post(environment.apiPath + "UpdatePrimaryReviewSource", data).
      subscribe((res) => {
        scope.pageService.hideLoader();
        if (res['success']) {
          scope.pageService.showSuccess('Updated', 'Reviewsite details updated successfully.');
          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
          scope.loadContentpage();
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  updateReviewpageData() {
    var scope = this;

    var datatosend = {
      Key: scope.accessKey,
      ProfileId: scope.profileId,
      LayoutId: scope.selectedFunnelId,
      Threshold: scope.valueOfThreshold,
      AutoRedirection: scope.autoRedirection,
      IsOpenSameTab: scope.opensameTab,
      hidePublicReviewMessage: scope.hidePublicReviewMsg
    }

    scope.pageService.showLoader();
    scope.http.post(environment.apiPath + 'UpdateProfileReviewlayOut', datatosend)
      .subscribe((res) => {
        scope.pageService.hideLoader();

        if (res['success']) {
          scope.pageService.showSuccess('Updated', 'Layout details updated successfully.');
          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
          scope.loadReviewPageData();
         // this.child.ngOnInit();
         // this.child.parseData();
        //  this.child.loadPage();
         // this.child.loadContentpage();
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  //Content page setting fuctions
  dataDis;

  loadContentpage() {
    var scope = this;
    scope.reviewSiteList = [];
    let tempArr = [];
    scope.cdr.markForCheck();
    scope.loading = true;
    scope.http.get(environment.apiPath + 'GetReviewSource?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId)
      .subscribe((res) => {
        scope.loading = false;

        if (res['success']) {
          // tempArr = JSON.parse(JSON.stringify(res['data']));
          scope.reviewSiteList = [];

          res['data'].filter((e) => {
            if (e.getReview) {
              this.reviewSiteList.push(e);
            }
          })
          // scope.reviewSiteList = JSON.parse(JSON.stringify(res['data']));
          // if (tempArr.length > 0) {
          //   scope.reviewSiteList[0] = tempArr.filter(element => {
          //     if (element.isPrimary == true) {
          //       return element
          //     }
          //   })[0];
          //   tempArr.forEach(element => {
          //     if (element.isPrimary == false) {
          //       scope.reviewSiteList.push(element)
          //     }
          //   });
          // }

          scope.cdr.markForCheck();
          this.child.ngOnInit();
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  //Review page setting fuctions

  loadReviewPageSettings() {
    var scope = this;
    // scope.resetReviewPageSetting()
    scope.pageService.showLoader()
    scope.http.get(environment.apiPath + 'GetReviewPageSetting?Key=' + scope.accessKey +
      '&ProfileId=' + scope.profileId)
      .subscribe((res) => {
        scope.pageService.hideLoader()
        if (res['success']) {
          let revireSettingData = res['data'];
          scope.reviewPageSettingModel.headerFontColor = revireSettingData.headerFontColor;
          scope.reviewPageSettingModel.headerBackgroundColor = revireSettingData.headerBackgroundColor;
          scope.reviewPageSettingModel.headerTitle = revireSettingData.headerTitle;
          scope.reviewPageSettingModel.headerBody = revireSettingData.headerBody;
          scope.reviewPageSettingModel.messageforPublicReview = revireSettingData.messageforPublicReview;
          scope.reviewPageSettingModel.welcomemessageforPublicReview = revireSettingData.publicWelcomeMessage;
          scope.reviewPageSettingModel.publicReviewThankYouMsg = revireSettingData.publicReviewThankYouMsg;
          scope.reviewPageSettingModel.publicPostClickhereText = revireSettingData.publicPostClickhereText;
          scope.reviewPageSettingModel.publicLeaveYourReviewMsg = revireSettingData.publicLeaveYourReviewMsg;
          scope.reviewPageSettingModel.publicPostAReviewTextMsg = revireSettingData.publicPostAReviewTextMsg;
          scope.reviewPageSettingModel.publicReviewThankyouMessage = revireSettingData.reviewThankyouMessage;
          scope.reviewPageSettingModel.publicReviewAppreciateMessage = revireSettingData.publicReviewAppreciateMessage;
          scope.reviewPageSettingModel.publicBadExperienceTextMsg = revireSettingData.publicBadExperienceTextMsg;
          scope.reviewPageSettingModel.publicGoodExperienceTextMsg = revireSettingData.publicGoodExperienceTextMsg;
          scope.reviewPageSettingModel.publicExtremelyLikely = revireSettingData.publicExtremelyLikely;
          scope.reviewPageSettingModel.publicNotLikelyatAll = revireSettingData.publicNotLikelyatAll;
          scope.reviewPageSettingModel.publicBadFaceTextMsg = revireSettingData.publicBadFaceTextMsg;
          scope.reviewPageSettingModel.publicGoodFaceTextMsg = revireSettingData.publicGoodFaceTextMsg;
          scope.reviewPageSettingModel.publicOkayFaceTextMsg = revireSettingData.publicOkayFaceTextMsg;
          scope.reviewPageSettingModel.messageforPrivateReview = revireSettingData.messageforPrivateReview;
          scope.reviewPageSettingModel.contactFromThankyouMsg = revireSettingData.contactFromThankyouMsg;
          scope.reviewPageSettingModel.privatePostAReviewTextMsg = revireSettingData.privatePostAReviewTextMsg;
          scope.reviewPageSettingModel.privateWhatToDoPlaceHolderMsg = revireSettingData.privateWhatToDoPlaceHolderMsg;
          scope.reviewPageSettingModel.privateNamePlaceholderMsg = revireSettingData.privateNamePlaceholderMsg;
          scope.reviewPageSettingModel.hasNameRequired = revireSettingData.hasNameRequired;
          scope.reviewPageSettingModel.hasNameEnable = revireSettingData.hasNameEnable;
          scope.reviewPageSettingModel.privatePhoneNumberPlaceholderMsg = revireSettingData.privatePhoneNumberPlaceholderMsg;
          scope.reviewPageSettingModel.hasPhoneNumberRequired = revireSettingData.hasPhoneNumberRequired;
          scope.reviewPageSettingModel.hasPhoneNumberEnable = revireSettingData.hasPhoneNumberEnable;
          scope.reviewPageSettingModel.privateCloseButtonTextMsg = revireSettingData.privateCloseButtonTextMsg;
          scope.reviewPageSettingModel.privateSubmitButtonTextMsg = revireSettingData.privateSubmitButtonTextMsg;
          scope.reviewPageSettingModel.privateEmailPlaceholderMsg = revireSettingData.privateEmailPlaceholderMsg;
          scope.reviewPageSettingModel.hasEmailRequired = revireSettingData.hasEmailRequired;
          scope.reviewPageSettingModel.hasEmailEnable = revireSettingData.hasEmailEnable;
          scope.reviewPageSettingModel.privatePostClickhereText = revireSettingData.privatePostClickhereText;
          // scope.reviewPageSettingModel.formType = revireSettingData.formType;
          scope.cdr.markForCheck();
        }

      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  //Review page setting functions
  onReviewSettingFormSubmit() {
    var scope = this;
    scope.fromSubmitted = true;
    if (scope.reviewSettingForm.invalid) {
      return;
    }
    let finalData = {
      Key: scope.accessKey,
      ProfileId: scope.profileId,
      HeaderFontColor: scope.reviewPageSettingModel.headerFontColor,
      HeaderBackgroundColor: scope.reviewPageSettingModel.headerBackgroundColor,
      HeaderTitle: scope.reviewPageSettingModel.headerTitle,
      HeaderBody: scope.reviewPageSettingModel.headerBody,
      MessageforPublicReview: scope.reviewPageSettingModel.messageforPublicReview,
      PublicWelcomeMessage: scope.reviewPageSettingModel.welcomemessageforPublicReview,
      publicReviewThankYouMsg: scope.reviewPageSettingModel.publicReviewThankYouMsg,
      PublicPostClickhereText: scope.reviewPageSettingModel.publicPostClickhereText,
      PublicLeaveYourReviewMsg: scope.reviewPageSettingModel.publicLeaveYourReviewMsg,
      PublicPostAReviewTextMsg: scope.reviewPageSettingModel.publicPostAReviewTextMsg,
      PublicReviewThankyouMessage: scope.reviewPageSettingModel.publicReviewThankyouMessage,
      PublicReviewAppreciateMessage: scope.reviewPageSettingModel.publicReviewAppreciateMessage,
      PublicBadExperienceTextMsg: scope.reviewPageSettingModel.publicBadExperienceTextMsg,
      PublicGoodExperienceTextMsg: scope.reviewPageSettingModel.publicGoodExperienceTextMsg,
      PublicExtremelyLikely: scope.reviewPageSettingModel.publicExtremelyLikely,
      PublicNotLikelyatAll: scope.reviewPageSettingModel.publicNotLikelyatAll,
      PublicBadFaceTextMsg: scope.reviewPageSettingModel.publicBadFaceTextMsg,
      PublicGoodFaceTextMsg: scope.reviewPageSettingModel.publicGoodFaceTextMsg,
      PublicOkayFaceTextMsg: scope.reviewPageSettingModel.publicOkayFaceTextMsg,
      MessageforPrivateReview: scope.reviewPageSettingModel.messageforPrivateReview,
      ContactFromThankyouMsg: scope.reviewPageSettingModel.contactFromThankyouMsg,
      hidePublicReviewMsg: scope.reviewPageSettingModel.hidePublicReviewMsg,
      PrivatePostAReviewTextMsg: scope.reviewPageSettingModel.privatePostAReviewTextMsg,
      PrivateWhatToDoPlaceHolderMsg: scope.reviewPageSettingModel.privateWhatToDoPlaceHolderMsg,
      PrivateNamePlaceholderMsg: scope.reviewPageSettingModel.privateNamePlaceholderMsg,
      HasNameRequired: scope.reviewPageSettingModel.hasNameRequired,
      HasNameEnable: scope.reviewPageSettingModel.hasNameEnable,
      PrivatePhoneNumberPlaceholderMsg: scope.reviewPageSettingModel.privatePhoneNumberPlaceholderMsg,
      HasPhoneNumberRequired: scope.reviewPageSettingModel.hasPhoneNumberRequired,
      HasPhoneNumberEnable: scope.reviewPageSettingModel.hasPhoneNumberEnable,
      PrivateCloseButtonTextMsg: scope.reviewPageSettingModel.privateCloseButtonTextMsg,
      PrivateSubmitButtonTextMsg: scope.reviewPageSettingModel.privateSubmitButtonTextMsg,
      PrivateEmailPlaceholderMsg: scope.reviewPageSettingModel.privateEmailPlaceholderMsg,
      HasEmailRequired: scope.reviewPageSettingModel.hasEmailRequired,
      HasEmailEnable: scope.reviewPageSettingModel.hasEmailEnable,
      PrivatePostClickhereText: scope.reviewPageSettingModel.privatePostClickhereText,
      // FormType: scope.reviewPageSettingModel.formType,
    }
    scope.updateReviewSetting(finalData)
  }
  updateReviewSetting(finalData) {
    var scope = this;
    scope.loading = true;
    scope.http.post(environment.apiPath + 'UpdateReviewPageSetting', finalData)
      .subscribe((res) => {
        scope.loading = false;
        if (res['success']) {
          scope.pageService.showSuccess('Updated', 'Review page settings updated.');
          this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();
          //  this.ngOnInit();
          scope.loadReviewPageSettings();
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
        scope.cdr.markForCheck();
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  resetReviewPageSetting() {
    this.reviewPageSettingModel = {
      headerFontColor: '',
      headerBackgroundColor: '',
      headerTitle: '',
      headerBody: '',
      messageforPublicReview: '',
      welcomemessageforPublicReview: '',
      publicReviewThankYouMsg: '',
      publicPostClickhereText: '',
      publicLeaveYourReviewMsg: '',
      publicPostAReviewTextMsg: '',
      publicReviewThankyouMessage: '',
      publicReviewAppreciateMessage: '',
      publicBadExperienceTextMsg: '',
      publicGoodExperienceTextMsg: '',
      publicExtremelyLikely: '',
      publicNotLikelyatAll: '',
      publicBadFaceTextMsg: '',
      publicGoodFaceTextMsg: '',
      publicOkayFaceTextMsg: '',
      messageforPrivateReview: '',
      contactFromThankyouMsg: '',
      hidePublicReviewMsg: false,
      privatePostAReviewTextMsg: '',
      privateWhatToDoPlaceHolderMsg: '',
      privateNamePlaceholderMsg: '',
      hasNameRequired: '',
      hasNameEnable: '',
      privatePhoneNumberPlaceholderMsg: '',
      hasPhoneNumberRequired: '',
      hasPhoneNumberEnable: '',
      privateCloseButtonTextMsg: '',
      privateSubmitButtonTextMsg: '',
      privateEmailPlaceholderMsg: '',
      hasEmailRequired: '',
      hasEmailEnable: '',
      privatePostClickhereText: ''
      // formType: ""
    }
  }

  hidePublicReview(e) {
    this.reviewPageSettingModel.hidePublicReviewMsg = e.checked;
  }

  headerBackgroundColor: any = '#3b3838'
  headerFontColor: any = '#fcaa1d';

  changeCustomColor(e, type) {
    if (type == 'font') {
      this.reviewPageSettingModel.headerFontColor = e.target.value;
    } else if (type == 'background') {
      this.reviewPageSettingModel.headerBackgroundColor = e.target.value;
    }
  }

}
