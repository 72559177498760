import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { MyReviewsComponent } from './views/pages/my-reviews/my-reviews.component';
import { MyModalComponent } from './views/pages/wizard/my-modal/my-modal.component';
import { ReviewSitesComponent } from './views/pages/review-sites/review-sites.component';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthGuard } from './core/auth';
import { CompanySettingsComponent } from './views/pages/company-settings/company-settings.component';
import { GoogleAccountDetailsComponent } from './views/pages/google-account-details/google-account-details.component';
import { FacebookAccountDetailsComponent } from './views/pages/facebook-account-details/facebook-account-details.component';
import { NoGoogleAccountComponent } from './views/pages/no-google-account/no-google-account.component';
import { PrivateFeedbackComponent } from './views/pages/private-feedback/private-feedback.component';
// import { ReviewRequestFormComponent } from './views/pages/Landing-page/review-request-form/review-request-form.component';

const routes: Routes = [
	{ path: 'auth', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule) },
	// {path: '', loadChildren: () => import('../app/views/pages/client-review/client-review.module').then(m => m.ClientReviewModule)},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			
			{
				path: 'company-setting',
				component: CompanySettingsComponent,
			},
			
			{
				path: 'review-platforms',
				component: ReviewSitesComponent
			},
			{
				path: 'my-model',
				component: MyModalComponent
			},
			{
				path: 'my-reviews',
				component: MyReviewsComponent
			},
			{
				path: 'google-account-details',
				component: GoogleAccountDetailsComponent
			},
			{
				path: 'facebook-account-details',
				component: FacebookAccountDetailsComponent
			},
			{
				path: 'get-reviews',
				loadChildren: () => import('../app/views/pages/Get-Reviews/get-reviews.module').then(m => m.GetReviewsModule)
			},
			{
				path: 'customization-options',
				loadChildren: () => import('../app/views/pages/Landing-page/landing-page.module').then(m => m.LandingPageModule)
			},
			{
				path: 'widgets',
				loadChildren: () => import('../app/views/pages/Widgets/widgets.module').then(m => m.WidgetsModule)
			},
			{
				path: 'reports',
				loadChildren: () => import('../app/views/pages/reports/reports.module').then(m => m.ReportsModule)
			},
			{
				path: 'general',
				loadChildren: () => import('../app/views/pages/General-settings/general-settings.module').then(m => m.GeneralSettingsModule)
			},
			{
				path: 'no-google-account',
				component: NoGoogleAccountComponent
			},
			{
				path: 'private-feedback',
				component: PrivateFeedbackComponent
			},
			{
				path: 'help',
				loadChildren: () => import('../app/views/pages/help/help.module').then(m => m.HelpModule)
			},
			{
				path: 'invite-friends',
				loadChildren: () => import('../app/views/pages/invite-friends/invite-friends.module').then(m => m.InviteFriendsModule)
			},
			{
				path: 'wizard',
				loadChildren: () => import('../app/views/pages/wizard/wizard.module').then(m => m.WizardModule)
			},
			{
				path: 'builder',
				loadChildren: () => import('../app/views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },	

		]
	},

	{
		path: 'error/403',
		component: ErrorPageComponent,
	},
	{
		path: 'onboarding',
		loadChildren: () => import('../app/views/pages/onboarding/onboarding.module').then(m => m.OnboardingModule),
		canActivate: [AuthGuard]
	},
	{ path: 'optin', loadChildren: () => import('../app/views/pages/optinpage/optinpage.module').then(m => m.OptinpageModule) },
	{ path: '**', loadChildren: () => import('../app/views/pages/client-review/client-review.module').then(m => m.ClientReviewModule) },
	
];

@NgModule({
	imports: [
	RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {

}
// {useHash:true}