import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key

registerLicense('Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSn5SdkFnWH9bcHZVQw==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUViWXtacXRQRmNbVw==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjWH9adHVVQ2BZUkE=;MTIyODk5OUAzMjMwMmUzNDJlMzBhdDFkeGEvdEx2MnZYazlwTGNsSGpqSGk1aHVPNzhNWDdWN3FmQU5Tay9ZPQ==;MTIyOTAwMEAzMjMwMmUzNDJlMzBsdHlteFhrRC80NmRncTJhL2M0UzJLVk81eGRtMUFubFVCWHNMelo4TFg0PQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVgWX5edHBURmVdUkJ2;MTIyOTAwMkAzMjMwMmUzNDJlMzBBS3doZFQ0MXluMDFXTlhNKy9oT2ROUWxCTmtIYWI4elFLTnlaQ25LT1ZvPQ==;MTIyOTAwM0AzMjMwMmUzNDJlMzBtTjREZy9YRGtwbXAzR1p1dmt1WTBlc1VkVGpGRWZ3dDhiaWQwTW1zcG5BPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjWH9adHVVQ2JeU0U=;MTIyOTAwNUAzMjMwMmUzNDJlMzBJTXpmbVppdEZqbTRtSHV1MU5aT1NBMEtTc0hMU3BkdFUrUE04enY2QzBNPQ==;MTIyOTAwNkAzMjMwMmUzNDJlMzBKOTlqclVWWkNEZDQwQ2ZzcVdqdm5kSm01Y3lhUUVTbUVNYzZDYmNLcnBFPQ==;MTIyOTAwN0AzMjMwMmUzNDJlMzBBS3doZFQ0MXluMDFXTlhNKy9oT2ROUWxCTmtIYWI4elFLTnlaQ25LT1ZvPQ==');


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
