// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// eslint-disable-next-line no-debugger
		// modify request
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: `Bearer ${localStorage.getItem('accessToken')}`
		// 	}
		// });
		return next.handle(request).pipe(
			tap(
				event => {
					setTimeout(() => {
						$('.loaderChild').hide();
					}, 3000);

					 if (event instanceof HttpResponse) {

						// http response status code

					}
				},
				error => {
					setTimeout(() => {
						$('.loaderChild').hide();
					}, 3000);
					// http response status code

					// console.error('status code:');
					// eslint-disable-next-line no-debugger
					console.error(error.status);
					console.error(error.message);

				}
			)
		);
	}
}
