<div class='col-12 padding-15'>
	<div class='row'>
		<div class='col-md-11 text-1-2x text-bold form-control no-border'>
			Custom Link
		</div>
		<div class='col-md-1 text-1-2x text-center'>
			<i class="fa fa-times cursor-pointer" (click)='dialogRef.close()'></i>
		</div>
	</div>
</div>
<div class="col-12">
	<form [formGroup]="customForm">
		<div class='row'>
			<div class='col-3'>
				<mat-label>Link Image :</mat-label>
				<div class="col-12">
					<div class="kt-avatar kt-avatar--outline" id="kt_user_avatar_2">
						<div class="kt-avatar__holder businessLogoImg" style="background-image: url(/assets/media/users/default.jpg)"></div>
						<label class="kt-avatar__upload" data-toggle="kt-tooltip" title="" data-original-title="Change avatar">
							<i class="fa fa-pen"></i>
							<input #linkImg type="file" name="profile_avatar" formControlName="logoImg" accept=".png, .jpg, .jpeg" (change)="onFilesAdded($event)">
						</label>
						<span class="kt-avatar__cancel custom-logo-cancel" data-toggle="kt-tooltip" title="" data-original-title="Cancel avatar" (click)='deleteImg()'>
							<i class="fa fa-times"></i>
						</span>

					</div>
					<mat-error *ngIf="imageError">
						<strong *ngIf='imageError'>*Maximum 1 MB image size allowed. </strong>
					</mat-error>
				</div>
			</div>
			<div class="col-9 form-group">
				<div class='col-12 form-group'>
					<div>
						<mat-label>Link Text :</mat-label>
						<input type="text" class="form-control" name="link" formControlName="LinkText" placeholder="Link Text" [(ngModel)]="model.LinkText" />
					</div>
					<mat-error *ngIf="formSubmitted && customForm.controls.LinkText.errors">
						<strong *ngIf='formSubmitted && customForm.controls.LinkText.errors.required'>*Required</strong>
					</mat-error>
				</div>
				<div class="col-12 form-group">
					<div>
						<mat-label>URL :</mat-label>
						<input type="text" class="form-control" name="link" formControlName="URL" placeholder='URL' (change)='textEntered()' [(ngModel)]="model.URL" />
					</div>
					<mat-error *ngIf="formSubmitted && customForm.controls.URL.errors">
						<strong *ngIf='formSubmitted && customForm.controls.URL.errors.required'>*Required</strong>
					</mat-error>
					<mat-error *ngIf="formSubmitted && invalidURL">
						<strong *ngIf='formSubmitted && invalidURL'>*Invalid URL</strong>
					</mat-error>
				</div>
			</div>
		</div>
		<div class="col-12 form-group">
			<div>
				<mat-label>Instruction :</mat-label>
				<ejs-richtexteditor formControlName="Instruction" [toolbarSettings]='tools' [(ngModel)]="model.Instruction"></ejs-richtexteditor>
			</div>
		</div>
		<div mat-dialog-actions class='text-center'>
			<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5" (click)="onSubmitCustom()">Submit</button>
			<button mat-button (click)="dialogRef.close()">Cancel</button>
		</div>
	</form>
</div>
