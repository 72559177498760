
import { DataTableService } from './../../../../core/_base/layout/services/datatable.service';
import { Router } from '@angular/router';
import { environment } from './../../../../../environments/environment';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
// import { MyModalComponent } from './../my-modal/my-modal.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
// import { EditModalComponent } from '../edit-modal/edit-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';

declare function phoneText(any1, any2, any3, any4, any5): any
declare function phoneTextCustomCountry(any2, any3, any4, any5, any1): any
@Component({
	selector: 'kt-wizard1',
	templateUrl: './wizard1.component.html',
	styleUrls: ['./wizard1.component.scss']
})
export class Wizard1Component implements OnInit, AfterViewInit {
	@ViewChild('wizard', { static: true }) el: ElementRef;
	updateForm: UntypedFormGroup;
	submittedform1: boolean = false;
	separateDialCode = true;
	subscription: Subscription;
	btnLoader: boolean = false;
	profileId: any;
	conectedList = []
	profileReviewDeatils = {};
	placeResult: any;
	searchProfileModel = '';
	listOfSites = [];
	step = 0;
	profileData: {}
	httpOptions: any;
	myTimer: any;
	shortNameFlag: boolean = false;
	loadText: boolean = false;
	rightIcon: boolean = false;
	wrongIcon: boolean = false;
	industryList = [
		'Auto & Car Dealer',
		'Auto Repair',
		'Dentistry',
		'Education & School',
		'Elder Care',
		'Employment',
		'Financial Services',
		'Healthcare',
		'Home Services',
		'Lawyer/Legal',
		'Moving & Movers',
		'Pet Services',
		'Product',
		'Property & Rentals',
		'Real Estate',
		'Recreation',
		'Religious Institution',
		'Restaurant & Dining',
		'Salons & Beauty',
		'Software',
		'Travel & Hospitality',
		'Wedding Industry',
		'Other',
	];
	public step1Model = {
		CompanyName: '',
		Email: '',
		PhoneNumber: "",
		PersonName: "",
		ShortName: "",
		Industry: "",
		TimeZone: '',
		LogoURL: ''
	};
	public countryList = []
	shortUrlRegEx = /[^A-Za-z0-9_-]/;
	public mainWizard: any;
	countryCode: any;

	//dtOptions: DataTables.Settings = {};

	constructor(
		public formBuilder: UntypedFormBuilder,
		public http: HttpClient,
		public pageService: PageConfigService,
		public dataService: DataTableService,
		public router: Router,
		public cdr: ChangeDetectorRef) {
		var scope = this;
		//set Validation
	}
	ngOnInit() {

		// this.dtOptions = {
		// 	ordering: false,
		// 	searching: false,
		// 	paging: false,
		// 	info: false,
		// }
		
		this.updateForm = this.formBuilder.group({
			CompanyName: ["", Validators.required],
			Email: ["", [Validators.required, Validators.email]],
			PhoneNumber: [""],
			PersonName: [""],
			ShortName: ["", [Validators.required]],
			Industry: ["", [Validators.required]],
			Country: ["", [Validators.required]]
		})


		//set http header for calling api
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			})
		};
		var scope = this;
		// this.model.email=localStorage.getItem('adminemail');

		this.profileId = JSON.parse(localStorage.getItem('ProfileId'))
		scope.pageService.callChooseProfile();

		if (scope.profileId == null || scope.profileId < 0) {
			scope.pageService.createNewProfilePopup();
		}
		this.loadProfileDetails();
		this.subscription = this.pageService.getProgileId().subscribe(message => {

			if (message.id) {
				// this.profileId=message.id
				this.profileId = JSON.parse(localStorage.getItem('ProfileId'))

				if (scope.profileId == null || scope.profileId < 0) {
					scope.pageService.createNewProfilePopup();
				}
				if (scope.router.url.includes('setup')) {

					this.loadProfileDetails();
					this.mainWizard.goTo(1, () => { })
					this.cdr.markForCheck();
				}

			}
		});
		let ip = localStorage.getItem("IpAddress")
		var input = document.querySelector("#setupInputText");
		var errorMsg = document.querySelector("#error-msg-phone-setup");
		var validMsg = document.querySelector("#valid-msg-phone-setup");

		// scope.http.get("https://ipapi.co/" + ip + "/json/")
		// 	.subscribe((res) => {
		// 		this.countryCode = res['country_code'];
		// 	})
		// phoneText(ip, input, errorMsg, validMsg, scope)

		scope.countryList = scope.dataService.countryList;

		var accountCode = localStorage.getItem('currentCountry');
		const toSelect = this.countryList.filter(c => c.code == accountCode);

		var val = toSelect[0]
		this.updateForm.get('Country').setValue(val.code);

		this.country = val.name;
		this.countryCode = val.code
	}
	loadProfileDetails() {
		
	}
	generteShortName() {
		if (this.step1Model.ShortName == undefined || this.step1Model.ShortName.length == 0) {
			this.step1Model.ShortName = this.step1Model.CompanyName.replace(/ /g, '-').replace(/[&\/\\#,+()$~%.'":*?<>{}@]/g, '-').toLowerCase();
			this.cdr.markForCheck();

		}
		this.validateUrl();
	}
	//Validate shortname 
	validateUrl() {
		var scope = this;
		scope.rightIcon = false;
		scope.wrongIcon = false;

		if (scope.step1Model.ShortName.length <= 0) {
			return;
		}
		
		if (scope.shortUrlRegEx.test(scope.step1Model.ShortName)) {
			scope.wrongIcon = true;
			return;
		}
		scope.loadText = true;
		let accessKey = localStorage.getItem(environment.authTokenKey)
		scope.http.get(environment.apiPath + 'ValidateShortname?ShortName=' + scope.step1Model.ShortName
			+ '&Key=' + accessKey)
			.subscribe((res) => {
				scope.loadText = false;;
				if (res['success']) {
					scope.rightIcon = true;
				} else {
					scope.wrongIcon = true;
					scope.pageService.showError('Oops!!!', res['errorMsg'])
					if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}

				}
				scope.cdr.markForCheck()
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}
	//serachSocial function called when user searches any services from step 2 
	serachSocial() {

		let index;
		if (this.searchProfileModel.length > 0) {
			this.listOfSites.forEach((alone, i) => {
				if (alone.title.toLocaleLowerCase().includes(this.searchProfileModel.toLocaleLowerCase())) {
					this.setStep(i)
				} else {
					alone.list.forEach((aln) => {
						if (aln.reviewSiteName != null) {
							if (aln.reviewSiteName.toLocaleLowerCase().includes(this.searchProfileModel.toLocaleLowerCase())) {
								this.setStep(i)
							}
						}
					})
				}
			})
		}
	}
	setStep(index: number) {
		this.step = index;

	}
	nextStep() {

		this.step++;
	}
	prevStep() {
		this.step--;
	}


	ngAfterViewInit(): void {
		var scope = this;
		// Initialize form wizard
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});
		this.mainWizard = wizard;
		// Validation before going to next page
		wizard.on('beforeNext', function (wizardObj) {
			
			if (wizardObj.currentStep == 1) {
				scope.btnLoader = true;
				wizardObj.stop();

				scope.submittedform1 = true;
				// Validate First step	
				if (scope.updateForm.invalid) {
					wizardObj.stop();
					scope.cdr.markForCheck();
					scope.btnLoader = false;
					return;
				}
				if (!document.querySelector("#error-msg-phone-setup").classList.contains('hide')) {
					scope.btnLoader = false;
					return;
				}
				if (scope.wrongIcon) {
					scope.btnLoader = false;
					return;
				}
				//Stop Wizard to send user to next step after completion of api calls

				let intlObj = scope.dataService.getData('IntlObj')
				let datatosend = scope.step1Model;
				datatosend['Key'] = localStorage.getItem(environment.authTokenKey);
				datatosend['ProfileId'] = scope.profileId;

				scope.updateProfileApi(wizardObj, datatosend)
			} else if (wizardObj.currentStep == 2) {

				

			}
		});

		// Change event
		wizard.on('change', function (wizard) {

			setTimeout(function () {
				KTUtil.scrollTop();

			}, 500);
		});
	}
	updateProfileApi(wizardObj, datatosend) {
		var scope = this;
		scope.pageService.showLoader();

		scope.http.post(environment.apiPath + 'UpdateProfile', datatosend, scope.httpOptions)
			.subscribe((resp) => {

				scope.pageService.hideLoader();
				scope.btnLoader = false;
				if (resp['success']) {
					scope.pageService.showSuccess('Updated', 'Proile details updated successfully.')
					this.router.navigate(['/review-platforms'])
					wizardObj.goNext();
				} else {
					//err
					scope.pageService.showError('Oops!!!', resp['errorMsg'])
					if (resp['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || resp['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	country: any;
	selectCountry(event) {

		var selectedCountry = event.value.toString().split('"').join('')
		const selectedValue = this.countryList.filter(c => c.code == selectedCountry);

		this.country = selectedValue[0].name;
		this.countryCode = selectedValue[0].code;



	}

	onsubmit1() {
		var scope = this;
		// scope.pageService.setProfileId(scope.profileId)
		scope.router.navigate(['/dashboard'])
	}

	accessKey = localStorage.getItem(environment.authTokenKey)
	copyProfileId = '';
	loading: boolean = false;

	submitData() {
		var scope = this;
		this.pageService.showLoader();
		let datatosend = scope.updateForm.value;
		datatosend['Key'] = scope.accessKey;
		// datatosend['TimeZone']=scope.getCurrentTimeZone()
		if (scope.copyProfileId.length > 0) {
			datatosend['CopyProfileId'] = scope.copyProfileId
		} else {
			datatosend['CopyProfileId'] = '';
		}

		datatosend['CountryCode'] = this.countryCode;
		datatosend['Country'] = this.country;
		scope.loading = true;

		scope.http.post(environment.apiPath + 'CreateProfile', datatosend)
			.subscribe((res) => {
				scope.loading = false;
				if (res['success']) {
					this.pageService.hideLoader();
					localStorage.setItem('ProfileId', JSON.stringify(res['profileId']))
					localStorage.setItem('isConnnectedReviewPlatform', "false");
					scope.pageService.setProfileListSubscriber()
					scope.pageService.showSuccess('Created', 'Profile created successfully.')
					this.router.navigate(['/review-platforms'])
					//   scope.dialogRef.close({ flag: true, profileId: res['profileId'] })
				} else {
					this.pageService.hideLoader();
					scope.pageService.showError('Oops!!!', res['errorMsg'])
					if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	/**
 * Checking control validation
 *
 * @param controlName: string => Equals to formControlName
 * @param validationType: string => Equals to valitors name
 */

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.updateForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
