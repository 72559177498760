<div id="GoodModal" role="dialog">
	<div class="col-md-12">
		<div class="row padding-10">
			<div class="col-md-12 text-center">
				<span class="modal-title" style="font-size: 24px; text-transform: capitalize"
					[innerHTML]="companyName"></span>
					<button type="button" class="close pull-right" aria-label="Close" 
					 (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
			</div>
		</div>
		<div *ngIf="reviewSiteList?.length > 0" class="row padding-5 text-center"
			[innerHTML]="sanitizeHtml(layoutData['MessageforPublicReview'])"></div>
		<div class="col-12" *ngIf="reviewSiteList?.length > 0">
			<mat-divider></mat-divider>
		</div>
		<div class="modal-body">
			<div class="reviewsource-row" *ngIf="reviewSiteList?.length > 0">
				<div class="hilight-source">
					<label *ngIf="openUrlCount == 0" class="leave-review-label">
						<span [innerHTML]="
								sanitizeHtml(
									layoutData['PublicLeaveYourReviewMsg']
								)
							"></span>
					</label>
					<!-- <label *ngIf="openUrlCount > 0" class="leave-review-label">We'd also appreciate your review
						on</label> -->
						<label *ngIf="openUrlCount > 0" class="leave-review-label">
							{{PublicReviewAppreciateMessage}}
						</label>
					<div *ngFor="let item of reviewSiteList">
						<a class="source-wrap external-review logged-in-box {{
								item.SiteName
							}}" rel="noreferrer" (click)="openUrl(item.SiteName, item.ReviewUrl)">
							<div class="source-logo-wrap">
								<img class="source-logo-icon-wrap" [src]="item.ImageURL" *ngIf="
										item.IsCustom == true &&
										item.ImageURL != ''
									" />

								<img class="source-logo-icon-wrap" [src]="
										'../../../../assets/media/social-logo/' +
										item.SiteName +
										'.png'
									" *ngIf="item.IsCustom == false" />
								<div id="profileImage" *ngIf="
										item.IsCustom == true &&
										item.ImageURL == 0
									">
									<b class="custContentName" id="demo">{{
										item.SiteName
										}}</b>
								</div>
							</div>
							<div class="source-logo-txt">
								{{ item.SiteName }}
							</div>
							<div class="source-arrow-icon">
								<i class="fa fa-chevron-right"></i>
							</div>
						</a>
						<div class="logged-in-text success-color text-1-2x {{
								item.SiteName
							}}" *ngIf="
								item.SiteName == 'Google' ||
								item.SiteName == 'Facebook'
							">
							You are logged in
						</div>

						<img style="display: none" *ngIf="item.SiteName == 'Google'"
							(load)="show_login_status('Google', true)" (error)="show_login_status('Google', false)"
							src="https://accounts.google.com/CheckCookie?continue=https://www.google.com/intl/en/images/logos/accounts_logo.png" />
						<img style="display: none" *ngIf="item.SiteName == 'Facebook'"
							src="https://m.facebook.com/login/?next=https://m.facebook.com/images/litestand/bookmarks/sidebar/icons/mobile/icon-instagram-gray.png"
							(load)="show_login_status('Facebook', true)"
							(error)="show_login_status('Facebook', false)" />
					</div>
				</div>
			</div>
			<div *ngIf="reviewSubmitted.length > 0">
				<label *ngIf="totalPlatformCount == openUrlCount" class="thank-you-label">{{ ReviewThankyouMessage }}
				</label>
				<div *ngFor="let item of reviewSubmitted">
					<a class="source-wrap external-review logged-in-box {{
							item.SiteName
						}}" style="opacity: 50% !important" rel="noreferrer">
						<div class="source-logo-wrap">
							<img class="source-logo-icon-wrap" [src]="item.ImageURL" *ngIf="
									item.IsCustom == true && item.ImageURL != ''
								" />

							<img class="source-logo-icon-wrap" [src]="
									'../../../../assets/media/social-logo/' +
									item.SiteName +
									'.png'
								" *ngIf="item.IsCustom == false" />
							<div id="profileImage" *ngIf="
									item.IsCustom == true && item.ImageURL == 0
								">
								<b class="custContentName" id="demo">{{
									item.SiteName
									}}</b>
							</div>
						</div>
						<div class="source-logo-txt">{{ item.SiteName }}</div>
						<div class="source-arrow-icon">
							<i class="fa fa-chevron-right"></i>
						</div>
					</a>
				</div>
			</div>
			<div class="reviewsource-bottomtxt">
				<span>
					{{PublicPostAReviewTextMsg}} 
				</span>
				<span class="down primary-color cursor-pointer" (click)="closeDialog()">{{PublicPostClickhereText}}</span>
			</div>
		</div>
		<!-- <div class="modal-footer">
			<button type="button" class="btn btn-primary" (click)='dialogRef.close()'>Close</button>
		</div> -->
	</div>
</div>