import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { PageConfigService } from './../../../../../core/_base/layout/services/page-config.service';

@Component({
  selector: 'kt-thankyoubilling',
  templateUrl: './thankyoubilling.component.html',
  styleUrls: ['./thankyoubilling.component.scss']
})
export class ThankyoubillingComponent implements OnInit {

  isPaymentDone: boolean = false;
  isPaymentNotDone: boolean = false;

  constructor(public router: Router,
    public dialog:MatDialogRef<ThankyoubillingComponent>,
    public pageService: PageConfigService) {
    if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state != undefined) {

      if (this.router.getCurrentNavigation().extras.state.isPaymentDone) {
        this.isPaymentDone = true;
        this.isPaymentNotDone = false;
      } else {
        this.isPaymentNotDone = true;
        this.isPaymentDone = false;
      }
      this.pageService.paymentDone('paymentUpdated');
    } else {
      // this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.pageService.createProfileJson(JSON.parse(localStorage.getItem('ProfileId'))).subscribe();

  }


  redirectUser(e) {
    if (e == 'billing') {
      this.router.navigate(['/general/billing']);
    } else if (e == 'dashboard') {
      this.router.navigate(['/dashboard']);
    }
  }

  choosePlan() {
    this.router.navigate(['/general/billing'], { state: { showPlan: true } });
  }

  openBilling() {
    this.dialog.close();
    window.location.reload()
    // this.router.navigate(['/general/billing']); 
  }
}