<!-- <div class="col-12 text-center margin-tb-10">
    <img src="../../../../../assets/media/logos/Grab-Your-Review-LOGO-old.png" style="height: 75x;">
</div> -->

<div class='row text-center'>
    <mat-card class='col-md-8 col-sm-12 col-xs-12 margin-20 padding-20'>
        
        <div class='row' >
            <div class='col-12 text-center padding-tb-10'>
                <img src='' class='verifyIcon' style="height: 100px;"/>
            </div>
            <div class='col-12 text-center padding-tb-10 text-2x text-bold verifyMainText'>
                
            </div>
            <div class='col-12 text-center padding-tb-5 text-1x verifySubText'>
                
            </div>
            <div class='col-12 text-center margin-tb-10'>
                <button (click)="submit()"  class="btn btn-primary btn-elevate kt-login__btn-primary">
                    Sign In Now</button>
            </div>
        </div>
        
    </mat-card>

</div>
<script type="text/javascript">
    

    
  </script>