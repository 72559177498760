

<div class="row padding-10" *ngIf="!isFeedback">

	<div class="col-md-4 col-sm-9 col-xs-9" style="margin-bottom: 1%;">
		<input class="form-control" type='text' (keyup)="clearSearch($event)" [(ngModel)]="searchInput"
			placeholder='Search'>
	</div>
	<div class="col-md-2 col-sm-3 col-xs-3">
		<button class="btn btn-primary" (click)="searchFeedbackByName()">Search &nbsp;<i class="fa fa-search"
				aria-hidden="true"></i></button>
	</div>
	<div class='col-8'>
		<ngb-pagination [collectionSize]="collectionSize" [(page)]="page" (pageChange)='searchReview($event)'
			[pageSize]="pageSize" [maxSize]='6'></ngb-pagination>
	</div>
	<div class='flexClass text-right col-4'>
		<div class='text-1-2x'>Show &nbsp;</div>
		<div>
			<select class='form-control' [(ngModel)]="pageSize" (ngModelChange)='searchAllReview($event);demo();'>
				<option value='10'>10</option>
				<option value='20'>20</option>
				<option value='50'>50</option>
				<option value='100'>100</option>
			</select>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-12" *ngFor="let i of negativeFeedbackArr">
		<mat-card>
			<div class="row">
				<!-- <div style="padding-right: 10px;">
					<img class='profile-logo' src="../../../../assets/media/users/default.jpg">
				</div> -->
				<div class='col-12 flexClass no-padding'>
					<div class='col-md-12 col-sm-12 col-xs-12 flexClass text-left no-padding'>
						<div class='text-center no-padding'>
							<img class='profile-logo' src='../../../../assets/media/images/no-review-profile.png' />
						</div>
						<div class='col-9 text-left'>
							<span class='text-1-2x'><b style="visibility: visible;">{{i.name}}</b></span><br />
							<span class='text-12px'>{{i.feedbackDate}}</span>
						</div>
					</div>

					<!-- added unnati -->
					<div class="col-md-2 col-sm-12 col-xs-12 text-right flexClass">
						<a style="cursor: pointer;" (click)="MoveReview(i.id)">
							<i class="fa-regular fa-right-left" style="font-size: 16px;margin-right: 10%; background-color:#f70707;"
								matTooltip="Move to My Reviews"></i>
								<i class="fa-solid fa-arrow-right-arrow-left"></i>
						</a>
					</div>
					<!-- end unnati -->
				</div>
				<!-- <div class="col-md-6 col-sm-6 col-xs-6">

					{{i.name}}
					<br>
					{{i.feedbackDate}}
				</div> -->
			</div>
			<br>
			{{i.feedBack}}
			<br>
			<div class="reply" *ngIf="i.feedbackReply != null">
				<b style="visibility: visible;">Reply from owner at</b> {{i.feedbackReplyDate}}
				<br>
				{{i.feedbackReply}}
			</div>
			<!-- added unnati -->
			<div class='col-12 flexClass no-padding'>
				<div class='col-md-6 col-sm-12 col-xs-12 flexClass text-left no-padding'>
					<div class='stars-outer star-size'>
						<div class='stars-inner star-size' [style.width.%]="i.rating * 20"></div>
					</div>
				</div>
				<div class=' col-md-6 col-sm-12 col-xs-12 text-right  buttonDiv' style="text-align: right;">
					<a style="cursor: pointer; background-color:#686868;
					padding: 6px;border-radius: 5px;" (click)="openModal(showDetails,i,'showDetails')">
						<i class="fa fa-eye" matTooltip="View Details">
						</i>
					</a>
					<a *ngIf="i.feedbackReply == null" class='padding-lr-8' 
						style="margin-left:2%;cursor: pointer; background-color:#368cd7;
						padding: 6px;
						border-radius: 5px;" (click)="openModal(template,i,'showReply')">
						<i class="fas fa-share" matTooltip="Respond">
						</i>
					</a>
					<a class='padding-lr-8' style="margin-left:2%; cursor: pointer;font-size: 16px; background-color:#ff9102;padding: 6px;
						border-radius: 5px;"  (click)="MoveReview(i.id)">
						 <i class="fa fa-arrow-right" matTooltip="Mark As Reviews"></i> 
					</a>
				</div>
			</div>
		</mat-card>
		<br>
	</div>
</div>
<div class="row padding-10" *ngIf="!isFeedback">
	<div class='col-8'>
		<ngb-pagination [collectionSize]="collectionSize" [(page)]="page" (pageChange)='searchReview($event)'
			[pageSize]="pageSize" [maxSize]='6'></ngb-pagination>
	</div>
	<div class='flexClass text-right col-4'>
		<div class='text-1-2x'>Show &nbsp;</div>
		<div>
			<select class='form-control' [(ngModel)]="pageSize" (ngModelChange)='searchAllReview($event);demo();'>
				<option value='10'>10</option>
				<option value='20'>20</option>
				<option value='50'>50</option>
				<option value='100'>100</option>
			</select>
		</div>
	</div>
</div>

<ng-template #template>
	<div class="modal-header">
		<img class='profile-logo' src='../../../../assets/media/images/no-review-profile.png'
			*ngIf='modalService.config.initialState.profileImgUrl==null' />
		<h4 class="modal-title pull-left padding-10">{{ modalService.config.initialState.name }}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" [formGroup]="replyForm">

		<span *ngIf="modalService.config.initialState.review">
			<span class="grey-color text-c1"><b>Review</b></span>
			<br>
			<span class="grey-color text-c1">{{modalService.config.initialState.review}}</span>
			<br><br>
		</span>
		<span class="grey-color text-c1"><b>Reply</b></span>
		<br>
		<br>
		<textarea formControlName="reply" [(ngModel)]="comment" [innerHtml]="comment"
			[ngClass]="{errorTextBox: fromSubmitted && replyForm.controls.reply.errors?.required}"></textarea>
		<!-- <mat-error *ngIf="fromSubmitted && replyForm.controls.reply.errors">
			<strong *ngIf='fromSubmitted && replyForm.controls.reply.errors.required'>*Required</strong>
		</mat-error> -->
		<br>
		<div>
			<span class="badge badge-secondary msg" *ngFor="let item of reviewRespons"
				(click)="setThanksMsg(item)">{{item}}</span>
		</div>

		<br>
		<button class="btn btn-primary" (click)="sReply(comment)"
			[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}"
			style="float: right;">Send Reply</button>
	</div>
</ng-template>

<ng-template #showDetails>
	<div class="container p-3">
		<div class="row text-center">
			<div class="col-12">
				<h4>{{ modalService.config.initialState.name }}</h4>
				<span>Reviewed on {{ modalService.config.initialState.feedbackDate }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-12 showdetailscol">
				<span class="bold">Feedback:</span>
				<br>
				<span>{{ modalService.config.initialState.feedBack }}</span>
			</div>
			<div class="col-12 showdetailscol" *ngIf="modalService.config.initialState.feedbackReply">
				<span class="bold">Reply from owner:</span>
				<br>
				<span>{{modalService.config.initialState.feedbackReply}}</span>
			</div>
			<div class="col-12 showdetailscol">
				<span class="bold">Contact details:</span>
				<br>
				<span *ngIf="modalService.config.initialState.email != null">Email: &nbsp; <a
						href="mailto:{{modalService.config.initialState.email}}">{{modalService.config.initialState.email}}</a></span>
				<br>
				<span *ngIf="modalService.config.initialState.phone != null">Phone number: &nbsp; <a
						href="tel:{{modalService.config.initialState.phone}}">{{modalService.config.initialState.phone}}</a></span>
			</div>
		</div>
	</div>
</ng-template>

<div class="container" *ngIf="isFeedback">
	<img src="../../../../assets/media/no-feedback.png" style="width:50%;height: auto;position: relative;left:30%">
</div>