<div class='col-12 card padding-10'>
	<mat-tab-group class='customTab' (selectedTabChange)='tabChanged($event)'>
		<mat-tab class='text-bold' label="Email Template">
			<ng-container [ngTemplateOutlet]="tab1Template"></ng-container>
		</mat-tab>
		<mat-tab label="SMS/Text Template" *ngIf="isShow">
			<ng-container [ngTemplateOutlet]="tab2Template"></ng-container>
		</mat-tab>
		<mat-tab label="Private Feedback Template">
			<ng-container [ngTemplateOutlet]="tab3Template"></ng-container>
		</mat-tab>

	</mat-tab-group>
	<ng-template #tab1Template>
		<kt-email-template-page *ngIf='emailTemplateFlag'></kt-email-template-page>
	</ng-template>
	<!-- SMS/Text Template -->
	<ng-template #tab2Template>
		<kt-sms-template-page *ngIf='smsTemplateFlag'></kt-sms-template-page>
	</ng-template>
	<!-- Whatsapp template -->
	<!-- <ng-template #tab3Template>
		<kt-whtsapp-template-page *ngIf='whatsappTemplateFlag'></kt-whtsapp-template-page>
	</ng-template> -->

	<!-- Private feedback template -->
	<ng-template #tab3Template>
		<kt-private-feedback-template-page *ngIf='pvtFeeddbackTemplateFlag'></kt-private-feedback-template-page>
	</ng-template>

</div>
