import { Router } from '@angular/router';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'kt-opt-in-page',
  templateUrl: './opt-in-page.component.html',
  styleUrls: ['./opt-in-page.component.scss']
})
export class OptInPageComponent implements OnInit {
  authDataModel = {
    username: '',
    password: ''
  };
  submittedFlag: boolean = false;
  step = 0;
  authToggle: boolean = true;
  profileId = '';
  accessKey = '';
  optInData: any;
  magicLink = '';
  btnLoader: boolean = false;
  optinUrl = '';
  logo = '';
  companyName = '';
  isSMSTemplate;
  isDisable = false;
  constructor(public pageService: PageConfigService,
    public http: HttpClient,
    public cdr: ChangeDetectorRef,
    public clipboard: ClipboardService,
    public router: Router) { 

      

    }

  ngOnInit() {
    var scope = this;

    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
    scope.pageService.callChooseProfile();
    scope.loadAuthData();

    scope.pageService.getProgileId().subscribe(message => {

      if (message.id) {
        scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
        if (scope.router.url.includes('opt-in-page')) {

          scope.loadAuthData()
        }

      }
    });
    scope.pageService.setTitle('OptIn');

    // if (localStorage.getItem("isConnnectedReviewPlatform") == "false") {
    //   this.isDisable = true;
    // } else {
    //   this.isDisable = false;
    // }
  }
  loadAuthData() {
    var scope = this;
    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'GetOptInPageDetails?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId)
      .subscribe((res) => {
        scope.pageService.hideLoader();
        if (res['success']) {
          let data = scope.pageService.decrypt(res['data']);
          scope.optInData = JSON.parse(data)

          // UserName: null
          // Password: null
          // MagicLink: "https://dev.grabyou
          scope.authDataModel.username = scope.optInData['UserName'];
          scope.authDataModel.password = scope.optInData['Password'];
          scope.magicLink = scope.optInData['MagicLink'];
          scope.optinUrl = scope.optInData['OptinPageUrl'];
          scope.companyName = scope.optInData['CompanyName'];
          if (scope.optInData['LogoUrl'] != undefined && scope.optInData['LogoUrl'] != null &&
            scope.optInData['LogoUrl'].length > 0) {
            scope.logo = scope.optInData['LogoUrl'] + '?lastmd=' + Math.random()
          }
          else {
            scope.logo = '';
          }
          // scope.logo = scope.optInData['LogoUrl']+'?lastmd='+Math.random();
          scope.authToggle = scope.optInData['IsAuthentication'];
          scope.cdr.markForCheck();
        }
      },(err) =>{
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!',err.error.errorMsg);
        }
			})
  }
  //Accordian Code
  setStep(index: number) {
    this.step = index;
  }
  changeToggle(event) {
    this.authToggle = event.checked;

    this.changeAuthToggle();
  }
  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //Autthentication
  submitAuthentication() {
    var scope = this;
    scope.submittedFlag = true;

    if (scope.authDataModel.username.length == 0 || scope.authDataModel.password.length == 0) {
      return;
    }

    scope.submitAuthDetails();

  }
  //ChangeAuth toggle
  changeAuthToggle() {
    var scope = this;
    let data = {
      Key: scope.accessKey,
      ProfileId: scope.profileId,
      IsAuthentication: scope.authToggle
    }
    let finalData = { Data: scope.pageService.encrypt((JSON.stringify(data))) }

    scope.http.post(environment.apiPath + 'UpdateOptInPage', finalData)
      .subscribe((res) => {

        if (res['success']) {
          scope.loadAuthData();
        }
      },(err) =>{
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!',err.error.errorMsg);
        }
			})
  }
  submitAuthDetails() {
    var scope = this;
    let data = {
      Key: scope.accessKey,
      ProfileId: scope.profileId,
      UserName: scope.authDataModel.username,
      Password: scope.authDataModel.password,
    }
    let finalData = { Data: scope.pageService.encrypt((JSON.stringify(data))) }
    scope.btnLoader = true;
    scope.http.post(environment.apiPath + 'UpdateOptInPageAuthentication', finalData)
      .subscribe((res) => {
        scope.btnLoader = false;
        scope.cdr.markForCheck();
        if (res['success']) {
          scope.loadAuthData();
        }
      },(err) =>{
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

				this.pageService.hideLoader();
				this.pageService.showError('Oops!',err.error.errorMsg);
        }
			})
  }
}
