<div class='col-12'>
	<div class='row padding-15'>
		<div class='col-11 text-1-2x text-bold'>
			Add User
		</div>
		<div class='col-1 text-1-2x '>
			<i class="fa fa-times cursor-pointer" (click)='closeModal(undefined)'></i>
		</div>
	</div>
	<div class='col-12'>
		<mat-divider></mat-divider>
	</div>
	<div class='row padding-15'>
		<div class='col-12'>
			<form [formGroup]="addUserForm">
				<div class='col-12'>
					<div class='row margin-tb-10'>
						<div class="col-12 form-group">
							<div>
								<mat-label class='text-bold'>First Name</mat-label>
								<input type="text" class="form-control" name="firstName" placeholder="First Name" formControlName="FirstName" [(ngModel)]="userDataModel.FirstName" />
							</div>
							<mat-error *ngIf="fromSubmitted && addUserForm.controls.FirstName.errors">
								<strong *ngIf='fromSubmitted && addUserForm.controls.FirstName.errors.required'>*Required</strong>
							</mat-error>
						</div>
					</div>
					<div class='row margin-tb-10'>
						<div class="col-12 form-group">
							<div>
								<mat-label class='text-bold'>Last Name</mat-label>
								<input type="text" class="form-control" name="LastName" placeholder="Last Name" formControlName="LastName" [(ngModel)]="userDataModel.LastName" />
							</div>
						</div>
					</div>
					<div class='row margin-tb-10'>
						<div class="col-12 form-group">
							<div>
								<mat-label class='text-bold'>Email</mat-label>
								<input type="text" class="form-control" name="Email" placeholder="Email" formControlName="Email" [(ngModel)]="userDataModel.Email" />
							</div>
							<mat-error *ngIf="fromSubmitted && addUserForm.controls.Email.errors">
								<strong *ngIf='fromSubmitted && addUserForm.controls.Email.errors.required'>*Required</strong>
							</mat-error>
						</div>
					</div>
					<div class='row margin-tb-10'>
						<div class="col-12 form-group">
							<div>
								<mat-label class='text-bold'>Company</mat-label>
								<select class="form-control" formControlName="ProfileId" multiple='true' name="ProfileId" [(ngModel)]="userDataModel.ProfileId">
									<option value="00">Select</option>
									<option [value]="item.profileId" *ngFor="let item of profileList">{{item.companyName}}</option>
								</select>
							</div>
							<mat-error *ngIf="fromSubmitted && addUserForm.controls.ProfileId.errors">
								<strong *ngIf='fromSubmitted && addUserForm.controls.ProfileId.errors.required'>*Company selection required</strong>

								
							</mat-error>
							<mat-error *ngIf="fromSubmitted && profileErr">
								<strong *ngIf='fromSubmitted && profileErr'>*Company selection required</strong>

								
							</mat-error>
						</div>
					</div>
					<div class='row margin-tb-10'>
						<div class='col-md-4 col-sm-6 col-xs-6'>
							<mat-checkbox formControlName="canLogin" [(ngModel)]="userDataModel.canLogin" (ngModelChange)='changeCheckbox()'>Can login?</mat-checkbox>
						</div>
						<div class='col-md-4 col-sm-6 col-xs-6'>
							<mat-checkbox formControlName="HasApi" [(ngModel)]="userDataModel.HasApi" (ngModelChange)='changeCheckbox()'>Has API access?</mat-checkbox>
						</div>
						
					</div>
				</div>
				<div class='col-12'>
					<div class='row margin-tb-10 text-center text-bold text-underline'>
						Custom Permissions
					</div>
					<div class='row margin-tb-10'>
						<div class='col-md-3 col-sm-6 col-xs-6'>
							<mat-checkbox formControlName="LandingPage" [(ngModel)]="userDataModel.LandingPage" (ngModelChange)='changeCheckbox()'>Landing Page</mat-checkbox>
						</div>
						<div class='col-md-3 col-sm-6 col-xs-6'>
							<mat-checkbox formControlName="Widgets" [(ngModel)]="userDataModel.Widgets" (ngModelChange)='changeCheckbox()'>Widgets</mat-checkbox>
						</div>
						<div class='col-md-3 col-sm-6 col-xs-6'>
							<mat-checkbox formControlName="Invites" [(ngModel)]="userDataModel.Invites" (ngModelChange)='changeCheckbox()'>Invites</mat-checkbox>
						</div>
						<div class='col-md-3 col-sm-6 col-xs-6'>
							<mat-checkbox formControlName="Reporting" [(ngModel)]="userDataModel.Reporting" (ngModelChange)='changeCheckbox()'>Reporting</mat-checkbox>
						</div>
					</div>
				</div>
				<div class='col-12 text-center' mat-dialog-actions>
					<button class="btn btn-primary btn-elevate kt-login__btn-primary" 
					[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}" 
					(click)="onSubmit()">Submit</button>
					<button mat-button (click)="closeModal(undefined)">Cancel</button>
				</div>
			</form>
		</div>
	</div>
</div>
