export const environment = {
	production: false,
	isMockEnabled: true, // You have to switch this, when your real back-end is done

	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	Key: 'E5nbKf73VPzRbBu1XQyY',

	//apiPath:'http://localhost:59492/',
	//apiV2Path:"http://localhost:7071/api/",
	apiPath: 'https://staginggyr.azurewebsites.net/',
	apiV2Path: "https://staging-gyrfunctions20240506115722.azurewebsites.net/api/",

	// apiPath: 'https://api.grabyourreviews.com/',
	
	// apiV2Path: "https://apiv2.grabyourreviews.com/api/",

	// apiPath:'https://grabyourreview20200704105113.azurewebsites.net/',
	// apiPath:'http://localhost:59492/',
	imagePath: 'https://imagegrabyourreviews.now.sh/images/',
	ipApiPath: 'http://freegeoip.net/json/?callback',
	systemName: 'Grab Your Reviews',
	googleMapsApiKey: 'AIzaSyC6_3XiLWKHOukoXJ5obHrafoMEGDBc7TQ',
	apiPathForPlaceidFromCid: 'https://maps.googleapis.com/maps/api/place/details/json?',
	//domainName: 'http://localhost:4200/',
	domainName: 'https://app.grabyourreviews.com',
	//Project Settings
	writeReviewPath: 'http://search.google.com/local/writereview?placeid=',
	// noReplyEmail:'noreply.invitations@grabyoureviews.com',
	noReplyEmail: 'noreply',
	ipAddressApiPath: 'https://jsonip.com',
	sessionTimeOut: 1000,
	sessionTimeOutMsg: 'session expired',
	userNotFound: 'user details not found',

	//Encyption data
	chipherKey: 'htklHf45A03CXOlNhQa2hNAWS',
	keySize: 256,
	ivSize: 128,
	saltSize: 256,
	iterations: 1000,

	// zapier url with client id
	zapUrl: 'https://api.zapier.com/v1/zap-templates?client_id=UZQCa97gUNvr2r9HylISdrI9KwC9tMuanWsSUaYY',

	//ImageUplaod api
	// imagePath
	// uploadImageApiPath: 'https://img.grabyourreviews.com/',
	// deleteImageApiPath: 'https://img.grabyourreviews.com/',
	uploadImageApiPath: 'https://api.grabyourreviews.com/',
	deleteImageApiPath: 'https://api.grabyourreviews.com/',
	imageApiKey: 'fKe8JoJveD7fyDMfukbzEAzs',

	//LandingPageDomain
	landingPageDomain: 'https://app.grabyourreviews.com',

	// widget links
	widgetDomain: 'https://my.grabyourreviews.com',
	widgetIconImagePathDomain: 'https://app.grabyourreviews.com',


	//setup domain api
	setupDomainApiPath: "https://api.zeit.co",

	//Analytics id
	getAnalyticsId: 'GTM-5HNPKPT',

	//billing
	localdomain: 'http://localhost:4200/',
	pricingPageInd: 'https://grabyourreviews.com/pricing/',
	pricinigPage: 'https://grabyourreviews.com/pricing/',

//QuickBooks
	quickBoolClinetId:'ABu4nUrUYZbtOdBIDFKHuz8q4vb4lVGgqMgnKBGehQYfuJLqsB',
	//quickBoolClinetId:'ABEHcy8Go8J1NOBHT9GTZKh1MBXG5z5ho2Qu8Y44CddZFiP9kF', //Sandbox

	//Stripe details
	stripeKey: 'pk_live_VPpBZQcANTDIHeC45rFSPBrf00Ww6DEjlo',
	//stripeKey: 'pk_test_PtpfU5Nk2DYlqYbatPBW9u3K0088067hve',
	
};
// api path - (get place id from service area link or map link)
// https://maps.googleapis.com/maps/api/place/details/json?cid={Cid}&key=YpurApiKey
// 12574495977099902443
//718-inspect https://maps.googleapis.com/maps/api/place/details/json?cid=12574495977099902443&key=AIzaSyC6_3XiLWKHOukoXJ5obHrafoMEGDBc7TQ
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
