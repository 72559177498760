// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';

@Injectable()
export class MenuHorizontalService {
	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	identity = new BehaviorSubject('');
	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
	constructor(private menuConfigService: MenuConfigService) {
		this.loadMenu();
		this.identity.next('initial_value');
	}

	/**
	 * Load menu list
	 */

	updateIndentity(newId: string) {
		this.identity.next(newId);
	}

	loadMenu() {
		// get menu list
		const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'header.items');
		this.menuList$.next(menuItems);
	}

	passToken(token: string) {
		this.identity.next(token);
	}
}
