import { MatDialog } from '@angular/material';
import { ConfrimAlertComponent } from '../../confrim-alert/confrim-alert.component';
import { environment } from '../../../../../environments/environment';
import { DataTableService } from '../../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from '../../../../core/_base/layout/services/page-config.service';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as XLSX from 'xlsx'
import { Router } from '@angular/router';
@Component({
  selector: 'kt-review-request-form',
  templateUrl: './review-request-form.component.html',
  styleUrls: ['./review-request-form.component.scss']
})
export class ReviewRequestFormComponent implements OnInit {
  model = {
    emailFlag: true,
    smsFlag: false,
    whatsAppFlag: false,
    fname: '',
    lname: '',
    email: '',
    phoneNumber: '',
    certified: false,
  };
  submittedform: boolean = false;
  isDisable = false;
  loading: boolean = false;
  inviteForm: UntypedFormGroup;
  accessKey: any;
  profileId: any;
  countryCode: any;
  emailSmsErrorFlag: boolean = false;
  csvRecords: any[];
  emailRegEx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  phoneRegEx = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  sampleArr = [
    {
      Firstname: 'Firstname',
      Lastname: 'Lastname',
      Email: 'Email',
      Phonenumber: 'Phonenumber',
      Countrycode: 'Countrycode'
    },
    {
      Firstname: 'John',
      Lastname: '',
      Email: 'john@hotmail.com ',
      Phonenumber: '987852121',
      Countrycode: '91'
    }
  ];
  @Input() logo: string;
  @Input() companyName: string;

  isShow = false;
  emailCount: any = 0;
  smsCount: any = 0;
  usedEmailClr: any;
  usedSmsClr: any;
  usedEmailPer: any;
  usedSmsPer: any;
  totalEmailAvil: any;
  totalSmsAvial: any;
  isEmailFlag:any;
  isSMSFlag:any;
  smsTempLateData = [];
  constructor(public formBuilder: UntypedFormBuilder,
    public http: HttpClient,
    public pageService: PageConfigService,
    public dataService: DataTableService,
    public dialogRef: MatDialog,
    public router: Router,
    public cdr: ChangeDetectorRef) {

  }

  ngOnInit() {

    var scope = this;
    // alert()
    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
    scope.pageService.callChooseProfile();
    scope.loadSMSTemplates()
    scope.pageService.getProgileId().subscribe(message => {
      if (message.id) {
        scope.profileId = JSON.parse(localStorage.getItem('ProfileId'));
        // if (scope.router.url.includes('invite-customer')) {
        //  scope.loadSMSTemplates()
        // }
        scope.cdr.markForCheck();
      }
    });
    //Register controls for validation
    scope.inviteForm = scope.formBuilder.group({
      emailFlag: [false],
      smsFlag: [false],
      // whatsAppFlag: [false],
      fname: [''],
      lname: [''],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      certified: [false, Validators.requiredTrue]
    })

    if (localStorage.getItem("isConnnectedReviewPlatform") == "false") {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
  }
  get form() { return this.inviteForm.controls; }

  //hide error message when enters text 
  changeCheckbox() {
    var scope = this;
    scope.emailSmsErrorFlag = false;
    scope.cdr.markForCheck();
    if (scope.model.emailFlag) {
      scope.inviteForm.controls.email.enable();
    } else {
      scope.model.email = ''
      scope.inviteForm.controls.email.disable();
    }
    if (scope.model.smsFlag) {
      if (scope.smsTempLateData.length == 0) {
        //  scope.opencreateSMStemplateALert();
        scope.model.smsFlag = false;
      } else {
        scope.inviteForm.controls.phoneNumber.enable();
        setTimeout(() => {
          var input = document.querySelector("#inviteText");
          var errorMsg = document.querySelector("#error-msg-phone-invite");
          var validMsg = document.querySelector("#valid-msg-phone-invite");
          let ip = localStorage.getItem('IpAddress');

          phoneText(ip, input, errorMsg, validMsg, scope)
        }, 100)
      }
    } else {
      scope.model.phoneNumber = ''
      scope.inviteForm.controls.phoneNumber.disable();
    }
  }

  opencreateSMStemplateALert() {
    var scope = this;
    var str = '<div class="text-center">To send review invitation through SMS,You need to create atleast one sms template. <br/>Template settings > SMS/Text template</div>';
    let smstempalte = scope.dialogRef.open(ConfrimAlertComponent, {
      width: '700px',
      panelClass: 'editModal',
      // position:{top:'10px'},
      disableClose: true,
      data: {
        message: str,
        title: 'Create SMS/Text Tempate',
        buttonText: 'Create template now',
        source: 'create sms'
      }
    });
    smstempalte.afterClosed().subscribe((res) => {
      if (res) {
        if (res.flag == true) {
          scope.router.navigate([res.url])
        }
      }
    })
  }

  onsubmitForm() {
    var scope = this;
    scope.submittedform = true;

    if (scope.inviteForm.invalid) {
      if (!scope.model.emailFlag && !scope.model.smsFlag) {
        scope.emailSmsErrorFlag = true;
      }
      return;
    }
    if (!scope.model.emailFlag && !scope.model.smsFlag) {
      scope.emailSmsErrorFlag = true;
      return;
    }
    if (scope.model.smsFlag && !document.querySelector("#error-msg-phone-invite").classList.contains('hide')) {
      return;
    }

    scope.callInviteUserAPi();
  }
  callInviteUserAPi() {
    var scope = this;
    let finalData = {
      FirstName: scope.model.fname,
      LastName: scope.model.lname,
      Email: scope.model.email,
      IsEmail: scope.model.emailFlag,
      IsSMS: scope.model.smsFlag,
      PhoneNumber: scope.model.phoneNumber,
      IsWhatsApp: scope.model.whatsAppFlag,
      ProfileId: scope.profileId,
      IsOptin: true,
    }
    let intlObj = scope.dataService.getData('IntlObj')
    if (scope.model.smsFlag) {
      finalData['CountryCode'] = this.countryCode;
      scope.model.email = ''
      scope.inviteForm.controls.email.disable();
    }
    // InviteCustomer
    if (this.model.smsFlag && localStorage.getItem("currentCountry").toLowerCase() != intlObj['selectedCountryData'].iso2) {
      this.pageService.showError("Opps!!", "You are not allowed to send messages to " + intlObj['selectedCountryData'].name + " from the register country")
    } else {
      scope.loading = true;
      scope.http.post(environment.apiPath + 'InviteCustomer', finalData)
        .subscribe((res) => {
          scope.loading = false;
          scope.cdr.markForCheck();
          if (res['success']) {
            scope.pageService.showSuccess('Invited', 'Customer Invited successfully.');
            this.loadSMSTemplates();
            scope.inviteForm.reset();
            scope.resetmodel()
          } else {
            scope.pageService.showError('Oops!!!', res['errorMsg'])
            if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
              setTimeout(() => {
                scope.pageService.sessionExpired();
              }, environment.sessionTimeOut)
            }
          }
        }, (err) => {
          if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

            this.pageService.hideLoader();
            this.pageService.showError('Oops!', err.error.errorMsg);
          }
        })
    }
  }
  //Your CSV must contain the headers Firstname, Lastname, Email, and Phonenumber(only when you send sms invitation) in order to be accepted.
  openCSVAlert() {
    var scope = this;
    var str = 'Your CSV must contain the headers Firstname, Lastname, Email, and Phonenumber (only when you send sms invitation) in order to be accepted.';
    let csvAlert = scope.dialogRef.open(ConfrimAlertComponent, {
      width: '700px',
      panelClass: 'editModal',
      // position:{top:'10px'},
      disableClose: true,
      data: {
        message: str,
        title: 'Upload CSV/XLSX ',
        buttonText: 'Upload',
        source: 'csv upload warning'
      }
    });
    csvAlert.afterClosed().subscribe((res) => {
      if (res) {
        if (res.flag == true) {
          document.querySelector('#kt_uppy_5_input_control_rr')['click']();
        }
      }
    })

  }
  //Upload csv
  onFilesAdded(event) {

    var scope = this;
    // var preview = document.querySelector('.businessLogoImg');

    // var file  = document.querySelector('input[type=file].fileInput')['files'][0];

    var reader = new FileReader();

    reader.addEventListener("load", function (resp) {

      var contents = scope.processExcel(resp.target['result']);
      var finalJson = JSON.parse(contents)
      let completeArr = JSON.parse(finalJson.completeArr)
      let inCompleteArr = JSON.parse(finalJson.inCompleteArr)





      scope.inviteCustomerFromCSV(inCompleteArr, completeArr)
      // let csvData = reader.result;

      // let csvRecordsArray = csvData['split'](/\r\n|\n/);
      // scope.csvRecords = scope.getDataRecordsArrayFromCSVFile(csvRecordsArray);


    }, false);

    if (event.target.files[0]) {

      if (/.*\.(xlsx|xls|csv)$/i.test(event.target.files[0].name)) {

        reader.readAsBinaryString(event.target.files[0])
        // reader.readAsArrayBuffer(event.target.files[0])
        // reader.readAsText(event.target.files[0]);
      } else {
        alert('Invalid file')
      }
    }
  }

  processExcel(data) {
    var scope = this;
    var workbook = XLSX.read(data, {
      type: 'binary'
    });

    var firstSheet = workbook.SheetNames[0];
    let returnData = scope.to_json(workbook);
    return returnData;
  };

  to_json(workbook) {
    var scope = this;
    var result = {};
    workbook.SheetNames.forEach(function (sheetName) {
      var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1
      });
      if (roa.length) result[sheetName] = roa;
    });

    let data = scope.createFinalJson(result)
    return JSON.stringify(data);
  };
  createFinalJson(sheetArr) {
    var scope = this;

    let completeArr = [];
    let inCompleteArr = [];
    sheetArr.Sheet1.forEach((alone, i) => {
      if (i > 0 && alone.length > 0) {
        let temp = {};
        let csvDatafname = alone[0] == undefined ? '' : typeof alone[0] == 'string' ? alone[0] : JSON.stringify(alone[0]);
        let csvDatalname = alone[1] == undefined ? '' : typeof alone[1] == 'string' ? alone[1] : JSON.stringify(alone[1]);
        let csvDataemail = alone[2] == undefined ? '' : typeof alone[2] == 'string' ? alone[2].replace(/ /g, '') : JSON.stringify(alone[2]);
        let csvDataphonenumber = alone[3] == undefined ? '' : typeof alone[3] == 'string' ? alone[3].replace(/ /g, '') : JSON.stringify(alone[3]);
        let csvDatacountrycode = alone[4] == undefined ? '' : typeof alone[4] == 'string' ? alone[4].replace(/ /g, '') : JSON.stringify(alone[4])

        if (csvDataemail.length > 0 && csvDataphonenumber.length > 0 && csvDatacountrycode.length > 0 &&
          scope.emailRegEx.test(csvDataemail) == true &&
          scope.phoneRegEx.test('+' + csvDataphonenumber) == true) {//data with fname lname email phone number

          temp['FirstName'] = csvDatafname;
          temp['LastName'] = csvDatalname;
          temp['Email'] = csvDataemail;
          temp['PhoneNumber'] = csvDataphonenumber;
          temp['CountryCode'] = '+' + csvDatacountrycode;
          temp['IsSMS'] = true;
          temp['IsEmail'] = true;
          completeArr.push(temp);
        } else if (csvDataemail.length > 0 &&
          scope.emailRegEx.test(csvDataemail) == true &&
          csvDataphonenumber.length == 0) {//data with fname lname only email not phonenumber

          temp['FirstName'] = csvDatafname;
          temp['LastName'] = csvDatalname;
          temp['Email'] = csvDataemail;
          temp['PhoneNumber'] = '';
          temp['CountryCode'] = '';
          temp['IsSMS'] = false;
          temp['IsEmail'] = true;
          completeArr.push(temp);
        } else if (csvDataemail.length == 0 && csvDataphonenumber.length > 0 && csvDatacountrycode.length > 0 &&
          scope.phoneRegEx.test('+' + csvDataphonenumber) == true) {//data with fname lname only phonenumber not email

          temp['FirstName'] = csvDatafname;
          temp['LastName'] = csvDatalname;
          temp['Email'] = '';
          temp['PhoneNumber'] = csvDataphonenumber;
          temp['CountryCode'] = '+' + csvDatacountrycode;
          temp['IsSMS'] = true;
          temp['IsEmail'] = false;
          completeArr.push(temp);
        } else {

          temp['FirstName'] = csvDatafname;
          temp['LastName'] = csvDatalname;
          temp['Email'] = csvDataemail;
          temp['PhoneNumber'] = csvDataphonenumber;
          temp['CountryCode'] = csvDatacountrycode;
          inCompleteArr.push(temp)
        }
      }
    })
    var dataToReturn = {
      completeArr: JSON.stringify(completeArr),
      inCompleteArr: JSON.stringify(inCompleteArr)
    }
    return dataToReturn
  }
  openInCompleteAlert(inCompleteArr) {
    var scope = this;


    var str = '<div class="text-center text-bold text-1-2x"> ' + inCompleteArr.length + ' customers can not be invited. Download the error log file here.</div>';

    const confirmFromInvite = scope.dialogRef.open(ConfrimAlertComponent, {
      width: '700px',
      panelClass: 'editModal',
      // position:{top:'10px'},
      disableClose: true,
      data: {
        message: str,
        buttonText: 'Download error log',
        source: 'invite customer csv'
      }
    });
    confirmFromInvite.afterClosed().subscribe(result => {

      if (result) {
        let dataToCsv = [
          {
            Firstname: 'Firstname',
            Lastname: 'Lastname',
            Email: 'Email',
            Phonenumber: 'Phonenumber',
            Countrycode: 'Countrycode',
            Error: 'Error'
          }
        ];
        inCompleteArr.forEach((element, i) => {
          let temp = {
            Firstname: element['FirstName'] != null && element['FirstName'] != undefined ? element['FirstName'] : '',
            Lastname: element['LastName'] != null && element['LastName'] != undefined ? element['LastName'] : '',
            Email: element['Email'] != null && element['Email'] != undefined ? element['Email'] : '',
            Phonenumber: element['PhoneNumber'] == null || element['PhoneNumber'] == undefined ? '' : typeof element['PhoneNumber'] == 'string' ? element['PhoneNumber'] : JSON.stringify(element['PhoneNumber']),
            CountryCode: element['CountryCode'] != null && element['CountryCode'] != undefined ? element['CountryCode'] : '',
            Error: element['Error'] != null && element['Error'] != undefined ? element['Error'] : '',
          }
          temp['Phonenumber'] = temp.Phonenumber.replace('+', '')
          let pushData = JSON.parse(JSON.stringify(temp))
          dataToCsv.push(pushData);
        });

        scope.downloadSample(dataToCsv, 'Error-log-list')
      }
    })
  }
  sampleDownloaCalled() {
    this.downloadSample(this.sampleArr, 'csv-sample')
  }
  downloadSample(dataToCsv, filename) {
    new ngxCsv(dataToCsv, filename);
  }
  inviteCustomerFromCSV(inCompleteArr, completeArr) {
    var scope = this;

    // let finalData = {
    //   Profileid: scope.profileId,
    //   Data: completeArr,
    //   IsOptin: true,
    // }

    let finalData = {
      Key: scope.accessKey,
      IsOptin: false,
      Profileid: scope.profileId,
      Data: completeArr
    }

    let tempIncompleteArr = JSON.parse(JSON.stringify(inCompleteArr));
    let docErr = [];

    scope.http.post(environment.apiPath + "ImportInviteCustomer", finalData)
      .subscribe((res) => {

        if (res['success']) {
          scope.pageService.showSuccess('Invited', 'Customers invited successfully.');
          if (res['docErrors'].length > 0) {
            docErr = JSON.parse(JSON.stringify(res['docErrors']));
            docErr.filter((ele) => {
              let element = completeArr.filter((aln) => {
                if ((ele.email != null && ele.email != undefined && ele.email.toLocaleLowerCase() == aln['Email'].toLocaleLowerCase()) ||
                  (ele.phoneNumber != null && ele.phoneNumber != undefined && ele.phoneNumber.toLocaleLowerCase() == aln['PhoneNumber'].toLocaleLowerCase())) {
                  return aln;
                }
              })

              let data = {
                FirstName: element[0].FirstName,
                LastName: element[0].LastName,
                Email: element[0].Email,
                PhoneNumber: element[0].PhoneNumber,
                CountryCode: element[0].CountryCode,
                Error: ele.errorMsg
              }

              tempIncompleteArr.push(data)
              // {email: "divya@yopmail.com", errorMsg: "Email Address Already invited"}
            })
            if (tempIncompleteArr.length > 0) {
              scope.openInCompleteAlert(tempIncompleteArr)
            }
          }
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  resetmodel() {
    this.submittedform = false;
    this.model = {
      emailFlag: true,
      smsFlag: false,
      whatsAppFlag: false,
      fname: '',
      lname: '',
      email: '',
      phoneNumber: '',
      certified: false,
    };
  }


  //sms api added 

  loadSMSTemplates() {
    var scope = this;
    scope.smsTempLateData = [];
    //   this.inviteForm.reset();
    //  this.inviteForm.controls['emailFlag'].setValue(true);
    //  this.inviteForm.controls['smsFlag'].setValue(false);
    this.model.smsFlag = false;
    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'getSMStemplateDetails?key=' + scope.accessKey +
      '&ProfileId=' + scope.profileId)
      .subscribe((response: any) => {
        scope.pageService.hideLoader();

        this.emailCount = response.usedEmail;
        this.smsCount = response.usedSMS;
        this.usedEmailPer = response.usedEmailpercentage;
        this.usedSmsPer = response.usedsmspercentage;
        this.isEmailFlag = response.isEmail;
        this.isSMSFlag = response.isSMS;
        if (response['success']) {

          if (this.usedEmailPer >= 0 && this.usedEmailPer <= 25) {
            this.usedEmailClr = "#ADFA9E";
          } else if (this.usedEmailPer >= 26 && this.usedEmailPer <= 85) {
            this.usedEmailClr = "#FBC97F";
          } else if (this.usedEmailPer > 85) {
            this.usedEmailClr = "#FFA38C";
          }

          if (this.usedSmsPer >= 0 && this.usedSmsPer <= 60) {
            this.usedSmsClr = "#ADFA9E";
          } else if (this.usedSmsPer >= 61 && this.usedSmsPer <= 90) {
            this.usedSmsClr = "#FBC97F";
          } else if (this.usedSmsPer > 90) {
            this.usedSmsClr = "#FFA38C";
          }

          this.totalEmailAvil = response.totalEmail;
          this.totalSmsAvial = response.totalSMS;

          scope.smsTempLateData = response['data'];
          let country = response.country;
          let accountCountry = localStorage.getItem("currentCountry");
          //added new appsumo condition
          
          let planName = localStorage.plan;
          if (planName == "\"Grab Your Reviews Tier 2\"" || planName == "\"Grab Your Reviews Tier 3\"" || planName == "\"Grab Your Reviews Tier 1\"") {
            this.isShow = true;
            this.countryCode = '+1';
          }
          else if (country.toLocaleUpperCase() == "IN" || country.toLocaleUpperCase() == "US" || country.toLocaleUpperCase() == "UK" || country.toLocaleUpperCase() == "CA" || country.toLocaleUpperCase() == "AU" || country.toLocaleUpperCase() == "GB" || country.toLocaleUpperCase() == "ZA") {
            this.isShow = true;
            if (country == "US" || country == "CA") {
              this.countryCode = '+1';
              this.cdr.markForCheck();
            } else if (country == "IN") {
              this.countryCode = '+91';
              this.cdr.markForCheck();
            } else if (country == "UK" || country == "GB") {
              this.countryCode = '+44';
              this.cdr.markForCheck();
            } else if (country == "AU") {
              this.countryCode = '+61';
              this.cdr.markForCheck();
            } else if (country == "ZA") {
              this.countryCode = '+27';
              this.cdr.markForCheck();
            }

            this.cdr.markForCheck();
          } else {
            this.isShow = false;
            this.form.phoneNumber.disable()
            this.cdr.markForCheck();
          }
          // }

        } else {
          scope.pageService.showError('Oops!!!', response['errorMsg']);
          if (response['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || response['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }

        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }


}
