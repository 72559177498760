import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { QrCodeComponent } from '../qr-code/qr-code.component';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void
  }
}

@Component({
  selector: 'kt-create-qrcode',
  templateUrl: './create-qrcode.component.html',
  styleUrls: ['./create-qrcode.component.scss']
})

export class CreateQrcodeComponent implements OnInit {
  formsubmitted: boolean = false;
  Employee;
  Department;
  layout;
  layoutUrl = '';
  showqrcode: boolean = false;
  @ViewChild("qrcode") qrcode: any;

  key: any;
  profileId: any;

  EmployeeQR: UntypedFormGroup;
  fromSubmitted: boolean = false;

  constructor(public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<QrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public navData: any,
    public http: HttpClient,
    public router: Router,
    public pageService: PageConfigService) { }

  ngOnInit() {
    var scope = this;
    this.key = localStorage.getItem(environment.authTokenKey);
    this.profileId = JSON.parse(localStorage.getItem("ProfileId"));
    scope.EmployeeQR = scope.fb.group({
      employee: ['', Validators.required],
      department: ['']
    });
  }
  ongenerate() {
    var scope = this;

    if (scope.showqrcode == true) {
      scope.pageService.showInfo('', 'Generating image please wait...');
      let parent = document.querySelector(".qrcodeparent");
      if (parent.querySelector("canvas") != null) {
        var canvas1 = parent.querySelector("canvas");

        // canvas1.width = 512;
        // canvas1.height = 512;
        var image = canvas1.toDataURL();
        var link = document.createElement('a');
        link.download = "Qrcode-" + scope.Employee;
        link.href = image;
        link.click();

      } else if (parent.querySelector("img") != null) {
        var parentElement = parent.querySelector("img").src
        // converts base 64 encoded image to blobData
        let blobData = scope.convertBase64ToBlob(parentElement);

        // saves as image
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
          window.navigator.msSaveOrOpenBlob(blobData, "Qrcode-" + scope.Employee);
        } else { // chrome
          const blob = new Blob([blobData], { type: "image/png" });
          const url = window.URL.createObjectURL(blob);
          // window.open(url);
          const link = document.createElement('a');
          link.href = url;
          link.download = "Qrcode-" + scope.Employee;
          link.click();
        }
      }
      // close(){
      //   this.dialogRef.close();
      // }
    }
    else {
      scope.callAddApi();
      // scope.layout = scope.navData;
      // this.formsubmitted = true;
      // if (this.EmployeeQR.invalid) {
      //   return;
      // }
      // let Data = {
      //   Employee: this.Employee,
      //   Department: this.Department
      // }
      // if (Data != undefined) {
      //   scope.showqrcode = true;
      //    scope.callAddApi();
      //   if (Data.Department == undefined) {
      //     scope.layoutUrl = scope.layout + '&emp=' + scope.Employee;
      //   }
      //   else {
      //     scope.layoutUrl = scope.layout + '&emp=' + scope.Employee + '&dept=' + scope.Department;
      //   }
      // }

      // else {
      //   this.dialogRef.close();
      // }
    }
  }

  loading: any;
  callAddApi() {
    var scope = this;
    scope.fromSubmitted = true;
    if (scope.EmployeeQR.invalid) {
      return;
    }

    let data = {
      ProfileId: this.profileId,
      Key: this.key,
      EmployeeName: this.Employee,
      Department: this.Department
    }
    scope.loading = true;
    scope.http.post(environment.apiPath + 'AddEmployee', data)
      .subscribe((res) => {
        scope.loading = false;
        if (res['success']) {
          // added by unnati
          let Data = {
            Employee: this.Employee,
            Department: this.Department
          }
          if (Data != undefined) {
            scope.showqrcode = true;
            scope.layoutUrl = res['qRcodeURL'];
            // scope.EmployeeQR.reset();

          }

          else {
            this.dialogRef.close();
          }

        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg']);
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }






  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

}
