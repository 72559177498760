import { DragDropModule } from '@angular/cdk/drag-drop';
import { TemplateSettingsComponent } from './template-settings/template-settings.component';
import { EmailTemplatePageComponent } from './template-settings/email-template-page/email-template-page.component';
import { WhtsappTemplatePageComponent } from './template-settings/whtsapp-template-page/whtsapp-template-page.component';
import { SmsTemplatePageComponent } from './template-settings/sms-template-page/sms-template-page.component';
import { ReviewRequestFormComponent } from './review-request-form/review-request-form.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { PartialsModule } from './../../partials/partials.module';
import { LandingPageComponent } from './landing-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../core/core.module';
import { MatCardModule, MatTabsModule, MatFormFieldModule, MatDividerModule, MatButtonModule, MatIconModule, MatCheckboxModule, MatSlideToggleModule, MatTooltipModule, MatRadioModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { ReviewPageComponent } from './review-page/review-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OptInPageComponent } from './opt-in-page/opt-in-page.component';
import { ClipboardModule } from 'ngx-clipboard';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CreateQrcodeComponent } from './create-qrcode/create-qrcode.component';
import { PrivateFeedbackTemplatePageComponent } from './template-settings/private-feedback-template-page/private-feedback-template-page.component';
import { CUSTOM_ELEMENTS_SCHEMA, } from '@angular/core';
import { ClientReviewModule } from '../client-review/client-review.module';

@NgModule({
    declarations: [
        CreateQrcodeComponent,
        LandingPageComponent,
        ReviewPageComponent,
        OptInPageComponent,
        ReviewRequestFormComponent,
        SmsTemplatePageComponent,
        WhtsappTemplatePageComponent,
        EmailTemplatePageComponent,
        TemplateSettingsComponent,
        QrCodeComponent,
        CreateQrcodeComponent,
        PrivateFeedbackTemplatePageComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        MatRadioModule,
        FormsModule,
        DragDropModule,
        PartialsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDividerModule,
        MatButtonModule,
        MatCardModule,
        MatTabsModule,
        RichTextEditorAllModule,
        MatExpansionModule,
        ClipboardModule,
        MatIconModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        ClientReviewModule,
        MatTooltipModule,
        PickerModule,
        NgbTooltipModule,
        QRCodeModule,
        RouterModule.forChild([
            {
                path: '',
                component: LandingPageComponent,
                children: [
                    {
                        path: 'review-page',
                        component: ReviewPageComponent
                    },
                    {
                        path: 'opt-in-page',
                        component: OptInPageComponent
                    },
                    {
                        path: 'template-settings',
                        component: TemplateSettingsComponent
                    },
                    {
                        path: 'qr-code',
                        component: QrCodeComponent
                    }
                ]
            },
        ]),
    ],
    exports: [LandingPageComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LandingPageModule { }
