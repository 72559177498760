import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientReviewComponent } from './client-review.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ClientReviewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: ClientReviewComponent
      },
    ]),
  ],
   exports: [ClientReviewComponent]  // <== export the component you want to use in another module
})
export class ClientReviewModule { }
