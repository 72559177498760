import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';

@Component({
  selector: 'kt-template-settings',
  templateUrl: './template-settings.component.html',
  styleUrls: ['./template-settings.component.scss']
})
export class TemplateSettingsComponent implements OnInit {
  emailTemplateFlag: boolean = true;
  smsTemplateFlag: boolean = false;
  whatsappTemplateFlag: boolean = false;
  pvtFeeddbackTemplateFlag = false;
  constructor(public cdr: ChangeDetectorRef,
    public router: Router,
    public pageService: PageConfigService) { }

  isShow = true;

  ngOnInit() {
    this.hideTab();
    this.pageService.getProgileId().subscribe(message => {
      if (message.id) {
        // this.profileId = JSON.parse(localStorage.getItem('ProfileId'))
        if (this.router.url.includes('customization-options/template-settings')) {
          this.hideTab();
        }
      }
    })
  }

  hideTab() {

    // this.pageService.checkReviewStatus().then((res: any) => {
    //   let country = res.country.toLocaleUpperCase()
    //   if (country == "ZA" || country == "GB" || country == "US" || country == "CA" || country == "IN" || country == "AU" || country == "UK") {
    //     this.isShow = true;
    //     this.cdr.markForCheck();
    //   } else {
    //     this.isShow = false;
    //     this.cdr.markForCheck();
    //   }
    // })
  }
  tabChanged(event) {
    var scope = this;
    scope.emailTemplateFlag = false;
    scope.smsTemplateFlag = false;
    if (event.index == 0) {
      scope.emailTemplateFlag = true;
    } else if (event.index == 1) {
      scope.smsTemplateFlag = true;
      if (!this.isShow) {
        this.pvtFeeddbackTemplateFlag = true;
      }
    } else if (event.index == 2) {
      scope.pvtFeeddbackTemplateFlag = true;
    }
    scope.cdr.markForCheck()
  }
  addEmoji(event) {

    // const { message } = this;
    // const text =${message}${event.emoji.native}`
  }
}
