import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';

@Component({
  selector: 'kt-facebook-account-details',
  templateUrl: './facebook-account-details.component.html',
  styleUrls: ['./facebook-account-details.component.scss']
})
export class FacebookAccountDetailsComponent implements OnInit {

  constructor(public http: HttpClient, public router: Router, public cdr: ChangeDetectorRef,
    public pageService: PageConfigService) {
    this.data = this.router.getCurrentNavigation().extras.state.example;
  }
  data: any;
  facebookPageList = [];

  ngOnInit() {
    this.pageService.showLoader();
    this.http.post(environment.apiPath + 'GetFacebookPageList', this.data).subscribe((res: any) => {
      if (res.success) {
        this.pageService.hideLoader();
        for (let i in res.data) {
          this.facebookPageList.push(res.data[i])
        }
        this.cdr.markForCheck();
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }
  submit(fbData) {
    this.pageService.showLoader();
    let fb_data = {
      PageId: fbData.id,
      AccessToken: this.data.AccessToken,
      ProfileId: JSON.parse(localStorage.getItem("ProfileId")),
      PageAccessToken: fbData.accessToken,
      Key: localStorage.getItem(environment.authTokenKey)
    }
    let profileId = JSON.parse(localStorage.getItem('ProfileId'))
    this.http.post(environment.apiPath + "LinkFacebook", fb_data).subscribe((res: any) => {
      if (res.success) {
        this.http.get(environment.apiPath + 'FetchFacebookReview?IsFirstTime=True' + '&ProfileId=' + profileId).subscribe()
        // this.http.get(environment.apiPath + 'FatchFacebookReview?key=' + JSON.parse(localStorage.getItem("ProfileId"))).subscribe()
        this.pageService.createProfileJson(JSON.parse(localStorage.getItem("ProfileId"))).subscribe();

        this.pageService.hideLoader();
        this.router.navigate(['/review-platforms']);
        localStorage.setItem("isConnnectedReviewPlatform", "true")
      } else {
        this.pageService.hideLoader();
        this.pageService.showError("Error", res.errorMsg)
      }
    }, (err) => {
      if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

        this.pageService.hideLoader();
        this.pageService.showError('Oops!', err.error.errorMsg);
      }
    })
  }
}
