import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { PageConfigService } from '../../../../core/_base/layout/services/page-config.service';
import { environment } from '../../../../../environments/environment';
import { loadStripe } from '@stripe/stripe-js';

@Component({
  selector: 'kt-add-more-model',
  templateUrl: './add-more-model.component.html',
  styleUrls: ['./add-more-model.component.scss']
})
export class AddMoreModelComponent implements OnInit {
  source = '';
  headerTitle = '';
  oneTimeOrRecurring = '1';
  qty: number = 0;
  isUpgrade: boolean = false;
  accessKey: any;
  loading: boolean = false;
  isAgency: boolean = false;
  currentPlan = '';
  usageData: any;
  buttonDisable: boolean = true;
  isChanged: boolean = false;
  currency = 'inr';
  oneTimeCheckBox: boolean = true;
  recurringCheckBox: boolean = false;
  isRecurring = false;
  isFinalRecurring = false;
  stripeObj: any;
  terms: boolean = false;
  isShowCard: boolean = false;
  cardRadioButton = '1';
  formSubmitted: boolean = false;
  invalidCard: boolean = false;
  cardNumberObj: any;
  cardExpiryDateObj: any;
  cardCvvObj: any;
  hideexistingRadio: boolean = false;
  cardNumber: any;
  brandName: any;
  expMonth: any;
  expYear: any;
  accountCountry: any;

  isMaxProfileMsg: boolean = false;
  isMinProfileMsg: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public navData: any,
  public dialogRef: MatDialogRef<AddMoreModelComponent>,
  public cdr: ChangeDetectorRef,
  public http: HttpClient,
  public pageService: PageConfigService) { }
  
  ngOnInit() {
    this.loadSt();
    var scope = this;
    // if (JSON.parse(localStorage.getItem("isCard")) == true) {
    //   this.isShowCard = true;
    // }
    // else {
    //   this.isShowCard = false;
    // }

    scope.accessKey = localStorage.getItem(environment.authTokenKey);
    scope.isUpgrade = JSON.parse(localStorage.getItem('isAgency'))
    scope.currentPlan = JSON.parse(localStorage.getItem('current-plan')).toLocaleLowerCase();
    scope.accountCountry = JSON.parse(localStorage.getItem('account'));

    scope.source = scope.navData.source;
    scope.currency = scope.navData.currency;
    scope.usageData = JSON.parse(scope.navData.targetData);
    this.isRecurring = this.navData.isRecurring;
    this.isFinalRecurring = this.navData.isRecurring;
    if (this.navData.isRecurring) {
      this.oneTimeCheckBox = false;
      this.recurringCheckBox = true;
    } else {
      this.oneTimeCheckBox = true;
      this.recurringCheckBox = false;
    }
    if (scope.source == 'email') {
      scope.headerTitle = 'Additional Email Credits';
      scope.qty = scope.usageData.additionalEmail;
    } else if (scope.source == 'sms') {
      scope.headerTitle = 'Additional SMS Credits';
      scope.qty = scope.usageData.additionalSms;
    } else if (scope.source == 'profile') {
      scope.headerTitle = 'Addon Profiles';
      scope.qty = scope.usageData.totalProfile;
    } else if (scope.source == 'users') {
      scope.headerTitle = 'Addon Users';
    }

    scope.cdr.markForCheck();
    scope.loadCardData()
  }

  // countPrice(){
  //   this.Price = 0;
  //    for(let p of this.packagesArray){
  //      this.Price += p.price*p.quantity
  //    }
  //   }

    Price = 0;
    emailPrice = 5;
    smsPrice = 8;

  increaseQty() {
    var scope = this;
    scope.buttonDisable = false;
    scope.isChanged = true;
    scope.isUpgrade = true;
    if (scope.source == 'email') {
      scope.qty += 100;
      this.Price = (scope.qty) * (this.emailPrice);
    } else if (scope.source == 'sms') {
      scope.qty += 100;
      this.Price =(scope.qty * this.smsPrice);
    } else if (scope.source == 'profile') {
      this.isMinProfileMsg = false;
      this.isMaxProfileMsg = false;
      scope.qty += 1;
    } else if (scope.source == 'users') {
      scope.qty += 1;
    }
    scope.cdr.markForCheck()
  }

  decreaseQty() {
    var scope = this;
    scope.isUpgrade = false;
    scope.isChanged = true;
    scope.buttonDisable = false
    if (scope.qty > 0) {
      if (scope.source == 'email') {
        scope.qty -= 100;
        this.Price = (scope.qty) * (this.emailPrice)
        if (this.qty == 0) {
          this.buttonDisable = true;
        }
      } else if (scope.source == 'sms') {
        scope.qty -= 100;
        this.Price = (scope.qty) * (this.smsPrice);
        if (this.qty == 0) {
          this.buttonDisable = true;
        }
      } else if (scope.source == 'profile') {
        if (this.qty == this.usageData.minimumProfiles) {
          this.isMinProfileMsg = true;
          return
        } else if (this.qty <= this.usageData.usedProfile) {
          this.isMaxProfileMsg = true;
          return
        }
        if (scope.qty > scope.usageData.usedProfile) {
          scope.qty -= 1;
        }
      } else if (scope.source == 'users') {
        scope.qty -= 1;
      }
    } else {
      if (scope.isChanged == true) {
        scope.buttonDisable = true
      } else {
        scope.buttonDisable = false
      }
    }
    // else if (scope.qty == 0 && (scope.source == 'email' || scope.source == 'sms')) {
    //   if (scope.source == 'email' && scope.usageData.additionalEmail > 0) {
    //     scope.buttonDisable = false
    //   } else if (scope.source == 'sms' && scope.usageData.additionalSms > 0) {
    //     scope.buttonDisable = false
    //   }
    // } else {
    //   scope.buttonDisable = true

    // }

    scope.cdr.markForCheck()
  }

  submit() {
    var scope = this;
    scope.invalidCard = false;
    scope.formSubmitted = true;
    if (scope.currentPlan == "Grab Your Reviews Tier 1" || "Grab Your Reviews Tier 2" || "Grab Your Reviews Tier 3") {
      if (scope.cardRadioButton == '2') {
        scope.stripeObj.createToken(scope.cardNumberObj, scope.cardExpiryDateObj, scope.cardCvvObj).then(function (result) {

          if (result.error) {
            // Inform the user if there was an error.
            var errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;

            if (scope.terms == false) {
              return;
            }
          } else {
            // Send the token to your server.
            // if (scope.terms == false) {
            //   return;
            // }
            scope.stripeTokenHandler(result.token);
          }
        });
      } else {
        scope.stripeTokenHandler('');
      }
    }
    else {
      if (scope.source == 'profile') {
        scope.updateProfiles()
      } else {
        scope.callAddOns();
      }
    }
  }

  callAddOns() {
    var scope = this;
    let data = {
      Key: scope.accessKey,
      Quantity: scope.qty,
      Type: scope.source,
      IsRecurring: this.isFinalRecurring
      // IsUpgrade: scope.isUpgrade,
      // IsOneTime: true,
    }
    if (scope.oneTimeOrRecurring == '2') {
      data['IsOneTime'] = false;
    }
    scope.loading = true;
    scope.cdr.markForCheck()

    let finalData = { Data: scope.pageService.encrypt(JSON.stringify(data)) }
    scope.http.post(environment.apiPath + 'Addaddons', finalData)
      .subscribe((res) => {
        scope.loading = false;
        scope.cdr.markForCheck()
        if (res['success'] == true) {
          if (scope.source == 'email') {
            scope.pageService.showSuccess('Success', 'Email purchased successfully.')
          } else if (scope.source == 'sms') {
            scope.pageService.showSuccess('Success', 'SMS purchased successfully.')
          } else if (scope.source == 'users') {
            scope.pageService.showSuccess('Success', 'Users purchased successfully.')
          }
          scope.closeModal({ flag: true })
          this.isRecurring = false;
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          scope.loading = false;
          this.pageService.hideLoader();
          this.pageService.showError('Oops', err.error.errorMsg);
        }
      })

  }

  updateProfiles() {
    var scope = this;
    let data = {
      Key: scope.accessKey,
      Quantity: scope.qty,
      IsUpgrade: scope.isUpgrade
    }
    let finalData = { Data: scope.pageService.encrypt(JSON.stringify(data)) }
    scope.loading = true;
    scope.cdr.markForCheck()
    scope.http.post(environment.apiPath + 'Upgradecompanycount', finalData)
      .subscribe((res) => {

        scope.loading = false;
        scope.cdr.markForCheck()
        if (res['success'] == true) {
          scope.pageService.showSuccess('Updated', 'Company profile limit updated.')
          scope.closeModal({ flag: true })
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.loading = false;
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  closeModal(data) {
    if (data != undefined) {
      this.dialogRef.close(data)
    } else {
      this.dialogRef.close()
    }
  }

  recurring(e) {
    this.isFinalRecurring = e.checked;
  }

  changeCheckBox(e) {
    if (e == 'onetime') {
      this.isFinalRecurring = false;
      this.oneTimeCheckBox = true;
      this.recurringCheckBox = false;
    } else if (e == 'recurring') {
      this.isFinalRecurring = true;
      this.recurringCheckBox = true;
      this.oneTimeCheckBox = false;
    }
  }

  //payment method new added  by unnati
  radioButtonChange() {
    var scope = this;

    if (scope.cardRadioButton == '2') {
      setTimeout(() => {
        scope.loadSt()

      }, 1000)
    }
  }

  async loadSt() {
    var scope = this;
    scope.stripeObj = await loadStripe(environment.stripeKey);
    var elements = scope.stripeObj.elements();
    var style = {
      base: {
        color: '#000',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#ff0000',
        iconColor: '#ff0000'
      }
    };

    // Create an instance of the card Element.
    scope.cardNumberObj = elements.create('cardNumber', { iconStyle: "solid", showIcon: true, hidePostalCode: true, style: style });
    scope.cardExpiryDateObj = elements.create('cardExpiry', { style: style });
    scope.cardCvvObj = elements.create('cardCvc', { style: style });

    // Add an instance of the card Element into the `card-element` <div>.
    scope.cardNumberObj.mount('#cardNumber');
    scope.cardExpiryDateObj.mount('#cardExpiryDate');
    scope.cardCvvObj.mount('#cardCvv');
  }

  loadCardData() {
    var scope = this;
    scope.pageService.showLoader()
    scope.http.get(environment.apiPath + 'GetCardDetails?Key=' + scope.accessKey)
      .subscribe((res: any) => {

        scope.pageService.hideLoader()
        if (res['success'] == true) {
          scope.hideexistingRadio = false;

          this.cardNumber = 'XXXX XXXX XXXX ' + res.lastDigits;
          this.brandName = res.brand;
          this.expMonth = res.expMonth;
          this.expYear = res.expYear;

          scope.cardRadioButton = '1';
          scope.radioButtonChange();
          scope.cdr.markForCheck()
        } else {

          scope.hideexistingRadio = true;
          scope.cardRadioButton = '2';
          scope.radioButtonChange();
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }

  stripeTokenHandler(token) {
    var scope = this;
    if (token.id != undefined) {
      if (token.card != undefined) {

        if (scope.accountCountry.toLocaleLowerCase() == 'in') {
          if (token.card.brand.toLocaleLowerCase() == 'visa' || token.card.brand.toLocaleLowerCase() == "mastercard") {
            scope.callUpdateCard(token.id)
          } else {
            scope.invalidCard = true;
            return
          }
        }
        else {
          scope.callUpdateCard(token.id);
        }

      } else {
        scope.invalidCard = true;
        return
      }

    } else {
      scope.invalidCard = true;
      this.callUpdateCard(token)
      return
    }
  }

  callUpdateCard(token) {
    var scope = this;
    let data = {
      CardToken: token,
      Key: scope.accessKey,
      Type: scope.source,
      Quantity: scope.qty,
      IsRecurring: this.isFinalRecurring
    }
    if (scope.oneTimeOrRecurring == '2') {
      data['IsOneTime'] = false;
    }
    scope.loading = true;
    scope.cdr.markForCheck()

    //  let finalData = { Data: scope.pageService.encrypt(JSON.stringify(data)) }
    scope.http.post(environment.apiPath + 'AddMoreCredit', data)
      .subscribe((res) => {
        scope.loading = false;
        scope.cdr.markForCheck()
        if (res['success'] == true) {
          if (scope.source == 'email') {
            scope.pageService.showSuccess('Success', 'Email purchased successfully.')
          } else if (scope.source == 'sms') {
            scope.pageService.showSuccess('Success', 'SMS purchased successfully.')
          } else if (scope.source == 'users') {
            scope.pageService.showSuccess('Success', 'Users purchased successfully.')
          }
          scope.closeModal({ flag: true })
          this.isRecurring = false;
        } else {
          scope.pageService.showError('Oops!!!', res['errorMsg'])
          if (res['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || res['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
            setTimeout(() => {
              scope.pageService.sessionExpired();
            }, environment.sessionTimeOut)
          }
        }
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          scope.loading = false;
          this.pageService.hideLoader();
          this.pageService.showError('Oops', err.error.errorMsg);
        }
      })

  }

}
