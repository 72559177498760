import { DataTableService } from './../../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
import { Router } from '@angular/router';
import { environment } from './../../../../../environments/environment';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ConfirmPasswordValidator } from '../register/confirm-password.validator';
import { HttpClient } from '@angular/common/http';
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  resetPassForm: UntypedFormGroup;
  loading = false;
  errors: any = [];
  modelData = {
    password: ''
  };
  token = '';
  currentDomainUrl='';
  brandData:any
  constructor(public formBuilder: UntypedFormBuilder,
    public http: HttpClient,
    public authNoticeService: AuthNoticeService,
    public router: Router,
    public pageService: PageConfigService,
    public cdr:ChangeDetectorRef,
    public dataService:DataTableService) {
   }

  ngOnInit() {
    var scope = this;
    scope.resetPassForm = scope.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
        Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-]).{8,}$')
      ])
      ],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
    scope.pageService.setTitle('Reset Password')

    if (scope.router['browserUrlTree'].queryParams.token == undefined || scope.router['browserUrlTree'].queryParams.token == null) {
      scope.authNoticeService.setNotice('Invalid Token', 'danger');
      return;
    }
    scope.token = scope.router['browserUrlTree'].queryParams.token
    if (scope.token.length == null || scope.token.length == 0) {
      scope.authNoticeService.setNotice('Invalid Token', 'danger');
      return;
    }
   }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetPassForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  submit() {
    var scope = this;
    const controls = scope.resetPassForm.controls;
    if (scope.token.length == null || scope.token.length == 0) {
      scope.authNoticeService.setNotice('Invalid Token', 'danger');
      return;
    }
    if (scope.resetPassForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    scope.loading = true;
    let data = {
      Key: environment.Key,
      Password: scope.modelData.password,
      Token: scope.token
    };

    let finalData = { Data: scope.pageService.encrypt(JSON.stringify(data)) }
    scope.http.post(environment.apiPath + 'SetPassword', finalData)
      .subscribe((res) => {

        scope.loading = false;
        if (res['success']) {
          scope.authNoticeService.setNotice('Your password successfully updated.', 'success');
          setTimeout(() => {
            scope.router.navigateByUrl('/auth/login');
          }, 3000)
          // https://dev.grabyourreviews.com/ForgetPassword?token=Q%2fSK3M29NR7jDkYIu0O%2bOA%3d%3d
        } else {
          scope.authNoticeService.setNotice(res['errorMsg'], 'danger');
        }
      },(err) =>{
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
          this.pageService.showError('Oops!',err.error.errorMsg);
        }
      })
  }
}
