import { Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators';
import { DataTableService } from './../../../../core/_base/layout/services/datatable.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { PageConfigService } from './../../../../core/_base/layout/services/page-config.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CreateQrcodeComponent } from '../create-qrcode/create-qrcode.component';
import { MatDialog } from '@angular/material';
import { data } from 'jquery';
import { result } from 'lodash';
import { Observer } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'kt-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {
  profileId: any;
  accessKey: string;
  layoutUrl = '';
  companyName = '';
  profileList: any;
  tempArr: any = [];
  hasSentiment: boolean = false;

  constructor(public pageService: PageConfigService,
    public cdr: ChangeDetectorRef,
    public http: HttpClient,
    public dataService: DataTableService,
    public router: Router,
    public dialog: MatDialog) {
  }


  ngOnInit() {
    var scope = this;

    scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
    scope.accessKey = localStorage.getItem(environment.authTokenKey);
      // add hasSentiment code 
      let view = JSON.parse(localStorage.getItem('view'));
      let viewPermission = JSON.parse(this.pageService.decrypt(view));
      this.hasSentiment =  viewPermission['HasSentiments'];
  
    scope.pageService.callChooseProfile();
    scope.loadUrl();
   scope.addEmp();
    scope.pageService.getProgileId().subscribe(message => {
      if (message.id) {
        scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
        scope.cdr.markForCheck();
        if (scope.router.url.includes('qr-code')) {
          scope.loadUrl();
          scope.addEmp();
        }
        // scope.loadReviewPageSettings();
      }
    });
    scope.pageService.setTitle('QR Code');
  }
  openEmployeeQR() {
    let scope = this;
    let empdialogebox = scope.dialog.open(CreateQrcodeComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'editModal',
      data: this.layoutUrl
    });

  }
  loadUrl() {
    var scope = this;
    scope.pageService.showLoader();
    scope.http.get(environment.apiPath + 'GetProfileReviewlayOut?Key=' + scope.accessKey + '&ProfileId=' + scope.profileId)
      .subscribe((res) => {
        scope.pageService.hideLoader();
        if (res['success']) {

          scope.profileList = scope.dataService.getData('ProfileList');
          scope.layoutUrl = res['data'].landingPageUrl + '?utm_source=qr'


          if (scope.profileList.some((alone) => { if (alone.profileId == scope.profileId) { return alone } })) {
            scope.companyName = scope.profileList.filter((alone) => { if (alone.profileId == scope.profileId) { return alone } })[0].companyName
          }
          // scope.profileList.filter((alone)='')
          scope.cdr.markForCheck();
        } else {

        }
        // threshold: null
        // layoutId: 1
        // profileId: 30286
        // layoutName: "Thumbs"
      }, (err) => {
        if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

          this.pageService.hideLoader();
          this.pageService.showError('Oops!', err.error.errorMsg);
        }
      })
  }
  qrWiseData = [];
  addEmp() {
    this.tempArr = [];
    this.pageService.showLoader();
    this.http.get(environment.apiPath + 'GetEmployeesQRList?Key=' + localStorage.getItem(environment.authTokenKey) + '&ProfileId=' + this.profileId)
      .subscribe((res: any) => {
        if (res.success) {
          this.tempArr.length = 0;
          this.qrWiseData = res['empList'];
          for (let i in this.qrWiseData) {
            let dd: any = {
              department: this.qrWiseData[i].department,
              employeeName: this.qrWiseData[i].employeeName,
              landingPageURL: this.qrWiseData[i].landingPageURL,
            }
            this.tempArr.push(dd);
          }
        
          window.dispatchEvent(new Event('resize'));
          this.pageService.hideLoader();
          this.cdr.detectChanges();
        } else {
          this.pageService.showError("", res.errorMsg);
        }
      })
  }
  
  saveAsImage(parent) {
    var scope = this;
    // fetches base 64 date from image
    scope.pageService.showInfo('', 'Generating image please wait...')
    if (parent.qrcElement.nativeElement.querySelector("canvas") != null) {
      var canvas1 = parent.qrcElement.nativeElement.querySelector("canvas");

      // canvas1.width = 512;
      // canvas1.height = 512;
      var image = canvas1.toDataURL();
      var link = document.createElement('a');
      link.download = "Qrcode";
      link.href = image;
      link.click();

    } else if (parent.qrcElement.nativeElement.querySelector("img") != null) {
      var parentElement = parent.qrcElement.nativeElement.querySelector("img").src
      // converts base 64 encoded image to blobData
      let blobData = scope.convertBase64ToBlob(parentElement);

      // saves as image
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
      } else { // chrome
        const blob = new Blob([blobData], { type: "image/png" });
        const url = window.URL.createObjectURL(blob);
        // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Qrcode';
        link.click();
      }
    }
  }

  print() {
    window.print();
  }
  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

  employee ;
  saveQRAsImage(parent,employee) {
    var scope = this;
    // fetches base 64 date from image
    scope.pageService.showInfo('', 'Generating image please wait...')

    if (parent.qrcElement.nativeElement.querySelector("canvas") != null) {
      var canvas1 = parent.qrcElement.nativeElement.querySelector("canvas");
      var image = canvas1.toDataURL();
      var link = document.createElement('a');
      link.download = employee + "_review_qr" + '.jpg';
      link.href = image;
      link.click();

    } else if (parent.qrcElement.nativeElement.querySelector("img") != null) {
      var parentElement = parent.qrcElement.nativeElement.querySelector("img").src
      // converts base 64 encoded image to blobData
      let blobData = scope.convertBase64ToBlob(parentElement);

      // saves as image
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
      } else { // chrome
        const blob = new Blob([blobData], { type: "image/png" });
        const url = window.URL.createObjectURL(blob);
        // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = employee + "_review_qr" + '.jpg';
        link.click();
      }
    }
  }

  
    // Upgradeunlock click added unnati 
    Upgradeunlock(){
      this.router.navigate(['/general/billing']);
    }
}
