export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					alignment: 'left',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
				}

			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon-home-2',
					page: '/dashboard',
				},
				{
					title: 'Review Platforms',
					root: true,
					icon: 'flaticon2-layers-1',
					page: '/review-platforms',
				},
				{
					title: 'My Reviews',
					root: true,
					icon: 'far fa-star',
					page: '/my-reviews',
				},
				{
					title: 'Private Feedback',
					root: true,
					icon: 'flaticon-lock',
					page: '/private-feedback',
				},
				{
					title: 'Get Reviews',
					root: true,
					icon: 'fas fa-rocket',
					bullet: 'dot',
					submenu: [
						{
							title: 'Invite Customer',
							page: '/get-reviews/invite-customer'
						},
						{
							// title : 'Bulk Invite ⁽ᴮᵉᵗᵃ⁾',
							title: 'Bulk Invite',
							page: '/get-reviews/imported-invitation-list'
						},
						{
							title: 'Invited Customers',
							page: '/get-reviews/invited-customer-list'
						},
						{
							title: 'Email Settings',
							page: '/get-reviews/email-settings'
						},
						{
							title: 'Template Settings',
							page: '/customization-options/template-settings'
						},

					]
				},
				{
					title: 'Widgets',
					root: true,
					icon: 'flaticon2-clip-symbol',
					bullet: 'dot',
					submenu: [
						{
							title: 'Website Widgets',
							page: '/widgets/website-widgets'
						},
						{
							title: 'Social Sharing',
							page: '/widgets/social-sharing'
						},
						{
							title: 'Email Widgets',
							page: '/widgets/email-widgets'
						},
					]
				},
				{
					title: 'Customization Options',
					root: true,
					icon: 'fas fa-hammer',
					bullet: 'dot',
					submenu: [
						{
							title: 'Review Page',
							page: '/customization-options/review-page'
						},
						// {
						// 	title: 'New-Client-Landing Page',
						// 	page: '/customization-options/new-client-landing-page'
						// },
						{
							title: 'Opt In Page',
							page: '/customization-options/opt-in-page'
						},
						{
							title: 'QR Code',
							page: '/customization-options/qr-code'
						},
					]
				},
				{
					title: 'Notifications',
					page: '/general/manage-notification',
					icon: 'far fa-bell',
					root: true,
				},
				{
					title: 'Reports',
					root: true,
					icon: 'flaticon2-graph-2',
					bullet: 'dot',
					submenu: [
						{
							title: 'My Report',
							page: '/reports/my-report'
						},
						{
							title: 'Performance Report',
							page: '/reports/performance-report'
						},
						{
							title: 'QR Report',
							page: '/reports/employee-report'
						},
					]
				},
				{
					title: 'Company Setting',
					root: true,
					icon: 'flaticon2-user-1',
					page: '/company-setting',
					bullet: 'dot',
				},
				{
					title: 'Integrations',
					page: '/general/integrations',
					icon: 'fas fa-key',
					root: true,
					bullet: 'dot',
				},
				{
					title: 'A2P Brand Registration',
					page: '/general/A2P-Brand-Registration',
					icon: '	far fa-comment-alt',
					root: true,
					bullet: 'dot',
				},
				// {
				// 	title: 'A2P Brand Registration',
				// 	page: '/general/A2P-Brand-Registration',
				// 	icon: '	far fa-comment-alt',
				// 	root: true,
				// 	bullet: 'dot',
				// },
				// {
				// 	title: 'Invite Friends',
				// 	root: true,
				// 	icon: 'flaticon2-rocket',
				// 	bullet: 'dot',
				// 	submenu: [
				// 		{
				// 			title: 'Send Invitation',
				// 			page: '/invite-friends/send-invitation'
				// 		},
				// 		{
				// 			title: 'Invited Friends',
				// 			page: '/invite-friends/invited-frineds'
				// 		}
				// 	]
				// },
				{
					title: 'General Settings',
					root: true,
					icon: 'flaticon-settings',
					bullet: 'dot',
					submenu: [
						{
							title: 'Setup Domain',
							page: '/general/setup-domain'
						},

						// {
						// 	title: 'Branding',
						// 	page: '/general/branding'
						// },
						{
							title: 'Billing',
							page: '/general/billing'
						},
						{
							title: 'Usage',
							page: '/general/usage'
						},
						{
							title: 'Users',
							page: '/general/users'
						},
						// {
						// 	title: 'Notifications',
						// 	page: '/general/manage-notification'
						// },
						{
							title: 'Company Profiles',
							page: '/general/company-profiles'
						},
						{
							title: 'Profile Usage',
							page: '/general/profile-usage'
						},
						{
							title: 'Twilio Integration',
							page: '/general/twilio-integration'
						},
						{
							title: 'My Account',
							page: '/general/my-account'
						},
						{
							title: 'Change Password',
							page: '/general/change-password'
						}

						// {
						// 	title: 'Default Settings',
						// 	page: '/general/default-settings'
						// },
						// {
						// 	title: 'Default Template Settings',
						// 	page: '/general/default-template-settings'
						// },
					]
				},
				{
					title: 'Help Documentation',
					root: true,
					icon: 'flaticon-questions-circular-button',
					bullet: 'dot',
					page: "https://grabyourreviews.zendesk.com/hc/en-us"
				},
			]
		},
	};

	public get configs(): any {
		let country = localStorage.getItem('currentCountry');
		let plan = JSON.parse(localStorage.getItem('plan'));
		let IsReseller: boolean = (localStorage.getItem("IsReseller") == "true" ? true : false);
		if (IsReseller) {
			if (plan && country) {
				country = country.toLocaleLowerCase();
				plan = plan.toLocaleLowerCase();

				if ((plan == "small" || plan == "medium") && (country == "us" || country == "ca")) {
					this.defaults.aside.items = this.defaults.aside.items.map(x => {
						if (x.title == "General Settings") {
							x.submenu = x.submenu.map(y => {
								if (y.title != "Twilio Integration") {
									return y;
								}
							})
							x.submenu = x.submenu.filter(x => x != null);
							return x;
						}
						else {
							return x;
						}
					})
				}
				else {
					this.defaults.aside.items = this.defaults.aside.items.filter(x => x.title != "A2P Brand Registration");
					this.defaults.aside.items = this.defaults.aside.items.filter(x => x != null);
					if(country == "in"){
						this.RemoveTwilioOption();
					}
				}
			}
			}
		else {
			// Remove Twilio and A2P menu Options
			this.RemoveTwilioOption();
			this.defaults.aside.items = this.defaults.aside.items.filter(x => x.title != "A2P Brand Registration");
			this.defaults.aside.items = this.defaults.aside.items.filter(x => x != null);
		}
		return this.defaults;
	}

	RemoveTwilioOption():void{

		this.defaults.aside.items =  this.defaults.aside.items.map(x => {
			if (x.title == "General Settings") {
				x.submenu = x.submenu.map(y => {
					if (y.title != "Twilio Integration") {
						return y;
					}
				})
				x.submenu = x.submenu.filter(x => x != null);
				return x;
			}
			else {
				return x;
			}
		})
	}
}
