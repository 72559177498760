<div *ngIf='isExpired==true'>
	<div class="card p-3 text-center text-1-3x danger-color">
		You do not have permission to access this page
	</div>
</div>
<div *ngIf='isExpired==false'>
	<div style="text-align: right; width: 100%;" *ngIf='viewDocumentation'><a href="https://grabyourreviews.zendesk.com/hc/en-us/articles/360043714234-Connect-Review-Platforms" target="_blank"><i class="fa fa-question-circle"></i> View Help Documentation</a></div>
	<mat-card>
		<div class='col-12 text-bold text-1-5x text-center'>
			Review Platforms
		</div>
		<div class='col-12 text-1-2x text-center gray-color'>
			Connect a platform you’d like to get a review from
		</div>
		<div class='row margin-tb-10'>
			<div class='col-2'></div>
			<div class='col-8'>
				<input class="form-control" type='text' [(ngModel)]="searchInput" placeholder='Search review platforms' (ngModelChange)='serachSocial()'>
			</div>
			<div class='col-2'></div>
		</div>
		<div class="note" *ngIf="isGoogleAuthError">
			Enable cookies to connect google my business. Follow this
			<a style="text-decoration: underline;" href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">
				step by step guide
			</a>
			to enable cookies in browser.
		</div>
		<div class='contentModel padding-10'>
			<div class='row' *ngIf='connectedList.length>0'>
				<div class='col-12 padding-tb-10 padding-lr-10 text-1-2x ' [innerHTML]='"Connected"'></div>
				<div class='col-12 padding-lr-5 flexClass'>
					<div class='imgBoxParent col-lg-2 col-md-4 col-sm-6 col-xs-12' *ngFor="let item of connectedList">
						<div class='imgBox roundedBox-border roundedBox-radius padding-tb-5 margin-tb-10  
					cursor-pointer {{item.reviewSiteName}}' (click)='openDialog(item,"connected")'>
							<img [src]="'/assets/media/social-logo/'+item.reviewSiteName+'.png'" class='cursor-pointer' *ngIf='item.isCustom==false' />

							<span *ngIf='item.isCustom == true'>
								<img [src]="item.imageURL" class='cursor-pointer' *ngIf="showCustImg" />
								<div id="profileImage" *ngIf="showCustLogo">
									<b class="custContentName" id="demo">{{item.reviewSiteName}}</b>
								</div>
							</span>
							<div class='text-1-3x reviewSiteText cursor-pointer' [innerHTML]='item.reviewSiteName'></div>

						</div>
						<div class='closeIcon text-center text-1-3x'>
							<i class="fa fa-trash danger-color margin-lr-5 cursor-pointer" (click)='openAlertDialog(item)'></i>
						</div>

					</div>
				</div>
				<div class='col-12 padding-tb-10'>
					<mat-divider></mat-divider>
				</div>
			</div>
			<div *ngIf="disSearchArr.length==0 && !Notfound">
				<div class='row' *ngFor="let temp of disArr">
					<div class='col-12 padding-tb-10 padding-lr-10 text-1-2x'>
						<span>{{temp.title}}</span> &nbsp; 
						<span *ngIf="temp.title == 'Collect and Get Reviews'">
							<i class="fa fa-info-circle" style="color:rgb(241, 185, 0)"
							ngbTooltip="Collect and genrate new reviews from review platforms to display in dashboard."></i>
						</span>
						<span *ngIf="temp.title == 'Get Reviews'">
							<i class="fa fa-info-circle" style="color:rgb(241, 185, 0)"
							ngbTooltip="Genrate reviews from your customers."></i>
						</span>
					</div>
					<div class='col-12 padding-lr-5 flexClass'>
						<div class='col-lg-2 col-md-4 col-sm-6 col-xs-12' *ngFor="let item of temp.list">
							<div class='imgBox roundedBox-border roundedBox-radius padding-tb-5 margin-tb-10 cursor-pointer {{item.reviewSiteName}}' (click)='openDialog(item,"notconnected")'>
								<img class='cursor-pointer' [src]="'/assets/media/social-logo/'+item.reviewSiteName+'.png'" />
								<div class='text-1-3x reviewSiteText cursor-pointer' [innerHTML]='item.reviewSiteName'></div>
							</div>
						</div>
					</div>
					<div class='col-12 padding-tb-10'>
						<mat-divider></mat-divider>
					</div>
				</div>
			</div>

			<div class='row' *ngIf="disSearchArr.length>0 ">
				<div class='col-12 padding-lr-5 flexClass'>
					<div class='col-lg-2 col-md-4 col-sm-6 col-xs-12' *ngFor="let item of disSearchArr">
						<div class='imgBox roundedBox-border roundedBox-radius padding-tb-5 margin-tb-10 
					cursor-pointer {{item.reviewSiteName}}' (click)='openDialog(item,"notconnected")'>
							<img class='' [src]="'/assets/media/social-logo/'+item.reviewSiteName+'.png'" />
							<div class='text-1-3x reviewSiteText cursor-pointer' [innerHTML]='item.reviewSiteName'></div>
						</div>
					</div>
				</div>
			</div>
				<div class='row' *ngIf="!freetrialFlag">
				<div class='col-12 padding-lr-5 flexClass'>
					<div class='col-lg-2 col-md-4 col-sm-6 col-xs-12'>
						<div class='cursor-pointer imgBox roundedBox-border roundedBox-radius padding-tb-5 margin-tb-10 customLink' (click)='openCustomDialog("new",undefined)'>
							<!-- <img [src]="'/assets/media/social-logo/'+item.reviewSiteName+'.png'" /> -->
							<div class='text-1-3x reviewSiteText customLink primary-color'>Custom link</div>
						</div>
					</div>
				</div>
			</div>
			<div class='row padding-20' *ngIf='disSearchArr.length==0 && Notfound'>
				We don't support this site - please add it to our &nbsp;<a href=''>feedback forum </a>&nbsp;
				and we'll let you know once it's added!
			</div>
		</div>
	</mat-card>
</div>
