import { environment } from './../../../../environments/environment';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'kt-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  public targetSiteData:any
  systemName=''
  constructor(public dialogRef:  MatDialogRef<AlertModalComponent>,
            @Inject(MAT_DIALOG_DATA) public navData: any) { }

  ngOnInit() {
    var scope = this;

    scope.targetSiteData=scope.navData.targetSiteData
    
    scope.systemName=JSON.parse(localStorage.getItem('brand-info')).brandName;
    if(this.systemName == null || this.systemName == undefined){
      this.systemName = "Grab Your Reviews"
    }else{
      this.systemName = JSON.parse(localStorage.getItem('brand-info')).brandName;
    }
    

    // reviewSiteName: "Google"
    // siteURL: "https://www.google.com/search?q=M2WebSolution&ludocid=2366104139394406368#lrd=0x0:0x20d6172dba2f57e0,1"
    // isActive: true
    // isPrimary: false
    // skipInstructions: false
    // collectReview: true
    // id: 10042
    // isReviewScrapAvailable: true

  }
  onSubmit(){
    this.dialogRef.close({targetSiteData:this.targetSiteData})
  }

}
