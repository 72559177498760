<!--begin::Body-->
<div class="kt-login__body">
	<!--begin::Signin-->
	<div class="kt-login__form">
		<div class="kt-login__title">
			<h3>Reset Password</h3>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<form class="kt-form" [formGroup]="resetPassForm" autocomplete="off" (ngSubmit)='submit()'>
			<div class="form-group">
				<mat-form-field>
					<mat-label>Password</mat-label>
					<input matInput type="password" placeholder="Passsword" 
					formControlName="password" autocomplete="off" [(ngModel)]="modelData.password"/>
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','pattern')">
						<strong>{{ 'AUTH.VALIDATION.PASSWORD_PATTERN' | translate }}</strong>
					</mat-error>
                </mat-form-field>
                <mat-form-field>
					<mat-label>Confirm Password</mat-label>
					<input matInput type="password" placeholder="Confirm Password" 
					formControlName="confirmPassword" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error *ngIf="resetPassForm.get('confirmPassword').errors && resetPassForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and Confirm Password didn't match.</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<!--begin::Action-->
			<div class="kt-login__actions">
				<button routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">Back</button>
				<button type='submit' id="kt_login_signin_submit" 
				class="btn btn-primary btn-elevate kt-login__btn-primary" 
				[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}">Submit</button>
			</div>
			<!--end::Action-->
		</form>
	</div>
</div>

