import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
 
@Component({
  selector: 'kt-process-guide',
  template: `
        <script type="text/javascript" src="https://zapier.com/apps/embed/widget.js?services=grab-your-reviews&limit=10"></script>
        <div>Template</div>`
})

export class ProcessGuideComponent  {
}


