import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MyModalComponent } from '../wizard/my-modal/my-modal.component';

@Component({
  selector: 'kt-no-google-account',
  templateUrl: './no-google-account.component.html',
  styleUrls: ['./no-google-account.component.scss']
})
export class NoGoogleAccountComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
	setTimeout(this.preBack,0)
  }

  preBack(){
	  window.history.forward();
  }


  signWithOther() {

		let tempData = { mapFlag: true }
		const dialogRef = this.dialog.open(MyModalComponent, {
			width: '700px',
			data: tempData,
			panelClass: 'editModal',
			disableClose: true
		});
	}
}
