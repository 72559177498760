import { DataTableService } from './../../../../core/_base/layout/services/datatable.service';
// Angular
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
// Layout
import { LayoutConfigService, PageConfigService, ToggleOptions } from '../../../../core/_base/layout';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'kt-header-mobile',
	templateUrl: './header-mobile.component.html',
	styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {
	// Public properties
	headerLogo: string;
	asideDisplay: boolean;
	brandLogo = '';
	brandName = '';
	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-header__topbar--mobile-on',
		togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
	};
	loaded:boolean=false
	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService,
		public dataService: DataTableService,
		public cdr: ChangeDetectorRef,
		public router: Router,
		public http: HttpClient,
		public pageService: PageConfigService,
		public titleService: Title) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		var scope = this;

		let brandData = JSON.parse(localStorage.getItem('brand-info'))//scope.dataService.getData('brand-info')
		if(brandData)
		{
		scope.brandLogo = brandData['brandLogo'];
		scope.brandName = brandData['brandName'];
		}
		this.headerLogo = this.layoutConfigService.getStickyLogo();
		this.asideDisplay = this.layoutConfigService.getConfig('aside.self.display');

		scope.dataService.getBrandbserver().subscribe((res) => {
			scope.loaded=true;

			if (res == true) {
				let brandData = JSON.parse(localStorage.getItem('brand-info'))//scope.dataService.getData('brand-info')
				if(brandData)
		{
				scope.brandLogo = brandData['brandLogo'];
				scope.brandName = brandData['brandName'];
		}

				scope.cdr.markForCheck();
			}
		})

		if (localStorage.getItem('brand-info') != null) {
			scope.loaded=true;

			let brandData = JSON.parse(localStorage.getItem('brand-info'))//scope.dataService.getData('brand-info')
			scope.brandLogo = brandData['brandLogo'];

			scope.brandName = brandData['brandName'];

			scope.cdr.markForCheck();
		}else{
			scope.loaded=true;
		}
	}

	logout(){
		localStorage.clear();
		setTimeout(() => {
			window.location.reload();
		}, 500);

	}
}
