<div class='col-12' *ngIf='currentCountry != "IN"'>
	<div class='row pr-2 pt-2'>
		<div class='col-12 text-1-2x text-right'>
			<i class="fa fa-times cursor-pointer" (click)='closeModal(undefined)'></i>
		</div>
	</div>
	<div class='row padding-15'>
		<div class='col-md-12 col-sm-12 col-xs-12' *ngIf='isAgencyPlan==false'>
			<div class="notes roundedBox-radius padding-10 notePlanColor ">
				<div class="text-1-2x text-bold mb-2 mt-2">New 
					<span *ngIf="navData.data.isYearly || isSwitchToYearly">yearly</span> 
					<span *ngIf="!navData.data.isYearly && !isSwitchToYearly">monthly</span> 
					fee
				</div>
				<div class="row mt-2 mb-2">
					<div class="col-md-6 col-sm-12 col-xs-12">
						{{navData.data.PlanName}}
					</div>
					<div class="col-md-6 col-sm-12 col-xs-12 text-right" *ngIf='navData.data.Currency=="USD"'>
						<span *ngIf="!isSwitchToYearly">
							$ {{navData.data.Price | number: '1.0-0'}}
						</span>
						<span *ngIf="isSwitchToYearly">
							$ {{(navData.data.Price  * 12) - ((navData.data.Price  * 12) * 20)/ 100 | number: '1.0-0' }}
						</span>						
					</div>
					<div class="col-12" *ngIf="!navData.data.isYearly">
						<div class="row mt-2" style="align-items: center;">
							<div class="col-3 pr-0" style="font-size: 13px;font-weight: bold;">
								<span>Save ${{((navData.data.Price  * 12) * 20)/ 100 | number: '1.0-0' }}</span>
							</div>
							<div class="col-4 pl-1">
								<label class="switch" for="checkbox">
									<input type="checkbox" id="checkbox" (change)="switchToYearly($event)"/>
									<div class="slider round"></div>
								</label>
							</div>
							<div class="col-5">

							</div>
						</div>
					</div>
				</div>
				<div class='col-md-12 col-sm-12 col-xs-12 mt-2 mb-2'>
					<mat-divider></mat-divider>
				</div>
				<div class="row ">
					<div class="col-md-6 col-sm-12 col-xs-12 text-bold">
						Total
					</div>
					<div class="col-md-6 col-sm-12 col-xs-12 text-right text-bold" *ngIf='navData.data.Currency=="USD"'>
						<span *ngIf="!isSwitchToYearly">
							$ {{navData.data.Price | number: '1.0-0'}}
						</span>
						<span *ngIf="isSwitchToYearly">
							$ {{(navData.data.Price  * 12) - ((navData.data.Price  * 12) * 20)/ 100 | number: '1.0-0' }}
						</span>
					</div>
				</div>
				<div class="row pl-3">
					<i class="fas fa-check success-color" style="line-height: 23px;"></i>
					&nbsp;&nbsp;Cancel anytime
				</div>
			</div>
		</div>
		<div class='col-md-12 col-sm-12 col-xs-12' *ngIf='isAgencyPlan==true'>
			<div class="notes roundedBox-radius padding-10 notePlanColor ">
				<div class="text-1-2x text-bold mb-2 mt-2">New monthly fee</div>

				<div class="row mt-2 mb-2">
					<div class="col-md-6 col-sm-12 col-xs-12">
						Agency - {{navData.data.PlanName}}
					</div>

					<div class="col-md-6 col-sm-12 col-xs-12 text-right">
						{{'$ '+navData.data.Price}}
					</div>

					<div class="col-12" style="font-size: 12px;">
						<b>{{navData.data.MinSeats}} - seats included</b>
					</div>
				</div>
				<div class='col-md-12 col-sm-12 col-xs-12 mt-2 mb-2'>
					<mat-divider></mat-divider>
				</div>
				<div class="row ">
					<div class="col-md-6 col-sm-12 col-xs-12 text-bold">
						Total
					</div>
					<div class="col-md-6 col-sm-12 col-xs-12 text-right text-bold">
						{{'$ '+navData.data.Price}}
					</div>
				</div>
				<div class="row pl-3">
					<i class="fas fa-check success-color" style="line-height: 23px;"></i>
					&nbsp;&nbsp;Cancel anytime
				</div>
			</div>
		</div>
		<div class='col-md-12 col-sm-12 col-xs-12 mt-3'>
			<div class="mb-1 text-1-2x text-bold">
				Payment method
			</div>
			<div>
				<mat-radio-group [(ngModel)]='cardRadioButton' (ngModelChange)='radioButtonChange()'>
					<mat-radio-button value="1" *ngIf='!hideexistingRadio'>Use existing card</mat-radio-button>
					<mat-radio-button value="2">Add new card</mat-radio-button>
				</mat-radio-group>
				<div *ngIf='cardRadioButton=="1"'>
					<div class="row">
						<div class="col-12">
							<b>Card:</b> {{cardNumber}}
						</div>
						<div class="col-12">
							<b>Expiry Date:</b> {{expMonth}}/{{expYear}}
						</div>
					</div>					
				</div>
				<div *ngIf='cardRadioButton=="2"'>
					<div class="row mb-2 text-1x">
						<div class="col-md-12 col-sm-6 col-xs-6 text-left">
							We accept <img src='../../../../../../assets/media/icons/visa-and-mastercard.png' width='100' />							
						</div>
					</div>
				<div class="mb-3 text-1x ">
					New payment method
				</div>
				<div class="row" style="margin: 0% 0.5%;">
					<div class="col-12">
						<label class="f-b">Card Number</label>
						<div id="cardNumber"></div>
						<!-- A Stripe Element will be inserted here. -->
					</div>
					<div class="col-6">
						<label class="f-b">Expiry Date</label>
						<div id="cardExpiryDate"></div>
					</div>
					<div class="col-6">
						<label class="f-b">CVC</label>
						<div id="cardCvv"></div>
					</div>
				</div>
				<!-- <div  -->
				<mat-error *ngIf="formSubmitted && invalidCard">
					<strong class="danger-color ">Invalid card type</strong>
				</mat-error>
			</div>

			<div id="card-errors" role="alert"></div>
			<div class="text-bold text-1x mt-2">
				Enter Coupon Code
			</div>
			<div class="input-group mt-2">
				<input class="form-control " [(ngModel)]='couponCode' (ngModelChange)='changeCoupon()'>
			</div>
			<div class='flexClass mt-4 nowrap'>
				<mat-checkbox name='invite_cust_terms' [(ngModel)]="terms"></mat-checkbox>
				&nbsp;<label for='invite_cust_terms'>I have read the <a href="https://grabyourreviews.com/terms-of-service/" target="_blank">Terms & services</a>.</label>
			</div>
			<mat-error *ngIf="formSubmitted && !terms">
				<strong class="danger-color ">You must agree to the terms & conditions.</strong>
			</mat-error>
			<div class="mt-3 text-center">
				<button class='btn btn-sm primary-back-color text-color-light red margin-lr-5 cursor-pointer ' [disabled]="!terms" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light pointer-disable': loading}" (click)='submitForm()'>Submit</button>
			</div>
		</div>
	</div>
</div>

<div class="col-12" *ngIf='currentCountry == "IN"'>
	<h3 style="text-align: center;margin: 2%;">Contact Sales</h3>	
	<form class="row" [formGroup]="contactSales">
		<div class="form-group col-md-6 sales-form-margin">
		  <label for="exampleFormControlInput1">Person Name</label>
		  <input type="text" class="form-control" formControlName="personName" [ngClass]="{'errorTextBox' : isSubmit && contactSales.controls.personName.errors?.required}">
		</div>
		<div class="form-group col-md-6 sales-form-margin">
			<label for="exampleFormControlInput1">Select Plan</label>
			<select class="form-control" formControlName="planName" [ngModel]="planName">
				<option *ngFor="let i of planList">{{i.PlanName}}</option>
			</select>
		</div>
		<div class="form-group col-md-6 sales-form-margin">
			<label for="exampleFormControlInput1">Email address</label>
			<input type="email" class="form-control" formControlName="email" [ngClass]="{'errorTextBox' : isSubmit && contactSales.controls.email.errors?.required || contactSales.controls.email.errors?.email}">
		</div>
		<div class="form-group col-md-6 sales-form-margin">
			<label for="exampleFormControlInput1">Phone Number</label>
			<div class="input-group mb-3">
  				<div class="input-group-prepend">
    				<span class="input-group-text" id="basic-addon3">+91</span>
  				</div>
  				<input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3"
				  formControlName="phoneNumber" [ngClass]="{'errorTextBox' : isSubmit && contactSales.controls.phoneNumber.errors?.required || contactSales.controls.phoneNumber.errors?.pattern}">
			</div>
			<!-- <div class="input-group-prepend">
				<span class="input-group-text" id="basic-addon1">@</span>
			</div>
			<input type="number" class="form-control" formControlName="phoneNumber" [ngClass]="{'errorTextBox' : isSubmit && contactSales.controls.phoneNumber.errors?.required || contactSales.controls.phoneNumber.errors?.pattern}"> -->
		</div>
		
		<div class="form-group col-md-12 sales-form-margin">
			<label for="exampleFormControlInput1">Message</label>
			<textarea class="form-control" rows="3" formControlName="message"></textarea>
		</div>
		<div class="col-md-12" style="text-align: center;text-align: center;margin: 2% 0%;">
			<button type="submit" class="btn btn-primary" (click)="contactSale()">Submit</button>
			<button type="submit" style="margin-left: 2%;" class="btn btn-secondary btn-elevate kt-login__btn-secondary" (click)='closeModal(undefined)'>Close</button>
		</div>
	  </form>
</div>
