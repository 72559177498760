import { DataTableService } from './../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { environment } from './../../../../environments/environment';
// Angular
import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from '../../../core/_base/layout';
// Auth
import { AuthNoticeService } from '../../../core/auth';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	headerLogo: string;
	hideAside: boolean = false;
	systemName = '';
	brandLogo = '';
	brandName = '';
	currentDomainUrl = '';
	brandData: any;
	loaded:boolean=false;
	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param layoutConfigService: LayoutConfigService
	 * @param authNoticeService: authNoticeService
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2,
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private splashScreenService: SplashScreenService,
		public router: Router,
		public cdr: ChangeDetectorRef,
		public pageService: PageConfigService,
		public dataService: DataTableService,
		public http: HttpClient,
		public titleService: Title) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	
	ngOnInit(): void {
		var scope = this;
		if (top.location != window.location) {
			document.getElementById("kt_login").style.display = "none";
		}
		scope.pageService.setauthComponentObj(scope);
		scope.cdr.markForCheck();
		scope.translationService.setLanguage(scope.translationService.getSelectedLanguage());
		scope.headerLogo = scope.layoutConfigService.getLogo();
		scope.systemName = environment.systemName
		scope.splashScreenService.hide();



		var url = window.location.href
		var arr = url.split("/");
		var result = arr[0] + "//" + arr[2];
		scope.currentDomainUrl = result.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
		// scope.currentDomainUrl =
		
		// scope.currentDomainUrl='reviews.likereferrals.com'
		if (!scope.currentDomainUrl.includes('localhost') && !scope.currentDomainUrl.includes('grabyourreviews')) {

			scope.cdr.markForCheck();
			// scope.currentDomainUrl='mynamem2.grabyourreviews.com'
			scope.http.get(environment.apiPath + 'GetBranding?DomainName='+scope.currentDomainUrl)
				.subscribe((res) => {
					
					scope.loaded=true;
					if (res['success']) {
						scope.brandData = JSON.parse(scope.pageService.decrypt(res['data']))
						
						let temp = {
							brandName: scope.brandData['BrandName'],
							brandLogo: scope.brandData['LogoUrl'],
							currentDomain: scope.currentDomainUrl
						}
						scope.brandLogo = scope.brandData['LogoUrl'];
						scope.brandName = scope.brandData['BrandName'];
						scope.cdr.markForCheck()
						// scope.dataService.setData('brand-info', JSON.stringify(temp))
						localStorage.setItem('IsSignUpLink', JSON.stringify(false))
						if (scope.brandName.length > 0) {
							scope.titleService.setTitle(scope.brandName)
						}
						// if(scope.brandLogo.length>0){
						// 	scope.pageService.setFaviconByUrl(scope.brandLogo)
						// }
						// scope.model.brandName=
					} else {
						scope.router.navigate['/error/403']
					}
				},(err) =>{
					if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
						this.pageService.showError('Oops!',err.error.errorMsg);
					}
				})
		}else{
			scope.loaded=true;
			localStorage.setItem('IsSignUpLink', JSON.stringify(true))
			scope.titleService.setTitle('Grab Your Reviews')
			scope.pageService.setFaviconByUrl('grabFav')
		}
	}
	asideHideFunction() {


		if (this.router.url.includes('verification')) {
			this.hideAside = true;
		} else {
			this.hideAside = false;
		}
		this.cdr.markForCheck();
	}
	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * @param styleUrl
	 */
	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
