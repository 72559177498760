import { DomSanitizer } from '@angular/platform-browser';
import { style } from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ClientReviewComponent } from '../client-review/client-review.component';

@Component({
  selector: 'kt-good-modal',
  templateUrl: './good-modal.component.html',
  styleUrls: ['./good-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class GoodModalComponent implements OnInit {
  @ViewChild(ClientReviewComponent) child: ClientReviewComponent;

  layoutData = {};
  reviewSiteList = [];
  reviewSite = [];
  companyName = '';
  isGoogleLogin: boolean;
  isFbLogin: boolean;
  PublicPostClickhereText: any;
  PublicPostAReviewTextMsg: any;
  PublicReviewAppreciateMessage: any;
  ReviewThankyouMessage;
  Nid: any;

  constructor(public dialogRef: MatDialogRef<GoodModalComponent>,
    @Inject(MAT_DIALOG_DATA) public navData: any,
    public cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    public deviceService: DeviceDetectorService,
    public pageService: PageConfigService,
    public http: HttpClient) { }

  sanitizeHtml(html) {
    var scope = this;
    return scope.sanitizer.bypassSecurityTrustHtml(html)
  }

  siteNameDy;
  ngOnInit() {
    var scope = this;
   
    scope.layoutData = scope.navData['ReviewPageSettings'];
    scope.Nid = scope.navData['NId'];
    scope.reviewSiteList = JSON.parse(JSON.stringify(scope.navData['ReviewSource']));
    
    this.PublicPostAReviewTextMsg = scope.layoutData['PublicPostAReviewTextMsg'];
    this.PublicPostClickhereText = scope.layoutData['PublicPostClickhereText'];
    this.PublicReviewAppreciateMessage = scope.layoutData['PublicReviewAppreciateMessage'];
    this.ReviewThankyouMessage = scope.layoutData['ReviewThankyouMessage'];
    scope.companyName = scope.navData['CompanyName'];

    // scope.reviewSiteList.forEach((aln) => {
    //   // if (aln.SiteName.toLocaleLowerCase() == 'facebook') {
    //   //   if (!aln.ReviewUrl.includes('/reviews')) {
    //   //     if (aln.ReviewUrl[aln.ReviewUrl.length - 1] == '/') {

    //   //       aln.ReviewUrl += 'reviews';
    //   //     } else {

    //   //       aln.ReviewUrl += '/reviews';
    //   //     }
    //   //   }
    //   // }
    // });
    scope.cdr.markForCheck();

    if (scope.deviceService.isDesktop()) {
      scope.redirectTo()
    }
    if (scope.deviceService.isMobile()) {
      scope.redirectTo()
    }
    if (scope.deviceService.isTablet()) {
      scope.redirectTo()
    }

  }

  redirectTo() {
    var scope = this;
    if (scope.navData['AutoRedirection'] == true) {
      let aloneData = scope.reviewSiteList.filter((aln) => { if (aln.IsPrimary == true) { return aln } })[0];

      let link = this.reviewSiteList[0].ReviewUrl;
      let siteName = this.reviewSiteList[0].SiteName;
      scope.openUrl(siteName, link)
    }
    // if (scope.isGoogleLogin == true && scope.isFbLogin == true ) {
    //   if (scope.reviewSiteList.some((aln) => { if (aln.SiteName.toLocaleLowerCase() == 'google' 
    //   && aln.IsPrimary == true) { return aln } })) {

    //     let aloneData = scope.reviewSiteList.filter((aln) => { if (aln.SiteName.toLocaleLowerCase() == 'google') { return aln } })[0];
    //     let link = aloneData.ReviewUrl;
    //     let siteName = aloneData.SiteName;
    //     window.open(link, '_blank')
    //     scope.openUrl(siteName)
    //   } else {

    //     let aloneData = scope.reviewSiteList.filter((aln) => { 
    //       if (aln.SiteName.toLocaleLowerCase() == 'facebook' && aln.IsPrimary == true) 
    //     { return aln } })[0];
    //     let link = aloneData.ReviewUrl;
    //     let siteName = aloneData.SiteName;
    //     window.open(link, '_blank')
    //     scope.openUrl(siteName)
    //   }
    // } else if (scope.isGoogleLogin == true) {

    //   let aloneData = scope.reviewSiteList.filter((aln) => { if (aln.SiteName.toLocaleLowerCase() == 'google' && aln.IsPrimary == true) { return aln } })[0];
    //   let link = aloneData.ReviewUrl;
    //   let siteName = aloneData.SiteName;
    //   window.open(link, '_blank')
    //   scope.openUrl(siteName)
    // } else if (scope.isFbLogin == true) {

    //   let aloneData = scope.reviewSiteList.filter((aln) => { if (aln.SiteName.toLocaleLowerCase() == 'facebook' && aln.IsPrimary == true) { return aln } })[0];
    //   let link = aloneData.ReviewUrl;
    //   let siteName = aloneData.SiteName;
    //   window.open(link, '_blank')
    //   scope.openUrl(siteName)
    // }
  }
  show_login_status(network, status) {
    var scope = this;
    if (status) {
      // loggedIn-back
      document.querySelector(".logged-in-text." + network)['style'].display = 'block';
      document.querySelector('.logged-in-box.' + network).classList.add('loggedIn-back')
      if (network == 'Google') {
        scope.isGoogleLogin = true;

      } else if (network == 'Facebook') {
        scope.isFbLogin = true;
      }
    } else {
      document.querySelector(".logged-in-text." + network)['style'].display = 'none';
      document.querySelector('.logged-in-box.' + network).classList.remove('loggedIn-back');
      if (network == 'Google') {
        scope.isGoogleLogin = false;

      } else if (network == 'Facebook') {
        scope.isFbLogin = false;
      }
    }
  }
  
  closeDialog() {
    this.dialogRef.close({ isOpenBad: true })
  }

  //added by unnati
  close() {
    let siteName: any = JSON.parse(localStorage.getItem("saveData"));
    
    if(siteName == null){
      this.dialogRef.close()
    }
    //let siteNameD = [];
    // this.navData['ReviewSource'].forEach(element => {
    //    let siteNameDy = element.SiteName;
    //    this.siteNameDy = siteNameDy;
    //   siteNameD.push(siteNameDy);
    // });

    this.siteNameDy = siteName.sitename;
    
    this.dialogRef.close({ isReviewed: true, siteName: this.siteNameDy });
  }

  reviewSubmitted: any = [];
  openUrlCount = 0;
  totalPlatformCount = 0;

  thankYouMsgs;
  randomMsg: any;

  reviewdSiteName = [];
  data: any;
  saveData = JSON.parse(localStorage.saveData || null) || {};
  //scope.child.loadPage();

  openUrl(sitename, ReviewUrl) {
    this.saveData.sitename = sitename;
    this.totalPlatformCount = this.navData.ReviewSource.length;
    this.openUrlCount++;
    // if (this.navData['OpenSameTab'] == false) {
    // this.dialogRef.close({ isReviewed: true, siteName: sitename });
    //   window.open(ReviewUrl, '_blank')
    // } else {
    // //  this.data = this.child.reviewSourceQrTracker(sitename);
    // this.dialogRef.close({ isReviewed: true, siteName: sitename });
    //   window.open(ReviewUrl, '_self');
    // }
    this.dialogRef.close({ isReviewed: true, siteName: sitename });
     
    window.open(ReviewUrl, '_blank')

// call review platform tracker api
    if(this.Nid!= '' && this.navData.slug ==''){
      let data = {
        NId: this.Nid,
        pageActivity: this.pageService.encrypt(sitename)
      }
    this.http.post(environment.apiPath + 'ClickOnReviewSite', data)
      .subscribe((res) => {
      })
    }
    // review platform tracker api end
    if (this.navData.ReviewSource.length > 0) {
      this.navData.ReviewSource.filter((e) => {
        if (sitename == e.SiteName) {
          this.reviewSubmitted.push(e);
        }
      })
    }
    let arr;

    if (this.reviewSiteList.length > 0) {
      arr = this.reviewSiteList.filter((e) => {
        return e.SiteName !== sitename
      })
    }
    this.reviewSiteList = [];
    this.reviewSiteList = arr;

    this.thankYouMsgs = this.ReviewThankyouMessage + this.companyName,
    this.PublicReviewAppreciateMessage;
    const rndInt = Math.floor(Math.random() * 8) + 1
    this.randomMsg = this.thankYouMsgs[rndInt - 1];
    this.cdr.detectChanges();
    localStorage.saveData = JSON.stringify(this.saveData);
  }

}
