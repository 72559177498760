// import { ThemeModule } from './../theme/theme.module';
import { OptinpageModule } from './optinpage/optinpage.module';
import { ClipboardModule } from 'ngx-clipboard';
import { MyModalComponent } from './wizard/my-modal/my-modal.component';
import { Wizard1Component } from './wizard/wizard1/wizard1.component';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
// import { MyPageComponent } from './profile/';
import { SetupAccountComponent } from './setup-account/setup-account.component';
import {
	MatButtonModule, MatIconModule, MatCheckboxModule, MatFormFieldModule, MatInputModule,
	MatSelectModule, MatDialogModule, MatExpansionModule, MatDatepickerModule, MatAutocompleteModule,
	MatTabsModule, MatCardModule, MatTableModule, MatTooltipModule, MatChipsModule, MatDividerModule,
	MatRadioModule, MatProgressSpinnerModule, MatSlideToggleModule, MatPaginatorModule
} from '@angular/material';

import { MaterialPreviewModule } from '../partials/content/general/material-preview/material-preview.module';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { LayoutModule } from '@angular/cdk/layout';
// import { EditModalComponent } from './wizard/edit-modal/edit-modal.component';
import { NgbTimepickerModule, NgbTabsetModule, NgbTooltipModule,NgbPopoverModule, NgbPaginationModule, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
// import { VerificationComponent } from './verification/verification.component';
import { PopupModelComponent } from './popup-model/popup-model.component'
// import { RatingModule } from 'ng-starrating';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import { AddUserModelComponent } from './add-user-model/add-user-model.component';
import { AddProfileComponent } from './add-profile/add-profile.component';
import { ReviewSitesComponent } from './review-sites/review-sites.component';
import { PreviewTemplateComponent } from './preview-template/preview-template.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { MyModalSecondComponent } from './wizard/my-modal-second/my-modal-second.component';
import { DataTablesModule } from 'angular-datatables';
import { CustomReviewsiteLinkModelComponent } from './wizard/custom-reviewsite-link-model/custom-reviewsite-link-model.component';
import { ConfrimAlertComponent } from './confrim-alert/confrim-alert.component';
import { MyReviewsComponent } from './my-reviews/my-reviews.component';
// import { NgChartjsModule } from 'ng-chartjs';
import { ChooseProfileComponent } from './choose-profile/choose-profile.component';
import { GoodModalComponent } from './good-modal/good-modal.component';
import { BadModalComponent } from './bad-modal/bad-modal.component';
import { ThankyouModalComponent } from './thankyou-modal/thankyou-modal.component';
import { Title } from '@angular/platform-browser';
import { HelpComponent } from './help/help.component';
import {
	GoogleApiModule,
	NgGapiClientConfig,
	NG_GAPI_CONFIG,

} from "ng-gapi";
import { NgSelect2Module } from 'ng-select2';
import { DatePipe } from '@angular/common'
import { ErrorPageComponent } from './error-page/error-page.component';
import { GoogleAccountDetailsComponent } from './google-account-details/google-account-details.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FacebookAccountDetailsComponent } from './facebook-account-details/facebook-account-details.component';
import { NoGoogleAccountComponent } from './no-google-account/no-google-account.component';
// import { ReportsComponent } from './reports/reports.component';
import { AddMoreModelComponent } from '../pages/General-settings/add-more-model/add-more-model.component';
import { PrivateFeedbackComponent } from './private-feedback/private-feedback.component'
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SetLimitProfileComponent } from './set-limit-profile/set-limit-profile.component';

let gapiClientConfig: NgGapiClientConfig = {
	client_id: "128406289351-rr08pponvupvj9cumrnrpskekf3gm0kv.apps.googleusercontent.com",
	discoveryDocs: ["https://analyticsreporting.googleapis.com/$discovery/rest?version=v4"],
	ux_mode: 'redirect',
	redirect_uri: 'http://dev.grabyourreviews.com/',
	scope: [
		"https://www.googleapis.com/auth/plus.business.manage",
		"https://www.googleapis.com/auth/business.manage",
	].join(" ")

};
@NgModule({
    declarations: [SetupAccountComponent, Wizard1Component, PopupModelComponent,
        MyModalComponent,
        // MenuHorizontalComponent,
        MyModalSecondComponent,
        PopupModelComponent, CompanySettingsComponent, AddUserModelComponent,
        AddProfileComponent, ReviewSitesComponent, PreviewTemplateComponent, AlertModalComponent,
        CustomReviewsiteLinkModelComponent, ConfrimAlertComponent, MyReviewsComponent,
        ChooseProfileComponent, AddMoreModelComponent, GoodModalComponent, BadModalComponent, ThankyouModalComponent,
        HelpComponent, ErrorPageComponent, GoogleAccountDetailsComponent, FacebookAccountDetailsComponent,
        NoGoogleAccountComponent, PrivateFeedbackComponent, SetLimitProfileComponent
        // OnboardingComponent, OnboardingSetupProfileComponent, OnboardingReviewSitesComponent, OnboardingInviteCustomerComponent,
    ],
    exports: [MatTableModule],
    imports: [
        CommonModule,
        Ng2SearchPipeModule,
        HttpClientModule,
        FormsModule,
        NgbPopoverModule,
        NgbDatepickerModule,
        MaterialPreviewModule,
        ReactiveFormsModule,
        CoreModule,
        ClipboardModule,
        PartialsModule,
        MatTableModule,
        MatDialogModule,
        MatCardModule,
        MatChipsModule,
        MatSlideToggleModule,
        DragDropModule,
        MatDividerModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatDatepickerModule,
        MatCheckboxModule,
        NgSelect2Module,
        MatExpansionModule,
        LayoutModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatSelectModule,
        NgbTimepickerModule,
        MatRadioModule,
        MatInputModule,
        NgbTabsetModule,
        MatProgressSpinnerModule,
        // RatingModule,
        MatTabsModule,
        DataTablesModule,
        NgbPaginationModule,
        MatPaginatorModule,
        // NgChartjsModule,
        OptinpageModule,
        NgbModule,
        ModalModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        GoogleApiModule.forRoot({
            provide: NG_GAPI_CONFIG,
            useValue: gapiClientConfig,
        }),
        RichTextEditorAllModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyC6_3XiLWKHOukoXJ5obHrafoMEGDBc7TQ',
            libraries: ['places'],
        }),
        MatGoogleMapsAutocompleteModule.forRoot()
    ],
    providers: [
        GoogleMapsAPIWrapper,
        Title, DatePipe
    ],
    bootstrap: [MyReviewsComponent]
})
export class PagesModule {
}