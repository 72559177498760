<div class='col-12'>
	<div class='col-md-12 padding-tb-5 text-1-2x text-right'>Share this URL with your customers to start generating
		reviews:
		<span class='primary-color cursor pointer text-bold'>
			<a [href]='layoutUrl' target='_blanck'>
				{{layoutUrl}}
			</a>
		</span>
	</div>

	<mat-card>
		<mat-tab-group #invitationTabGroup class='customTab' (selectedTabChange)='tabChanged($event)'>
			<mat-tab label="Review Page Settings">
				<ng-container [ngTemplateOutlet]="contentSetting"></ng-container>
			</mat-tab>
			<mat-tab label="Content Settings">
				<ng-container [ngTemplateOutlet]="reviewSetting"></ng-container>
			</mat-tab>
		</mat-tab-group>

		<ng-template #contentSetting>
			<div class='padding-lr-20 padding-tb-10' *ngIf='contentTabFlag'>
				<div class="row">
					<div class="col-md-3">
						<div class="row">
							<div class="col-md-12">
								<div class=" form-group">
									<mat-label class='text-bold'>Choose Review Page Layout</mat-label>
									<select class="form-control funnel-option" [(ngModel)]="seletedFunnel"
										(change)="changeFunnel($event.target.value)">
										<option *ngFor='let item of layoutList' [value]='item.layoutName'>
											{{item.layoutName}}
										</option>
									</select>
								</div>
								<div class=" form-group" *ngIf='seletedFunnel!="Location"'>
									<mat-label>Minimum Value to prompt review platforms :</mat-label>
									<select class='form-control' [(ngModel)]="valueOfThreshold"
										*ngIf='seletedFunnel=="Thumbs"' (ngModelChange)='changeThreshold()'>
										<option *ngFor="let item of thumbsThresholdArr" [value]='item'>
											{{item}}
										</option>
									</select>
									<select class='form-control' [(ngModel)]="valueOfThreshold"
										*ngIf='seletedFunnel=="Stars"' (ngModelChange)='changeThreshold()'>
										<option *ngFor="let item of starsThresholdArr" [value]='item'>
											{{item}}
										</option>
									</select>
									<select class='form-control' [(ngModel)]="valueOfThreshold"
										*ngIf='seletedFunnel=="Faces"' (ngModelChange)='changeThreshold()'>
										<option *ngFor="let item of facesThresholdArr" [value]='item'>
											{{item}}
										</option>
									</select>
									<select class='form-control' [(ngModel)]="valueOfThreshold"
										*ngIf='seletedFunnel=="NPS"' (ngModelChange)='changeThreshold()'>
										<option *ngFor="let item of npsThreshholddArr" [value]='item'>
											{{item}}
										</option>
									</select>
									<select class='form-control' [(ngModel)]="valueOfThreshold"
										*ngIf='seletedFunnel=="Review Platforms"' (ngModelChange)='changeThreshold()'>
										<option *ngFor="let item of platformThreshholddArr" [value]='item'>
											{{item}}
										</option>
									</select>
								</div>

								<div class=" form-group">
									<div class="notes roundedBox-radius padding-10 notes-back-color">
										Recovering unhappy customers before they write a review is common-sense
										customer
										service.
										<strong>However, some publishers may view the practice as manipulating or
											"gaming"
											reviews.
										</strong>
										<span>
											<i class="fa fa-question-circle cursor-pointer"
												[ngbTooltip]="thresholdTooltip" placement="right"
												triggers='click:blur'></i>
										</span>
									</div>
									<ng-template #thresholdTooltip>
										<p><b>Note:</b> Google’s <a
												href='https://support.google.com/contributionpolicy/answer/7411351'
												target="_blank">review content policy</a> changed on April 12, 2018
											to
											state:
											"Don’t discourage or prohibit negative reviews or selectively solicit
											positive
											reviews from customers." It is anyone’s guess how Google will interpret
											this
											policy
											or if they will enforce it.</p>
										<p>To err on the side of caution, you can use a non-segmenting review funnel
											layout
											or
											simply show <b>all</b> visitors the same options.</p>
									</ng-template>

								</div>
								<div class=" form-group" *ngIf='seletedFunnel!="Location"'>
									<mat-slide-toggle [(ngModel)]='autoRedirection' (ngModelChange)='changeAuto()'>
										Autoredirection</mat-slide-toggle>
								</div>
								<div class=" form-group">
									<mat-slide-toggle [(ngModel)]='opensameTab'
										(ngModelChange)='changeOpenTabStatus()'>Redirect
										in same tab</mat-slide-toggle>
								</div>

								<div class="form-group">
									<div class="notes roundedBox-radius padding-10 notes-back-color">
										Auto-redirect user to primary review platform.
									</div>
									<!-- <span>
										<i class="fa fa-question-circle cursor-pointer"
											ngbTooltip="Auto-redirect user to primary review platform."
											placement="right" triggers='click:blur'></i>
									</span> -->
									<ng-template #thresholdTooltip>
										<p><b>Note:</b> Google’s <a
												href='https://support.google.com/contributionpolicy/answer/7411351'
												target="_blank">review content policy</a> changed on April 12, 2018
											to
											state:
											"Don’t discourage or prohibit negative reviews or selectively solicit
											positive
											reviews from customers." It is anyone’s guess how Google will interpret
											this
											policy
											or if they will enforce it.</p>
										<p>To err on the side of caution, you can use a non-segmenting review funnel
											layout
											or
											simply show <b>all</b> visitors the same options.</p>
									</ng-template>

								</div>

								<div class='form-group  reviewsite-section'
									*ngIf="reviewSiteList.length>0 && seletedFunnel!='Location'">
									<div class='row'>
										<mat-label class='text-bold padding-10'>Review Platforms:</mat-label>
										<div cdkDropList class="example-listOld" (cdkDropListDropped)="drop($event)">
											<div class="example-boxOld" *ngFor="let item of reviewSiteList;let i=index"
												cdkDrag>
												<div class='col-10 flexClass' *ngIf="reviewSiteList.length>0">
													<img class='reviewSiteImg'
														[src]='"../../../../../assets/media/social-logo/"+item.reviewSiteName+".png"'
														*ngIf='item.isCustom==false'>
													<img class='reviewSiteImgOld' [src]='item.imageURL'
														*ngIf='item.isCustom==true'>
													<div id="profileImage"
														*ngIf='item.isCustom==true && item.imageURL == 0'><b
															class="custContentNameOld"
															id="demo">{{item.reviewSiteName}}</b></div>
													<div class='text-1x padding-tb-10 source-logo-textOld'>
														&nbsp;&nbsp;{{item.reviewSiteName}}</div>
												</div>
												<div class='col-2 text-right text-1-5x star-parentOld'>
													<i class="fas fa-star star-iconOld"
														[ngClass]="{'primry-icon': i==0}"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-9 text-center">
						<mat-label class='text-bold ' style="font-size: 20px;">Preview</mat-label>
						<kt-client-review [dataDis]="dataDis" style="pointer-events: none;"></kt-client-review>
					</div>
				</div>
			</div>
		</ng-template>

		<!-- <ng-template #contentSetting>
			<div class='padding-lr-20 padding-tb-10' *ngIf='contentTabFlag'>
				<div class='col-12 '>
					<div class='row text-1-5x margin-tb-5'>
						Choose Review Page Layout
					</div>
					<div class='row'>
						<div class='col-md-3 col-sm-4 col-xs-4 funnel-option' *ngFor='let item of layoutList'>
							<mat-card class='no-padding ' (click)='changeFunnel(item)'
								[ngClass]="{'selected-funnel':seletedFunnel==item.layoutName}">
								<img [src]="'../../../../../assets/media/funnel-images/'+item.layoutName+'.png'">
							</mat-card>
						</div>
					</div>
				</div>
				<div class='col-12 margin-tb-20' *ngIf='seletedFunnel!="Location"'>
					<div class='row text-1x'>
						<div class='col-md-4 col-sm-12 col-xs-12 padding-10'>
							Minimum Value to prompt review platforms :
						</div>
						<div class='col-md-4 col-sm-12 col-xs-12'>
							<select class='form-control' [(ngModel)]="valueOfThreshold" *ngIf='seletedFunnel=="Thumbs"'
								(ngModelChange)='changeThreshold()'>
								<option *ngFor="let item of thumbsThresholdArr" [value]='item'>
									{{item}}
								</option>
							</select>
							<select class='form-control' [(ngModel)]="valueOfThreshold" *ngIf='seletedFunnel=="Stars"'
								(ngModelChange)='changeThreshold()'>
								<option *ngFor="let item of starsThresholdArr" [value]='item'>
									{{item}}
								</option>
							</select>
							<select class='form-control' [(ngModel)]="valueOfThreshold" *ngIf='seletedFunnel=="Faces"'
								(ngModelChange)='changeThreshold()'>
								<option *ngFor="let item of facesThresholdArr" [value]='item'>
									{{item}}
								</option>
							</select>
							<select class='form-control' [(ngModel)]="valueOfThreshold" *ngIf='seletedFunnel=="NPS"'
								(ngModelChange)='changeThreshold()'>
								<option *ngFor="let item of npsThreshholddArr" [value]='item'>
									{{item}}
								</option>
							</select>
						</div>
						<div class='col-md-4 col-sm-12 col-xs-12'>
							<div class="notes roundedBox-radius padding-10 notes-back-color">
								Recovering unhappy customers before they write a review is common-sense customer
								service.
								<strong>However, some publishers may view the practice as manipulating or "gaming"
									reviews.
								</strong>
								<span>
									<i class="fa fa-question-circle cursor-pointer" [ngbTooltip]="thresholdTooltip"
										placement="left" triggers='click:blur'></i>
								</span>
							</div>
							<ng-template #thresholdTooltip>
								<p><b>Note:</b> Google’s <a
										href='https://support.google.com/contributionpolicy/answer/7411351'
										target="_blank">review content policy</a> changed on April 12, 2018 to state:
									"Don’t discourage or prohibit negative reviews or selectively solicit positive
									reviews from customers." It is anyone’s guess how Google will interpret this policy
									or if they will enforce it.</p>
								<p>To err on the side of caution, you can use a non-segmenting review funnel layout or
									simply show <b>all</b> visitors the same options.</p>
							</ng-template>

						</div>
					</div>
				</div>
				<div class='col-12 margin-tb-20' *ngIf='seletedFunnel!="Location"'>
					<div class='row text-1x'>
						<div class='col-md-3 col-sm-12 col-xs-12'>
							<mat-slide-toggle [(ngModel)]='autoRedirection' (ngModelChange)='changeAuto()'>
								Autoredirection</mat-slide-toggle>
						</div>
						<div class='col-md-3 col-sm-12 col-xs-12'>
							<mat-slide-toggle [(ngModel)]='opensameTab' (ngModelChange)='changeOpenTabStatus()'>Redirect
								in same tab</mat-slide-toggle>
						</div>
						<div class='col-md-6 col-sm-12 col-xs-12'>
							<div class="notes roundedBox-radius padding-10 notes-back-color">
								Auto-redirect user to primary review platform.
							</div>
							<ng-template #thresholdTooltip>
								<p><b>Note:</b> Google’s <a
										href='https://support.google.com/contributionpolicy/answer/7411351'
										target="_blank">review content policy</a> changed on April 12, 2018 to state:
									"Don’t discourage or prohibit negative reviews or selectively solicit positive
									reviews from customers." It is anyone’s guess how Google will interpret this policy
									or if they will enforce it.</p>
								<p>To err on the side of caution, you can use a non-segmenting review funnel layout or
									simply show <b>all</b> visitors the same options.</p>
							</ng-template>

						</div>
					</div>
					<div class='row text-1x'>
					</div>
				</div>
				<div class='col-12 margin-tb-20' *ngIf='seletedFunnel!="Location"'>
					<div class='row text-1x'>
					</div>
				</div>
				<div class='col-12  reviewsite-section' *ngIf="reviewSiteList.length>0 && seletedFunnel!='Location'">
					<div class='row '>
						<div class='col-12 padding-10 text-1-5x margin-tb-5 '>Review Platforms</div>
						<div class='col-md-6 col-sm-12 col-xs-12'>
						
							<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
								<div class="example-box" *ngFor="let item of reviewSiteList;let i=index" cdkDrag>
									<div class='col-10 flexClass' *ngIf="reviewSiteList.length>0">
										<img class='reviewSiteImg'
											[src]='"../../../../../assets/media/social-logo/"+item.reviewSiteName+".png"'
											*ngIf='item.isCustom==false'>
										<img class='reviewSiteImg' [src]='item.imageURL' *ngIf='item.isCustom==true'>
										<div id="profileImage" *ngIf='item.isCustom==true && item.imageURL == 0'><b
												class="custContentName" id="demo">{{item.reviewSiteName}}</b></div>
										<div class='text-1x padding-tb-10 source-logo-text'>
											&nbsp;&nbsp;{{item.reviewSiteName}}</div>
									</div>
									<div class='col-2 text-right text-1-5x star-parent'>
										<i class="fas fa-star star-icon" [ngClass]="{'primry-icon': i==0}"></i>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</ng-template>  -->

		<!-- Review Tab -->
		<ng-template #reviewSetting>
			<div class='padding-20' *ngIf='reviewSettingTabFlag'>
				<form class="padding-lr-20" [formGroup]="reviewSettingForm" (ngSubmit)="onReviewSettingFormSubmit()">
					<div class="row">
						<div class="col-md-6 form-group">
							<mat-label class='text-color-dark'>Header Font Color</mat-label>
							<div class="input-group">
								<input type="text" class="form-control" formControlName="headerFontColor"
									[(ngModel)]="reviewPageSettingModel.headerFontColor">
								<div class="input-group-append">
									<input type="color" style="padding: 5px;height: 43px;"
										class="input-group-text colorPicker" id="colorpicker"
										formControlName="headerFontColor"
										[(ngModel)]="reviewPageSettingModel.headerFontColor"
										(change)="changeCustomColor($event,'font')">
								</div>
							</div>
							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.headerFontColor.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.headerFontColor.errors.required'>*Header
									Font Color Required</strong>
							</mat-error>
						</div>
						<div class="col-md-6 form-group">
							<mat-label class='text-color-dark'>Header Background Color</mat-label>
							<div class="input-group">
								<input type="text" class="form-control" formControlName="headerBackgroundColor"
									[(ngModel)]="reviewPageSettingModel.headerBackgroundColor">
								<div class="input-group-append">
									<input type="color" style="padding: 5px;height: 43px;"
										class="input-group-text colorPicker" id="colorpicker"
										formControlName="headerBackgroundColor"
										[(ngModel)]="reviewPageSettingModel.headerBackgroundColor"
										(change)="changeCustomColor($event,'background')">
								</div>
							</div>

							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.headerBackgroundColor.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.headerBackgroundColor.errors.required'>*Header
									Background Color Required</strong>
							</mat-error>
						</div>
					</div>

					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Header Message</mat-label>
							<input type="text" formControlName="headerTitle" class="form-control" autocomplete="off"
								name="headerTitle" [(ngModel)]="reviewPageSettingModel.headerTitle">
							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.headerTitle.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.headerTitle.errors.required'>*Header
									Message Required</strong>
							</mat-error>
						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Welcome Message</mat-label>
							<input type="text" formControlName="welcomemessageforPublicReview" class="form-control"
								autocomplete="off" name="welcomemessageforPublicReview"
								[(ngModel)]="reviewPageSettingModel.welcomemessageforPublicReview">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.welcomemessageforPublicReview.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.welcomemessageforPublicReview.errors.required'>*Welcome
									Message Required</strong>
							</mat-error>
						</div>

					</div>
					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Body Message</mat-label>
							<input type="text" formControlName="headerBody" class="form-control" autocomplete="off"
								name="headerBody" [(ngModel)]="reviewPageSettingModel.headerBody">

							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.headerBody.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.headerBody.errors.required'>*Body
									Message Required</strong>
							</mat-error>
						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Thank You Message</mat-label>
							<input type="text" formControlName="publicReviewThankYouMsg" class="form-control"
								autocomplete="off" name="publicReviewThankYouMsg"
								[(ngModel)]="reviewPageSettingModel.publicReviewThankYouMsg">

							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.publicReviewThankYouMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicReviewThankYouMsg.errors.required'>*Thank
									You Message Required</strong>
							</mat-error>
						</div>
					</div>
					<div class='col-12 padding-tb-15'>
						<mat-divider></mat-divider>
					</div>
					<div class='row text-bold-600 text-1-3x text-center'>
						Public Review Workflow
					</div>
					<div class='row margin-tb-10 text-center'>
						<strong>Instructions presented to customers on your review landing page.</strong>
					</div>

					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Review Request Message</mat-label>
							<input type="text" formControlName="messageforPublicReview" class="form-control"
								autocomplete="off" name="messageforPublicReview"
								[(ngModel)]="reviewPageSettingModel.messageforPublicReview">

							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.messageforPublicReview.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.messageforPublicReview.errors.required'>*Review
									Request Message Required</strong>
							</mat-error>
						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Leave Review Message
							</mat-label>
							<input type="text" formControlName="publicLeaveYourReviewMsg" class="form-control"
								autocomplete="off" [(ngModel)]="reviewPageSettingModel.publicLeaveYourReviewMsg"
								maxlength="25" ngbTooltip="Value must be less than 25 characters">

							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.publicLeaveYourReviewMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicLeaveYourReviewMsg.errors.required'>
									*Leave Review Message Required</strong>

								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicLeaveYourReviewMsg.errors.maxlength'>
									*Value must be less than 25 characters</strong>
							</mat-error>
						</div>
					</div>
					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Public Review Message</mat-label>
							<input type="text" formControlName="publicPostAReviewTextMsg" class="form-control"
								autocomplete="off" name="publicPostAReviewTextMsg"
								[(ngModel)]="reviewPageSettingModel.publicPostAReviewTextMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.publicPostAReviewTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicPostAReviewTextMsg.errors.required'>*Private
									Review Message Required</strong>
							</mat-error>
						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Public Review Link Text</mat-label>
							<input type="text" formControlName="publicPostClickhereText" class="form-control"
								autocomplete="off" name="publicPostClickhereText"
								[(ngModel)]="reviewPageSettingModel.publicPostClickhereText">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.publicPostClickhereText.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicPostClickhereText.errors.required'>*Private
									Review Link Text Required</strong>
							</mat-error>
						</div>
					</div>

					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Public Review Appreciate Message</mat-label>
							<input type="text" formControlName="publicReviewAppreciateMessage" class="form-control"
								autocomplete="off" name="publicReviewAppreciateMessage"
								[(ngModel)]="reviewPageSettingModel.publicReviewAppreciateMessage">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.publicReviewAppreciateMessage.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicReviewAppreciateMessage.errors.required'>
									*Public Review Appreciate Message Required</strong>
							</mat-error>
						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Public Review Thank you Message</mat-label>
							<input type="text" formControlName="publicReviewThankyouMessage" class="form-control"
								autocomplete="off" name="publicReviewThankyouMessage"
								[(ngModel)]="reviewPageSettingModel.publicReviewThankyouMessage">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.publicReviewThankyouMessage.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicReviewThankyouMessage.errors.required'>
									*Public Review Thank you Message Required</strong>
							</mat-error>
						</div>
					</div>

					<div class='col-12 padding-tb-15'>
						<mat-divider></mat-divider>
					</div>
					<div class='row text-bold-600 text-1-3x text-center'>
						Private Feedback Workflow
					</div>
					<div class='row margin-tb-10 text-center'>
						<strong> These visitors are presented with a contact form or custom feeback form.</strong>
					</div>
					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Review Header Message</mat-label>
							<input type="text" formControlName="messageforPrivateReview" class="form-control"
								name="messageforPrivateReview" autocomplete="off"
								[(ngModel)]="reviewPageSettingModel.messageforPrivateReview">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.messageforPrivateReview.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.messageforPrivateReview.errors.required'>*Review
									Header Message Required</strong>
							</mat-error>
						</div>

						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Thank You Message</mat-label>
							<input type="text" formControlName="contactFromThankyouMsg" class="form-control"
								name="contactFromThankyouMsg" autocomplete="off"
								[(ngModel)]="reviewPageSettingModel.contactFromThankyouMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.contactFromThankyouMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.contactFromThankyouMsg.errors.required'>*Thank
									You Message Required</strong>
							</mat-error>

						</div>
					</div>
					<div class='row'>
						<!-- added by unnati -->
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark '>Private Review Message
							</mat-label>
							<input type="text" formControlName="privatePostAReviewTextMsg" class="form-control"
								name="privatePostAReviewTextMsg" autocomplete="off"
								[(ngModel)]="reviewPageSettingModel.privatePostAReviewTextMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.privatePostAReviewTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.privatePostAReviewTextMsg.errors.required'>*Review
									Message Required</strong>
							</mat-error>

						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Private Review Link Text</mat-label>
							<input type="text" formControlName="privatePostClickhereText" class="form-control"
								autocomplete="off" name="privatePostClickhereText"
								[(ngModel)]="reviewPageSettingModel.privatePostClickhereText">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.privatePostClickhereText.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.privatePostClickhereText.errors.required'>*Review
									Link Text Required</strong>
							</mat-error>
						</div>
					</div>

					<div class='row'>
						<div class='col-md-6 col-sm-12 col-xs-12'>
							<mat-slide-toggle [checked]='hidePublicReviewMsg' (change)='hidePublicReview($event)'>Hide
								Public Review Message</mat-slide-toggle>
						</div>
					</div>

					<div class='col-12 padding-tb-15'>
						<mat-divider></mat-divider>
					</div>

					<!-- added by unnati -->
					<div class='row  text-bold-600 text-1-3x text-center'>
						Private Feedback Form
					</div>
					<div class='row margin-tb-10 text-center'>
					</div>
					<div class='row'>
						<div class='col-md-12 form-group'>
							<mat-label class='text-color-dark'>Message Placeholder Text</mat-label>
							<input type="text" formControlName="privateWhatToDoPlaceHolderMsg" class="form-control"
								name="privateWhatToDoPlaceHolderMsg" autocomplete="off"
								[(ngModel)]="reviewPageSettingModel.privateWhatToDoPlaceHolderMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.privateWhatToDoPlaceHolderMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.privateWhatToDoPlaceHolderMsg.errors.required'>*Message
									PlaceHolder Required</strong>
							</mat-error>
						</div>
					</div>
					<div class='row'>
						<div class='col-md-4 form-group'>
							<mat-label class='text-color-dark'>Name Placeholder</mat-label>
							<input type="text" formControlName="privateNamePlaceholderMsg" class="form-control"
								name="privateNamePlaceholderMsg" autocomplete="off"
								[(ngModel)]="reviewPageSettingModel.privateNamePlaceholderMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.privateNamePlaceholderMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.privateNamePlaceholderMsg.errors.required'>*Name
									Placeholder Required</strong>
							</mat-error>
						</div>
						<div class="col-md-4 form-group">
							<div>Required</div>
							<mat-radio-group formControlName="hasNameRequired"
								[(ngModel)]="reviewPageSettingModel.hasNameRequired">
								<mat-radio-button [value]="true">Yes</mat-radio-button>
								<mat-radio-button [value]="false">No</mat-radio-button>
							</mat-radio-group>
						</div>
						<div class="col-md-4 form-group">
							<div>Show Field</div>
							<mat-radio-group formControlName="hasNameEnable"
								[(ngModel)]="reviewPageSettingModel.hasNameEnable">
								<mat-radio-button [value]="true">True</mat-radio-button>
								<mat-radio-button [value]="false">False</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>

					<div class='row'>
						<div class='col-md-4 form-group'>
							<mat-label class='text-color-dark'>Email Placeholder
							</mat-label>
							<input type="text" formControlName="privateEmailPlaceholderMsg" class="form-control"
								autocomplete="off" name="privateEmailPlaceholderMsg"
								[(ngModel)]="reviewPageSettingModel.privateEmailPlaceholderMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.privateEmailPlaceholderMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.privateEmailPlaceholderMsg.errors.required'>*Email
									Placeholder Required</strong>
							</mat-error>
						</div>
						<div class="col-md-4">
							<div>Required </div>
							<mat-radio-group formControlName="hasEmailRequired"
								[(ngModel)]="reviewPageSettingModel.hasEmailRequired">
								<mat-radio-button [value]="true">Yes
								</mat-radio-button>
								<mat-radio-button [value]="false">No
								</mat-radio-button>
							</mat-radio-group>
						</div>
						<div class="col-md-4">
							<div>Show Field</div>
							<mat-radio-group formControlName="hasEmailEnable"
								[(ngModel)]="reviewPageSettingModel.hasEmailEnable">
								<mat-radio-button [value]="true">True</mat-radio-button>
								<mat-radio-button [value]="false">False</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>

					<div class="row">
						<div class='col-md-4 form-group'>
							<mat-label class='text-color-dark'>Phone Number Placeholder</mat-label>
							<input type="text" formControlName="privatePhoneNumberPlaceholderMsg" class="form-control"
								autocomplete="off" name="privatePhoneNumberPlaceholderMsg"
								[(ngModel)]="reviewPageSettingModel.privatePhoneNumberPlaceholderMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.privatePhoneNumberPlaceholderMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.privatePhoneNumberPlaceholderMsg.errors.required'>*Phone
									Number Placeholder Required</strong>
							</mat-error>
						</div>
						<div class="col-md-4 form-group">
							<div>Required</div>
							<mat-radio-group formControlName="hasPhoneNumberRequired"
								[(ngModel)]="reviewPageSettingModel.hasPhoneNumberRequired">
								<mat-radio-button [value]="true">Yes</mat-radio-button>
								<mat-radio-button [value]="false">No</mat-radio-button>
							</mat-radio-group>
						</div>
						<div class="col-md-4 form-group">
							<div> Show Field</div>
							<mat-radio-group formControlName="hasPhoneNumberEnable"
								[(ngModel)]="reviewPageSettingModel.hasPhoneNumberEnable">
								<mat-radio-button [value]="true">True</mat-radio-button>
								<mat-radio-button [value]="false">False</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>

					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Submit Button Text
							</mat-label>
							<input type="text" formControlName="privateSubmitButtonTextMsg" class="form-control"
								autocomplete="off" name="privateSubmitButtonTextMsg"
								[(ngModel)]="reviewPageSettingModel.privateSubmitButtonTextMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.privateSubmitButtonTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.privateSubmitButtonTextMsg.errors.required'>*Submit
									Button Text Required</strong>
							</mat-error>
						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Close Button Text</mat-label>
							<input type="text" formControlName="privateCloseButtonTextMsg" class="form-control"
								autocomplete="off" name="privateCloseButtonTextMsg"
								[(ngModel)]="reviewPageSettingModel.privateCloseButtonTextMsg">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.privateCloseButtonTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.privateCloseButtonTextMsg.errors.required'>*Close
									Button Text Required</strong>
							</mat-error>
						</div>
					</div>
					<div class='col-12 padding-tb-15'>
						<mat-divider></mat-divider>
					</div>
					<div class='row  text-bold-600 text-1-3x text-center'>
						Layout Option's
					</div>
					<strong class='row margin-tb-10'>
						Thumbs Layout
					</strong>
					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Good Experience Message</mat-label>
							<input type="text" formControlName="publicGoodExperienceTextMsg" class="form-control"
								[(ngModel)]="reviewPageSettingModel.publicGoodExperienceTextMsg" autocomplete="off"
								maxlength="25" ngbTooltip="Value must be less than 25 characters">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.publicGoodExperienceTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicGoodExperienceTextMsg.errors.required'>*Good
									Experience Message Required </strong>
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicGoodExperienceTextMsg.errors.required'>
									*Bad Experience Message Required
								</strong>
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicGoodExperienceTextMsg.errors.maxlength'>
									*Value must be less than 25 characters</strong>
							</mat-error>
						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Bad Experience Message</mat-label>
							<input type="text" formControlName="publicBadExperienceTextMsg" class="form-control"
								[(ngModel)]="reviewPageSettingModel.publicBadExperienceTextMsg" autocomplete="off"
								maxlength="25" ngbTooltip="Value must be less than 25 characters">
							<mat-error
								*ngIf="fromSubmitted && reviewSettingForm.controls.publicBadExperienceTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicBadExperienceTextMsg.errors.required'>
									*Bad Experience Message Required
								</strong>
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicBadExperienceTextMsg.errors.maxlength'>
									*Value must be less than 25 characters</strong>
							</mat-error>
						</div>
					</div>
					<strong class='row margin-tb-10'>
						NPS Layout
					</strong>
					<div class='row'>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Extremely Likely Message</mat-label>
							<input type="text" formControlName="publicExtremelyLikely" class="form-control"
								autocomplete="off" name="publicExtremelyLikely"
								[(ngModel)]="reviewPageSettingModel.publicExtremelyLikely" maxlength="25"
								ngbTooltip="Value must be less than 25 characters">
							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.publicExtremelyLikely.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicExtremelyLikely.errors.required'>*Extremely
									Likely Message Required</strong>
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicExtremelyLikely.errors.maxlength'>
									*Value must be less than 25 characters</strong>
							</mat-error>
						</div>
						<div class='col-md-6 form-group'>
							<mat-label class='text-color-dark'>Not Likely At All Message</mat-label>
							<input type="text" formControlName="publicNotLikelyatAll" class="form-control"
								autocomplete="off" name="publicNotLikelyatAll"
								[(ngModel)]="reviewPageSettingModel.publicNotLikelyatAll" maxlength="25"
								ngbTooltip="Value must be less than 25 characters">
							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.publicNotLikelyatAll.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicNotLikelyatAll.errors.required'>*Not
									Likely At All Message Required</strong>
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicNotLikelyatAll.errors.maxlength'>
									*Value must be less than 25 characters</strong>
							</mat-error>
						</div>
					</div>
					<strong class='row margin-tb-10'>
						Face Layout
					</strong>
					<div class='row'>
						<div class='col-md-4 form-group'>
							<mat-label class='text-color-dark'>Good Face Message</mat-label>
							<input type="text" formControlName="publicGoodFaceTextMsg" class="form-control"
								autocomplete="off" name="publicGoodFaceTextMsg"
								[(ngModel)]="reviewPageSettingModel.publicGoodFaceTextMsg" maxlength="25"
								ngbTooltip="Value must be less than 25 characters">
							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.publicGoodFaceTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicGoodFaceTextMsg.errors.required'>*Good
									Face Message Required</strong>
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicGoodFaceTextMsg.errors.maxlength'>
									*Value must be less than 25 characters</strong>
							</mat-error>
						</div>
						<div class='col-md-4 form-group'>
							<mat-label class='text-color-dark'>Okay Face Message</mat-label>
							<input type="text" formControlName="publicOkayFaceTextMsg" class="form-control"
								autocomplete="off" name="publicOkayFaceTextMsg"
								[(ngModel)]="reviewPageSettingModel.publicOkayFaceTextMsg" maxlength="25"
								ngbTooltip="Value must be less than 25 characters">
							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.publicOkayFaceTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicOkayFaceTextMsg.errors.required'>*Okay
									Face Message Required</strong>
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicOkayFaceTextMsg.errors.maxlength'>
									*Value must be less than 25 characters</strong>
							</mat-error>
						</div>
						<div class='col-md-4 form-group'>
							<mat-label class='text-color-dark'>Bad Face Message</mat-label>
							<input type="text" formControlName="publicBadFaceTextMsg" class="form-control"
								autocomplete="off" name="publicBadFaceTextMsg"
								[(ngModel)]="reviewPageSettingModel.publicBadFaceTextMsg" maxlength="25"
								ngbTooltip="Value must be less than 25 characters">
							<mat-error *ngIf="fromSubmitted && reviewSettingForm.controls.publicBadFaceTextMsg.errors">
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicBadFaceTextMsg.errors.required'>*Bad
									Face Message Required</strong>
								<strong
									*ngIf='fromSubmitted && reviewSettingForm.controls.publicBadFaceTextMsg.errors.maxlength'>
									*Value must be less than 25 characters</strong>
							</mat-error>

						</div>
					</div>
					<div class='row'>
						<div class='col-12 text-center margin-tb-10'>
							<button class="btn btn-primary btn-elevate kt-login__btn-primary margin-lr-5"
								[ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">
								Save Changes</button>
						</div>
					</div>
				</form>
			</div>
		</ng-template>
	</mat-card>
</div>