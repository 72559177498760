// import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { PageConfigService } from '../../../core/_base/layout/services/page-config.service';
import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit {
  landingPagePermissionFlag: boolean = true;
  isExpired:boolean=false;
  constructor(public pageService: PageConfigService,
    public router: Router,
    public cdr: ChangeDetectorRef) { }

  ngOnInit() {
    var scope = this;


    let profileId = JSON.parse(JSON.stringify(localStorage.getItem('ProfileId')));
    if (profileId == null || profileId < 0) {
      scope.pageService.createNewProfilePopup();
    } else {
      scope.isExpired=JSON.parse(localStorage.getItem('isExpired'))
      let permission =localStorage.getItem('user-access')
      var jsonStr=permission;
      var jsonObject = (new Function( "return " + jsonStr ) )() ;
      if (jsonObject !=undefined) {
        scope.landingPagePermissionFlag = jsonObject['LandingPage']

        scope.cdr.detectChanges()

      }
    }
  }
}
