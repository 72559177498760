import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
// Angular
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import {
	NavigationCancel,
	NavigationEnd,
	NavigationStart,
	RouteConfigLoadEnd,
	RouteConfigLoadStart,
	Router
} from '@angular/router';
// Object-Path
import * as objectPath from 'object-path';
// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';
// Layout
import { LayoutConfigService, LayoutRefService, MenuHorizontalService } from '../../../core/_base/layout';
// HTML Class Service
import { HtmlClassService } from '../html-class.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpgradePlanComponent } from '../../pages/General-settings/billing/upgrade-plan/upgrade-plan.component';
import { ThankyoubillingComponent } from '../../pages/General-settings/billing/thankyoubilling/thankyoubilling.component';
import { access } from 'fs';

declare const window: any;


@Component({
	selector: 'kt-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
	// Public properties
	planNotification: boolean = false;
	menuHeaderDisplay: boolean;
	fluid: boolean;
	isVerify: boolean = true;
	isWarning = false;
	isSmsEmail;
	isFetching: boolean = false;
	@ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;
	reviewFetchMsg = '';
	isExpired: boolean = false;
	currentPlan = '';
	email: any;
	isFreeTrial = false;
	freeTrialNotice: any = false;

	upgradeAccount: any;
	IsReseller: boolean = false;
	country: string = "";
	plan: string = "";
	showA2PNotice: boolean;
	allowSMS:number = 1; //Don't show the error while loading
	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param layoutRefService: LayoutRefService
	 * @param layoutConfigService: LayoutConfigService
	 * @param loader: LoadingBarService
	 * @param htmlClassService: HtmlClassService
	 */
	constructor(
		private router: Router,
		public modalService: NgbModal,
		private layoutRefService: LayoutRefService,
		private layoutConfigService: LayoutConfigService,
		public loader: LoadingBarService,
		public menuService: MenuHorizontalService,
		public htmlClassService: HtmlClassService,
		public http: HttpClient,
		public snackbar: MatSnackBar,
		public pageConfigService: PageConfigService,
		public cdr: ChangeDetectorRef,
		public pageService: PageConfigService,
		public dialog: MatDialog,
	) {
		// page progress bar percentage
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				// set page progress bar loading to start on NavigationStart event router
				this.loader.start();
			}
			if (event instanceof RouteConfigLoadStart) {
				this.loader.increment(35);
			}
			if (event instanceof RouteConfigLoadEnd) {
				this.loader.increment(75);
			}
			if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
				// set page progress bar loading to end on NavigationEnd event router
				this.loader.complete();
			}
		});

		var scope = this;
		if (localStorage.getItem('plan'))
			var planName = JSON.parse(localStorage.getItem('plan').toLocaleLowerCase());
		var profileCountry = localStorage.getItem('profileCountry');
		if ((planName == "small" || planName == "medium" || planName == "large") && (profileCountry == "US" || profileCountry == "CA")) {
			this.showA2PNotice = true;
		}

		if (localStorage.getItem('ProfileId') == "null" || localStorage.getItem('ProfileId') == null || localStorage.getItem('ProfileId') == "-1") {
			this.isWarning = false;
			this.cdr.markForCheck()
		} else {
			// setInterval(() => {
				if(localStorage.getItem("isConnnectedReviewPlatform") == null || localStorage.getItem("isConnnectedReviewPlatform") == undefined){
					this.loadProfile();
				}
				if (localStorage.getItem("isConnnectedReviewPlatform") == "true") {
					this.isWarning = false;
					this.cdr.markForCheck()
				} else {
					this.isWarning = true;
				}


				if (localStorage.getItem("platformError") == null || localStorage.getItem("platformError") == '') {
					this.isShowHead = false;
					this.notificationMsg = ''
					this.cdr.markForCheck();
				}
				else {
					this.isShowHead = true;
					this.notificationMsg = localStorage.getItem("platformError")
					this.cdr.markForCheck();
				}
			} 
		// 	, 500)
		// }
		this.email = localStorage.getItem("adminemail");
		scope.pageConfigService.getFetchReviewFlag().subscribe((result) => {
			scope.isFetching = result.isFetch;

			if (result.status == 'pending') {
				scope.reviewFetchMsg = 'We are fetching reviews, it may take some time'
			} else if (result.status == 'maintenance') {
				scope.reviewFetchMsg = 'We are fetching reviews, it may take 2 or 3 hours'
			} else if (result.status == 'invalid_url') {
				scope.reviewFetchMsg = "Please reconnect your " + result.target + " review site. It looks like link or name you've entered is invalid or wrong"
			}
			scope.cdr.markForCheck();
		})

	}

	redirect(url: string) {
		console.log(url);
		this.router.navigate([url]);
	}

	sendEmailAgain() {
		// this.snackbar.open('I','close',{duration:5000})
		let key = localStorage.getItem(environment.authTokenKey)
		this.http.get(environment.apiPath + 'SendEmail?Key=' + key)
			.subscribe((res) => {
				if (res['success']) {
					this.pageConfigService.showSuccess('Sent', 'Verification email sent successfully')
				} else {
					this.pageConfigService.showError('Oops!!!', res['errorMsg'])

				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	updateEmail() {
		this.router.navigate(['/general/my-account'])
	}

	loadProfile() {
		
				
		// if(this.showloader == true || this.hideloader == true) scope.pageService.showLoader()
		this.pageService.showLoader()
		var accessKey = localStorage.getItem(environment.authTokenKey);
		
		var profileId=JSON.parse(localStorage.getItem("ProfileId"));
		let data={Key:accessKey,
			ProfileId:profileId
		}
		this.http.post(environment.apiPath + 'GetDashboardDetails',data)
			///// this.http.get(environment.apiPath + 'test')
			.subscribe((res: any) => {
			
				// if(this.showloader == true) scope.pageService.hideLoader()
				if (res['success']) {
					this.pageService.hideLoader()

					if (res['reviewSites'].length > 0) 
						localStorage.setItem("isConnnectedReviewPlatform", "true")
					else 
						localStorage.setItem("isConnnectedReviewPlatform", res.isConnnectedReviewPletform)

					

					if (res['isConnnectedReviewPlatform'] == false) {
						localStorage.setItem('isConnnectedReviewPlatform', 'false')
					}

				} 
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					//this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	closeFetching() {
		var scope = this;
		scope.isFetching = false;
	}
	closeVeriFy() {
		var scope = this;
		scope.isVerify = true;
	}
	closeFreePlan() {
		var scope = this;
		scope.planNotification = false;
	}
	closeExpired() {
		var scope = this;

		scope.isExpired = false;
	}

	paymentErrorMsg: any = '';
	notificationMsg: any = '';
	isShowHead: any = false;
	ngOnInit(): void {
		var scope = this;

		if (localStorage.getItem('currentCountry'))
			this.country = localStorage.getItem('currentCountry').toLocaleLowerCase();
		if (localStorage.getItem('plan'))
			this.plan = JSON.parse(localStorage.getItem('plan').toLocaleLowerCase());
		const config = scope.layoutConfigService.getConfig();
		this.getDetails();

		this.menuService.identity.subscribe((res: any) => {
			if (res == "setNotice" || res == "deletereviewSource") {
				setInterval(() => {
					if (localStorage.getItem("isConnnectedReviewPlatform") == "true") {
						this.isWarning = false;
						this.cdr.markForCheck()
					} else {
						this.isWarning = true;
						this.cdr.markForCheck()
					}

					if (localStorage.getItem("platformError") == null || localStorage.getItem("platformError") == '') {
						this.isShowHead = false;
						this.notificationMsg = ''
						this.cdr.markForCheck();
					}
					else {
						this.isShowHead = true;
						this.notificationMsg = localStorage.getItem("platformError")
						this.cdr.markForCheck();
					}
				}, 500)

			} else if (res == "updateEmail") {
				this.email = localStorage.getItem("adminemail");
				this.cdr.markForCheck();
			} else if (res == "resendEmail") {
				this.sendEmailAgain();
			}
		})
		// review platform notice

		setInterval(() => {
			if (localStorage.getItem("isConnnectedReviewPlatform") == "true") {
				this.isWarning = false;
				this.cdr.markForCheck()
			} else if (localStorage.getItem("isConnnectedReviewPlatform") == "false") {
				this.isWarning = true;
				this.cdr.markForCheck()
			}

			if (localStorage.getItem("platformError") == null || localStorage.getItem("platformError") == '') {
				this.isShowHead = false;
				this.notificationMsg = ''
				this.cdr.markForCheck();
			}
			else {
				this.isShowHead = true;
				this.notificationMsg = localStorage.getItem("platformError")
				this.cdr.markForCheck();
			}

			scope.currentPlan = JSON.parse(localStorage.getItem('current-plan'))
			if (scope.currentPlan == "Free Trial") {
				this.isFreeTrial = true;
				this.cdr.markForCheck()
			} else {

				this.isFreeTrial = false;
				this.cdr.markForCheck()
			}

			scope.pageService.getProfileDetails();
		}, 500)

		// get menu header display option
		scope.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');

		// header width fluid
		scope.fluid = objectPath.get(config, 'header.self.width') === 'fluid';

		// animate the header minimize the height on scroll down. to be removed, not applicable for default demo

		let currentplan = JSON.parse(localStorage.getItem('plan'))

		if (localStorage.getItem("GRProfileDetail")) {
			let gRProfileDetail:any = JSON.parse(localStorage.getItem("GRProfileDetail"));
			if(gRProfileDetail && gRProfileDetail.data){
				this.allowSMS = gRProfileDetail.data.allowSMS;
			}
		}
	}

	ngAfterViewInit(): void {
		// keep header element in the service
		this.layoutRefService.addElement('header', this.ktHeader.nativeElement);


		if (localStorage.getItem("GRProfileDetail")) {
			let GRProfileDetail: any = JSON.parse(localStorage.getItem("GRProfileDetail"));
			if (GRProfileDetail && GRProfileDetail.data) {
				let plan = "";
				if (localStorage.getItem('current-plan')) {
					plan = JSON.parse(localStorage.getItem('current-plan'))
				}
				window.usetifulTags = {
					userId: GRProfileDetail.data.id.toString(),
					plan: plan,
				};

				(function (w, d, s) {
					var a = d.getElementsByTagName('head')[0];
					var r: any = d.createElement('script');
					r.async = 1;
					r.src = s;
					r.setAttribute('id', 'usetifulScript');
					r.dataset.token = "972265c27eadc636c8d7a2116235c3be";
					a.appendChild(r);
				})(window, document, "https://www.usetiful.com/dist/usetiful.js")
			}
		}

	}

	upgradePlan() {
		this.router.navigate(['/general/billing'])
	}

	connectPlatform() {
		this.router.navigate(['/review-platforms'])
	}

	showSmsCountNotice: any = false;
	showEmailCountNotice: any = false;

	getDetails() {
		this.pageService.getPermissions().then((res: any) => {
			if (res.paymentErrorMessage != null || res.paymentErrorMessage != undefined || res.paymentErrorMessage != '') {
				this.paymentErrorMsg = res.paymentErrorMessage;
			} else {
				this.paymentErrorMsg = '';
			}

			if (res.usedEmailpercentage > 90) {
				this.showEmailCountNotice = true;
			} else {
				this.showEmailCountNotice = false;
			}

			if (res.usedsmspercentage > 90) {
				this.showSmsCountNotice = true;
			} else {
				this.showSmsCountNotice = false;
			}

			if (res.plan && res.plan.toLocaleLowerCase() == 'free trial') {
				this.freeTrialNotice = true;
			} else {
				this.freeTrialNotice = false;
			}
			this.isVerify = res.isVerified
			this.cdr.markForCheck();
		})
	}

	usageData = {
		totalProfile: 0,
		usedProfile: 0,
		usedProfilePer: 0,
		totalUsers: 0,
		usedUsers: 0,
		usedUsersPer: 0,
		totalEmail: 0,
		usedEmail: 0,
		usedEmailPer: 0,
		additionalEmail: 0,
		totalSms: 0,
		usedSms: 0,
		usedSmsPer: 0,
		additionalSms: 0,
		minimumProfiles: 0
	};

	upgradeAc(val) {
		if (localStorage.getItem('plan') && JSON.parse(localStorage.getItem('plan')).toLocaleLowerCase() == 'free trial') {
			this.router.navigate(['/general/billing']);
		} else {
			this.router.navigate(['/general/usage']);
		}

	}

	updateCard(contentModal) {
		this.router.navigate(['/general/billing'], { state: { example: 'updateCard' } })
	}

	billingDa = {
		curentPlan: '',
		nextBillingDate: '',
		subscriptionAmount: '',
		cardNumber: '',
		cardExpiry: '',
		cardCvv: '',
		brand: '',
		balance: '',
		nextBilligAmt: '',
		isCancelled: false
	};

	openPlans() {
		var scope = this;
		let upgrageModel = this.dialog.open(UpgradePlanComponent, {
			width: '700px',
			panelClass: 'editModal',
			data: {
				targetData: JSON.stringify(this.usageData),
				isCancelled: JSON.stringify(this.billingDa.isCancelled),
				expiryDate: this.billingDa.nextBillingDate
			}
		})


	}

	openThanyou() {
		var scope = this;

		// scope.router.navigate(['/general/billing/pricing-plans'])
		let thankMOdel = scope.dialog.open(ThankyoubillingComponent, {
			width: '600px',
			panelClass: 'editModal',
			// position:{top:'10px'},
			// disableClose: true,
		})

		thankMOdel.afterClosed().subscribe(modelResult => {
			location.reload()
		})
	}


	Reconnect() {
		this.router.navigate(['/review-platforms'])
	}

	copyCode() {
		// document.getElementById("demo").innerHTML = "You copied text!"
		navigator.clipboard.writeText
			("Coupon Code copied.");

	}


	openBilling() {
		this.upgradeAccount = true;
		this.router.navigate(['/general/billing'], { queryParams: this.upgradeAccount })
		//this.router.navigate(['/general/billing'], { queryParams: this.PlanName });
	}

	showA2PWarning(): boolean {

		if (this.plan && this.country) {
			if (this.plan == "free trial") {
				return false;
			}
			if (
				(this.plan == "small" || this.plan == "medium" || this.plan == "large") && (this.country == "us" || this.country == "ca")

			) {
				return true;
			}
			else {
				return false;
			}
		}
		else {
			return true;
		}


	}

	showTwilioWarning(): boolean {
		if (this.plan && this.country) {
			if (
				(this.plan == "free trial" || this.plan == "small" || this.plan == "medium" || this.plan == "large") && (this.country == "us" || this.country == "ca")
			) {
				return false;
			}
			else {
				if (this.country == "in") {
					return false;
				}
				else {
					if(this.allowSMS == 0){
						return true;
					}
					else
					{
						return false;
					}

				}
			}
		}
		else {
			return false;
		}


	}

	IsDashboard(): boolean {

		return (localStorage.getItem("IsReseller") == "true" ? true : false) && (localStorage.getItem("ShouldShowTwilio") == "true" ? true : false);


	}


}
