import { DataTableService } from './../../../core/_base/layout/services/datatable.service';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ControlContainer } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Select2OptionData } from 'ng-select2';
import { Router } from '@angular/router';
declare function phoneText(any1, any2, any3, any4, any5): any
declare function phoneTextCustomCountry(any2, any3, any4, any5, any1): any

@Component({
	selector: 'kt-company-settings',
	templateUrl: './company-settings.component.html',
	styleUrls: ['./company-settings.component.scss']
})

export class CompanySettingsComponent implements OnInit {
	generalSettingForm: UntypedFormGroup;
	profileData: any = {};
	submittedform: boolean = false;
	model = {
		conatctPersonName: '',
		companyName: '',
		shortName: '',
		email: '',
		phoneNumber: '',
		industry: '',
		timeZone: '',
		Country: '',
		CountryCode: '',
		logo: '',
		alphaNumericSenderId: '',
		fromPhoneNumber: ''
	};
	loading: boolean = false;
	imageError: boolean = false;
	industryList = [
		'Auto & Car Dealer',
		'Auto Repair',
		'Dentistry',
		'Education & School',
		'Elder Care',
		'Employment',
		'Financial Services',
		'Healthcare',
		'Home Services',
		'Lawyer/Legal',
		'Moving & Movers',
		'Pet Services',
		'Product',
		'Property & Rentals',
		'Real Estate',
		'Recreation',
		'Religious Institution',
		'Restaurant & Dining',
		'Salons & Beauty',
		'Software',
		'Travel & Hospitality',
		'Wedding Industry',
		'Other',
	];
	profileId: any;
	accessKey: any;
	loadText: boolean = false;
	rightIcon: boolean = false;
	wrongIcon: boolean = false;
	imageData: any;
	deletedImageArr = [];
	timeZoneList: Array<Select2OptionData>;
	oldLogo: any;
	timezoneReq: boolean = false;
	isDisable = false;
	isShowAlphaNumeric: boolean = false;
	uniqueUrl: any;
	public countryList: any = [];
	shortUrlRegEx = /[^A-Za-z0-9_-]/;
	activePhoneNumberList: string[] = new Array<string>();
	fetchTwilioNumbers: boolean = false;
	fetchLoading: boolean = false;
	profileCountry: string = "";
	shouldShowSMSTwilioNumber: boolean = true;
	constructor(public formBuinder: UntypedFormBuilder,
		public http: HttpClient,
		public pageService: PageConfigService,
		public cdr: ChangeDetectorRef,
		public dataService: DataTableService,
		public router: Router) {
	}

	ngOnInit() {
		this.profileCountry = localStorage.getItem("profileCountry");
		if (this.profileCountry == "IN") {
			this.shouldShowSMSTwilioNumber = false;

		}
		let brandUrl = JSON.parse(localStorage.getItem("brand-info"));
		if (brandUrl != null) {
			if (brandUrl.domainName == null) {
				this.uniqueUrl = environment.landingPageDomain;
			} else {
				this.uniqueUrl = "https://" + brandUrl.domainName;
			}
		}
		var scope = this;
		if (localStorage.getItem("isExpired") == "true") {
			this.router.navigate(['/general/billing']);
			return
		}
		let accountCountry = localStorage.getItem("profileCountry");
		scope.generalSettingForm = scope.formBuinder.group({
			ConatctPersonName: [''],
			CompanyName: ['', Validators.required],
			ShortName: ['', Validators.required],
			Email: ['', [Validators.required, Validators.email]],
			PhoneNumber: [''],
			//AlphaNumericSenderId: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
			AlphaNumericSenderId: [''],
			Industry: ['', Validators.required],
			Country: ['', Validators.required],
			fromPhoneNumber: ['', this.shouldShowSMSTwilioNumber == false ? null:Validators.required],
		})
		scope.profileId = JSON.parse((localStorage.getItem('ProfileId')));
		scope.accessKey = localStorage.getItem(environment.authTokenKey)
		// if (accountCountry.toLocaleLowerCase() == "in") {
		//   this.generalSettingForm.controls.Country.disable();
		// }

		if (accountCountry == 'ZA' || accountCountry == 'GB' || accountCountry == 'AU' || accountCountry == 'UK') {
			this.generalSettingForm.controls.AlphaNumericSenderId.enable();
			this.isShowAlphaNumeric = true;
		} else {
			this.generalSettingForm.controls.AlphaNumericSenderId.disable();
			this.isShowAlphaNumeric = false;
		}
		scope.pageService.callChooseProfile();
		if (scope.profileId == null || scope.profileId < 0) {
			scope.pageService.createNewProfilePopup();
			return;
		}

		scope.getTimezone()
		scope.pageService.getProgileId().subscribe(message => {
			if (message.id) {
				scope.profileId = JSON.parse(localStorage.getItem('ProfileId'))
				if (scope.profileId == null || scope.profileId < 0) {
					scope.pageService.createNewProfilePopup();
					return;
				}
				if (scope.router.url.includes('company-setting')) {
					//scope.loadProfileDetails();;
					scope.getTimezone();
				}
			}
			else {

			}
		});
		scope.loadProfileDetails();
		scope.pageService.setTitle('Company Settings')
		scope.countryList = scope.dataService.countryList;
		// this.getCountry();
	}

	country: any = {}
	selectCountry(item) {
		let dropDownData = this.countryList.filter((data: any) => data.name === item);
		this.model.CountryCode = dropDownData[0].code;
		this.model.Country = dropDownData[0].name;

		if (this.model.CountryCode == 'ZA' || this.model.CountryCode == 'GB' || this.model.CountryCode == 'AU' || this.model.CountryCode == 'UK') {
			this.generalSettingForm.controls.AlphaNumericSenderId.enable();
			this.isShowAlphaNumeric = true;
		} else {
			this.generalSettingForm.controls.AlphaNumericSenderId.disable();
			this.isShowAlphaNumeric = false;
		}
	}

	getTimezone() {
		var scope = this
		scope.timeZoneList = scope.pageService.getTimeZone();
		scope.cdr.markForCheck();
	}

	notificationMsg: any = '';
	loadProfileDetails() {
		var scope = this;
		//Get Profile details to set all details id profile selected
		scope.pageService.showLoader();
		scope.pageService.getProfileDetails()
			.then((res) => {

				scope.pageService.hideLoader()
				var platf = localStorage.getItem('platformError');
				if (platf) {
					this.notificationMsg = localStorage.platformError;
				} else {
					this.notificationMsg = '';
				}
				scope.profileData = JSON.parse(JSON.stringify(res));
				scope.model.companyName = scope.profileData['companyName'];
				scope.model.email = scope.profileData['email'];
				scope.model.conatctPersonName = scope.profileData['personName'];
				scope.model.shortName = scope.profileData['shortName'];
				scope.model.industry = scope.profileData['industry'];
				scope.model.phoneNumber = scope.profileData['phoneNumber'];
				scope.model.timeZone = scope.profileData['timeZone'];
				scope.model.Country = scope.profileData['country']
				scope.model.CountryCode = scope.profileData['countryCode'];
				scope.model.fromPhoneNumber = scope.profileData['fromPhoneNumber'];
				if (scope.model.fromPhoneNumber == null || scope.model.fromPhoneNumber == undefined || scope.model.fromPhoneNumber == "") {
					this.fetchTwilioNumbers = true;
				}
				else {
					this.fetchTwilioNumbers = false;
				}
				if (this.profileData.countryCode == 'ZA' || this.profileData.countryCode == 'GB' || this.profileData.countryCode == 'AU' || this.profileData.countryCode == 'UK') {
					this.generalSettingForm.controls.AlphaNumericSenderId.enable();
					this.isShowAlphaNumeric = true;
				} else {
					this.generalSettingForm.controls.AlphaNumericSenderId.disable();
					this.isShowAlphaNumeric = false;
				}
				this.model.alphaNumericSenderId = this.profileData['alphanumericsenderid'];
				scope.cdr.markForCheck();
				if (scope.profileData['logoURL'] != null
					&& scope.profileData['logoURL'].length > 0) {
					var preview = document.querySelector('.businessLogoImg');
					scope.oldLogo = scope.profileData['logoURL']
					preview['style'].backgroundImage = 'url(' + scope.profileData['logoURL'] + '?lastmod=' + Math.random() + ')';
					scope.model.logo = scope.profileData['logoURL'];
					document.querySelector('.business-logo-cancel')['style'].display = 'flex';
					scope.cdr.markForCheck();
				}
				var input = document.querySelector("#generalSettingInput");
				var errorMsg = document.querySelector("#error-msg-phone-general");
				var validMsg = document.querySelector("#valid-msg-phone-general");
				if (scope.profileData['phoneNumber'] != null && scope.profileData['phoneNumber'].length > 0) {
					let counryCode = scope.profileData['countryCode'];
					// scope.step1Model.PhoneNumber = scope.profileData['phoneNumber'];
					var input = document.querySelector("#generalSettingInput");
					// input['value'] = scope.step1Model.PhoneNumber
					phoneTextCustomCountry(input, errorMsg, validMsg, scope, counryCode)
				} else {
					let ip = localStorage.getItem('IpAddress');
					phoneText(ip, input, errorMsg, validMsg, scope);
				}
				scope.cdr.markForCheck();
				if (this.profileCountry != "IN") {
					this.GetActivePhoneNumberList(this.fetchTwilioNumbers);
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					scope.pageService.showError('Oops!!!', err);
					scope.pageService.hideLoader()
					scope.router.navigateByUrl('/dashboard');
					if (err.toLocaleLowerCase() == environment.sessionTimeOutMsg || err.toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
			})
	}

	generteShortName() {
		this.model.shortName = this.model.companyName.replace(/ /g, '-').replace(/[&\/\\#,+()$~%.'":*?<>{}@]/g, '-').toLowerCase();
		this.cdr.markForCheck();
		this.validateUrl();
	}

	validateUrl() {
		var scope = this;
		var scope = this;
		scope.rightIcon = false;
		scope.wrongIcon = false;

		if (scope.model.shortName.length <= 0) {
			return;
		}
		if (scope.model.shortName == scope.profileData['shortName']) {
			return;
		}
		if (scope.shortUrlRegEx.test(scope.model.shortName)) {
			scope.wrongIcon = true;
			return;
		}
		scope.loadText = true;
		if (scope.model.shortName.includes(' ')) {
			scope.loadText = false;
			scope.wrongIcon = true;
			return;
		}
		scope.loadText = true;
		let accessKey = localStorage.getItem(environment.authTokenKey)
		scope.http.get(environment.apiPath + 'ValidateShortname?ShortName=' + scope.model.shortName
			+ '&Key=' + accessKey)
			.subscribe((res) => {
				scope.loadText = false;;
				if (res['success']) {
					scope.rightIcon = true;
				} else {
					scope.wrongIcon = true;
				}
				scope.cdr.markForCheck()
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	onSubmit() {
		this.AddOrClearTwilioNumberValidation();
		var scope = this;
		scope.submittedform = true;
		scope.timezoneReq = false;
		if (scope.generalSettingForm.invalid) {
			if (scope.model.timeZone == null ||
				scope.model.timeZone.length == 0) {
				scope.timezoneReq = true;
			}
			return;
		}
		if (!document.querySelector("#error-msg-phone-general").classList.contains('hide')) {
			return;
		}
		if (scope.imageError == true) {
			return;
		}
		if (scope.wrongIcon) {
			return;
		}
		// scope.loading = true;
		scope.callUpdateSettingApi();
	}
	onFilesAdded(event) {
		var scope = this;
		scope.imageError = false;
		var preview = document.querySelector('.businessLogoImg');
		// var file  = document.querySelector('input[type=file].fileInput')['files'][0];
		var reader = new FileReader();
		reader.addEventListener("load", function (resp) {
			preview['style'].backgroundImage = 'url(' + resp.currentTarget['result'] + ')';
			scope.model.logo = resp.currentTarget['result'];
			document.querySelector('.business-logo-cancel')['style'].display = 'flex';
		}, false);
		if (event.target.files[0]) {
			if (/\.(jpe?g|png|gif)$/i.test(event.target.files[0].name)) {
				if (event.target.files[0].size <= 1000000) {
					reader.readAsDataURL(event.target.files[0]);
					scope.imageData = event.target.files[0];
				} else {
					scope.imageError = true;
					// alert('image size exceeded')
				}
			} else {
				alert('Invalid Image')
			}
		}
	}

	//Delete Image
	deleteImg() {
		var scope = this;
		scope.deletedImageArr.push(scope.model.logo);
		// if (scope.model.logo.includes(environment.imagePath)) {
		//   let data = scope.model.logo.replace(environment.imagePath, './images/')
		// }
		var preview = document.querySelector('.businessLogoImg');
		preview['style'].backgroundImage = 'url(/assets/media/users/default.jpg)';
		scope.model.logo = '';
		document.querySelector('.business-logo-cancel')['style'].display = 'none';
	}

	callUpdateSettingApi() {
		var scope = this;
		scope.loading = true;
		if (scope.imageData && scope.imageData['name'] != null && scope.imageData['name'] != undefined) {
			if (scope.deletedImageArr.length > 0) {
				scope.deleteImageApi('callUpload');
			} else {
				//uppload image api
				scope.uploadImageApi();
			}
		} else {
			if (scope.deletedImageArr.length > 0) {
				scope.deleteImageApi('');
			} else {
				let finalData = {
					Key: scope.accessKey,
					ProfileId: scope.profileId,
					CompanyName: scope.model.companyName,
					PersonName: scope.model.conatctPersonName,
					Email: scope.model.email,
					ShortName: scope.model.shortName,
					Industry: scope.model.industry,
					PhoneNumber: scope.model.phoneNumber,
					LogoURL: scope.model.logo,
					Country: scope.model.Country,
					CountryCode: scope.model.CountryCode,
					//alphanumericsenderid: this.generalSettingForm.controls.AlphaNumericSenderId.value,
					alphanumericsenderid: null,
					TimeZone: document.querySelector('.timeZone').children[0]['value'],
					fromPhoneNumber: scope.model.fromPhoneNumber
				}
				scope.updateCompamySettings(finalData)
			}

		}

	}

	deleteImageApi(target) {
		var scope = this;
		let formData = new FormData();
		scope.http.get(environment.apiPath + 'DeleteImage?ImageUrl=' + scope.deletedImageArr[0])
			.subscribe((res) => {
				if (res['success'] == true) {
					let finalData = {
						Key: scope.accessKey,
						ProfileId: scope.profileId,
						CompanyName: scope.model.companyName,
						PersonName: scope.model.conatctPersonName,
						Email: scope.model.email,
						ShortName: scope.model.shortName,
						Industry: scope.model.industry,
						PhoneNumber: scope.model.phoneNumber,
						LogoURL: scope.model.logo,
						Country: scope.model.Country,
						CountryCode: scope.model.CountryCode,
						TimeZone: document.querySelector('.timeZone').children[0]['value'],
					}
					let intlObj = scope.dataService.getData('IntlObj');
					if (intlObj != undefined && intlObj['selectedCountryData'] != undefined) {
						finalData['CountryCode'] = intlObj['selectedCountryData'].iso2
					}
					else {
						finalData['CountryCode'] = scope.profileData['countryCode']
					}
					// scope.loading = false;
					scope.deletedImageArr = [];
					if (target == 'callUpload') {
						scope.uploadImageApi();
					} else {
						scope.updateCompamySettings(finalData)
					}
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {

					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	uploadImageApi() {
		var scope = this;
		let formData = new FormData();
		let data = {
			ResellerId: '',
			Source: 'logo',
			Sitename: '',
			Shorturl: scope.model.shortName,
			Imgbase64: scope.model.logo,
			Type: scope.imageData["name"].split('.')[1]
		};
		scope.http.post(environment.apiPath + 'ImageUpload', data)
			.subscribe((res) => {
				if (res['success'] == true) {
					let finalData = {
						Key: scope.accessKey,
						ProfileId: scope.profileId,
						CompanyName: scope.model.companyName,
						PersonName: scope.model.conatctPersonName,
						Email: scope.model.email,
						ShortName: scope.model.shortName,
						Industry: scope.model.industry,
						PhoneNumber: scope.model.phoneNumber,
						LogoURL: res["imgName"],
						Country: this.generalSettingForm.controls['Country'].value,
						TimeZone: document.querySelector('.timeZone').children[0]['value'],
						fromPhoneNumber: scope.model.fromPhoneNumber
					}
					let intlObj = scope.dataService.getData('IntlObj');
					if (intlObj != undefined && intlObj['selectedCountryData'] != undefined) {
						finalData['CountryCode'] = intlObj['selectedCountryData'].iso2
					}
					else {
						finalData['CountryCode'] = scope.profileData['countryCode']
					}
					scope.pageService.showSuccess('Saved', 'Logo saved sucessfully.');
					scope.imageData = {}
					scope.updateCompamySettings(finalData)
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	updateCompamySettings(finalData) {
		var scope = this;
		scope.http.post(environment.apiPath + 'UpdateProfile', finalData)
			.subscribe((resp) => {
				scope.loading = false;
				scope.cdr.markForCheck();
				if (resp['success']) {
					scope.pageService.showSuccess('Updated', 'Profile detail updated successfully.')
					this.pageService.createProfileJson(JSON.parse(localStorage.getItem('ProfileId'))).subscribe(x=>{
						this.pageService.refreshProfileDetails().then(x=>{
							scope.loadProfileDetails();
						});
					});

					// location.reload()
				} else {
					//err
					scope.pageService.showError('Oops!!!', resp['errorMsg'])
					if (resp['errorMsg'].toLocaleLowerCase() == environment.sessionTimeOutMsg || resp['errorMsg'].toLocaleLowerCase() == environment.userNotFound) {
						setTimeout(() => {
							scope.pageService.sessionExpired();
						}, environment.sessionTimeOut)
					}
				}
			}, (err) => {
				if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
					scope.loading = false;
					this.pageService.showError('Oops!', err.error.errorMsg);
				}
			})
	}

	/**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.generalSettingForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
	}

	isShowCountryCodeError = false;
	checkCountryCode(v) {
		if (v == '+') {
			this.isShowCountryCodeError = true;
		} else {
			this.isShowCountryCodeError = false;
		}
	}

	Reconnect() {
		this.router.navigate(['/review-platforms'])
	}


	GetActivePhoneNumberList(fetchTwilioNumbers: any) {
		if (fetchTwilioNumbers) {
			var scope = this;
			this.fetchLoading = true;
			scope.http.post(environment.apiPath + 'twilio/GetActivePhoneNumberList', {

				Key: localStorage.getItem(environment.authTokenKey),
				ProfileId: this.profileId
			})
				.subscribe((resp: any) => {
					this.fetchLoading = false;
					this.fetchTwilioNumbers = true;
					this.activePhoneNumberList = resp.activePhoneNumberList.map(x => { return x.phone_number; });
					if (this.activePhoneNumberList.filter(x => x == this.model.fromPhoneNumber).length <= 0) {
						this.model.fromPhoneNumber = null;
					}
				}, (err) => {
					this.fetchLoading = false;
					this.shouldShowSMSTwilioNumber = false;
					
					this.AddOrClearTwilioNumberValidation();
					if (err.statusText != "Incomplete twilio information.") {
						
						if(err.status==404){
							scope.loading = false;
						}
						else if (err.statusText != "Internal Server Error" && err.statusText != "Unknown Error" || err.status != 500 && err.status != 0) {
							scope.loading = false;
							this.pageService.showError('Oops!', err.error.errorMsg);
						}
						
					}
				})

		}
	}

	AddOrClearTwilioNumberValidation():void{
		if(this.shouldShowSMSTwilioNumber){
			for (const key in this.generalSettingForm.controls) {
				if(key == "fromPhoneNumber"){
				this.generalSettingForm.get(key).setValidators(Validators.required);
				this.generalSettingForm.get(key).updateValueAndValidity();
				}
			}
		}
		else
		{
			for (const key in this.generalSettingForm.controls) {
				if(key == "fromPhoneNumber"){
				this.generalSettingForm.get(key).clearValidators();
				this.generalSettingForm.get(key).updateValueAndValidity();
				}
			}
		}

	}

}

